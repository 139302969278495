import { gql } from '@apollo/client';
import {
  User,
  Waterpoint,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_WATERPOINTS = gql`
  query GetSavedWaterpoints($userId: ID) {
    user(id: $userId) {
      id
      savedWaterpoints {
        id
        name
        type
        elevation
        location
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export const SAVE_WATERPOINT_TO_USER = gql`
  mutation SaveWaterpointToUser($userId: ID!, $waterpointId: ID!) {
    user: saveWaterpointToUser(userId: $userId, waterpointId: $waterpointId) {
      id
      savedWaterpoints {
        id
        name
        type
        elevation
        location
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_WATERPOINT_FROM_USER = gql`
  mutation RemoveSaveWaterpointToUser($userId: ID!, $waterpointId: ID!) {
    user: removeSavedWaterpointFromUser(userId: $userId, waterpointId: $waterpointId) {
      id
      savedWaterpoints {
        id
        name
        type
        elevation
        location
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  waterpointId: string;
}

export interface WaterpointDatum {
  id: Waterpoint['id'];
  __typename: string;
  name: Waterpoint['name'];
  type: Waterpoint['type'];
  elevation: Waterpoint['elevation'];
  location: Waterpoint['location'];
  locationText: Waterpoint['locationText'];
  locationTextShort: Waterpoint['locationTextShort'];
  userNote: Waterpoint['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
  __typename: string;
    savedWaterpoints: (null | WaterpointDatum)[];
  };
}
