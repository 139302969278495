import React, {useEffect, useState} from 'react';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {useSavedParks} from '../../../../../queries/parks/useSavedParks';
import SignUpModal from '../../../SignUpModal';
import StarButton from '../../../StarButton';

interface Props {
  id: string;
  compact?: boolean;
}

const StarParkButton = ({id, compact}: Props) => {
  const user = useCurrentUser();
  const userId = user ? user._id : null;

  const {response: {loading, data}, saveParkToUser, removeSavedParkFromUser} = useSavedParks();

  const [isActive, setIsActive] = useState<boolean>(
    data && data.user ? Boolean(data.user.savedParks.find(n => n && n.id === id)) : false,
  );

  useEffect(() => {
    if (data && data.user) {
      setIsActive(Boolean(data.user.savedParks.find(n => n && n.id === id)));
    }
  }, [data, id]);

  const [isSignUpModal, setIsSignUpModal] = useState<boolean>(false);

  const openSignUpModal = () => setIsSignUpModal(true);
  const closeSignUpModal = () => setIsSignUpModal(false);

  const toggleActive = async () => {
    if (userId && data) {
      if (isActive) {
        setIsActive(false);
        removeSavedParkFromUser({variables: {userId,  parkId: id}});
      } else {
        setIsActive(true);
        saveParkToUser({variables: {userId,  parkId: id}});
      }
    } else {
      openSignUpModal();
    }
  };

  const signUpModal = isSignUpModal === false ? null : (
    <SignUpModal
      onCancel={closeSignUpModal}
    />
  );

  return (
    <div style={loading ? {visibility: 'hidden'} : undefined}>
      <StarButton
        starred={Boolean(isActive)}
        toggleStarred={toggleActive}
        compact={compact}
      />
      {signUpModal}
    </div>
  );
};

export default StarParkButton;
