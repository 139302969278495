import {Point} from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';
import { getPadding } from '../components/template/globalMap/map';
import { navHeight } from '../styling/Grid';
import { Coordinate } from '../types/graphQLTypes';
import { mobileSize } from '../Utils';
import useMapContext from './useMapContext';

const useConstrainedMapBbox = () => {
  const mapContext = useMapContext();
  const [bbox, setBbox] = useState<[Coordinate, Coordinate]>([[0, 0], [0, 0]]);

  const getBbox = useCallback(() => {
    if (mapContext.intialized) {
      let padding: {top: number, left: number, bottom: number, right: number};
      if (window.innerWidth <= mobileSize) {
        padding = {
          top: navHeight,
          bottom: navHeight,
          left: 0,
          right: 0,
        };
      } else {
        padding = {
          ...getPadding(),
          top: navHeight,
          bottom: navHeight,
        };
      }
      const {left, top, bottom, right} = padding;
      const bottomLeft = new Point(left, window.innerHeight - bottom);
      const upperRight = new Point(window.innerWidth - right, top);
      const bottomLeftLatLng = mapContext.map.unproject(bottomLeft);
      const upperRightLatLng = mapContext.map.unproject(upperRight);
      if (bottomLeftLatLng.lat && bottomLeftLatLng.lng && upperRightLatLng.lat && upperRightLatLng.lng) {
        setBbox([
          [bottomLeftLatLng.lng, bottomLeftLatLng.lat],
          [upperRightLatLng.lng, upperRightLatLng.lat],
        ]);
      }
    }
  }, [mapContext]);

  useEffect(() => getBbox(), [getBbox]);

  return {bbox, refreshBbox: getBbox};
};

export default useConstrainedMapBbox;
