import upperFirst from 'lodash/upperFirst';
import React from 'react';
import useFluent from '../../../hooks/useFluent';
import {useBasicTrailDetail} from '../../../queries/trails/useBasicTrailDetail';
import {CoreItem} from '../../../types/itemTypes';
import ExploreNearby from '../../sharedComponents/detailComponents/exploreNearby';
import GettingThere, { Via } from '../../sharedComponents/detailComponents/gettingThere';
import ReportsAndReviews from '../../sharedComponents/detailComponents/ReportsAndReviews';
import Weather from '../../sharedComponents/detailComponents/weather';

interface Props {
  id: string;
}

const Content = (props: Props) => {
  const  {id} = props;
  const {data} = useBasicTrailDetail(id);

  const getString = useFluent();

  if (data && data.trail) {
    const {type, center, locationTextShort, roadAccessPoint} = data.trail;
    const stateAbbr = locationTextShort && locationTextShort.length >= 2 ? locationTextShort.slice(0, 2) : null;
    const formattedType = upperFirst(getString('global-formatted-trail-type', {type}));
    const name = data.trail.name ? data.trail.name : formattedType;
    let via: Via;
    if (roadAccessPoint) {
      via = Via.Driving;
    } else {
      via = Via.Trails;
    }
    return (
      <>
        <Weather
          forecastTabs={[
            {title: getString('weather-forecast-forecast'), location: center},
          ]}
          snowReport={stateAbbr ? {location: center, stateAbbr} : undefined}
        />
        <GettingThere
          via={via}
          name={name}
          location={roadAccessPoint ? roadAccessPoint : center}
        />
        <ExploreNearby
          location={roadAccessPoint ? roadAccessPoint : center}
          name={name}
          id={id}
        />
        <ReportsAndReviews
          id={id}
          name={name}
          item={CoreItem.trail}
        />
      </>
    );
  }

  return null;
};

export default Content;
