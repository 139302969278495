import { useContext } from 'react';
import {
  AppLocalizationAndBundleContext,
} from '../contextProviders/getFluentLocalizationContext';

export type GetString = (arg1?: any, arg2?: any, arg3?: any) => string;

const useFluent = () => {
  const {localization} = useContext(AppLocalizationAndBundleContext);
  const getFluentString: GetString = (...args: [any, any, any]) => localization.getString(...args);
  return getFluentString;
};

export default useFluent;
