import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import useFluent from '../../../hooks/useFluent';
import { CoreItem } from '../../../types/itemTypes';
import DetailSegment, {Panel} from './DetailSegment';
import LatestWilderlistReports from './latestWilderlistReports';

interface Props {
  id: string;
  name: string;
  item: CoreItem;
}

const TripsNotesAndReports = (props: Props) => {
  const {id, name, item} = props;
  const getString = useFluent();
  const panels: Panel[] = [
    {
      title: getString('trip-report-latest-title'),
      dontShowTitle: true,
      reactNode: <LatestWilderlistReports id={id} name={name} type={item} />,
      renderHiddenContent: true,
    },
  ];

  return (
    <DetailSegment
      title={getString('trip-report-latest-title')}
      icon={faCommentDots}
      panels={panels}
      panelId={'tripsNotesAndReportsPanelId'}
    />
  );
};

export default TripsNotesAndReports;
