import {
  faBolt,
  faCampground,
  faDollarSign,
  faFire,
  faShower,
  faTint,
  faToilet,
  faUsers,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { GetString } from '../../../../hooks/useFluent';
import { Campsite } from '../../../../types/graphQLTypes';
import { Detail } from '../../../sharedComponents/detailComponents/header/SimpleHeader';

interface Input {
  campsite: {
    electricity: Campsite['electricity'];
    toilets: Campsite['toilets'];
    drinking_water: Campsite['drinking_water'];
    showers: Campsite['showers'];
    fee: Campsite['fee'];
    tents: Campsite['tents'];
    capacity: Campsite['capacity'];
    internet_access: Campsite['internet_access'];
    fire: Campsite['fire'];
    maxtents: Campsite['maxtents'];
  };
  getString: GetString;
}

const informationDetails = ({ campsite, getString}: Input) => {
  const information: Detail[] = [];
  if (campsite.fee !== null) {
    information.push({
      text: getString('campsite-detail-formatted-required-fee', {
        value: campsite.fee.toString(),
      }),
      icon: faDollarSign,
    });
  }
  if (campsite.tents !== null) {
    information.push({
      text: getString('campsite-detail-formatted-allows-tents', {
        value: campsite.tents.toString(),
      }),
      icon: faCampground,
    });
  }
  if (campsite.capacity !== null) {
    information.push({
      text: getString('campsite-detail-formatted-max-capacity', {
        value: campsite.capacity.toString(),
      }),
      icon: faUsers,
    });
  }
  if (campsite.maxtents !== null) {
    information.push({
      text: getString('campsite-detail-formatted-max-tents', {
        value: campsite.maxtents.toString(),
      }),
      icon: faCampground,
    });
  }
  if (campsite.electricity !== null) {
    information.push({
      text: getString('campsite-detail-formatted-electricity', {
        value: campsite.electricity.toString(),
      }),
      icon: faBolt,
      faded: !campsite.electricity,
    });
  }
  if (campsite.toilets !== null) {
    information.push({
      text: getString('campsite-detail-formatted-toilets', {
        value: campsite.toilets.toString(),
      }),
      icon: faToilet,
      faded: !campsite.toilets,
    });
  }
  if (campsite.drinking_water !== null) {
    information.push({
      text: getString('campsite-detail-formatted-drinking-water', {
        value: campsite.drinking_water.toString(),
      }),
      icon: faTint,
      faded: !campsite.drinking_water,
    });
  }
  if (campsite.showers !== null) {
    information.push({
      text: getString('campsite-detail-formatted-showers', {
        value: campsite.showers.toString(),
      }),
      icon: faShower,
      faded: !campsite.showers,
    });
  }
  if (campsite.internet_access !== null) {
    information.push({
      text: getString('campsite-detail-formatted-internet-access', {
        value: campsite.internet_access.toString(),
      }),
      icon: faWifi,
      faded: !campsite.internet_access,
    });
  }
  if (campsite.fire !== null) {
    information.push({
      text: getString('campsite-detail-formatted-allows-fires', {
        value: campsite.fire.toString(),
      }),
      icon: faFire,
      faded: !campsite.fire,
    });
  }
  return information;
};

export default informationDetails;
