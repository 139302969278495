import { CONTENT_BODY_ID } from '../Grid';

const bounceContent = () => {
  const contentBody = document.getElementById(CONTENT_BODY_ID);
  if (contentBody) {
    contentBody.classList.add('bounce-animate');
    setTimeout(() => {
      contentBody.classList.remove('bounce-animate');
    }, 400);
  }
};

export default bounceContent;
