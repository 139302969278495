import React from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../../../hooks/useFluent';
import { AddEditVariables, useCommentsFor } from '../../../../queries/comments/useCommentsFor';
import { lightBaseColor, PaddedSection, PlaceholderText } from '../../../../styling/styleUtils';
import { AuxiliaryItem, CoreItem } from '../../../../types/itemTypes';
import LoadingSimple from '../../LoadingSimple';
import AddEditComment from './AddEditComment';
import AverageRating from './AverageRating';
import Comment from './Comment';

const Root = styled.div`
  position: relative;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const EmptyContainer = styled.div`
  padding: 1rem 0.5rem;
  text-align: center;
  color: ${lightBaseColor};
  font-style: italic;
  font-size: 0.875rem;
`;

interface Props {
  forId: string;
  forType: CoreItem | AuxiliaryItem;
}

const Comments = ({forId, forType}: Props) => {
  const {
    response: {loading, error, data},
    addEditComment: addEditCommentMutation,
    deleteComment,
  } = useCommentsFor({forId, forType});
  const addEditComment = (variables: AddEditVariables) => addEditCommentMutation({variables});
  const getString = useFluent();
  let output: React.ReactElement<any> | null;
  if (loading) {
    output = (
      <LoadingContainer>
        <LoadingSimple />
      </LoadingContainer>
    );
  } else if (error) {
    return (
      <PaddedSection>
        <PlaceholderText>
          {getString('global-error-retrieving-data')}
        </PlaceholderText>
      </PaddedSection>
    );
  } else if (data) {
    let totalRatingScore: number = 0;
    let totalRatingCount: number = 0;
    const comments = data.comments.map(comment => {
      if (comment.rating) {
        totalRatingScore += comment.rating;
        totalRatingCount++;
      }
      return (
        <Comment
          key={comment.id}
          id={comment.id}
          author={comment.author}
          created={comment.created}
          edited={comment.edited}
          forId={comment.forId}
          forType={comment.forType}
          comment={comment.comment}
          rating={comment.rating}
          user={comment.user}
          deleteComment={() => deleteComment({variables: {id: comment.id}})}
        />
      );
    });
    if (comments.length) {
      const averageRating = totalRatingCount && totalRatingScore
        ? (
          <AverageRating
            totalRatingCount={totalRatingCount}
            totalRatingScore={totalRatingScore}
          />
        ) : null;
      output = (
        <div>
          {averageRating}
          {comments}
        </div>
      );
    } else {
      output = (
        <PaddedSection>
          <EmptyContainer>
            {getString('global-text-value-no-reviews')}
          </EmptyContainer>
        </PaddedSection>
      );
    }
  } else {
    output = null;
  }
  return (
    <Root>
      <AddEditComment
        forId={forId}
        forType={forType}
        addEditComment={addEditComment}
      />
      {output}
    </Root>
  );
};

export default Comments;
