import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';

export const GET_TRAIL_NOTE = gql`
  query getTrailNote($userId: ID, $trailId: ID) {
    user(id: $userId) {
      id
      trailNote(trailId: $trailId) {
        id
        text
      }
    }
  }
`;

export interface QuerySuccessResponse {
  user: null | {
    id: User['name'];
    __typename: string;
    trailNote: null | {
      id: string;
      __typename: string;
      text: string;
    }
  };
}

export interface QueryVariables {
  userId: string | null;
  trailId: string | null;
}

export const ADD_TRAIL_NOTE = gql`
  mutation($userId: ID!, $trailId: ID!, $text: String!) {
    user: addTrailNote(
      userId: $userId,
      trailId: $trailId,
      text: $text
    ) {
      id
      trailNote(trailId: $trailId) {
        id
        text
      }
    }
  }
`;

export const EDIT_TRAIL_NOTE = gql`
  mutation($userId: ID!, $trailId: ID!, $text: String!) {
    user: editTrailNote(
      userId: $userId,
      trailId: $trailId,
      text: $text
    ) {
      id
      trailNote(trailId: $trailId) {
        id
        text
      }
    }
  }
`;

export interface TrailNoteSuccess {
  user: {
    id: User['id'];
    trailNote: User['trailNote'];
  };
}

export interface Variables {
  userId: string;
  trailId: string;
  text: string;
}
