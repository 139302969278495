import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFluent from '../../../../hooks/useFluent';
import { AddEditVariables } from '../../../../queries/comments/useCommentsFor';
import {
  ButtonPrimary,
  PaddedSection,
  TextareaDotted,
} from '../../../../styling/styleUtils';
import SignUpModal from '../../SignUpModal';
import StarRating from './StarRating';

const Root = styled(PaddedSection)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
`;

const Textarea = styled(TextareaDotted)`
  min-height: 1rem;
  transition: min-height .2s ease-in-out;

  &:focus,
  &:not(:placeholder-shown) {
    min-height: 5rem;
  }
`;

const SubmitButtonContainer = styled.div`
  overflow: hidden;
  transition: all .2s ease-in-out;
`;

interface Props {
  addEditComment: (variables: AddEditVariables) => void;
  initial?: {
    id: string,
    comment: string,
    rating: number | null,
    author: string,
  };
  forId: string;
  forType: string;
}

const AddEditComment = (props: Props) => {
  const {
    addEditComment, initial, forId, forType,
  } = props;
  const currentUser = useCurrentUser();
  const getString = useFluent();

  const [comment, setComment] = useState<string>(initial ? initial.comment : '');
  const [rating, setRating] = useState<number>(initial && initial.rating ? initial.rating : 0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const openModal = () => {
    const textareaNode: HTMLTextAreaElement | null = document.querySelector(':focus');
    if (textareaNode) {
      textareaNode.blur();
    }
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  const onSubmit = () => {
    if (currentUser) {
      let author = initial ? initial.author : null;
      if (!author && currentUser) {
        author = currentUser._id;
      }
      if (author && (rating || comment)) {
        addEditComment({
          id: initial ? initial.id : null,
          author,
          date: new Date(),
          forId,
          forType,
          comment,
          rating,
        });
        setComment('');
        setRating(0);
      }
    }
  };

  const signInModal = !currentUser && modalOpen ? (
    <SignUpModal
      onCancel={closeModal}
    />
  ) : null;

  return (
    <Root>
      <div>
        <Textarea
          placeholder='Add a public review'
          value={comment}
          onChange={e => setComment(e.target.value)}
          rows={1}
          onClick={!currentUser ? openModal : undefined}
        />
        <SubmitButtonContainer
          style={{
            height: !rating && !comment ? 0 : '2rem',
            paddingTop: !rating && !comment ? 0 : '.5rem',
          }}
        >
          <ButtonPrimary
            onClick={onSubmit}
            disabled={!rating && !comment}
          >
            {
              !initial
                ? getString('global-text-value-submit-review')
                : getString('global-text-value-save-changes')
            }
          </ButtonPrimary>
        </SubmitButtonContainer>
      </div>
      <div>
        <StarRating
          rating={rating}
          setRating={setRating}
          onNotLoggedInClick={openModal}
        />
      </div>
      {signInModal}
    </Root>
  );
};

export default AddEditComment;
