import React from 'react';
import styled from 'styled-components/macro';
import { useOfflineContext } from '../../../../contextProviders/OfflineContext';
import useFluent from '../../../../hooks/useFluent';
import { useBasicListDetails } from '../../../../queries/lists/useBasicListDetails';
import useQueryParams from '../../../../routing/useQueryParams';
import { addQueryParam } from '../../../../routing/Utils';
import { IconContainer, lightBaseColor, lightBorderColor, primaryColor, ShadowButtonLink, successColor } from '../../../../styling/styleUtils';
import SimpleTextLoading from '../../SimpleTextLoading';
import { trailDefaultSvg } from '../../svgIcons';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

const CompletedText = styled.span`
  color: ${successColor};
`;
const IncompleteText = styled.em`
  color: ${lightBaseColor};
`;

const LinkBase = styled(ShadowButtonLink)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkHighlighted = styled(LinkBase)`
  border-right: solid 1px ${lightBorderColor};
  background-color: ${primaryColor};
  color: #fff;

  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }

  ${IconContainer} {
    svg {
      width: 1rem;

      .fill-path {
        fill: #fff;
      }
      .stroke-path {
        fill: ${primaryColor};
      }
    }
  }
`;

interface Props {
  id: string;
}

const ListProgressButton = ({id}: Props) => {
  const {loading, data} = useBasicListDetails(id, null);
  const { view } = useQueryParams();
  const getString = useFluent();
  const {offline} = useOfflineContext();

  let percentCompleteText: React.ReactElement<any> | null;
  if (loading) {
    percentCompleteText = <SimpleTextLoading />;
  } else if (data && data.peakList) {
    const value = (
      data.peakList.numCompletedTrips /
      (data.peakList.numTrails + data.peakList.numCampsites + data.peakList.numMountains)
      * 100
    ).toFixed(2);
    const Text = data.peakList.numCompletedTrips ? CompletedText : IncompleteText;
    percentCompleteText = (
      <Text
        dangerouslySetInnerHTML={{__html: getString('park-trail-progress', {value})}}
      />
    );
  } else {
    percentCompleteText = null;
  }

  const buttonLink = view && view === 'list'
    ? window.location.pathname
    : addQueryParam(window.location.pathname, { view: 'list' });

  const ButtonRoot = view && view === 'list'
    ? LinkHighlighted
    : LinkBase;

  const viewTrailsButton = offline ? null : (
    <ButtonRoot to={buttonLink}>
      <IconContainer
        $color={primaryColor}
        dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
      />
      {getString('park-view-progress')}
    </ButtonRoot>
  );

  return (
      <Root>
        <small>
          {percentCompleteText}
        </small>
        {viewTrailsButton}
      </Root>
  );
};

export default ListProgressButton;
