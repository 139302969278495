import React from 'react';
import styled from 'styled-components';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { navHeight } from '../../../../styling/Grid';
import { mobileSize } from '../../../../Utils';
import { MapStyle } from '../map';
import Layers from './layers';
import { ListLegendRoot } from './ListLegend';
import Toggle3DModeButton from './Toggle3dModeButton';
import YourLocationButton from './YourLocation';
import ZoomControls from './ZoomControls';

const Root = styled.div<{$loggedIn: boolean}>`
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;

  * {
    pointer-events: all;
  }

  @media(max-width: ${mobileSize}px) {
    bottom: auto;
    top: ${navHeight * 1.2}px;
    right: 0.6rem;
  }
`;

interface Props {
  mapStyle: MapStyle;
  setMapStyle: (style: MapStyle) => void;
}

const Controls = (props: Props) => {
  const user = useCurrentUser();
  const windowWidth = useWindowWidth();

  if (windowWidth > mobileSize) {
    return (
      <Root $loggedIn={Boolean(user)}>
        <Toggle3DModeButton />
        <ZoomControls />
        <YourLocationButton />
        <Layers
          mapStyle={props.mapStyle}
          setMapStyle={props.setMapStyle}
        />
        <ListLegendRoot />
      </Root>
    );
  } else {
    return (
      <Root $loggedIn={Boolean(user)}>
        <Toggle3DModeButton />
        <Layers
          mapStyle={props.mapStyle}
          setMapStyle={props.setMapStyle}
        />
        <ZoomControls />
        <ListLegendRoot />
        <YourLocationButton />
      </Root>
    );
  }
};

export default Controls;
