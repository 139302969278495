import { Coordinate } from '../../../../../../types/graphQLTypes';
import {LineString} from './';

export interface WorkerMessageResponse {
  line?: LineString;
  error?: string;
  message?: string;
  loading?: boolean;
  key?: string;
}

const worker = new Worker(process.env.PUBLIC_URL + '/core-worker.js');

export const buildNetwork = (from: Coordinate) => {
  worker.postMessage({from});
};
interface FindRouteInput {
  from: Coordinate;
  to: Coordinate;
  networkCenter?: Coordinate;
  // Wait for network to be found
  wait?: boolean;
  key?: string;
}

export const findRoute = ({ from, to, networkCenter, key, wait }: FindRouteInput) => {
  worker.postMessage({ from, to, networkCenter, key, wait});
};

export default worker;
