import { faRoute } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import useFluent from '../../../../hooks/useFluent';
import { Coordinate } from '../../../../types/graphQLTypes';
import DetailSegment, { Panel } from '../DetailSegment';
import PanelDirections from './driving/PanelDirections';
import TrailheadsWithRoutes from './trailheads/TrailheadsWithRoutes';

export enum Via {
  Trails = 'trails',
  Driving = 'driving',
}

interface Props {
  name: string;
  location: Coordinate;
  via: Via;
}

const GettingThere = (props: Props) => {
  const {
    via, name, location,
  } = props;
  const getString = useFluent();
  const panels: Panel[] = [];
  if (via === Via.Trails) {
    panels.push({
      title: getString('global-text-value-directions'),
      dontShowTitle: true,
      reactNode: (
        <TrailheadsWithRoutes
          destination={location}
          destinationName={name}
        />
      ),
    });
  }
  if (via === Via.Driving) {
    panels.push({
      title: getString('global-text-value-directions'),
      dontShowTitle: true,
      reactNode: (
        <PanelDirections
          destination={location}
          destinationName={name}
        />
      ),
    });
  }

  return (
    <DetailSegment
      title={getString('header-text-menu-item-getting-there')}
      icon={faRoute}
      panels={panels}
      panelId={'gettingTherePanelId' + via + name}
    />
  );
};

export default GettingThere;
