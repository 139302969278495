import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_TRAIL_NOTE,
  EDIT_TRAIL_NOTE,
  GET_TRAIL_NOTE,
  QuerySuccessResponse,
  QueryVariables,
  TrailNoteSuccess,
  Variables,
} from './query';

export const useTrailNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_TRAIL_NOTE, {variables});

export const useAddTrailNote = () => {
  const [addTrailNote] = useMutation<TrailNoteSuccess, Variables>(ADD_TRAIL_NOTE);
  return addTrailNote;
};
export const useEditTrailNote = () => {
  const [editTrailNote] = useMutation<TrailNoteSuccess, Variables>(EDIT_TRAIL_NOTE);
  return editTrailNote;
};
