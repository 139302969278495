import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import useFluent from '../../../../hooks/useFluent';
import { AuxiliaryItem, CoreItem } from '../../../../types/itemTypes';
import DetailSegment, { Panel } from '../DetailSegment';
import Comments from './Comments';

interface Props {
  forId: string;
  name: string;
  forType: CoreItem | AuxiliaryItem;
}

const CommentsPanel = (props: Props) => {
  const {forId, forType} = props;
  const getString = useFluent();
  const panels: Panel[] = [
    {
      title: getString('trip-report-latest-title'),
      dontShowTitle: true,
      reactNode: <Comments forId={forId} forType={forType} />,
    },
  ];

  return (
    <DetailSegment
      title={getString('global-text-value-reviews')}
      icon={faCommentDots}
      panels={panels}
      panelId={'commentsPanelId'}
    />
  );
};

export default CommentsPanel;
