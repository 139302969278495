import { faMapSigns, faSort } from '@fortawesome/free-solid-svg-icons';
import orderBy from 'lodash/orderBy';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components/macro';
import NoSavedPlacesImage from '../../assets/images/empty-states/saved_places.svg';
import useFluent from '../../hooks/useFluent';
import useMapContext from '../../hooks/useMapContext';
import { useSavedCampsites } from '../../queries/campsites/useSavedCampsites';
import { CampsiteDatum } from '../../queries/campsites/useSavedCampsites/query';
import { useSavedMountains } from '../../queries/mountains/useSavedMountains';
import { MountainDatum } from '../../queries/mountains/useSavedMountains/query';
import { useSavedParks } from '../../queries/parks/useSavedParks';
import { ParkDatum } from '../../queries/parks/useSavedParks/query';
import { useSavedTrailheads } from '../../queries/trails/useSavedTrailheads';
import { TrailheadDatum } from '../../queries/trails/useSavedTrailheads/query';
import { useSavedTrails } from '../../queries/trails/useSavedTrails';
import { TrailDatum } from '../../queries/trails/useSavedTrails/query';
import { useSavedViewpoints } from '../../queries/viewpoints/useSavedViewpoints';
import { ViewpointDatum } from '../../queries/viewpoints/useSavedViewpoints/query';
import { useSavedWaterpoints } from '../../queries/waterpoints/useSavedWaterpoints';
import { WaterpointDatum } from '../../queries/waterpoints/useSavedWaterpoints/query';
import {
  campsiteDetailLink,
  mountainDetailLink,
  parkDetailLink,
  trailDetailLink,
  trailheadDetailLink,
  viewpointDetailLink,
  waterpointDetailLink,
} from '../../routing/Utils';
import {
  BasicContentContainer,
} from '../../styling/sharedContentStyles';
import {
  BasicIconInTextCompact,
  Label,
  PaddedSection,
  SelectBox,
} from '../../styling/styleUtils';
import { Coordinate, ParkingType } from '../../types/graphQLTypes';
import { AggregateItem, AuxiliaryItem, CoreItem } from '../../types/itemTypes';
import { slopeToSteepnessClass } from '../../utilities/trailUtils';
import DefaultError from '../sharedComponents/DefaultError';
import EmptyState from '../sharedComponents/EmptyState';
import { TypeProps as PlaceDatum } from '../sharedComponents/listComponents/ResultItem';
import Results from '../sharedComponents/listComponents/Results';
import LoadingSimple, { LoadingContainer } from '../sharedComponents/LoadingSimple';
import MapRenderProp, {
  CampsiteGeoDatum,
  MountainGeoDatum,
  TrailGeoDatum,
  TrailheadGeoDatum,
  ViewpointGeoDatum,
  WaterpointGeoDatum,
} from '../sharedComponents/MapRenderProp';
import StandardSearch from '../sharedComponents/StandardSearch';
import {
  mountainNeutralSvg,
  parkNeutralSvg,
  tentNeutralSvg,
  trailDefaultSvg,
  trailheadDefaultSvg,
  viewpointNeutralSvg,
  waterpointNeutralSvg,
} from '../sharedComponents/svgIcons';
import TagCheckbox from '../sharedComponents/TagCheckbox';

const FilterRoot = styled(PaddedSection)`
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-gap: 0.2rem;
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  margin-left: auto;
  margin-top: auto;
`;

enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

const lsKeySortDirection = 'localStorageKeySavedSortDirection';

const SavedPlaces = () => {
  const getString = useFluent();
  const {response: mountains} = useSavedMountains();
  const {response: campsites} = useSavedCampsites();
  const {response: trails} = useSavedTrails();
  const {response: trailheads} = useSavedTrailheads();
  const {response: viewpoints} = useSavedViewpoints();
  const {response: waterpoints} = useSavedWaterpoints();
  const {response: parks} = useSavedParks();
  const mapContext = useMapContext();

  const [search, setSearch] = useState<string>('');
  const [sortDirection, setSortDirectionState] = useState<SortDirection>(
    localStorage.getItem(lsKeySortDirection) === SortDirection.desc ? SortDirection.desc : SortDirection.asc,
  );
  const setSortDirection = (value: SortDirection) => {
    setSortDirectionState(value);
    localStorage.setItem(lsKeySortDirection, value);
  };
  const [showMountains, setShowMountains] = useState<boolean>(false);
  const [showParks, setShowParks] = useState<boolean>(false);
  const [showCampsites, setShowCampsites] = useState<boolean>(false);
  const [showViewpoints, setShowViewpoints] = useState<boolean>(false);
  const [showWaterpoints, setShowWaterpoints] = useState<boolean>(false);
  const [showTrailheads, setShowTrailheads] = useState<boolean>(false);
  const [showTrails, setShowTrails] = useState<boolean>(false);
  const totalVisibleTypes =
    Number(showMountains) +
    Number(showParks) +
    Number(showCampsites) +
    Number(showViewpoints) +
    Number(showWaterpoints) +
    Number(showTrailheads) +
    Number(showTrails);

  const onSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === SortDirection.asc) {
      setSortDirection(SortDirection.asc);
    } else {
      setSortDirection(SortDirection.desc);
    }
  };

  useEffect(() => () => {
    if (mapContext.intialized) {
      mapContext.clearMap();
    }
  }, [mapContext]);

  const loading = mountains.loading   ||
                  campsites.loading   ||
                  trails.loading      ||
                  trailheads.loading  ||
                  waterpoints.loading ||
                  parks.loading       ||
                  viewpoints.loading;
  let error: any | undefined;
  if (mountains.error) {
    error = mountains.error;
  } else if (campsites.error) {
    error = campsites.error;
  } else if (trailheads.error) {
    error = trails.error;
  } else if (trails.error) {
    error = trails.error;
  } else if (viewpoints.error) {
    error = trails.error;
  } else if (waterpoints.error) {
    error = trails.error;
  } else if (parks.error) {
    error = trails.error;
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSimple />
      </LoadingContainer>
    );
  } else if (
      mountains.data ||
      campsites.data ||
      trails.data ||
      trailheads.data ||
      viewpoints.data ||
      waterpoints.data
    ) {
    const mountainGeoPoints: MountainGeoDatum[] = [];
    const savedMountains: PlaceDatum[] = mountains.data
      && mountains.data.user
      && mountains.data.user.savedMountains
      && (showMountains || !totalVisibleTypes)
      ? mountains.data.user.savedMountains.filter(d => {
        const searchString = d
          ? `${d.name}${d.locationText}${d.locationTextShort}${d.elevation}`.toLowerCase()
          : '';
        return d && (!search || searchString.includes(search.toLowerCase()));
      }).map((d: MountainDatum) => {
        mountainGeoPoints.push({
          id: d.id,
          name: d.name,
          elevation: d.elevation,
          location: d.location,
        });
        return {
          id: d.id,
          title: d.name,
          url: mountainDetailLink(d.id),
          locationText: d.locationText,
          customIcon: true,
          icon: mountainNeutralSvg,
          type: CoreItem.mountain,
          elevation: d.elevation,
          location: d.location,
          distanceToCenter: 0,
          note: d.userNote,
        };
      }) : [];
    const campsiteGeoPoints: CampsiteGeoDatum[] = [];
    const savedCampsites: PlaceDatum[] = campsites.data
      && campsites.data.user
      && campsites.data.user.savedCampsites
      && (showCampsites || !totalVisibleTypes)
      ? campsites.data.user.savedCampsites.filter(d => {
        const searchString = d
          ? `${d.name}
              ${d.locationText}
              ${d.locationTextShort}
              ${getString('global-formatted-anything-type', { type: '' + d.type })}`
            .toLowerCase()
          : '';
        return d && (!search || searchString.includes(search.toLowerCase()));
      }).map((d: CampsiteDatum) => {
        const formattedType = upperFirst(getString('global-formatted-anything-type', { type: '' + d.type }));
        campsiteGeoPoints.push({
          id: d.id,
          name: d.name,
          type: d.type,
          location: d.location,
        });
        return {
          id: d.id,
          title: d.name ? d.name : formattedType,
          url: campsiteDetailLink(d.id),
          locationText: d.locationText,
          customIcon: true,
          icon: tentNeutralSvg,
          type: CoreItem.campsite,
          formattedType,
          location: d.location,
          distanceToCenter: 0,
          ownership: d.ownership,
          note: d.userNote,
        };
      }) : [];
    const trailheadGeoPoints: TrailheadGeoDatum[] = [];
    const savedTrailheads: PlaceDatum[] = trailheads.data
      && trailheads.data.user
      && trailheads.data.user.savedTrailheads
      && (showTrailheads || !totalVisibleTypes)
      ? trailheads.data.user.savedTrailheads.filter(d => {
        const searchString = d
          ? `${d.name}
              ${d.locationText}
              ${d.locationTextShort}
              trailhead`
            .toLowerCase()
          : '';
        return d && d.roadAccessPoint !== null &&
                typeof d.totalTrailMiles === 'number' &&
                (!search || searchString.includes(search.toLowerCase()));
      }).map((d: TrailheadDatum) => {
        const formattedType = upperFirst(getString('global-formatted-anything-type', { type: ParkingType.trailhead }));
        const location = d.roadAccessPoint as Coordinate;
        trailheadGeoPoints.push({
          id: d.id,
          name: d.name,
          location,
        });
        return {
          id: d.id,
          title: d.name ? d.name : formattedType,
          url: trailheadDetailLink(d.id),
          locationText: d.locationText,
          customIcon: true,
          icon: trailheadDefaultSvg,
          type: AuxiliaryItem.trailhead,
          formattedType,
          location,
          distanceToCenter: 0,
          totalTrailMiles: d.totalTrailMiles as number,
          note: d.userNote,
        };
      }) : [];
    const trailGeoLines: TrailGeoDatum[] = [];
    const savedTrails: PlaceDatum[] = trails.data
      && trails.data.user
      && trails.data.user.savedTrails
      && (showTrails || !totalVisibleTypes)
      ? trails.data.user.savedTrails.filter(d => {
        const searchString = d
          ? `${d.name}
              ${d.locationText}
              ${d.locationTextShort}
              ${getString('global-formatted-anything-type', { type: '' + d.type })}`
            .toLowerCase()
          : '';
        return d && (!search || searchString.includes(search.toLowerCase()));
      }).map((d: TrailDatum) => {
        if (d.type) {
          const formattedType = upperFirst(getString('global-formatted-anything-type', { type: '' + d.type }));
          const trailLength = d.trailLength ? d.trailLength : 0;
          const trailLengthText = trailLength < 0.1
            ? getString('distance-feet-formatted', { feet: Math.round(trailLength * 5280) }) // miles to feet conversion
            : getString('directions-driving-distance', { miles: parseFloat(trailLength.toFixed(1)) });
          trailGeoLines.push({
            id: d.id,
            name: d.name,
            type: d.type,
            line: d.line,
            trailLengthText,
          });
          const datum: PlaceDatum = {
            id: d.id,
            title: d.name ? d.name : formattedType,
            distanceToCenter: 0,
            url: trailDetailLink(d.id),
            locationText: d.locationText,
            customIcon: true,
            icon: trailDefaultSvg,
            type: CoreItem.trail,
            formattedType,
            trailLength: d.trailLength ? d.trailLength : 0,
            slopeText: d.avgSlope !== null
              ? `${upperFirst(slopeToSteepnessClass(d.avgSlope))}, ${parseFloat(d.avgSlope.toFixed(1))}°`
              : null,
            location: d.center,
            line: d.line,
            note: d.userNote,
          };
          return datum;
        } else {
          const datum: PlaceDatum = {
            id: d.id,
            title: d.name ? d.name : upperFirst(getString('global-formatted-anything-type', { type: '' + d.type })),
            type: AggregateItem.parentTrail,
            url: trailDetailLink(d.id),
            locationText: d.locationText,
            customIcon: false,
            icon: faMapSigns,
            numTrails: d.childrenCount,
            note: d.userNote,
          };
          return datum;
        }
      }) : [];
    const viewpointGeoPoints: ViewpointGeoDatum[] = [];
    const savedViewpoints: PlaceDatum[] = viewpoints.data
      && viewpoints.data.user
      && viewpoints.data.user.savedViewpoints
      && (showViewpoints || !totalVisibleTypes)
      ? viewpoints.data.user.savedViewpoints.filter(d => {
        const searchString = d
          ? `${d.name}${d.locationText}${d.locationTextShort}${d.elevation}`.toLowerCase()
          : '';
        return d && d.elevation !== null && (!search || searchString.includes(search.toLowerCase()));
      }).map((d: ViewpointDatum) => {
        viewpointGeoPoints.push({
          id: d.id,
          name: d.name ? d.name : 'Viewpoint',
          elevation: d.elevation ? d.elevation : 0,
          location: d.location,
        });
        return {
          id: d.id,
          title: d.name ? d.name : 'Viewpoint',
          url: viewpointDetailLink(d.id),
          locationText: d.locationText,
          customIcon: true,
          icon: viewpointNeutralSvg,
          type: AuxiliaryItem.viewpoint,
          elevation: d.elevation ? d.elevation : 0,
          location: d.location,
          distanceToCenter: 0,
          note: d.userNote,
        };
      }) : [];
    const waterpointGeoPoints: WaterpointGeoDatum[] = [];
    const savedWaterpoints: PlaceDatum[] = waterpoints.data
      && waterpoints.data.user
      && waterpoints.data.user.savedWaterpoints
      && (showWaterpoints || !totalVisibleTypes)
      ? waterpoints.data.user.savedWaterpoints.filter(d => {
        const searchString = d
          ? `${d.name}${d.locationText}${d.locationTextShort}`.toLowerCase()
          : '';
        return d && (!search || searchString.includes(search.toLowerCase()));
      }).map((d: WaterpointDatum) => {
        waterpointGeoPoints.push({
          id: d.id,
          name: d.name ? d.name : 'Waterpoint',
          location: d.location,
          type: d.type,
        });
        const formattedType = upperFirst(getString('global-formatted-water-type', { type: '' + d.type }));
        return {
          id: d.id,
          title: d.name ? d.name : 'Waterpoint',
          url: waterpointDetailLink(d.id),
          locationText: d.locationText,
          customIcon: true,
          icon: waterpointNeutralSvg,
          type: AuxiliaryItem.waterpoint,
          elevation: d.elevation ? d.elevation : 0,
          location: d.location,
          distanceToCenter: 0,
          formattedType,
          note: d.userNote,
        };
      }) : [];
    const savedParks: PlaceDatum[] = parks.data
      && parks.data.user
      && parks.data.user.savedParks
      && (showParks || !totalVisibleTypes)
      ? parks.data.user.savedParks.filter(d => {
        const searchString = d
          ? `${d.name}${d.locationText}${d.locationTextShort}`.toLowerCase()
          : '';
        return d && (!search || searchString.includes(search.toLowerCase()));
      }).map((d: ParkDatum) => {
        let formattedType: string;
        if (d.protectTitle) {
          formattedType = d.protectTitle;
        } else if (d.landType) {
          formattedType = d.landType;
        } else if (d.ownership) {
          formattedType = upperFirst(d.ownership) + ' Park';
        } else {
          formattedType = 'Park';
        }
        return {
          id: d.id,
          title: d.name,
          location: d.center,
          locationText: d.locationText,
          type: AuxiliaryItem.park,
          url: parkDetailLink(d.id),
          icon: parkNeutralSvg,
          customIcon: true,
          bbox: d.bbox,
          formattedType,
          numMountains: d.numMountains,
          trailMileage: d.trailMileage,
          numCampsites: Number(d.numCampsitesBackcountry) + Number(d.numCampsitesCar),
          note: d.userNote,
        };
      }) : [];

    const mergedPlaces: PlaceDatum[] = orderBy([
      ...savedParks,
      ...savedMountains,
      ...savedCampsites,
      ...savedTrailheads,
      ...savedTrails,
      ...savedViewpoints,
      ...savedWaterpoints,
    ], ['title'], ['asc']);

    if (sortDirection === SortDirection.asc) {
      mergedPlaces.reverse();
    }

    const hasMountains = mountains.data
      && mountains.data.user
      && mountains.data.user.savedMountains.length;
    const hasCampsites = campsites.data
      && campsites.data.user
      && campsites.data.user.savedCampsites.length;
    const hasTrailheads = trailheads.data
      && trailheads.data.user
      && trailheads.data.user.savedTrailheads.length;
    const hasTrails = trails.data
      && trails.data.user
      && trails.data.user.savedTrails.length;
    const hasViewpoints = viewpoints.data
      && viewpoints.data.user
      && viewpoints.data.user.savedViewpoints.length;
    const hasWaterpoints = waterpoints.data
      && waterpoints.data.user
      && waterpoints.data.user.savedWaterpoints.length;
    const hasParks = parks.data
      && parks.data.user
      && parks.data.user.savedParks.length;
    const toggleMountains = hasMountains &&
      (hasTrails || hasCampsites || hasTrailheads || hasViewpoints || hasWaterpoints || hasParks)
    ? (
      <TagCheckbox
        label={getString('global-text-value-mountains')}
        checked={showMountains}
        onChange={() => setShowMountains(!showMountains)}
        checkboxId={'checkbox-toggle-saved-mountains'}
      />
    ) : null;
    const toggleCampsites = hasCampsites &&
      (hasMountains || hasTrails || hasTrailheads || hasViewpoints || hasWaterpoints || hasParks)
    ? (
      <TagCheckbox
        label={getString('global-text-value-campsites')}
        checked={showCampsites}
        onChange={() => setShowCampsites(!showCampsites)}
        checkboxId={'checkbox-toggle-saved-campsites'}
      />
    ) : null;
    const toggleTrailheads = hasTrailheads &&
      (hasMountains || hasTrails || hasCampsites || hasViewpoints || hasWaterpoints || hasParks)
    ? (
      <TagCheckbox
        label={getString('global-text-value-trailheads')}
        checked={showTrailheads}
        onChange={() => setShowTrailheads(!showTrailheads)}
        checkboxId={'checkbox-toggle-saved-trailheads'}
      />
    ) : null;
    const toggleTrails = hasTrails &&
      (hasMountains || hasCampsites || hasTrailheads || hasViewpoints || hasWaterpoints || hasParks)
    ? (
      <TagCheckbox
        label={getString('global-text-value-trails')}
        checked={showTrails}
        onChange={() => setShowTrails(!showTrails)}
        checkboxId={'checkbox-toggle-saved-trails'}
      />
    ) : null;
    const toggleViewpoints = hasViewpoints &&
      (hasMountains || hasCampsites || hasTrailheads || hasTrails || hasWaterpoints || hasParks)
    ? (
      <TagCheckbox
        label={getString('global-text-value-viewpoints')}
        checked={showViewpoints}
        onChange={() => setShowViewpoints(!showViewpoints)}
        checkboxId={'checkbox-toggle-saved-viewpoints'}
      />
    ) : null;
    const toggleWaterpoints = hasWaterpoints &&
      (hasMountains || hasCampsites || hasTrailheads || hasTrails || hasViewpoints || hasParks)
    ? (
      <TagCheckbox
        label={getString('global-text-value-water')}
        checked={showWaterpoints}
        onChange={() => setShowWaterpoints(!showWaterpoints)}
        checkboxId={'checkbox-toggle-saved-waterpoints'}
      />
    ) : null;
    const toggleParks = hasParks &&
      (hasMountains || hasCampsites || hasTrailheads || hasTrails || hasViewpoints || hasWaterpoints)
    ? (
      <TagCheckbox
        label={getString('global-text-value-parks')}
        checked={showParks}
        onChange={() => setShowParks(!showParks)}
        checkboxId={'checkbox-toggle-saved-parks'}
      />
    ) : null;

    if (hasMountains || hasTrails || hasCampsites || hasTrailheads || hasViewpoints || hasWaterpoints || hasParks) {
      return (
        <>
          <Helmet>
            <title>{getString('meta-data-dashboard-places-title')}</title>
          </Helmet>
          <FilterRoot>
            <div>
              <Label><BasicIconInTextCompact icon={faSort} /> {getString('global-text-value-sort')}:</Label>
              <SelectBox onChange={onSortChange} value={sortDirection}>
                <option value={SortDirection.desc}>{getString('global-text-value-a-to-z')}</option>
                <option value={SortDirection.asc}>{getString('global-text-value-z-to-a')}</option>
              </SelectBox>
            </div>
            <CheckboxContainer>
              {toggleParks}
              {toggleMountains}
              {toggleCampsites}
              {toggleTrailheads}
              {toggleViewpoints}
              {toggleWaterpoints}
              {toggleTrails}
            </CheckboxContainer>
          </FilterRoot>
          <StandardSearch
            placeholder={getString('saved-search-saved-places')}
            setSearchQuery={setSearch}
            initialQuery={search}
            focusOnMount={false}
            noSearchIcon={true}
          />
          <BasicContentContainer style={{paddingTop: 0}}>
            <Results
              data={mergedPlaces}
              type={null}
              hideText={true}
            />
          </BasicContentContainer>
          <MapRenderProp
            id={'dashboard-saved-places-mountains-' + mountainGeoPoints.length +
                  '-campsites-' + campsiteGeoPoints.length +
                  '-trailheads-' + trailheadGeoPoints.length +
                  '-viewpoints-' + viewpointGeoPoints.length +
                  '-waterpoints-' + waterpointGeoPoints.length +
                  '-trails-' + trailGeoLines.length
              }
            mountains={mountainGeoPoints}
            campsites={campsiteGeoPoints}
            viewpoints={viewpointGeoPoints}
            waterpoints={waterpointGeoPoints}
            trailheads={trailheadGeoPoints}
            trails={trailGeoLines}
          />
        </>
      );
    }

  } else if (error) {
    return (
      <DefaultError
        error={error}
        offlineMessage={'Connect to the internet to sync your saved places.'}
      />
    );
  }

  return (
    <div>
      <Helmet>
        <title>{getString('meta-data-dashboard-places-title')}</title>
      </Helmet>
      <EmptyState
        title={getString('dashboard-empty-state-no-saved-places-title')}
        image={NoSavedPlacesImage}
      >
        {getString('dashboard-empty-state-no-saved-places-text')}
      </EmptyState>
      <MapRenderProp
        id={'dashboard-saved-places'}
      />
    </div>
  );
};

export default SavedPlaces;
