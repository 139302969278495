import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { GET_USERS_SAVED_TRAILS, MutationVariables, REMOVED_SAVED_TRAIL_FROM_USER, SAVE_TRAIL_TO_USER, SuccessResponse, Variables } from './query';

export const useSavedTrails = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_TRAILS, {variables: {userId}},
  );

  const [saveTrailToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_TRAIL_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_TRAILS, variables: {userId}}],
    });

  const [removeSavedTrailFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_TRAIL_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_TRAILS, variables: {userId}}],
    });

  return {response, saveTrailToUser, removeSavedTrailFromUser};
};
