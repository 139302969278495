import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  GET_USERS_SAVED_CAMPSITES,
  MutationVariables,
  REMOVED_SAVED_CAMPSITE_FROM_USER,
  SAVE_CAMPSITE_TO_USER,
  SuccessResponse,
  Variables,
} from './query';

export const useSavedCampsites = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_CAMPSITES, {variables: {userId}},
  );

  const [saveCampsiteToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_CAMPSITE_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_CAMPSITES, variables: {userId}}],
    });

  const [removeSavedCampsiteFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_CAMPSITE_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_CAMPSITES, variables: {userId}}],
    });

  return {response, saveCampsiteToUser, removeSavedCampsiteFromUser};
};
