import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import {
  golden,
  IconContainer,
  lightBaseColor,
} from '../../../../styling/styleUtils';

const StarContainer = styled.div`
  display: flex;
  padding-top: 0.3rem;
`;

const Star = styled(IconContainer)`
  margin: 0;
  padding: 0.1rem;
  font-size: 0.9rem;

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
  onNotLoggedInClick?: () => void;
}

const StarRating = ({rating, setRating, onNotLoggedInClick}: Props) => {
  const [hoveredRating, setHoveredRating] = useState<number>(0);
  const currentUser = useCurrentUser();

  const stars: React.ReactElement<any>[] = [];
  for (let i = 0; i < 5; i++) {
    const icon = (rating >= i + 1 && !hoveredRating) || hoveredRating >= i + 1
      ? faStarSolid : faStarOutline;
    const color = rating >= i + 1 || hoveredRating >= i + 1
      ? golden : lightBaseColor;
    const handleOnStarClick = () => {
      if (currentUser) {
        setRating(i + 1);
      } else if (onNotLoggedInClick) {
        onNotLoggedInClick();
      }
    };
    stars.push(
      <Star
        key={'addEditComment' + i + 'star'}
        $color={color}
        onMouseEnter={() => setHoveredRating(i + 1)}
        onMouseLeave={() => setHoveredRating(0)}
      >
        <FontAwesomeIcon
          icon={icon}
          onClick={handleOnStarClick}
        />
      </Star>,
    );
  }

  return (
    <StarContainer>
      {stars}
    </StarContainer>
  );
};

export default StarRating;
