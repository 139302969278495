import React from 'react';
import {useParams} from 'react-router-dom';
import ViewpointSearchPage from '../list';
import ViewpointDetail from './ViewpointDetail';

const ViewpointDetailPage = () => {
  const { id, viewpointId: possibleViewpointId }: any = useParams();
  const viewpointId = possibleViewpointId ? possibleViewpointId : id;

  if (viewpointId === 'search') {
    return <ViewpointSearchPage />;
  } else {
    return <ViewpointDetail id={viewpointId} />;
  }
};

export default ViewpointDetailPage;
