import { faAddressBook, faBars, faBook, faQuestionCircle, faTimes, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import useFluent from '../../../hooks/useFluent';
import { Routes } from '../../../routing/routes';
import { primaryColor } from '../../../styling/styleUtils';
import { mobileSize } from '../../../Utils';
import DropoutList, { MenuIcon, MenuLink } from './DropoutList';
import { linkStyles } from './Utils';

export const slideIn = keyframes`
  0% {
    transform: translate(100vw);
  }

  100% {
    transform: translate(0);
  }
`;

const Root = styled.div`
  width: 45px;
  pointer-events: all;

  @media(max-width: ${mobileSize}px) {
    &.active:before {
      animation: ${slideIn} 0.15s ease-in-out 1 forwards;
      content: '';
      position: fixed;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-color: #fff;
    }
  }
`;

const HamburgerButton = styled.button`
  ${linkStyles}
  @media(max-width: ${mobileSize}px) {
    background-color: #fff;
    color: ${primaryColor};
    box-shadow: 0px 0px 4px -1px #8d8d8d;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    margin-right: 0.65rem;
    margin-top: 0.25rem;

    && {
      border-radius: 1000px;
    }
  }
`;

interface Props {
  hamburgerMenuOpen: boolean;
  setHamburgerMenuOpen: (value: boolean) => void;
}

const HamburgerMenu = ({ hamburgerMenuOpen, setHamburgerMenuOpen }: Props) => {
  const toggleHamburgerMenu = () => setHamburgerMenuOpen(!hamburgerMenuOpen);

  const hamburgerMenuButtonEl = useRef<HTMLDivElement | null>(null);
  const getString = useFluent();
  useEffect(() => {
    if (hamburgerMenuButtonEl.current !== null) {
      const el = hamburgerMenuButtonEl.current;
      const preventClickFromPropagating = (e: MouseEvent) => {
        e.stopPropagation();
      };
      el.addEventListener('mousedown', preventClickFromPropagating);
      return () => el.removeEventListener('mousedown', preventClickFromPropagating);
    }
  }, [hamburgerMenuButtonEl]);

  const hamburgerIcon = hamburgerMenuOpen ? faTimes : faBars;
  const hamburgerMenuList = hamburgerMenuOpen ? (
    <DropoutList
      closeMenu={() => setHamburgerMenuOpen(false)}
    >
      <MenuLink to={Routes.About}>
        <MenuIcon icon={faQuestionCircle} />
        {getString('header-text-menu-item-about')}
      </MenuLink>
      <MenuLink to={Routes.Contact}>
        <MenuIcon icon={faAddressBook} />
        {getString('global-text-value-contact')}
      </MenuLink>
      <MenuLink to={Routes.TermsOfUse}>
        <MenuIcon icon={faBook} />
        {getString('header-text-menu-terms-of-use')}
      </MenuLink>
      <MenuLink to={Routes.PrivacyPolicy}>
        <MenuIcon icon={faUserShield} />
        {getString('header-text-menu-privacy-policy')}
      </MenuLink>
    </DropoutList>
  ) : null;

  return (
    <Root
      ref={hamburgerMenuButtonEl}
      className={hamburgerMenuOpen ? 'active' : undefined}
    >
      <HamburgerButton onClick={toggleHamburgerMenu}>
        <FontAwesomeIcon icon={hamburgerIcon} />
      </HamburgerButton>
      { hamburgerMenuList }
    </Root>
  );
};

export default HamburgerMenu;
