import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';
import { golden, IconContainer } from '../../../../styling/styleUtils';

const StarContainer = styled.div`
  display: flex;
  padding-top: 0.3rem;
  padding-bottom: 0.35rem;
`;

const Star = styled(IconContainer)`
  margin: 0;
  padding: 0.05rem;
  font-size: 0.65rem;
`;

const StaticStars = ({rating, id}: {rating: number, id: string}) => {

  const stars: React.ReactElement<any>[] = [];
  if (rating) {
    for (let i = 0; i < 5; i++) {
      const icon = rating >= i + 1 ? faStarSolid : faStarOutline;
      stars.push(<Star key={id + i + 'star'} $color={golden}><FontAwesomeIcon icon={icon} /></Star>);
    }
  }

  return (
    <StarContainer>
      {stars}
    </StarContainer>
  );
};

export default StaticStars;
