const {point} = require('@turf/helpers');
const distance = require('@turf/distance').default;
import React from 'react';
import styled from 'styled-components/macro';
import DirectionsIcon from '../../../../../assets/images/icons/car-highlighted.svg';
import ListIcon from '../../../../../assets/images/icons/list-highlighted.svg';
import MountainIcon from '../../../../../assets/images/icons/mountain-highlighted.svg';
import ParkIcon from '../../../../../assets/images/icons/park-highlighted.svg';
import RoadIcon from '../../../../../assets/images/icons/road-highlighted.svg';
import RouteIcon from '../../../../../assets/images/icons/route-highlighted.svg';
import TentIcon from '../../../../../assets/images/icons/tent-highlighted.svg';
import TrailIcon from '../../../../../assets/images/icons/trail-highlighted.svg';
import TrailheadIcon from '../../../../../assets/images/icons/trailhead-highlighted.svg';
import ViewpointIcon from '../../../../../assets/images/icons/viewpoint-highlighted.svg';
import WaterpointIcon from '../../../../../assets/images/icons/water-highlighted.svg';
import useFluent from '../../../../../hooks/useFluent';
import { baseColor, primaryFont } from '../../../../../styling/styleUtils';
import { Coordinate } from '../../../../../types/graphQLTypes';
import { AggregateItem, AuxiliaryItem, CoreItem, MapItem, UserContentItem } from '../../../../../types/itemTypes';
import { failIfValidOrNonExhaustive } from '../../../../../Utils';
import { getCachedUsersLocation } from '../../usersLocation/track';

const Root = styled.div`
  padding: 0.4rem 1rem 0.4rem 0.5rem;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-column-gap: 0.4rem;
  font-size: 0.85rem;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: ${primaryFont};
  color: ${baseColor};
  padding: 0;
  font-weight: 600;
`;

const CrowFliesDistance = styled.small`
  margin-top: 0.2rem;
  display: flex;
  line-height: 1.1;
  align-items: center;
`;

interface Props {
  name: string;
  subtitle: string;
  type: CoreItem | MapItem | AggregateItem | AuxiliaryItem | UserContentItem;
  location: Coordinate;
}

const OfflinePopup = (props: Props) => {
  const {
    name, subtitle, type, location,
  } = props;
  const getString = useFluent();
  const usersLocation = getCachedUsersLocation();
  const start = usersLocation && usersLocation.coords
    ? point([usersLocation.coords.longitude, usersLocation.coords.latitude])
    : point(location);
  const end = point(location);
  const crowFliesDistance = parseFloat(distance(start, end, {units: 'miles'}).toFixed(1));
  let imgSrc: string = '';
  if (type === CoreItem.mountain) {
    imgSrc = MountainIcon;
  } else if (type === CoreItem.trail) {
    imgSrc = TrailIcon;
  } else if (type === CoreItem.campsite) {
    imgSrc = TentIcon;
  } else if (type === AuxiliaryItem.trailhead) {
    imgSrc = TrailheadIcon;
  } else if (type === AuxiliaryItem.viewpoint) {
    imgSrc = ViewpointIcon;
  } else if (type === AuxiliaryItem.waterpoint) {
    imgSrc = WaterpointIcon;
  } else if (type === AuxiliaryItem.park) {
    imgSrc = ParkIcon;
  } else if (type === MapItem.route) {
    imgSrc = RouteIcon;
  } else if (type === MapItem.directions) {
    imgSrc = DirectionsIcon;
  } else if (type === AggregateItem.list) {
    imgSrc = ListIcon;
  } else if (type === AggregateItem.parentTrail) {
    imgSrc = TrailIcon;
  } else if (type === MapItem.vehicleRoad) {
    imgSrc = RoadIcon;
  } else if (type === UserContentItem.route) {
    imgSrc = RouteIcon;
  } else {
    imgSrc = MountainIcon;
    failIfValidOrNonExhaustive(type, 'Invalid value for type ' + type);
  }

  const crowFliesText = usersLocation && usersLocation.coords && crowFliesDistance ? (
    <CrowFliesDistance>
      <em>
        {getString('crow-flies-distance', {distance: crowFliesDistance, name: 'your location'})}
        <br />
        {getString('crow-flies')}
      </em>
    </CrowFliesDistance>
  ) : null;

  return (
    <Root>
      <div>
        <img src={imgSrc} title={name} alt={name} />
      </div>
      <Content>
        <Title>
          {name}
        </Title>
        <small>{subtitle}</small>
        {crowFliesText}
      </Content>
    </Root>
  );
};

export default OfflinePopup;
