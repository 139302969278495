import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import { GET_BASIC_LIST_DETAILS, SuccessResponse, Variables } from './query';

export const refetchBasicListDetails = (variables: Variables) => ({query: GET_BASIC_LIST_DETAILS, variables});

export const useBasicListDetails = (id: string, userId: string | null) => {
  const { offline } = useOfflineContext();
  const fetchPolicy: WatchQueryFetchPolicy = offline ? 'cache-only' : 'cache-and-network';

  return useQuery<SuccessResponse, Variables>(
    GET_BASIC_LIST_DETAILS, {variables: { id, userId }, fetchPolicy},
  );
};
