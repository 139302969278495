import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../../../../hooks/useFluent';
import { PrimaryGhostButton } from '../../../../../styling/styleUtils';
import { AggregateItem, AutoItem, AuxiliaryItem, CoreItem, UserContentItem } from '../../../../../types/itemTypes';
import UtilityButton from '../../../UtilityButton';
import ShareModal from './ShareModal';

const Button = styled(PrimaryGhostButton)`
  padding: 0;
`;

type Props = PropsWithChildren<{
  pageName: string;
  type: CoreItem | AutoItem | AggregateItem | AuxiliaryItem | UserContentItem | 'trip-report' | 'geo-location';
  snippet?: string;
  url?: string;
}>;

const SimpleShare = ({ pageName, type, snippet, url, children }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const getString = useFluent();
  const modal = modalOpen ? (
    <ShareModal
      onClose={() => setModalOpen(false)}
      pageName={pageName}
      type={type}
      snippet={snippet}
      url={url}
    />
  ) : null;
  return (
    <>
      {children ? (
        <Button onClick={() => setModalOpen(true)} title='Share'>
          {children}
        </Button>
      ) : (
        <UtilityButton
          text={getString('global-text-value-share')}
          icon={faShareAlt}
          onClick={() => setModalOpen(true)}
        />
      )}
      {modal}
    </>
  );
};

export default SimpleShare;
