import { faStar as faOutlineStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import useFluent from '../../hooks/useFluent';
import UtilityButton from './UtilityButton';

interface Props {
  starred: boolean;
  toggleStarred: () => void;
  compact?: boolean;
}

const StarButton = ({starred, toggleStarred, compact}: Props) => {
  const getString = useFluent();

  const text = compact ? '' : starred ? getString('global-text-value-saved') : getString('global-text-value-save');
  const icon = starred ? faSolidStar : faOutlineStar;

  return (
    <UtilityButton
      onClick={toggleStarred}
      text={text}
      icon={icon}
      highlighted={starred}
      compact={compact}
      rootWidth={'3.5rem'}
    />
  );
};

export default StarButton;
