import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';

export const GET_PEAK_LIST_NOTE = gql`
  query getPeakListNote($userId: ID, $peakListId: ID!) {
    user(id: $userId) {
      id
      peakListNote(peakListId: $peakListId) {
        id
        text
      }
    }
  }
`;

export interface QuerySuccessResponse {
  user: null | {
    id: User['name'];
    __typename: string;
    peakListNote: null | {
      id: string;
      text: string;
      __typename: string;
    }
  };
}

export interface QueryVariables {
  userId: string | null;
  peakListId: string | null;
}

export const ADD_PEAKLIST_NOTE = gql`
  mutation($userId: ID!, $peakListId: ID!, $text: String!) {
    user: addPeakListNote(
      userId: $userId,
      peakListId: $peakListId,
      text: $text
    ) {
      id
      peakListNote(peakListId: $peakListId) {
        id
        text
      }
    }
  }
`;

export const EDIT_PEAKLIST_NOTE = gql`
  mutation($userId: ID!, $peakListId: ID!, $text: String!) {
    user: editPeakListNote(
      userId: $userId,
      peakListId: $peakListId,
      text: $text
    ) {
      id
      peakListNote(peakListId: $peakListId) {
        id
        text
      }
    }
  }
`;

export interface PeakListNoteSuccess {
  user: {
    id: User['id'];
    peakListNote: User['peakListNote'];
  };
}

export interface PeakListNoteVariables {
  userId: string;
  peakListId: string;
  text: string;
}
