import React from 'react';
import { useBasicParkDetails } from '../../../queries/parks/useBasicParkDetails';
import AllItems from '../../peakLists/detail/AllItems';

interface Props {
  id: string;
}

const TrailBaggingListDetail = ({id}: Props) => {
  const {data} = useBasicParkDetails(id);
  if (data && data.park && data.park.trailBaggingList && data.park.trailBaggingList.id) {
    return (
      <AllItems peakListId={data.park.trailBaggingList.id} />
    );
  }
  return null;
};

export default TrailBaggingListDetail;
