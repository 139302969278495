import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { GET_USERS_SAVED_WATERPOINTS, MutationVariables, REMOVED_SAVED_WATERPOINT_FROM_USER, SAVE_WATERPOINT_TO_USER, SuccessResponse, Variables } from './query';

export const useSavedWaterpoints = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_WATERPOINTS, {variables: {userId}},
  );

  const [saveWaterpointToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_WATERPOINT_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_WATERPOINTS, variables: {userId}}],
    });

  const [removeSavedWaterpointFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_WATERPOINT_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_WATERPOINTS, variables: {userId}}],
    });

  return {response, saveWaterpointToUser, removeSavedWaterpointFromUser};
};
