// import { tilesInBbox } from 'tiles-in-bbox';
// const bboxPolygon = require('@turf/bbox-polygon').default;
// import slippyGrid from 'slippy-grid';
import {pointToTile} from '@mapbox/tilebelt';
import localforage from 'localforage';
import { failIfValidOrNonExhaustive } from '../../../Utils';

export const SAVED_MAPS_KEY = 'saved-offline-maps';
export const OFFLINE_MAPS_CACHE_NAME = 'map-user-specified-tile-cache';
type MapVersion = 2;
export const MAP_VERSION: MapVersion = 2;

// Saved in format https://api.mapbox.com/TILEPATH/${z}/${x}/${y}.vector.pbf?sku=SKU&access_token=TOKEN
export const MB_URL_TEMPLATE_KEY = 'MB_URL_TEMPLATE';

export const  MB_SKU_KEY = 'MB_SKU';
export const  MB_TOKEN_KEY = 'MB_TOKEN';

export interface SavedOfflineMap {
  extent: [number, number, number, number];
  count: number;
  resolution: number;
  name: string;
  date: Date;
  lastSync: Date;
  mapVersion: number;
}

const TILE_WIDTHS: Record<number, number> = {
  // https://wiki.openstreetmap.org/wiki/Zoom_levels
  0: 360,
  1: 180,
  2: 90,
  3: 45,
  4: 22.5,
  5: 11.25,
  6: 5.625,
  7: 2.813,
  8: 1.406,
  9: 0.703,
  10: 0.352,
  11: 0.176,
  12: 0.088,
  13: 0.044,
  14: 0.022,
};

const BUFFER = 0.5;

export const getTiles = (bbox: {bottom: number, left: number, top: number, right: number}, maxZoom: number) => {
  const tiles: { x: number, y: number, z: number }[] = [];
  let lon: number = bbox.bottom;
  let lat: number = bbox.left;
  let zoomLevel = maxZoom;
  while (zoomLevel > -1) {
    try {
      const [x, y, z] = pointToTile(lon, lat, zoomLevel);
      if (typeof x === 'number' && typeof y === 'number' && typeof z === 'number') {
        tiles.push({x, y, z});
      }
      lon = lon + (TILE_WIDTHS[zoomLevel] * BUFFER);
      if (lon > bbox.top) {
        lon = bbox.bottom;
        lat = lat + (TILE_WIDTHS[zoomLevel] * BUFFER);
        if (lat > bbox.right) {
          lat = bbox.left;
          zoomLevel = zoomLevel - 1;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  return tiles.filter((value, index, self) => {
    return self.findIndex(value2 => value.x === value2.x && value.y === value2.y && value.z === value2.z) === index;
  });
};

/**
 *
 * @param url
 * @returns [zoom, x, y]
 */
export const getSlippyFromMapboxTileUrl = (url: string): number[] => url
    .split('.vector.pbf')[0] // [FULL_URL_WITH_SLIPPY, DISCARD_REST]
    .split('/') // All remaining URL parts, split at '/'
    .filter(value => parseInt(value, 10).toString() === value) // only keep integers
    .map(Number);

export const mapboxUrlEqualsSlippy = (url: string, {x, y, z}: {x: string | number, y: string | number, z: string | number}) => {
  const slippy = getSlippyFromMapboxTileUrl(url);
  return (
    slippy[0] === z &&
    slippy[1] === x &&
    slippy[2] === y
  );
};

export const tileToMbUrl =
  ({x, y, z}: {x: number | string, y: number | string, z: number | string}, urlTemplate: string) =>
    urlTemplate
      .replace('${x}', `${x}`)
      .replace('${y}', `${y}`)
      .replace('${z}', `${z}`);

export const getUrlTemplate = async () => {
  const template = await localforage.getItem<string>(MB_URL_TEMPLATE_KEY);
  if (template) {
    return template;
  }
  // If not defined template, attempt to use the static defined URL.
  // This should be updated whenever the map changes
  const sku = await localforage.getItem<string>(MB_SKU_KEY);
  const token = await localforage.getItem<string>(MB_TOKEN_KEY);
  if (sku && token) {
    if (MAP_VERSION !== 2) {
      try {
        failIfValidOrNonExhaustive(MAP_VERSION, 'MAP_VERSION');
      } catch (error) {
        console.error(error);
      }
    }
    return `https://api.mapbox.com/v4/mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2,wsoeltz.wilderlist-trails-meta,wsoeltz.wilderlist_points_v2,wsoeltz.wilderlist-trails-high-zoom/${'${z}'}/${'${x}'}/${'${y}'}.vector.pbf?sku=${sku}&access_token=${token}`;
  }
};
