import { gql } from '@apollo/client';
import { RoutePlan, Segment, Trail, User } from '../../../types/graphQLTypes';

export const routePlanQuery = `
  id
  name
  visibility
  autoGenerated
  authors {
    id
    name
  }
  canEdit
  authorVisibility
  totalLength
  created
  modified
  segments {
    id
    name
    lines
    color
    trails {
      name
      type
      id
    }
  }
  center
`;

export const GET_ROUTE_PLAN_DETAIL = gql`
  query GetRouteDetail($id: ID!) {
    routePlan(id: $id) {
      ${routePlanQuery}
    }
  }
`;

export interface ReturnedRoutePlan {
  id: RoutePlan['id'];
  name: RoutePlan['name'];
  visibility: RoutePlan['visibility'];
  autoGenerated: RoutePlan['autoGenerated'];
  authors: (null | {
    id: User['id'],
    name: User['name'],
  })[];
  canEdit: RoutePlan['canEdit'];
  authorVisibility: RoutePlan['authorVisibility'];
  totalLength: RoutePlan['totalLength'];
  created: RoutePlan['created'];
  modified: RoutePlan['modified'];
  segments: {
    id: Segment['id'],
    name: Segment['name'],
    lines: Segment['lines'],
    color: Segment['color'],
    trails: {
      id: Trail['id'],
      name: Trail['name'],
      type: Trail['type'],
    }[],
  }[];
  center: RoutePlan['center'];
}

export interface SuccessResponse {
  routePlan: null | ReturnedRoutePlan;
}

export interface Variables {
  id: string | null;
}
