import * as Sentry from '@sentry/react';
import 'core-js/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import 'regenerator-runtime/runtime';
import App from './components/App';
import {
  appLocalizationAndBundle as fluentValue,
  AppLocalizationAndBundleContext as FluentText,
} from './contextProviders/getFluentLocalizationContext';
import OfflineContextProvider from './contextProviders/OfflineContext';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({dsn: 'https://6f07b454e1a84442b2f2ec02bc0996a4@o425164.ingest.sentry.io/5357955'});
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render((
  <OfflineContextProvider>
    <FluentText.Provider value={fluentValue}>
      <Router>
        <App />
      </Router>
    </FluentText.Provider>
  </OfflineContextProvider>
));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
