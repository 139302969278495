import { gql } from '@apollo/client';
import {
  PeakList,
  User,
} from '../../../types/graphQLTypes';

export interface CardPeakListDatum {
  __typename: string;
  id: PeakList['id'];
  name: PeakList['name'];
  shortName: PeakList['shortName'];
  type: PeakList['type'];
  locationText: PeakList['locationText'];
  numMountains: PeakList['numMountains'];
  numTrails: PeakList['numTrails'];
  numCampsites: PeakList['numCampsites'];
  numCompletedTrips: PeakList['numCompletedTrips'];
  numCompletedAscents: PeakList['numCompletedAscents'];
  numCompletedTrails: PeakList['numCompletedTrails'];
  numCompletedCampsites: PeakList['numCompletedCampsites'];
  privacy: PeakList['privacy'];
  latestTrip: PeakList['latestTrip'];
  bbox: PeakList['bbox'];
  isActive: PeakList['isActive'];
  userNote: PeakList['userNote'];
  parent: null | {id: PeakList['id'], __typename: string};
}

export const GET_USERS_PEAK_LISTS = gql`
  query GetUsersPeakLists($userId: ID) {
    user(id: $userId) {
      id
      peakLists {
        id
        name
        shortName
        type
        locationText
        parent {
          id
        }
        bbox
        numMountains
        numTrails
        numCampsites
        privacy
        userNote
        numCompletedTrips(userId: $userId)
        numCompletedAscents(userId: $userId)
        numCompletedTrails(userId: $userId)
        numCompletedCampsites(userId: $userId)
        latestTrip(userId: $userId)
        isActive(userId: $userId)
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface PeakListsSuccessResponse {
  user: {
    __typename: string;
    id: User['id'];
    peakLists: (CardPeakListDatum | null)[];
  };
}
