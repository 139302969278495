import mapboxgl, {CirclePaint, LineLayout, LinePaint} from 'mapbox-gl';
import {MapStyle} from '../';
import {primaryColor} from '../../../../../styling/styleUtils';

export const userPointLayerId = 'user-position-layer-id';
export const highlightedPointsLayerId = 'temporary-highlight-mountains-layer-id';
export const defaultGeoJsonPoint: mapboxgl.GeoJSONSourceSpecification['data'] = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Point',
    coordinates: [],
  },
};

export const hoveredShapeLayerId = 'temporary-hovered-highlight-shape-layer-id';
export const highlightedShapeLayerId = 'temporary-highlight-shape-layer-id';
export const highlightedShapeOutlineLayerId = 'highlight-shape-outline-layer-id';
export const hoveredPointLayerId = 'temporary-hovered-point-layer-id';
export const trailMileMarkerPointsLayerId = 'temporary-trail-mile-marker-points-layer-id';
export const roadMileMarkerPointsLayerId = 'temporary-road-mile-marker-points-layer-id';

export const highlightedTrailsLayerId = 'temporary-highlight-trails-layer-id';
export const highlightedTrailsLayerTopId = 'temporary-highlight-trails-top-layer-id';

export const highlightedTrailMileageLayerId = 'temporary-highlight-trail-mileage-layer-id';
export const highlightedRoadMileageLayerId = 'temporary-highlight-road-mileage-layer-id';

export const hoveredTrailsLayerId = 'temporary-hovered-trails-layer-id';
const hoveredTrailsLayerTopId = 'temporary-hovered-trails-top-layer-id';

export const highlightedRoadsLayerId = 'temporary-highlight-roads-layer-id';
const highlightedRoadsLayerTopId = 'temporary-highlight-roads-top-layer-id';

export const hoveredRoadsLayerId = 'temporary-hovered-roads-layer-id';
const hoveredRoadsLayerTopId = 'temporary-hovered-roads-top-layer-id';

export const newSegmentLayerId = 'segment-highlighted-dynamic-layer';
export const potentialSegmentLayerId = 'segment-potential-dynamic-layer';
export const segmentMarkerLayerId = 'segment-marker-layer';

export const defaultGeoJsonLineString: mapboxgl.GeoJSONSourceSpecification['data'] = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'LineString',
    coordinates: [],
  },
};

export const defaultGeoJsonPolygon: mapboxgl.GeoJSONSourceSpecification['data'] = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    coordinates: [],
  },
};

interface Input {
  map: mapboxgl.Map;
  style: MapStyle;
}

const trailHighlightedTopLayoutAndPaint: {layout: LineLayout, paint: LinePaint} = {
  layout: {'line-join': 'round', 'line-cap': 'round'},
  paint: {
      'line-width': [
        'interpolate',
        ['exponential', 1.96],
        ['zoom'],
        0, 8,
        17, 22,
        22, 32,
      ],
      'line-color': 'rgba(0, 0, 0, 0)',
      'line-translate': [0, 0],
  },
};
const trailHighlightedBottomLayoutAndPaint: {layout: LineLayout, paint: LinePaint} = {
  layout: {
    'line-join': 'round',
    'line-round-limit': 2,
    'line-cap': 'round',
  },
  paint: {
    'line-color': ['coalesce', ['get', 'color'], primaryColor],
    'line-width': [
      'interpolate',
      ['exponential', 1.96],
      ['zoom'],
      0, 1.7,
      11.5, 1.9,
      13, 2.1,
      17, 8,
      22, 16,
    ],
    'line-opacity': 1,
  },
};

const markerPointPaint: CirclePaint = {
  'circle-color': '#fff',
  'circle-stroke-color': ['coalesce', ['get', 'color'], primaryColor],
  'circle-stroke-width': [
      'interpolate',
      ['linear', 1.96],
      ['zoom'],
      0, 0.75,
      22, 4,
    ],
  'circle-radius': [
      'interpolate',
      ['linear', 1.96],
      ['zoom'],
      0, 0.75,
      22, 6.5,
    ],
};

const initLayers = ({map, style}: Input) => {
  const textHaloColor = style === MapStyle.satellite ? 'rgba(0, 0, 0, 0.25)' : 'hsla(0, 0%, 100%, 0.77)';
  const hoveredTextHaloColor = style === MapStyle.satellite ? '#206ca6' : 'hsla(0, 0%, 100%, 0.77)';
  const hoveredHighlightedTextColor = style === MapStyle.satellite ? '#fff' : '#206ca6';
  const highlightedTextColor = style === MapStyle.satellite ? '#fff' : '#5b6151';

  map.addSource(highlightedPointsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPoint,
  });
  map.addLayer({
    id: highlightedPointsLayerId,
    type: 'symbol',
    source: highlightedPointsLayerId,
      layout: {
        'text-optional': true,
        'text-size': [
            'interpolate',
            ['exponential', 0.81],
            ['zoom'],
            0,
            6,
            22,
            12,
        ],
        'icon-image': ['get', 'icon'],
        'text-font': [
          'Source Sans Pro Bold',
          'Arial Unicode MS Regular',
        ],
        'text-padding': 0,
        'text-offset': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            ['literal', [0, 1.1]],
            22,
            ['literal', [0, 1.25]],
        ],
        'icon-size': [
            'interpolate',
            ['exponential', 0.96],
            ['zoom'],
            0,
            0.2,
            22,
            1,
        ],
        'text-anchor': 'top',
        'text-field': [
            'concat',
            ['get', 'name'],
            '\n',
            ['get', 'subtitle'],
        ],
        'text-letter-spacing': 0.04,
        'icon-padding': 0,
        'icon-allow-overlap': true,
        'text-allow-overlap': false,
        'text-max-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            7.5,
            22,
            10,
        ],
    },
    paint: {
        'text-halo-color': [
          'case',
          [
            'boolean',
            [
              'feature-state',
              'hover',
            ],
            false,
          ],
          hoveredTextHaloColor,
          textHaloColor,
        ],
        'text-halo-width': 0.4,
        'text-color': [
          'case',
          [
            'boolean',
            [
              'feature-state',
              'hover',
            ],
            false,
          ],
          hoveredHighlightedTextColor,
          highlightedTextColor,
        ],
        'text-opacity': ['step', ['zoom'], 0, 12, 1],
    },
  });

  map.addSource(highlightedRoadsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: highlightedRoadsLayerId,
    type: 'line',
    source: highlightedRoadsLayerId,
    filter: ['!', ['has', 'hidden']],
    ...trailHighlightedBottomLayoutAndPaint,
  }, 'admin-1-boundary-bg');
  map.addLayer({
    id: highlightedRoadsLayerTopId,
    type: 'line',
    source: highlightedRoadsLayerId,
    filter: ['!', ['has', 'hidden']],
    ...trailHighlightedTopLayoutAndPaint,
  }, 'admin-1-boundary-bg');

  map.addSource(hoveredRoadsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: hoveredRoadsLayerId,
    type: 'line',
    source: hoveredRoadsLayerId,
    ...trailHighlightedBottomLayoutAndPaint,
  }, 'admin-1-boundary-bg');
  map.addLayer({
    id: hoveredRoadsLayerTopId,
    type: 'line',
    source: hoveredRoadsLayerId,
    ...trailHighlightedTopLayoutAndPaint,
  }, 'admin-1-boundary-bg');

  map.addSource(hoveredTrailsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: hoveredTrailsLayerId,
    type: 'line',
    source: hoveredTrailsLayerId,
    ...trailHighlightedBottomLayoutAndPaint,
  }, 'admin-1-boundary-bg');
  map.addLayer({
    id: hoveredTrailsLayerTopId,
    type: 'line',
    source: hoveredTrailsLayerId,
    ...trailHighlightedTopLayoutAndPaint,
  }, 'admin-1-boundary-bg');

  map.addSource(highlightedTrailsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: highlightedTrailsLayerId,
    type: 'line',
    source: highlightedTrailsLayerId,
    filter: ['!', ['has', 'hidden']],
    ...trailHighlightedBottomLayoutAndPaint,
  }, 'admin-1-boundary-bg');
  map.addLayer({
    id: highlightedTrailsLayerTopId,
    type: 'line',
    source: highlightedTrailsLayerId,
    filter: ['!', ['has', 'hidden']],
    ...trailHighlightedTopLayoutAndPaint,
  }, 'admin-1-boundary-bg');

  map.addSource(newSegmentLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: newSegmentLayerId,
    type: 'line',
    source: newSegmentLayerId,
    layout: trailHighlightedBottomLayoutAndPaint.layout,
    paint: {
      ...trailHighlightedBottomLayoutAndPaint.paint,
      'line-opacity': 0.9,
    },
  }, 'admin-1-boundary-bg');

  map.addSource(potentialSegmentLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: potentialSegmentLayerId,
    type: 'line',
    source: potentialSegmentLayerId,
    layout: trailHighlightedBottomLayoutAndPaint.layout,
    paint: {
      ...trailHighlightedBottomLayoutAndPaint.paint,
      'line-opacity': 0.7,
      'line-dasharray': [0.5, 1.25],
    },
  }, 'admin-1-boundary-bg');

  map.addSource(segmentMarkerLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPoint,
  });
  map.addLayer({
    id: segmentMarkerLayerId,
    type: 'circle',
    source: segmentMarkerLayerId,
    minzoom: 12,
    paint: markerPointPaint,
  }, 'campsites');

  map.addSource(hoveredShapeLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPolygon,
  });
  map.addLayer({
    id: hoveredShapeLayerId,
    type: 'fill',
    source: hoveredShapeLayerId,
    paint: {
      'fill-color': primaryColor,
      'fill-opacity': 0.2,
      },
  });
  map.addSource(highlightedShapeLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPolygon,
  });
  map.addLayer({
    id: highlightedShapeLayerId,
    type: 'fill',
    source: highlightedShapeLayerId,
    paint: {
      'fill-color': primaryColor,
      'fill-opacity': 0.2,
      },
  });
  map.addSource(highlightedShapeOutlineLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPolygon,
  });
  map.addLayer({
    id: highlightedShapeOutlineLayerId,
    type: 'line',
    source: highlightedShapeOutlineLayerId,
    paint: {
      'line-color': primaryColor,
      'line-opacity': 0.5,
      'line-width': 2,
    },
  });

  map.addSource(hoveredPointLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPoint,
  });
  map.addLayer({
    id: hoveredPointLayerId,
    type: 'circle',
    source: hoveredPointLayerId,
    paint: {
      'circle-color': '#fff',
      'circle-stroke-color': ['coalesce', ['get', 'color'], primaryColor],
      'circle-stroke-width': [
          'interpolate',
          ['linear', 1.96],
          ['zoom'],
          0, 1,
          22, 5,
        ],
      'circle-radius': [
          'interpolate',
          ['linear', 1.96],
          ['zoom'],
          0, 1,
          22, 8,
        ],
      },
  });

  map.addSource(trailMileMarkerPointsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPoint,
  });
  map.addLayer({
    id: trailMileMarkerPointsLayerId,
    type: 'circle',
    source: trailMileMarkerPointsLayerId,
    minzoom: 12,
    paint: markerPointPaint,
  }, 'campsites');

  map.addSource(roadMileMarkerPointsLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPoint,
  });
  map.addLayer({
    id: roadMileMarkerPointsLayerId,
    type: 'circle',
    source: roadMileMarkerPointsLayerId,
    minzoom: 12,
    paint: markerPointPaint,
  }, 'campsites');

  map.addSource(highlightedRoadMileageLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: highlightedRoadMileageLayerId,
    type: 'symbol',
    source: highlightedRoadMileageLayerId,
    minzoom: 12,
    layout: {
      'text-size': 12,
      'text-font': [
        'Source Sans Pro Bold',
        'Arial Unicode MS Regular',
      ],
      'text-field': ['to-string', ['get', 'trailLengthText']],
      'text-letter-spacing': 0.04,
      'text-anchor': 'center',
      'text-rotation-alignment': 'map',
      'text-padding': 0,
      'text-offset': [0, -1],
      'text-rotate': ['get', 'textAngle'],
      'text-allow-overlap': true,
    },
    paint: {
      'text-halo-color': '#ffffff',
      'text-halo-width': 1,
      'text-halo-blur': 0.1,
      'text-color': primaryColor,
    },
  });

  map.addSource(highlightedTrailMileageLayerId, {
    type: 'geojson',
    data: defaultGeoJsonLineString,
  });
  map.addLayer({
    id: highlightedTrailMileageLayerId,
    type: 'symbol',
    source: highlightedTrailMileageLayerId,
    minzoom: 12,
    layout: {
      'text-size': 12,
      'text-font': [
        'Source Sans Pro Bold',
        'Arial Unicode MS Regular',
      ],
      'text-field': ['to-string', ['get', 'trailLengthText']],
      'text-letter-spacing': 0.04,
      'text-anchor': 'center',
      'text-rotation-alignment': 'map',
      'text-padding': 0,
      'text-offset': [0, -1],
      'text-rotate': ['get', 'textAngle'],
      'text-allow-overlap': true,
    },
    paint: {
      'text-halo-color': textHaloColor,
      'text-halo-width': 1,
      'text-halo-blur': 0.1,
      'text-color': hoveredHighlightedTextColor,
    },
  });

  map.addSource(userPointLayerId, {
    type: 'geojson',
    data: defaultGeoJsonPoint,
  });
  map.addLayer({
    id: userPointLayerId,
    type: 'symbol',
    source: userPointLayerId,
      layout: {
        'icon-image': 'user-location',
        'icon-rotate': ['get', 'rotation'],
        'icon-size': 1,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-ignore-placement': true,
      },
      paint: {
        'icon-opacity': 0.95,
    },
  });

};

export default initLayers;
