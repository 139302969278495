import { faSun } from '@fortawesome/free-solid-svg-icons';
import React, {useCallback, useState} from 'react';
import SunCalc from 'suncalc';
import useFluent from '../../../../../hooks/useFluent';
import {
  InlineColumns,
} from '../../../../../styling/sharedContentStyles';
import {
  BasicIconInTextCompact,
  LinkButton,
  SemiBold,
  Subtext,
} from '../../../../../styling/styleUtils';
import OpenWeatherDetailModal from './OpenWeatherDetailModal';
import {
  Daylight,
  degToCompass,
  ForecastBlockContainer,
  ForecastBlockHeader,
  ForecastShort,
  formatAMPM,
  getDayAsText,
  getFaIcon,
  SunTimes,
  TempAndDaylightContainer,
  Temperatures,
  WeatherIcon,
} from './Utils';

export interface WeatherReportDatum {
  clouds: number;
  dew_point: number;
  dt: number;
  feels_like: {
    day: number,
    eve: number,
    morn: number,
    night: number,
  };
  humidity: number;
  pressure: number;
  sunrise: number;
  sunset: number;
  temp: {
    day: number,
    eve: number,
    max: number,
    min: number,
    morn: number,
    night: number,
  };
  uvi: number;
  weather: {
    description: string,
    icon: string,
    id: number,
    main: string,
  }[];
  wind_deg: number;
  wind_speed: number;
  wind_gust?: number;
  rain?: number;
  snow?: number;
}

export interface WeatherDetailDatum {
  clouds: number;
  dew_point: number;
  dt: number;
  feels_like: number;
  humidity: number;
  pressure: number;
  temp: number;
  weather: {
    description: string,
    icon: string,
    id: number,
    main: string,
  }[];
  wind_deg: number;
  wind_speed: number;
  wind_gust?: number;
  rain?: {'1h': number};
  snow?: {'1h': number};
}

export interface OpenWeatherForecastDatum {
  daily: WeatherReportDatum[];
  hourly: WeatherDetailDatum[];
}

interface Props {
  forecast: OpenWeatherForecastDatum;
  latitude: number;
  longitude: number;
}

const OpenWeatherForecast = (props: Props) => {
  const {forecast: {daily, hourly}, latitude, longitude} = props;

  const [weatherDetail, setWeatherDetail] = useState<WeatherReportDatum | null>(null);
  const closeWeatherDetail = useCallback(() => setWeatherDetail(null), []);

  const hourlyDetails = weatherDetail
    ? hourly.filter((hour) => new Date(hour.dt * 1000 ).getDate() === new Date(weatherDetail.dt * 1000).getDate())
    : [];
  const weatherDetailModal = weatherDetail === null ? null : (
    <OpenWeatherDetailModal
      onCancel={closeWeatherDetail}
      data={weatherDetail}
      hourly={hourlyDetails}
    />
  );

  const getString = useFluent();

  const forecastDays = daily.map(report => {
    const { dt, temp, wind_deg, wind_speed, weather } = report;
    const date = new Date(dt * 1000);
    const dateText = getDayAsText(date);
    const description = weather[0].description.charAt(0).toUpperCase() + weather[0].description.slice(1);
    const onClick = () => setWeatherDetail(report);

    const sunTimes = SunCalc.getTimes(date, latitude, longitude);
    const sunriseStr = formatAMPM(sunTimes.sunrise);
    const sunsetStr = formatAMPM(sunTimes.sunset);
    return (
      <ForecastBlockContainer key={dt}>
        <ForecastBlockHeader>
          <LinkButton onClick={onClick}>
            <SemiBold>{dateText}</SemiBold>
          </LinkButton>
          <WeatherIcon icon={getFaIcon(weather[0].id)} />
        </ForecastBlockHeader>
        <ForecastShort>{description}</ForecastShort>
        <TempAndDaylightContainer>
          <Temperatures>
            {Math.round(temp.max)}° / {Math.round(temp.min)}°F
          </Temperatures>
          <Daylight>
            <BasicIconInTextCompact icon={faSun} />
            <SunTimes>
              <div>
                <strong>↑</strong> {sunriseStr}
              </div>
              <div>
                <strong>↓</strong> {sunsetStr}
              </div>
            </SunTimes>
          </Daylight>
        </TempAndDaylightContainer>
        <InlineColumns>
          <Subtext>
            {getString('weather-forecast-wind')}: {Math.round(wind_speed)} mph {degToCompass(wind_deg)}
          </Subtext>
        </InlineColumns>
      </ForecastBlockContainer>
    );
  });

  return (
     <>
       {forecastDays}
       {weatherDetailModal}
     </>
  );
};

export default OpenWeatherForecast;
