import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/core';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import localforage from 'localforage';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import useOfflineSync from '../hooks/offline/useOfflineSync';

let apolloClient: ApolloClient<any> | null = null;
export const getApolloClient = () => apolloClient;

const ApolloClientContext = ({children}: PropsWithChildren<Record<string, unknown>>) => {
  const [clientState, setClientState] = useState<{
    client: ApolloClient<NormalizedCacheObject>,
    persistor: CachePersistor<NormalizedCacheObject>,
  }>();

  useOfflineSync(clientState?.client, clientState?.persistor);

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      const newPersistor = new CachePersistor({
        cache,
        storage: new LocalForageWrapper(localforage),
        debug: window.location.origin.includes('localhost'),
        trigger: 'write',
        maxSize: 1048576 * 20, // bytes to mega bytes
      });
      await newPersistor.restore().catch(console.error);
      const newClient = new ApolloClient({
          uri: '/graphql',
          cache,
        });
      apolloClient = newClient;
      setClientState({client: newClient, persistor: newPersistor});
    }

    init().catch(console.error);
  }, []);

  if (!clientState?.client) {
    return null;
  }

  return (
    <ApolloProvider client={clientState.client}>
      {children}
    </ApolloProvider>
  );
};

export default ApolloClientContext;
