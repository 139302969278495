import upperFirst from 'lodash/upperFirst';
import React from 'react';
import useFluent from '../../../hooks/useFluent';
import {useBasicCampsiteDetails} from '../../../queries/campsites/useBasicCampsiteDetails';
import {CoreItem} from '../../../types/itemTypes';
import ExploreNearby from '../../sharedComponents/detailComponents/exploreNearby';
import GettingThere, { Via } from '../../sharedComponents/detailComponents/gettingThere';
import ReportsAndReviews from '../../sharedComponents/detailComponents/ReportsAndReviews';
import Weather from '../../sharedComponents/detailComponents/weather';

interface Props {
  id: string;
}

const Content = (props: Props) => {
  const  {id} = props;
  const {loading, error, data} = useBasicCampsiteDetails(id);

  const getString = useFluent();

  if (loading) {
    return null;
  } else if (error !== undefined) {
    return <p>{error.message}</p>;
  } else if (data !== undefined && data.campsite) {
    const {campsite} = data;
    const {locationTextShort, location} = campsite;
    const formattedType = upperFirst(getString('global-formatted-campsite-type', {type: campsite.type}));
    const name = campsite.name ? campsite.name : formattedType;
    let via: Via;
    if (campsite.roadAccessMiles !== null && campsite.roadAccessMiles < 0.1) {
      via = Via.Driving;
    } else if (campsite.trailAccessMiles !== null && campsite.trailAccessMiles < 0.1) {
      via = Via.Trails;
    } else if (campsite.roadAccessMiles !== null && campsite.roadAccessMiles < 0.25) {
      via = Via.Driving;
    } else {
      via = Via.Trails;
    }
    return (
      <>
        <Weather
          forecastTabs={[
            {title: getString('weather-forecast-forecast'), location},
          ]}
          snowReport={{location, stateAbbr: locationTextShort}}
        />
        <GettingThere
          via={via}
          name={name}
          location={location}
        />
        <ExploreNearby
          location={location}
          name={name}
          id={id}
        />
        <ReportsAndReviews
          id={id}
          name={name}
          item={CoreItem.campsite}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Content;
