import { gql } from '@apollo/client';
import {
  CompletedCampsite,
  CompletedMountain,
  CompletedTrail,
  User,
} from '../../../types/graphQLTypes';

export const GET_PROGRESS = gql`
  query GetUsersProgress($userId: ID) {
    progress: user(id: $userId) {
      id
      mountains {
        mountainId
        dates
      }
      trails {
        trailId
        dates
      }
      campsites {
        campsiteId
        dates
      }
    }
  }
`;

export interface Progress {
    __typename: string;
    id: User['name'];
    mountains: null | {
      __typename: string,
      mountainId: string,
      dates: CompletedMountain['dates'],
    }[];
    trails: null | {
      __typename: string,
      trailId: string,
      dates: CompletedTrail['dates'],
    }[];
    campsites: null | {
      __typename: string,
      campsiteId: string,
      dates: CompletedCampsite['dates'],
    }[];
  }

export interface SuccessResponse {
  progress: null | Progress;
}

export interface Variables {
  userId: string | null;
}
