import styled from 'styled-components/macro';
import { lightBaseColor, lightBorderColor, tertiaryColor } from '../../../../styling/styleUtils';

export const Button = styled.button`
  display: flex;
  width: 100%;
  font-size: 0.85rem;
  padding: 0.4rem 0.8rem;
  gap: 0.25rem;
  background-color: #fff;
  border-bottom: solid 1px ${lightBorderColor};
  color: ${lightBaseColor};

  &:hover {
    background-color: ${tertiaryColor};
  }
`;
