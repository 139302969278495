import queryString from 'query-string';
import {
  useHistory,
} from 'react-router-dom';
import {
  GlobalQueryParams,
} from '../routing/routes';

export default () => {
  const history = useHistory();
  const params = queryString.parse(history.location.search) as unknown as GlobalQueryParams;
  return params;
};
