const {point} = require('@turf/helpers');
const midpoint = require('@turf/midpoint').default;
const distance = require('@turf/distance').default;
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import {useMemo} from 'react';
import { useOfflineContext } from '../../contextProviders/OfflineContext';
import useMapCenter from '../../hooks/useMapCenter';
import usePrevious from '../../hooks/usePrevious';
import {
  PeakList, PeakListVariants,
} from '../../types/graphQLTypes';

export interface PeakListDatum {
  id: PeakList['id'];
  name: PeakList['name'];
  locationText: PeakList['locationText'];
  center: PeakList['center'];
  bbox: PeakList['bbox'];
  numUsers: PeakList['numUsers'];
  numMountains: PeakList['numMountains'];
  numTrails: PeakList['numTrails'];
  numCampsites: PeakList['numCampsites'];
  numCompletedTrips: PeakList['numCampsites'];
}

export interface SuccessResponse {
  peakLists: PeakListDatum[];
}

export interface Variables {
  latitude: number;
  longitude: number;
  limit: number;
}

const GEO_NEAR_PEAK_LISTS = gql`
  query SearchPeakLists(
    $latitude: Float!,
    $longitude: Float!,
    $limit: Int!,
  ) {
    peakLists: geoNearPeakLists(
      latitude: $latitude,
      longitude: $longitude,
      limit: $limit,
    ) {
      id
      name
      locationText
      center
      bbox
      numUsers
      numMountains
      numTrails
      numCampsites
      numCompletedTrips
    }
  }
`;

export const useGeoNearVariables = () => {
  const [longitude, latitude] = useMapCenter();

  return useMemo(() => ({
    latitude: parseFloat(latitude.toFixed(2)),
    longitude: parseFloat(longitude.toFixed(2)),
    limit: 15,
  }), [latitude, longitude]);
};

export const useGeoNearPeakLists = () => {
  const {offline} = useOfflineContext();
  const client = useApolloClient();
  const variables = useGeoNearVariables();
  const {loading, error, data} = useQuery<SuccessResponse, Variables>(GEO_NEAR_PEAK_LISTS, {
    variables,
  });

  const prevData = usePrevious(data);
  let dataToUse: SuccessResponse | undefined;
  if (data !== undefined) {
    dataToUse = data;
  } else if (prevData !== undefined) {
    dataToUse = prevData;
  } else if (offline && client) {
  const origin = point([variables.longitude, variables.latitude]);
  const cachedData = client.extract();
  const peakLists: PeakListDatum[] =
    orderBy(
      Object.entries(cachedData)
        .filter(([, list]: [string, Partial<PeakList>]) => (
          list.__typename === 'PeakListType' &&
          list &&
          list.type &&
          list.type === PeakListVariants.standard &&
          list.id &&
          list.name &&
          list.locationText &&
          list.bbox &&
          Array.isArray(list.bbox) &&
          (list.bbox as any[]).length === 4 &&
          (list.numMountains || list.mountains) &&
          (list.numTrails || list.trails) &&
          (list.numCampsites || list.campsites)
        )).map(([, list]: [key: string, list: PeakList]) => {
          const numCompletedTrips = Object.entries(list).find(([key]: [string, any]) => key.includes('numCompletedTrips'));
          return {
            id: list.id,
            name: list.name,
            locationText: list.locationText,
            center: midpoint(
                point([(list.bbox as [number, number, number, number])[0], (list.bbox as [number, number, number, number])[1]]),
                point([(list.bbox as [number, number, number, number])[2], (list.bbox as [number, number, number, number])[3]]),
              ).geometry.coordinates,
            bbox: list.bbox,
            numUsers: list.numUsers || 0,
            numMountains: list.mountains && list.mountains.length ? list.mountains.length : (list.numMountains || 0) ,
            numTrails: list.trails && list.trails.length ? list.trails.length : (list.numTrails || 0),
            numCampsites: list.campsites && list.campsites.length ? list.campsites.length : (list.numCampsites || 0),
            numCompletedTrips: numCompletedTrips ? numCompletedTrips[1] : 0,
          };
        }),
        (list: PeakListDatum) => {
          return distance(origin, point(list.center));
        },
    ).slice(0, variables.limit);
  dataToUse = {
      peakLists,
    };
  } else {
    dataToUse = undefined;
  }
  return {loading, error, data: dataToUse, ...variables};
};

export const refetchGeoNearPeakLists = (variables: Variables) => ({query: GEO_NEAR_PEAK_LISTS, variables});
