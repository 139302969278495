import queryString from 'query-string';
import {PeakListVariants} from '../types/graphQLTypes';
import {
  Months,
  Seasons,
} from '../Utils';
import { GlobalQueryParams, Routes } from './routes';

export const listDetailLink = (id: string) => Routes.ListDetail.replace(':id', id);

export const mountainDetailLink = (id: string) => Routes.MountainDetail.replace(':id', id);

export const summitViewLink = (lat: number, lng: number, altitude: number, id: string) =>
  Routes.SummitView
    .replace(':id', id)
    .replace(':lat', lat.toFixed(6))
    .replace(':lng', lng.toFixed(6))
    .replace(':altitude', (altitude * 0.3048).toFixed(0));

export const campsiteDetailLink = (id: string) => Routes.CampsiteDetail.replace(':id', id);

export const trailDetailLink = (id: string) => Routes.TrailDetail.replace(':id', id);
export const trailheadDetailLink = (id: string) => Routes.TrailheadDetail.replace(':id', id);

export const viewpointDetailLink = (id: string) => Routes.ViewpointDetail.replace(':id', id);

export const waterpointDetailLink = (id: string) => Routes.WaterpointDetail.replace(':id', id);

export const parkDetailLink = (id: string) => Routes.ParkDetail.replace(':id', id);

export const routePlanLink = (id?: string, edit?: boolean) => {
  let params = '';
  if (id) {
    params = `?id=${id}`;
    if (edit) {
      params = params + '&edit=yes';
    }
  }
  return Routes.RoutePlan + params;
};

export interface GeoLocationParams {
  name?: string;
  subtitle?: string;
  coord?: [number, number];
}

export const geoLocationLink = (params: GeoLocationParams) => {
  const hashed = encodeURIComponent(btoa(JSON.stringify(params)));
  return Routes.GeoLocationDetail.replace(':id', hashed);
};

export const addQueryParam = (route: string, params: GlobalQueryParams, replace?: boolean) => {
  let allParams: { [key: string]: any } = { ...params };
  if (!replace) {
    const parsed = queryString.parse(location.search);
    allParams = { ...params, ...parsed};
  }
  const stringified = queryString.stringify(allParams);
  if (stringified.length) {
    return route + '?' + stringified;
  } else {
    return route;
  }
};

export type AddTripReportLinkParams = {
  refpath?: string | null;
  mountains?: string | string[] | null;
  trails?: string | string[] | null;
  campsites?: string | string[] | null;
  friends?: string | string[] | null;
  listtype?: PeakListVariants | null;
  month?: Months | null;
  season?: Seasons | null;
  date?: string | null;
  notification?: 'yes' | 'no' | null;
};

export type EditTripReportLinkParams = {
  refpath?: string | null;
  id: string;
};

export const addTripReportLink = (input: AddTripReportLinkParams) => {
  const query = queryString.stringify(input);
  return query ? Routes.AddTripReport + '?' + query : Routes.AddTripReport;
};
export const editTripReportLink = (input: EditTripReportLinkParams) => {
  const query = queryString.stringify(input);
  return query ? Routes.EditTripReport + '?' + query : Routes.AddTripReport;
};

export type TripReportDetailLinkParams = {
  id: string;
};

export const tripReportDetailLink = (input: TripReportDetailLinkParams) => {
  const query = queryString.stringify(input);
  return Routes.TripReportDetail + '?' + query;
};

export const userProfileLink = (id: string) => Routes.UserProfile.replace(':id', id);

export const otherUserPeakListLink = (friendId: string, peakListId: string) =>
  Routes.OtherUserPeakList.replace(':id', friendId).replace(':peakListId', peakListId);

export const editMountainLink = (id: string) => Routes.EditMountain.replace(':id', id);
export const editCampsiteLink = (id: string) => Routes.EditCampsite.replace(':id', id);
export const editWaterpointLink = (id: string) => Routes.EditWaterpoint.replace(':id', id);
export const editViewpointLink = (id: string) => Routes.EditViewpoint.replace(':id', id);
export const editTrailLink = (id: string) => Routes.EditTrail.replace(':id', id);
export const editTrailParentLink = (id: string) => Routes.EditTrailParent.replace(':id', id);
export const editPeakListLink = (id: string) => Routes.EditList.replace(':id', id);

export const autoRouteDetailLink = {
  parkingToMountain: (mountainId: string, parkingId: string) =>
    Routes.AutoRouteDetailParkingToMountain.replace(':mountainId', mountainId).replace(':parkingId', parkingId),
  mountainToCampsite: (mountainId: string, campsiteId: string) =>
    Routes.AutoRouteDetailMountainToCampsite.replace(':mountainId', mountainId).replace(':campsiteId', campsiteId),
  campsiteToCampsite: (campsiteId1: string, campsiteId2: string) =>
    Routes.AutoRouteDetailCampsiteToCampsite.replace(':campsiteId1', campsiteId1).replace(':campsiteId2', campsiteId2),
  trailToMountain: (trailId: string, mountainId: string) =>
    Routes.AutoRouteDetailTrailToMountain.replace(':trailId', trailId).replace(':mountainId', mountainId),
  trailToCampsite: (trailId: string, campsiteId: string) =>
    Routes.AutoRouteDetailTrailToCampsite.replace(':trailId', trailId).replace(':campsiteId', campsiteId),
};

export const preventNavigation = (e: React.SyntheticEvent) => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};
