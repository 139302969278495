import React from 'react';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import { DottedSegment, FullWidthContainer } from '../../../styling/styleUtils';
import DefaultError from '../../sharedComponents/DefaultError';
import Content from './Content';
import Header from './Header';

interface Props {
  id: string;
}

const ViewpointDetail = (props: Props) => {
  const { id } = props;
  const {offline} = useOfflineContext();

  let content: React.ReactElement<any> | null;
  if (offline) {
    content = (
      <FullWidthContainer>
        <DottedSegment>
          <DefaultError
            error={'You are offline'}
            offlineMessage={'Connect to the internet for more details.'}
          />
        </DottedSegment>
      </FullWidthContainer>
    );
  } else {
    content = <Content id={id} />;
  }

  return (
    <>
      <Header id={id} />
      {content}
    </>
  );
};

export default ViewpointDetail;
