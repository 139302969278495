import { faFacebookF, faRedditAlien, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../../../../hooks/useFluent';
import { BasicIconInText, lightBaseColor, primaryColor, primaryHoverColor, tertiaryColor } from '../../../../../styling/styleUtils';
import { AggregateItem, AutoItem, AuxiliaryItem, CoreItem, UserContentItem } from '../../../../../types/itemTypes';
import IconButton from '../../../IconButton';
import Modal, { DefaultCloseActions } from '../../../Modal';
import getShareFunctions from './shareFn';

const SectionTitle = styled.h3`
  text-align: center;
  margin-top: 0;
`;

const IconBase = styled(FontAwesomeIcon)`
  color: ${primaryColor};
  font-size: 1.25rem;
`;

const CopyUrlBar = styled.div`
  font-size: 0.875rem;
  min-height: 1rem;
  max-width: 450px;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 1.45rem 1fr auto;
  background-color: ${tertiaryColor};
  color: ${lightBaseColor};
  margin-bottom: 2rem;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
`;

const CopyIcon = styled(IconBase)`
  padding-left: 0.45rem;
  font-size: 0.9rem;
`;

const UrlText = styled.div`
  overflow: hidden;
  padding: 0.45rem 0.55rem;
`;

const CopyButton = styled.button`
  padding: 0.45rem 0.75rem;
  background-color: ${primaryColor};
  color: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  line-height: 0;
  font-size: 0.875rem;
  height: 100%;

  &:hover {
    background-color: ${primaryHoverColor};
  }
`;

const SocialMediaContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

interface Props {
  pageName: string;
  onClose: () => void;
  type: CoreItem | AutoItem | AggregateItem | AuxiliaryItem | UserContentItem | 'trip-report' | 'geo-location';
  snippet?: string;
  url?: string;
}

const ShareModal = (props: Props) => {
  const {
    onClose, pageName, type,
  } = props;
  const getString = useFluent();
  const url = props.url ? props.url : window.location.href;

  const [copied, setCopied] = useState<boolean>(false);
  const onCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };
  const copyText = copied ? 'Copied' : 'Copy';

  const { shareFacebook, shareTwitter, shareEmail, shareReddit } = getShareFunctions(url);

  const snippet = props.snippet ? props.snippet : getString('share-modal-snippet', {
    name: pageName,
    type,
  });

  return (
    <Modal
      onClose={onClose}
      height='auto'
      width='500px'
      actions={<DefaultCloseActions onClose={onClose} />}
    >
      <SectionTitle>
        {getString('share-modal-direct-link')}
      </SectionTitle>
      <CopyUrlBar onClick={onCopy}>
        <CopyIcon
          icon={faLink}
        />
        <UrlText>
          {url}
        </UrlText>
        <CopyButton>
          <BasicIconInText icon={faCopy} />
          {copyText}
        </CopyButton>
      </CopyUrlBar>
      <SectionTitle>
        {getString('share-modal-social-sharing')}
      </SectionTitle>
      <SocialMediaContainer>
        <IconButton
          icon={faFacebookF}
          color={'#4267B2'}
          onClick={() => shareFacebook()}
        />
        <IconButton
          icon={faTwitter}
          color={'#00acee'}
          onClick={() => shareTwitter(snippet)}
        />
        <IconButton
          icon={faRedditAlien}
          color={'#FF4500'}
          onClick={() => shareReddit()}
        />
        <IconButton
          icon={faEnvelope}
          onClick={() => shareEmail(
            type === 'trip-report' ? 'Wilderlist Trip Report: ' + pageName : getString('share-modal-email-subject', {name: pageName}),
            snippet,
            url,
          )}
        />
      </SocialMediaContainer>
    </Modal>
  );
};

export default ShareModal;
