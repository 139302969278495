import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faGoogle,
  faReddit,
} from '@fortawesome/free-brands-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localforage from 'localforage';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import BackupImage from '../../assets/images/default-user-image.jpg';
import HikerAtSummit from '../../assets/images/empty-states/trip_logs.svg';
import LogoPng from '../../assets/logo/logo.png';
import useFluent from '../../hooks/useFluent';
import {
  baseColor,
  BasicIconInTextCompact,
  borderRadius,
  GhostButton,
  lightBaseColor,
  lightBorderColor,
  LinkButton,
  primaryColor,
  tertiaryColor,
} from '../../styling/styleUtils';
import { User } from '../../types/graphQLTypes';
import { mobileSize } from '../../Utils';
import Modal from './Modal';

export const googleBlue = '#4285f4';
export const facebookBlue = '#1877F2';
export const redditRed = '#ff4500';

export const LoginButtonBase = styled.a`
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: auto 20px;
  max-height: 40px;
  min-width: 168px;
  max-width: 200px;
  text-decoration: none;
  border: 1px solid #efefef;
  color: ${lightBaseColor};

  &:hover {
    color: ${lightBaseColor};
    background-color: #efefef;
  }
`;

export const BrandIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-left: 8px;
`;

export const LoginText = styled.span`
  font-size: 14px;
  padding: 8px;
`;

const Root = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "logo logo"
    "img buttons"
    "tos tos";

  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "buttons"
      "tos";
  }
`;

const LogoContainer = styled.div`
  grid-area: logo;
  display: flex;
`;

const ImageContainer = styled.div`
  grid-area: img;

  img {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  grid-area: buttons;
  padding-right: 2rem;

  @media (max-width: ${mobileSize}px) {
    padding: 1rem;
  }
`;

const ToSContainer = styled.div`
  grid-area: tos;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  color: ${lightBaseColor};

  p {
    margin: 0;
    max-width: 200px;
    padding-bottom: 0;
  }
`;

const WilderlistLogo = styled.img`
  width: 180px;
  margin: 1rem auto 0;

  @media (max-width: ${mobileSize}px) {
    width: 200px;
    margin: 1rem auto 0;
  }
`;

const Title = styled.h2`
  font-size: 1.3rem;
  line-height: 1.3;
  font-weight: 200;
  max-width: 200px;
  margin: 1.5rem auto;

  @media (max-width: ${mobileSize}px) {
    margin 0 auto 1.5rem;
    text-align: center;
  }
`;

const LoginButton = styled(LoginButtonBase)`
  margin: 10px auto;
  max-width: 200px;
  max-height: 50px;
  border: 1px solid ${lightBorderColor};
`;

const CloseButton = styled(GhostButton)`
  display: block;
  padding: 0.35rem 0.5rem;
  text-align: right;
  display: flex;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
`;

const OnReturnRoot = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  width: 100%;
  justify-content: center;
  grid-template-areas:
    "logo"
    "buttons"
    "tos";

  @media (max-width: ${mobileSize}px) {
    margin-top: 1rem;
  }
`;

const OnReturnButtonsContainer = styled.div`
  grid-area: buttons;
  padding: 1rem;
`;

const LoginAgainButton = styled.a`
  width: min-content;
  max-width: 100%;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: auto 200px auto;
  padding: 0;
  color: ${baseColor};
  text-decoration: none;
  background-color: #fff;
  border: solid 1px ${lightBorderColor};
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  text-align: left;
  gap: 1rem;

  &:hover, &:active, &:focus {
    color: ${baseColor};
  }

  &:hover {
    background-color: ${tertiaryColor};
  }

  @media (max-width: ${mobileSize}px) {
    width: 100%;
  }

  @media (max-width: 320px) {
    grid-template-columns: auto 130px auto;
  }
`;

const ProfilePicture = styled.img`
  max-width: 3rem;
`;

const WelcomeBackText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0.2rem;
  font-size: 0.875rem;
`;
const WelcomeBackEmail = styled(WelcomeBackText)`
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
`;

const LoginIcon = styled(FontAwesomeIcon)`
  margin-left: auto;
  margin-right: 0.75rem;
  font-size: 1.3rem;
  color: ${primaryColor};
`;

const NotYouText = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const WelcomeBackUser = ({
  prevUser,
  onClearPrevUser,
}: {
  prevUser: User,
  onClearPrevUser: () => void,
}) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>(prevUser.profilePictureUrl);
  const onImageError = () => {
    if (profilePictureUrl !== BackupImage) {
      setProfilePictureUrl(BackupImage);
    }
  };

  let email: React.ReactElement<any> | null;
  let url: string;
  if (prevUser.googleId) {
    url = '/auth/google';
    email = (
      <>
        <BasicIconInTextCompact
          icon={faGoogle as IconDefinition}
          style={{color: googleBlue}}
        />
        {prevUser.email}
      </>
    );
  } else if (prevUser.facebookId) {
    url = '/auth/facebook';
    email = (
      <>
        <BasicIconInTextCompact
          icon={faFacebook as IconDefinition}
          style={{color: facebookBlue}}
        />
        {prevUser.email}
      </>
    );
  } else if (prevUser.redditId) {
    url = '/auth/reddit';
    email = (
      <>
        <BasicIconInTextCompact
          icon={faReddit as IconDefinition}
          style={{color: redditRed}}
        />
        {'u/'}{prevUser.name}
      </>
    );
  } else {
    url = '';
    email = null;
    onClearPrevUser();
  }

  return (
    <OnReturnButtonsContainer>
      <LoginAgainButton href={url}>
        <ProfilePicture src={profilePictureUrl} alt='Profile picutre' onError={onImageError} />
        <div>
          <WelcomeBackText>Welcome back, {prevUser.name}</WelcomeBackText>
          <WelcomeBackEmail>
            {email}
          </WelcomeBackEmail>
        </div>
        <LoginIcon icon={faSignInAlt} />
      </LoginAgainButton>
      <NotYouText>
        Not you? <LinkButton onClick={onClearPrevUser}>Sign in with a different account.</LinkButton>
      </NotYouText>
    </OnReturnButtonsContainer>
  );
};

export const StackedLoginButtons = () => {
  const getString = useFluent();

  const [prevUser, setPrevUser] = useState<'loading' | User | null>('loading');
  useEffect(() => {
    localforage.getItem<User>('prevUser')
      .then(d => setPrevUser(d))
      .catch(() => setPrevUser(null));
  });
  const onClearPrevUser = useCallback(() => {
    localforage.setItem('prevUser', null).then(() => setPrevUser(null));
  }, []);
  if (prevUser === 'loading') {
    return null;
  }
  if (prevUser === null) {
    return (
      <>
        <LoginButton href='/auth/google'>
          <BrandIcon
            icon={faGoogle as IconDefinition}
            style={{color: googleBlue}}
          />
          <LoginText>
            {getString('header-text-login-with-google')}
          </LoginText>
        </LoginButton>
        <LoginButton href='/auth/facebook'>
          <BrandIcon
            icon={faFacebook as IconDefinition}
            style={{color: facebookBlue}}
          />
          <LoginText>
            {getString('header-text-login-with-facebook')}
          </LoginText>
        </LoginButton>
        <LoginButton href='/auth/reddit'>
          <BrandIcon
            icon={faReddit as IconDefinition}
            style={{color: redditRed}}
          />
          <LoginText>
            {getString('header-text-login-with-reddit')}
          </LoginText>
        </LoginButton>
      </>
    );
  }
  return (
    <WelcomeBackUser
      prevUser={prevUser}
      onClearPrevUser={onClearPrevUser}
    />
  );
};

interface Props {
  onCancel: () => void;
}

const SignUpModal = (props: Props) => {
  const { onCancel } = props;
  const getString = useFluent();

  const [prevUser, setPrevUser] = useState<'loading' | User | null>('loading');
  useEffect(() => {
    localforage.getItem<User>('prevUser')
      .then(d => setPrevUser(d))
      .catch(() => setPrevUser(null));
  });
  const onClearPrevUser = useCallback(() => {
    localforage.setItem('prevUser', null).then(() => setPrevUser(null));
  }, []);
  if (prevUser === 'loading') {
    return null;
  }
  if (prevUser === null) {
    return (
      <Modal
        onClose={onCancel}
        width={'600px'}
        height={'auto'}
        actions={null}
        contentStyles={{padding: 0, borderRadius, overflow: 'hidden'}}
      >
        <Root>
          <LogoContainer>
            <WilderlistLogo src={LogoPng} />
          </LogoContainer>
          <ButtonsContainer>
            <Title dangerouslySetInnerHTML={{__html: getString('global-text-value-modal-join-next-step')}} />
            <StackedLoginButtons />
          </ButtonsContainer>
          <ToSContainer>
            <p dangerouslySetInnerHTML={{__html: getString('terms-of-use-you-accept')}} />
          </ToSContainer>
          <ImageContainer>
            <img src={HikerAtSummit} alt={'Hiker at summit with dog'} />
          </ImageContainer>
          <CloseButton onClick={onCancel}>×</CloseButton>
        </Root>
      </Modal>
    );
  }

  return (
    <Modal
      onClose={onCancel}
      width={'600px'}
      height={'auto'}
      actions={null}
      contentStyles={{padding: 0, borderRadius, overflow: 'hidden'}}
    >
      <OnReturnRoot>
        <LogoContainer>
          <WilderlistLogo src={LogoPng} />
        </LogoContainer>
        <WelcomeBackUser
          prevUser={prevUser}
          onClearPrevUser={onClearPrevUser}
        />
        <ToSContainer>
          <p dangerouslySetInnerHTML={{__html: getString('terms-of-use-you-accept')}} />
        </ToSContainer>
        <CloseButton onClick={onCancel}>×</CloseButton>
      </OnReturnRoot>
    </Modal>
  );

};

export default SignUpModal;
