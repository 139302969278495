import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import {Routes} from '../../../routing/routes';
import { navHeight } from '../../../styling/Grid';
import {
  GhostButton,
  lightBaseColor,
  lightBorderColor,
  tertiaryColor,
} from '../../../styling/styleUtils';
import {isTouchDevice, mobileSize} from '../../../Utils';
import BackButton from './backButton';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const RootBase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${mobileSize + 1}px) {
    display: none;
  }
`;

const InlineRoot = styled(RootBase)`
  margin: -1rem -1rem 0.5rem;
  width: auto;
`;

const Tab = styled.div`
  width: 100%;
  height: auto;
  background-color: ${tertiaryColor};
  pointer-events: all;
  position: relative;
  bottom: -1px;
  border: solid 1px ${lightBorderColor};
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  transform: translateY(0%);
  color: ${lightBaseColor};
`;

const CloseButton = styled(GhostButton)`
  padding: 0.75rem 0.5rem;
  line-height: 0;
  font-size: 1.75rem;
`;

const NavigationBackdrop = styled.div`
  width: 100%;
  position: absolute;
  height: ${navHeight}px;
  pointer-events: none;
  margin-bottom: ${navHeight * 2}px;

  &.sticky:after {
    content: '';
    animation: ${fadeIn} 0.25s ease-in-out 1 forwards;
    pointer-events: all;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: ${navHeight * 1.1}px;
    background-color: white;
    pointer-events: all;
    border-bottom: solid 1px ${lightBorderColor};
    box-shadow: 0 -1px 3px 1px #d1d1d1;
  }
`;

interface Props {
  hideTab?: boolean;
}
export const MOBILE_TAB_BAR_ID = 'mobile-tab-bar-id';

const MobileTab = ({hideTab}: Props) => {
  const { push } = useHistory();

  const tab = hideTab || !isTouchDevice() ? null : <Icon icon={faGripLines} />;

  const Root = hideTab ? InlineRoot : RootBase;

  const headerBackdropRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const node = headerBackdropRef.current;
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('sticky', e.intersectionRatio < 1),
      {threshold: [1]},
    );
    if (node) {
      observer.observe(node);
    }
  }, []);

  return (
    <>
      <Root id={MOBILE_TAB_BAR_ID}>
      <NavigationBackdrop ref={headerBackdropRef} />
        <Tab style={{
          backgroundColor: hideTab ? '#fff' : undefined,
          transform: hideTab ? 'translateY(0.3rem)' : undefined,
        }}>
          <BackButton
            mobileButton={true}
            key={'mobile-back-button'}
          />
          {tab}
          <CloseButton
            onClick={() => push(Routes.Landing)}
          >
            ×
          </CloseButton>
        </Tab>
      </Root>
    </>
  );
};

export default MobileTab;
