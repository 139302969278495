import React from 'react';
import useFluent from '../../../../../hooks/useFluent';
import { ButtonSecondary } from '../../../../../styling/styleUtils';
import Modal from '../../../../sharedComponents/Modal';
import { MapStyle } from '../../../globalMap/map';
import { AccessOverlay } from '../../map/access';
import { WeatherOverlay, WeatherState } from '../../map/weather';
import MapLayersSelection from './MapLayersSelection';

interface Props {
  onClose: () => void;
  mapStyle: MapStyle;
  setMapStyle: (style: MapStyle) => void;
  mapWeather: WeatherState | null;
  setWeather: (value: WeatherOverlay | null) => void;
  access: AccessOverlay | null;
  setAccess: (value: AccessOverlay | null) => void;
}

const LayersModal = (props: Props) => {
  const { onClose, mapStyle, setMapStyle, mapWeather, setWeather, access, setAccess} = props;
  const getString = useFluent();
  const actions = (
    <ButtonSecondary
      onClick={onClose}
      mobileExtend={true}
      style={{marginLeft: 'auto', display: 'block'}}
    >
      {getString('global-text-value-modal-close')}
    </ButtonSecondary>
  );

  return (
    <Modal
      onClose={onClose}
      width={'500px'}
      height={'auto'}
      actions={actions}
    >
      <MapLayersSelection
        mapStyle={mapStyle}
        setMapStyle={setMapStyle}
        mapWeather={mapWeather}
        setWeather={setWeather}
        access={access}
        setAccess={setAccess}
      />
    </Modal>
  );
};

export default LayersModal;
