import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  GET_USERS_SAVED_MOUNTAINS,
  MutationVariables,
  REMOVED_SAVED_MOUNTAIN_FROM_USER,
  SAVE_MOUNTAIN_TO_USER,
  SuccessResponse,
  Variables,
} from './query';

export const useSavedMountains = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_MOUNTAINS, {variables: {userId}},
  );

  const [saveMountainToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_MOUNTAIN_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_MOUNTAINS, variables: {userId}}],
    });

  const [removeSavedMountainFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_MOUNTAIN_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_MOUNTAINS, variables: {userId}}],
    });

  return {response, saveMountainToUser, removeSavedMountainFromUser};
};
