import { noop } from 'lodash';
import trackUser, { getCachedUsersLocation, setCachedUsersLocation } from '../components/template/globalMap/usersLocation/track';

interface Input {
  onNotSupported?: () => void;
  onBeforeFetch?: () => void;
  onError?: (error: GeolocationPositionError) => void;
  timeout?: number;
  onSuccess: (position: GeolocationPosition) => void;
}

const getUsersGeolocation = ({
  onNotSupported = noop,
  onBeforeFetch = noop,
  onError = noop,
  onSuccess,
  timeout = 10000,
}: Input) => {
  const cachedPosition = getCachedUsersLocation();
  if (cachedPosition) {
    onSuccess(cachedPosition);
    trackUser();
  } else if (!navigator.geolocation) {
      onNotSupported();
  } else {
    onBeforeFetch();
    navigator.geolocation.getCurrentPosition(position => {
      setCachedUsersLocation(position);
      onSuccess(position);
      trackUser();
    }, error => {
      console.error(error);
      onError(error);
    }, {timeout});
  }
};

export default getUsersGeolocation;
