import { useContext } from 'react';
import {
  UserContext,
} from '../contextProviders/userContext';

/**
 * `null` means the check for the current user hasn't happened yet\
 * `''` empty string means there is no logged in user\
 * `User` is the logged in user
 */
const useCurrentUser = () => {
  const user = useContext(UserContext);
  return user;
};

export default useCurrentUser;
