import debounce from 'lodash/debounce';
import { asyncForEach } from '../../../Utils';
import {
  getSlippyFromMapboxTileUrl,
  MAP_VERSION,
  OFFLINE_MAPS_CACHE_NAME,
} from './Utils';

const offlineMapsWorker = new Worker(process.env.PUBLIC_URL + '/offline-maps-worker.js');

const OFFLINE_NOTIFICATION_DIV_ID = 'offline-notification-div-id';

const offlineSyncNotification = `
  <div style="
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 250px;
    background-color: #d0e3b1;
    border: solid 1px #bdcfa0;
    color: #304213;
    border-top: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 0.5rem;
    box-sizing: border-box;
    box-shadow: 0 1px 3px 1px #d1d1d1;
    font-size: 0.875rem;
    font-weight: 600;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
  ">
    <div>
      Syncing offline maps...
      <br>
      <small>Do not close Wilderlist until complete</small>
    </div>
    <div><span id="offline-sync-percent-id">0</span>%</div>
  </div>
`;

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return event.returnValue = 'Offline maps are syncing. If you leave you may lose data.';
};

const fetchUrls = debounce((urls: string[]) => {
  if (urls.length) {
    const notification = document.getElementById(OFFLINE_NOTIFICATION_DIV_ID) || document.createElement('div');
    notification.id = OFFLINE_NOTIFICATION_DIV_ID;
    notification.style.display = 'none';
    notification.innerHTML = offlineSyncNotification;
    document.body.appendChild(notification);
    window.addEventListener('beforeunload', beforeUnloadListener);
    offlineMapsWorker.postMessage({message: 'FETCH_URLS', urls});
  }
}, 500);

interface OfflineSyncOptions {
  resetAll?: boolean;
}

offlineMapsWorker.addEventListener('message',
  async (event: MessageEvent<{
    message?: string,
    savedTiles?: Record<number, Record<number, Record<number, boolean>>>,
    urls?: string[]
    existingUrlKeys?: string[]
    resetAll?: boolean
    value?: number,
  }>) => {
    if (event.data.message === 'GENERATED_TILES_AND_URLS' && event.data.existingUrlKeys && event.data.urls) {
      const {savedTiles, urls, existingUrlKeys, resetAll} = event.data;
      const cache = await caches.open(OFFLINE_MAPS_CACHE_NAME);
      await asyncForEach(existingUrlKeys, async url => {
        const [z, x, y] = getSlippyFromMapboxTileUrl(url);
        if (resetAll || !savedTiles || !savedTiles?.[z]?.[x]?.[y] || !url.includes('--v=' + MAP_VERSION)) {
          await cache.delete(url);
        }
      });
      if (urls.length) {
        // Fetch any URLs that need to be synced
        fetchUrls(urls);
      }
    }

    if (event.data.message === 'SHOW_NOTIFICATION') {
      const notification = document.getElementById(OFFLINE_NOTIFICATION_DIV_ID);
      if (notification) {
        notification.style.display = 'block';
      }
    }
    if (event.data.message === 'UPDATE_NOTIFICATION_PROGRESS') {
      const countElm = document.getElementById('offline-sync-percent-id');
      if (countElm) {
          countElm.innerText = `${event.data.value ?? ''}`;
        }
    }
    if (event.data.message === 'REMOVE_NOTIFICATION') {
      const notification = document.getElementById(OFFLINE_NOTIFICATION_DIV_ID);
      if (notification) {
        notification.remove();
        window.removeEventListener('beforeunload', beforeUnloadListener);
      }
    }
  });

export const syncOfflineTiles = async (options?: OfflineSyncOptions) => {
  try {
    // Post to service-worker that a sync has been called
    // This will trigger the sw to rebuild its accepted tiles
    // in parallel. There is a possibility of a race condition
    if (navigator?.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({type: 'SYNC_OFFLINE_TILES'});
        // Convert saved map specifications into tiles with form {z: {x: {y: boolean}}}
      // const savedTiles = await getSavedTiles()
      // Open the offline maps cache
      const cache = await caches.open(OFFLINE_MAPS_CACHE_NAME);
      // Delete from cache any tiles that are no longer in our list or are from an older map version
      const keys = await cache.keys();
      const existingUrlKeys = keys.map(k => k.url);
      // Convert saved map specifications into tiles with form {z: {x: {y: boolean}}}
      offlineMapsWorker.postMessage({
        message: 'GET_TILES_AND_URLS',
        existingUrlKeys,
        resetAll: options?.resetAll,
      });
    } else {
      throw new Error('Service worker is not available');
    }
  } catch (error) {
    console.error(error);
  }
};
