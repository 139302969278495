import { useEffect, useState } from 'react';
import { isTouchDevice } from '../Utils';

interface Options {
  time?: number;
  touchDeviceOnly?: boolean;
}

const useDisabledOnLoad = (options?: Options) => {
  const [disabled, setDisabled] = useState<boolean>(options?.touchDeviceOnly ? isTouchDevice() : true);
  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, options && options.time !== undefined ? options.time : 500);
  }, [options]);
  return disabled;
};

export default useDisabledOnLoad;
