import {
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components/macro';
import { useOfflineContext } from '../../contextProviders/OfflineContext';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import useCurrentUser from '../../hooks/useCurrentUser';
import useFluent from '../../hooks/useFluent';
import {
  ItemTitle,
} from '../../styling/sharedContentStyles';
import {
  BasicIconInTextCompact,
  ButtonWarningLow,
  lightBaseColor,
  lowWarningColor,
  TextareaDotted,
} from '../../styling/styleUtils';
import SignUpModal from './SignUpModal';

const defaultHeight = 0.75; // in rem

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: ${defaultHeight}rem;
  position: relative;
`;

const Title = styled(ItemTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: all 0.2s ease;
  box-sizing: border-box;
  padding: 0.125rem 0 0;
  width: 100%;
`;

const SavedSuccess = styled.small`
  font-size: 0.65rem;
  color: ${lightBaseColor};
  position: absolute;
  transition: opacity 0.1s ease;
`;

const charLimit = 5000;

const SuccesfulSaveText = () => {
  const getString = useFluent();
  const [hide, setHide] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 3000);
  }, []);
  return (
    <SavedSuccess style={{ opacity: hide ? '0' : '1' }}>
      <em>{getString('global-text-value-all-changes-saved')}</em>
    </SavedSuccess>
  );
};

interface Props {
  placeholder: string;
  defaultValue: string;
  onSave: (value: string) => void;
}

const UserNote = (props: Props) => {
  const {placeholder, defaultValue, onSave} = props;
  const [value, setValue] = useState<string>(defaultValue);
  const [height, setHeight] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowSignUpModalVisible, setIsShowSignUpModalOpen] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const {offline} = useOfflineContext();

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef && textAreaRef.current) {
        setHeight(textAreaRef.current.scrollHeight);
      }
      setIsLoading(false);
    }, 0);
  }, [textAreaRef, defaultValue]);

  const user = useCurrentUser();

  const getString = useFluent();

  useBeforeUnload(!((!defaultValue && !value) || (defaultValue === value)));

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (user) {
      setValue(e.target.value);
    }
    if (e.target.scrollHeight > height) {
      setHeight(e.target.scrollHeight);
    }
  };
  let buttonText: React.ReactElement<any> | null;
  if ((!defaultValue && !value) || offline) {
    buttonText = null;
  } else if (defaultValue === value) {
    buttonText = (
      <SuccesfulSaveText />
    );
  } else {
    const innerText = isLoading
      ? getString('global-text-value-saving') + '...' : getString('global-text-value-save-changes');
    const onClick = () => {
      onSave(value.substring(0, charLimit));
      setIsLoading(true);
    };
    buttonText = (
      <ButtonWarningLow
        onClick={onClick}
        disabled={isLoading}
        style={{cursor: isLoading ? 'progress' : undefined, width: '100%'}}
      >
        {innerText}
      </ButtonWarningLow>
    );
  }

  const buttons = (
    <ButtonContainer>
      {buttonText}
    </ButtonContainer>
  );

  const signUpModal = !user && isShowSignUpModalVisible ? (
    <SignUpModal
      onCancel={() => setIsShowSignUpModalOpen(false)}
    />
  ) : null;

  const showSignUpModal = !user ? () => {
    const textareaNode: HTMLTextAreaElement | null = document.querySelector(':focus');
    if (textareaNode) {
      textareaNode.blur();
    }
    setIsShowSignUpModalOpen(true);
  } : undefined;

  const note = offline ? (
    <div><em><small>{value || '---'}</small></em></div>
  ) : (
    <TextareaDotted
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength={charLimit}
      ref={textAreaRef}
      style={{height, borderColor: defaultValue !== value ? lowWarningColor : undefined}}
      onClick={showSignUpModal}
    />
  );

  return (
    <Root>
      <Title>
        <div>
          <BasicIconInTextCompact icon={faUserEdit} />
          {getString('user-notes-title')}
        </div>
      </Title>
      {note}
      {buttons}
      {signUpModal}
    </Root>
  );
};

export default UserNote;
