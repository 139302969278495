import styled, { css } from 'styled-components/macro';
import { baseColor, primaryColor, tertiaryColor } from '../../../../styling/styleUtils';
import { mobileSize } from '../../../../Utils';

const buttonStyles = css`
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.3rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  font-weight: 600;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  border-radius: 600px;

  &:hover {
    background-color: ${tertiaryColor};
  }

  @media (min-width: ${mobileSize}px) {
    border-radius: 6px;
    color: ${primaryColor};
  }
`;

export const Button = styled.button`
  ${buttonStyles}
`;

export const ZoomControlsWrapper = styled.div`
  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    background-color: transparent;
    border: none;
    box-shadow: none;
    button {
      ${buttonStyles}
    }
  }
`;

export const LayerButton = styled(Button)`
  span {
    display: none;
  }

  @media (min-width: ${mobileSize}px) and (min-height: 600px) {
    width: 4.1rem;
    min-height: 2.8rem;
    box-sizing: border-box;
    padding: 0 0 0.2rem;
    flex-direction: column;
    font-size: 1.1rem;
    margin-top: 0.25rem;

    span {
      display: block;
      font-size: 0.65rem;
      color: ${baseColor};
      margin-bottom: 0.3rem;
    }
  }

  &:hover {
    background-color: ${tertiaryColor};
  }
`;
