import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {
  baseColor,
  BasicIconInText,
  IconContainer as IconContainerBase,
  lightBaseColor,
  lightBlue,
  lightBorderColor,
  primaryColor,
} from '../../../styling/styleUtils';
import { mediumSize, mobileSize } from '../../../Utils';

export const Root = styled.section`
  margin: 0 -1rem;
  border-top: solid 1px ${lightBorderColor};
`;

export const Header = styled.div`
  padding: 1rem 0 0.25rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0.5rem 1rem 0 0;
  display: flex;
  align-items: center;

  @media (min-width: ${mobileSize}px) and (max-width: ${mediumSize}px) {
    margin: 0.5rem 0.5rem 0 0;
    font-size: 1rem;
  }
`;

export const NavContainer = styled.nav`
  width: 100%;
  display: flex;
`;

export const NavButton = styled.button`
  background-color: transparent;
  color: ${lightBaseColor};
  padding: 0 0.1rem 0.1rem;
  margin-right: 0.7rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  text-align: center;
  border-bottom: solid 2px transparent;
  text-decoration: none;

  &:hover {
    border-bottom-color: ${lightBlue};
  }

  @media (min-width: ${mobileSize}px) and (max-width: ${mediumSize}px) {
    margin-right: 0.55rem;
  }
`;

export const ActiveNavButton = styled(NavButton)`
  color: ${baseColor};
  border-bottom-color: ${primaryColor};

  &:hover {
    border-bottom-color: ${primaryColor};
  }
`;

const renderedButHiddenClassName = 'detail-segment-content-rendered-inactive';

const PanelContent = styled.div`
  &.${renderedButHiddenClassName} {
    display: none;
  }
`;

export const IconContainer = styled(IconContainerBase)`
  margin-right: 0.5rem;
`;

export const TitleIcon = styled(BasicIconInText)`
  color: ${lightBaseColor};
`;

const TitleIconCustom = styled(IconContainerBase)`
  margin-right: 0.6rem;
`;

export const NavContent = styled.span`
  display: flex;
  align-items: center;
`;

export type Panel = {
  title: string;
  // reactNode is of type "any" due to an issue with TypeScript overloading memory
  reactNode: any;
  // if true, panel should render with display: none instead of null if not selected:
  renderHiddenContent?: boolean;
  dontShowTitle?: boolean;
  customIcon?: boolean;
  icon?: string | any;
} & (
  {
    customIcon?: undefined;
  } |
  {
    customIcon: true,
    icon: string,
  } | {
    customIcon: false,
  // icon is of type "any" due to an issue with TypeScript overloading memory
    icon: any,
  }
);

interface Props {
  title?: string;
  icon?: any;
  customIcon?: boolean;
  panels: Panel[];
  // panelCounts as seperate prop prevents rerender of panels reactNodes every time the number changes
  panelCounts?: {index: number, count: number, numerator?: number}[];
  panelId: string;
}

const localStorageId = (id: string) => 'detailPanel_' + id;

const DetailSegment = (props: Props) => {
  const {
    panels, panelCounts, panelId, customIcon,
  } = props;

  const initialPanelValue = localStorage.getItem(localStorageId(panelId));
  const initialPanelIndex = initialPanelValue ? parseInt(initialPanelValue, 10) : 0;

  const [panelIndex, setPanelIndex] = useState<number>(
    initialPanelIndex && initialPanelIndex < panels.length ? initialPanelIndex : 0,
  );

  const buttons: React.ReactElement<any>[] = [];
  const panelContents: React.ReactElement<any>[] = [];
  panels.forEach((panel, i) => {
    const active = panelIndex === i;

    const Button = active ? ActiveNavButton : NavButton;
    const selectPanel = () => {
      localStorage.setItem(localStorageId(panelId), i.toString());
      setPanelIndex(i);
    };
    let icon: React.ReactElement<any> | null;
    if (panel.customIcon === true) {
      icon = (
        <IconContainer
          $color={active ? baseColor : lightBaseColor}
          dangerouslySetInnerHTML={{__html: panel.icon}}
        />
      );
    } else if (panel.customIcon === false) {
      icon = (
        <IconContainer
          $color={active ? baseColor : lightBaseColor}
        >
          <FontAwesomeIcon icon={panel.icon} />
        </IconContainer>
      );
    } else {
      icon = null;
    }

    const titleCount = panelCounts ? panelCounts.find(p => p.index === i) : undefined;
    const numerator = titleCount && titleCount.numerator !== undefined ? titleCount.numerator + '/' : '';
    const title = titleCount ? `${panel.title} (${numerator}${titleCount.count})` : panel.title;

    if (!panel.dontShowTitle) {
      buttons.push(
        <Button
          key={'panel-nav-button' + panel.title + i}
          onClick={selectPanel}
          style={panels.length === 1 ? {outline: 'none', cursor: 'default'} : undefined}
        >
          <NavContent>
            {icon}
            {title}
          </NavContent>
        </Button>,
      );
    }

    const content = active || panel.renderHiddenContent === true ? (
      <PanelContent
        className={panel.renderHiddenContent === true && !active ? renderedButHiddenClassName : undefined}
      >
        {panel.reactNode}
      </PanelContent>
    ) : null;
    panelContents.push(
      <React.Fragment key={'panel-content' + panel.title + i}>
        {content}
      </React.Fragment>,
    );
  });

  const topLevelIcon = props.icon
    ? customIcon
      ? (
        <TitleIconCustom $color={lightBaseColor} dangerouslySetInnerHTML={{__html: props.icon}} />
      ) : (
        <TitleIcon icon={props.icon} />
      )
    : null;
  const topLevelTitle = props.title ? (
    <Title>{topLevelIcon}{props.title}</Title>
  ) : null;

  return (
    <Root>
      <Header>
        {topLevelTitle}
        <NavContainer>
          {buttons}
        </NavContainer>
      </Header>
      <div>
        {panelContents}
      </div>
    </Root>
  );
};

export default DetailSegment;
