import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';

export const GET_CAMPSITE_NOTE = gql`
  query getCampsiteNote($userId: ID, $campsiteId: ID) {
    user(id: $userId) {
      id
      campsiteNote(campsiteId: $campsiteId) {
        id
        text
      }
    }
  }
`;

export interface QuerySuccessResponse {
  user: null | {
    id: User['name'];
    __typename: string;
    campsiteNote: null | {
      id: string;
      __typename: string;
      text: string;
    }
  };
}

export interface QueryVariables {
  userId: string | null;
  campsiteId: string | null;
}

export const ADD_CAMPSITE_NOTE = gql`
  mutation($userId: ID!, $campsiteId: ID!, $text: String!) {
    user: addCampsiteNote(
      userId: $userId,
      campsiteId: $campsiteId,
      text: $text
    ) {
      id
      campsiteNote(campsiteId: $campsiteId) {
        id
        text
      }
    }
  }
`;

export const EDIT_CAMPSITE_NOTE = gql`
  mutation($userId: ID!, $campsiteId: ID!, $text: String!) {
    user: editCampsiteNote(
      userId: $userId,
      campsiteId: $campsiteId,
      text: $text
    ) {
      id
      campsiteNote(campsiteId: $campsiteId) {
        id
        text
      }
    }
  }
`;

export interface CampsiteNoteSuccess {
  user: {
    id: User['id'];
    campsiteNote: User['campsiteNote'];
  };
}

export interface Variables {
  userId: string;
  campsiteId: string;
  text: string;
}
