import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components/macro';
import NoTripsEmptyState from '../../../assets/images/empty-states/trip_logs.svg';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useFluent from '../../../hooks/useFluent';
import { useSortedMergedTripReports } from '../../../queries/users/useAllTripReports';
import { N_PER_PAGE } from '../../../queries/users/useAllTripReports/query';
import { addTripReportLink } from '../../../routing/Utils';
import { BasicContentContainer } from '../../../styling/sharedContentStyles';
import {
  BasicIconInText,
  BasicIconInTextCompact,
  ButtonPrimaryLink,
  DottedSegment,
  Label,
  lightBaseColor,
  lightBorderColor,
  lightFontWeight,
  PaddedSection,
  PlaceholderText,
  primaryFont,
  SelectBox,
  ShadowButtonIcon,
  ShadowButtonLink,
  tertiaryColor,
} from '../../../styling/styleUtils';
import DefaultError from '../../sharedComponents/DefaultError';
import EmptyState from '../../sharedComponents/EmptyState';
import LoadingSimple, { LoadingContainer } from '../../sharedComponents/LoadingSimple';
import Pagination from '../../sharedComponents/Pagination';
import StandardSearch from '../../sharedComponents/StandardSearch';
import DateSegment from './DateSegment';

const FilterRoot = styled(PaddedSection)`
  max-width: 100%;
  display: grid;
  grid-template-columns: 6rem auto 1fr;
  grid-gap: 1rem;
  align-items: flex-end;

  .react-datepicker-wrapper {
    max-width: min-content;
    position: relative;
  }

  .react-datepicker {
    border-color: ${lightBorderColor};
  }
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
    border-bottom-color: ${lightBorderColor};
  }
  .react-datepicker__input-container input {
    padding: 6px;
    box-sizing: border-box;
    max-width: 8rem;
    border: solid 1px ${lightBorderColor};
    font-size: 0.875rem;
    border-radius: 8px;
    font-weight: ${lightFontWeight};
  }

  button.react-datepicker__close-icon {
    &:after {
      color: ${lightBaseColor};
      background-color: transparent;
      line-height: 0;
      font-size: 1.2rem;
    }
  }

  .react-datepicker__month-container {
    font-family: ${primaryFont};
  }

  .react-datepicker__header {
    padding: 0.5rem;
    border-color: ${lightBorderColor};
    background-color: ${tertiaryColor};
  }
`;

const LogTripButtonContainer = styled.div`
  white-space: nowrap;
  margin-left: auto;
`;

enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

interface Props {
  author: string;
  mountain?: string;
  campsite?: string;
  trail?: string;
}

const Timeline = (props: Props) => {
  const { author, mountain, campsite, trail} = props;
  const [page, setPage] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.desc);
  const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [search, setSearch] = useState<string>('');
  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
    setPage(0);
  }, [setSearch, setPage]);
  const { loading, error, data, count } = useSortedMergedTripReports({
    author,
    mountain: mountain !== undefined ? mountain : null,
    campsite: campsite !== undefined ? campsite : null,
    trail: trail !== undefined ? trail : null,
    limit: N_PER_PAGE,
    offset: N_PER_PAGE * page,
    sortDirection,
    month: filterDate && `${filterDate.getFullYear()}-${(filterDate.getMonth() + 1).toString().padStart(2, '0')}`,
    search: search || null,
  });
  const {offline} = useOfflineContext();

  const onSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === SortDirection.asc) {
      setSortDirection(SortDirection.asc);
    } else {
      setSortDirection(SortDirection.desc);
    }
    setPage(0);
  };

  const getString = useFluent();

  if (error !== undefined || (offline && data === undefined)) {
    return (
      <DefaultError
        error={error}
        offlineMessage={'Connect to the internet to sync your trip history.'}
      />
    );
  } else if (data !== undefined) {
    const tripReportElms = data.sortedTripReports.map((d, i) => {
      return (
        <DottedSegment key={d.date + i + 'parent-key'} style={!i ? {borderTop: 'none'} : undefined}>
          <DateSegment
            date={d.date}
            tripReports={d.tripReports}
          />
        </DottedSegment>
      );
    });
    const noTripReports = data.totalReports === 0 || data.sortedTripReports.length === 0 ? (
      <>
        {data.totalReports === 0 && (
          <>
            <br />
            <br />
            <EmptyState
              title={getString('dashboard-empty-state-no-trip-history-title')}
              image={NoTripsEmptyState}
            >
              {getString('dashboard-empty-state-no-trip-history-text-1')}
              <p style={{textAlign: 'center'}}>
                <ButtonPrimaryLink
                  to={addTripReportLink({})}
                >
                  {getString('global-add-trip-report')}
                </ButtonPrimaryLink>
              </p>
            </EmptyState>
          </>
        )}
        <BasicContentContainer>
          {data.totalReports > 0 && <PlaceholderText dangerouslySetInnerHTML={{__html: getString('global-text-value-no-results-found')}} />}
        </BasicContentContainer>
      </>
    ) : null;
    return (
      <>
          {!offline && (
            <>
              <FilterRoot>
                <div>
                  <Label><BasicIconInTextCompact icon={faSort} /> {getString('global-text-value-sort')}:</Label>
                  <SelectBox onChange={onSortChange} value={sortDirection}>
                    <option value={SortDirection.desc}>{getString('global-text-value-new-to-old')}</option>
                    <option value={SortDirection.asc}>{getString('global-text-value-old-to-new')}</option>
                  </SelectBox>
                </div>
                <div>
                  <Label><BasicIconInTextCompact icon={faCalendarAlt} /> {getString('global-text-value-find-month')}:</Label>
                  <DatePicker
                    selected={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    showMonthYearPicker={true}
                    dateFormat={'MM/yyyy'}
                    isClearable={true}
                    clearButtonTitle={getString('global-text-value-reset')}
                    popperPlacement={'bottom'}
                  />
                </div>
                <LogTripButtonContainer>
                  <ShadowButtonLink to={addTripReportLink({})}>
                    <ShadowButtonIcon>
                      <BasicIconInText icon={faCalendarAlt} />
                    </ShadowButtonIcon>
                    {getString('global-add-trip-report')}
                  </ShadowButtonLink>
                </LogTripButtonContainer>
              </FilterRoot>
              <StandardSearch
                placeholder={getString('trip-report-search')}
                setSearchQuery={handleSearchChange}
                initialQuery={search}
                focusOnMount={false}
                noSearchIcon={true}
              />
            </>
          )}
        {loading ? (
          <LoadingContainer><LoadingSimple /></LoadingContainer>
        ) : (
          <div>
            {tripReportElms}
            {noTripReports}
            {Boolean(data.sortedTripReports.length) && !offline && (
              <Pagination
                nPerPage={N_PER_PAGE}
                total={count}
                page={page}
                goToPrev={() => setPage(curr => curr - 1)}
                goToNext={() => setPage(curr => curr + 1)}
              />
            )}
          </div>
        )}
      </>
    );
  }

  return null;

};

export default Timeline;
