import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import useFluent from '../../../hooks/useFluent';
import {
  baseColor,
  BasicIconInText,
  lightBorderColor,
  primaryColor,
  tertiaryColor,
} from '../../../styling/styleUtils';
import { mobileSize } from '../../../Utils';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const slideIn = keyframes`
  0% {
    transform: translate(100vw, 0.5rem);
  }

  100% {
    transform: translate(0, 0.5rem);
  }
`;

const MenuListContainerBase = styled.div`
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 100%;
  transform: translateX(0.5rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  min-width: 200px;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  animation: ${fadeIn} 0.15s ease-in-out 1 forwards;

  @media(max-width: ${mobileSize}px) {
    animation: ${slideIn} 0.15s ease-in-out 1 forwards;
    transform: translateX(0);
    left: auto;
    bottom: auto;
    top: 100%;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
  }
`;
const MenuListContainer = styled(MenuListContainerBase)`
  border-radius: 8px;
  overflow: hidden;

  @media(max-width: ${mobileSize}px) {
    width: 100vw;
  }
`;

const linkStyles = `
  width: 100%;
  background-color: #fff;
  border: solid 1px ${lightBorderColor};
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: ${baseColor};
  font-size: 0.9rem;

  &:hover {
    color: ${baseColor};
    background-color: ${tertiaryColor};
  }

  &:not(:first-child) {
    border-top: none;
  }


  @media(max-width: ${mobileSize}px) {
    display: flex;
    align-items: center;
    font-size: 1rem;
    min-height: 2.5rem;
  }
`;

export const MenuIcon = styled(BasicIconInText)`
  width: 1rem;
  color: ${primaryColor};
  font-size: 0.75rem;

  @media(max-width: ${mobileSize}px) {
    font-size: 1rem;
    width: 1.2rem;
  }
`;

export const MenuLink = styled(Link)`
  ${linkStyles}
`;

export const MenuAnchor = styled.a`
  ${linkStyles}
`;

interface Props {
  closeMenu: () => void;
  children: React.ReactNode;
}

const DropoutList = ({ closeMenu, children }: Props) => {
  const node = useRef<HTMLDivElement | null>(null);
  const getString = useFluent();
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const element = e.target as HTMLElement;
      if (!(node !== null && node.current !== null && node.current.contains(element))) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
  return (
    <MenuListContainer ref={node} onClick={closeMenu}>
      {children}
      <MenuAnchor href='#' onClick={closeMenu}>
        <MenuIcon icon={faTimes} />
        {getString('global-text-value-modal-close-menu')}
      </MenuAnchor>
    </MenuListContainer>
  );
};

export default DropoutList;
