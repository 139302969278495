import {
  faCar,
  faExternalLinkAlt,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import upperFirst from 'lodash/upperFirst';
import React, {useEffect} from 'react';
import { OriginLocation } from '../../../../../hooks/directions/useDirectionsOrigin';
import useDirectionsToParking from '../../../../../hooks/servicesHooks/directions/useDirectionsToParking';
import useFluent from '../../../../../hooks/useFluent';
import useMapContext from '../../../../../hooks/useMapContext';
import {
  BlockContainer,
  BlockHeaderVerticalCenter,
  CenteredHeader,
  Column,
  Details,
  DetailsCompact,
  EmptyBlock,
} from '../../../../../styling/sharedContentStyles';
import {
  BasicIconAtEndOfText,
  HighlightedLargeIconInText,
  LinkButtonCompact,
  SemiBold,
  SmallExternalLink,
  Subtext,
} from '../../../../../styling/styleUtils';
import {Coordinate} from '../../../../../types/graphQLTypes';
import {MapItem} from '../../../../../types/itemTypes';
import getGoogleMapsLink from '../../../../../utilities/getGoogleMapsLink';
import LoadingSimple from '../../../LoadingSimple';

interface Props {
  start: OriginLocation;
  end: {
    coordinates: Coordinate;
    name: string;
  };
  onClearStart: () => void;
}

const Destinations = ({start, end, onClearStart}: Props) => {
  const {loading, error, data} = useDirectionsToParking({
    start: start.coordinates,
    end: end.coordinates,
    considerDirect: true,
    totalResults: 1,
  });
  const mapContext = useMapContext();

  const onMouseLeave = () => {
    if (mapContext.intialized) {
      mapContext.clearExternalHoveredPopup();
    }
  };
  useEffect(() => {
    return () => {
      if (mapContext.intialized) {
        mapContext.clearExternalHoveredPopup();
      }
    };
  }, [mapContext]);
  const getString = useFluent();
  let output: React.ReactElement<any> | null;
  if (loading) {
    output = (
      <EmptyBlock>
        <CenteredHeader>
          <LoadingSimple /> Getting directions...
        </CenteredHeader>
      </EmptyBlock>
    );
  } else if (error !== undefined) {
    console.error(error);
    const googleUrl = getGoogleMapsLink(end.coordinates);
    output = (
      <EmptyBlock>
        <CenteredHeader>
          {getString('directions-nothing-found')}
        </CenteredHeader>
        <Details>
          <LinkButtonCompact onClick={onClearStart}>
            {getString('directions-origin-new')}
            <small><BasicIconAtEndOfText icon={faPencilAlt} /></small>
          </LinkButtonCompact>
        </Details>
        <Details>
          <SmallExternalLink href={googleUrl} target={'_blank'}>
            {getString('directions-open-in-google-maps')}
            <small><BasicIconAtEndOfText icon={faExternalLinkAlt} /></small>
          </SmallExternalLink>
        </Details>
      </EmptyBlock>
    );
  } else if (data !== undefined) {
    if (data.length && data[0]) {
      const destination = data[0];
      const {hours, minutes, originLat, originLng, originName, originType} = destination;
      const miles = destination.miles < 10
        ? parseFloat(destination.miles.toFixed(2)) : Math.round(destination.miles);
      const googleUrl = getGoogleMapsLink([originLng, originLat]);
      const formattedType = getString('global-formatted-anything-type', {type: originType});
      let name = originName ? originName : upperFirst(formattedType);
      if (name === 'SOURCE') {
        name = end.name ? end.name : 'Destination';
      }

      const onMouseEnter = () => {
        if (mapContext.intialized) {
          mapContext.setExternalHoveredPopup(
            name,
            MapItem.directions,
            getString('directions-driving-duration', {hours, minutes}) + ', ' +
              getString('directions-driving-distance', {miles}),
            [destination.originLng, destination.originLat],
          );
        }
      };

      output = (
        <BlockContainer
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <BlockHeaderVerticalCenter>
            <HighlightedLargeIconInText icon={faCar} />
            <Column>
              <SemiBold>
                {getString('directions-driving-duration', {hours, minutes})}
                {' '}
                drive ({getString('directions-driving-distance', {miles})})
              </SemiBold>
              <DetailsCompact>
                  <Subtext>
                    <em>from {start.name}</em>
                    <LinkButtonCompact onClick={onClearStart}>
                      <BasicIconAtEndOfText icon={faPencilAlt} />
                    </LinkButtonCompact>
                  </Subtext>
                  <Subtext>
                    <em>to {name}</em>
                  </Subtext>
              </DetailsCompact>
              <small>
                <SmallExternalLink href={googleUrl} target={'_blank'}>
                  {getString('directions-open-in-google-maps')}
                  <small><BasicIconAtEndOfText icon={faExternalLinkAlt} /></small>
                </SmallExternalLink>
              </small>
            </Column>
        </BlockHeaderVerticalCenter>
        </BlockContainer>
      );
    } else {
      const googleUrl = getGoogleMapsLink(end.coordinates);
      output = (
        <EmptyBlock>
          <CenteredHeader>
            {getString('directions-nothing-found')}
          </CenteredHeader>
          <Details>
            <LinkButtonCompact onClick={onClearStart}>
              {getString('directions-origin-new')}
              <small><BasicIconAtEndOfText icon={faPencilAlt} /></small>
            </LinkButtonCompact>
          </Details>
          <Details>
          <SmallExternalLink href={googleUrl} target={'_blank'}>
            {getString('directions-open-in-google-maps')}
            <small><BasicIconAtEndOfText icon={faExternalLinkAlt} /></small>
          </SmallExternalLink>
        </Details>
        </EmptyBlock>
      );
    }
  } else {
    output = null;
  }
  return (
    <>
      {output}
    </>
  );
};

export default Destinations;
