import { orderBy } from 'lodash';
import React from 'react';
import useRoutesToPoint from '../../../../hooks/servicesHooks/pathfinding/useRoutesToPoint';
import useFluent from '../../../../hooks/useFluent';
import { RoutesToPointInput } from '../../../../routing/services';
import { CenteredHeader, EmptyBlock, MediumScrollContainer } from '../../../../styling/sharedContentStyles';
import { Coordinate } from '../../../../types/graphQLTypes';
import LoadingSimple from '../../LoadingSimple';
import PlaceBlock from './PlaceBlock';

interface Props {
  originId: string;
  origin: Coordinate;
  originName: string;
  destinationType: RoutesToPointInput['destination'];
}

const NearbyPlaces = (props: Props) => {
  const {
    origin, originName, originId, destinationType,
  } = props;
  const {loading, error, data} = useRoutesToPoint({
    destination: destinationType,
    lat: origin[1],
    lng: origin[0],
    returnMaxPoints: true,
    skipElevation: true,
    originId,
  });
  const getString = useFluent();
  let output: React.ReactElement<any> | null;
  if (loading) {
    output = (
      <MediumScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            <LoadingSimple />
            <p>Finding nearby {destinationType}</p>
          </CenteredHeader>
        </EmptyBlock>
      </MediumScrollContainer>
    );
  } else if (error !== undefined) {
    console.error(error);
    output = (
      <MediumScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            {getString('global-text-value-no-results-found')}
          </CenteredHeader>
        </EmptyBlock>
      </MediumScrollContainer>
    );
  } else if (data) {
    const features = data.features;
    const trailheadRoutes = orderBy(features, f => {
      if (f.properties.routeLength) {
        return f.properties.routeLength;
      }
      if (f.properties.crowFliesDistance) {
        return f.properties.crowFliesDistance + 100;
      }
      return 200;
    }).map(feature => {
      return (
        <PlaceBlock
          key={feature.properties.destination._id}
          feature={feature}
          originName={originName}
          destinationType={destinationType}
        />
      );
    });
    if (trailheadRoutes.length === 0) {
      output = (
        <MediumScrollContainer hideScrollbars={false} $noScroll={true}>
          <EmptyBlock>
            <CenteredHeader>
              {getString('global-text-value-no-items-found', {type: destinationType})}
            </CenteredHeader>
          </EmptyBlock>
        </MediumScrollContainer>
      );
    } else {
      output = (
        <MediumScrollContainer $noScroll={features.length < 2} hideScrollbars={false}>
          {trailheadRoutes}
        </MediumScrollContainer>
      );
    }
  } else {
    output = null;
  }
  return (
    <>
      {output}
    </>
  );
};

export default NearbyPlaces;
