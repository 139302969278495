import React from 'react';
import {CoreItem} from '../../../../types/itemTypes';
import TripReports from './TripReports';

interface Props {
  id: string;
  name: string;
  type: CoreItem;
}

const LatestWilderlistReports = (props: Props) => {
  const {id, name, type} = props;
  return (
    <TripReports
      id={id}
      name={name}
      type={type}
    />
  );
};

export default LatestWilderlistReports;
