import React from 'react';
import useFluent from '../../../../hooks/useFluent';

interface Props {
  elevation: number | undefined;
}

const FormattedElevation = ({elevation}: Props) => {
  const getString = useFluent();

  const elevationText = elevation !== undefined
    ? (
      <>
        {elevation + ' ' + getString('global-text-value-feet') + ' '}
        ({Math.round(elevation * 0.3048) + ' ' + getString('global-text-value-meters')})
      </>
    ) : '----';

  return (
    <>
      {elevationText}
    </>
  );
};

export default FormattedElevation;
