import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components/macro';
import { GhostButton, primaryColor } from '../../styling/styleUtils';

const Icon = styled.div`
  font-size: 0.9rem;
  width: 1.875rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RootBase = styled(GhostButton)<{$color: string | undefined}>`
  color: ${({ $color }) => $color ? $color : primaryColor};
  font-weight: 400;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;

  &:hover {
    color: ${({ $color }) => lighten(0.1, $color ? $color : primaryColor)};
  }
`;

const RootCompact = styled(RootBase)`
  padding-top: 0;
`;

interface Props {
  icon: any;
  text?: string;
  compact?: boolean;
  onClick?: () => void;
  highlighted?: boolean;
  rootWidth?: string;
  color?: string;
}

const UtilityButton = ({ text, compact, icon, onClick, rootWidth, color }: Props) => {
  const Root = compact ? RootCompact : RootBase;

  return (
    <Root onClick={onClick} style={{ width: rootWidth }} $color={color}>
      <Icon>
        <FontAwesomeIcon icon={icon} />
      </Icon>
      <div>{text}</div>
    </Root>
  );
};

export default UtilityButton;
