import axios from 'axios';
import {getLineElevationUrl} from '../../../routing/services';
import {Coordinate} from '../../../types/graphQLTypes';
import {
  isUrlQueued,
  pushUrlToQueue,
  readLineElevationCache,
  removeUrlFromQueue,
  SuccessResponse,
  writeLineElevationCache,
} from './simpleCache';

interface Output {
  loading: boolean;
  error: any;
  data: undefined | SuccessResponse;
}

interface Input {
  lineId: string;
  line: null | Coordinate[];
  includeIncline?: boolean;
  includeMinMax?: boolean;
}

export default function fetchElevationLines(input: Input, onComplete: (output: Partial<Output>) => void) {
  const {line, includeIncline, includeMinMax, lineId} = input;
  const incline = includeIncline ? true : false;
  const minMax = includeMinMax ? true : false;
  const cacheKey = `${lineId}${incline}${minMax}`;
  let attempts = 0;
  const runFetch = () => {
    const cached = readLineElevationCache(cacheKey);
    if (cached) {
      onComplete({loading: false, error: undefined, data: cached.data});
    } else {
      if (!isUrlQueued(cacheKey) || attempts > 100) {
        pushUrlToQueue(cacheKey);
        onComplete({loading: true});
        axios({
          method: 'post',
          url: getLineElevationUrl(),
          data: {line, incline, minMax},
        }).then(response => {
            onComplete({loading: false, error: undefined, data: response.data});
            writeLineElevationCache(cacheKey, response.data);
            removeUrlFromQueue(cacheKey);
          })
          .catch(error => {
            onComplete({loading: false, error, data: undefined});
          });
      } else {
        attempts++;
        setTimeout(runFetch, 100);
      }
    }
  };
  if (line !== null && line.length) {
    runFetch();
  }
}
