import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_PEAKLIST_NOTE,
  EDIT_PEAKLIST_NOTE,
  GET_PEAK_LIST_NOTE,
  PeakListNoteSuccess,
  PeakListNoteVariables,
  QuerySuccessResponse,
  QueryVariables,
} from './query';
export const usePeakListNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_PEAK_LIST_NOTE, {variables});

export const useAddPeakListNote = () => {
  const [addPeakListNote] = useMutation<PeakListNoteSuccess, PeakListNoteVariables>(ADD_PEAKLIST_NOTE);
  return addPeakListNote;
};

export const useEditPeakListNote = () => {
  const [editPeakListNote] = useMutation<PeakListNoteSuccess, PeakListNoteVariables>(EDIT_PEAKLIST_NOTE);
  return editPeakListNote;
};
