import localforage from 'localforage';
import debounce from 'lodash/debounce';
import partition from 'lodash/partition';
import {CoordinateWithElevation} from '../../../../types/graphQLTypes';

export interface SuccessResponse {
  avg_slope?: number;
  elevation_gain?: number;
  elevation_loss?: number;
  elevation_max?: number;
  elevation_min?: number;
  line: CoordinateWithElevation[];
  max_slope?: number;
  min_slope?: number;
}

type LineElevationCache = {key: string, data: SuccessResponse}[];

let LINE_ELEVATION_CACHE: LineElevationCache = [];

const LINE_ELEVATION_CACHE_KEY: string = 'line-elevation-persisted-cache';
localforage.getItem<LineElevationCache>(LINE_ELEVATION_CACHE_KEY, function(err, persistedCache) {
  if (persistedCache !== null) {
    if (
      persistedCache &&
      typeof persistedCache === 'object' &&
      Array.isArray(persistedCache) &&
      persistedCache.length &&
      persistedCache.some(d => d.data.line)) {
      LINE_ELEVATION_CACHE = persistedCache;
    }
  }
  if (err) {
    console.error(err);
  }
});

const persistCache = debounce(() => {
  localforage.setItem(LINE_ELEVATION_CACHE_KEY, LINE_ELEVATION_CACHE);
}, 1000);

export const writeLineElevationCache = (key: string, data: SuccessResponse) => {
  const [matches, others] = partition(LINE_ELEVATION_CACHE, (d => d.key === key));
  if (matches && matches.length && matches[0] && matches[0].key && matches[0].data) {
    LINE_ELEVATION_CACHE = [...others];
  }
  LINE_ELEVATION_CACHE.push({
    key,
    data,
  });
  if (LINE_ELEVATION_CACHE.length > 300) {
    LINE_ELEVATION_CACHE.shift();
  }
  persistCache();
};

export const readLineElevationCache = (key: string) => {
  const [matches, others] = partition(LINE_ELEVATION_CACHE, (d => d.key === key));
  if (matches && matches.length && matches[0] && matches[0].key && matches[0].data) {
    LINE_ELEVATION_CACHE = [...others, matches[0]];
    return matches[0];
  }
  return undefined;
};

const QUEUED_URLS: string[] = [];

export const isUrlQueued = (url: string) => {
  if (QUEUED_URLS.includes(url)) {
    return true;
  }
  return false;
};

export const pushUrlToQueue = (url: string) => {
  if (!QUEUED_URLS.includes(url)) {
    QUEUED_URLS.push(url);
  }
};

export const removeUrlFromQueue = (url: string) => {
  const index = QUEUED_URLS.indexOf(url);
  if (index > -1) {
    QUEUED_URLS.splice(index, 1);
  }
};
