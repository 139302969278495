import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_MOUNTAIN_NOTE,
  EDIT_MOUNTAIN_NOTE,
  GET_MOUNTAIN_NOTE,
  MountainNoteSuccess,
  QuerySuccessResponse,
  QueryVariables,
  Variables,
} from './query';

export const useMountainNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_MOUNTAIN_NOTE, {variables});

export const useAddMountainNote = () => {
  const [addMountainNote] = useMutation<MountainNoteSuccess, Variables>(ADD_MOUNTAIN_NOTE);
  return addMountainNote;
};
export const useEditMountainNote = () => {
  const [editMountainNote] = useMutation<MountainNoteSuccess, Variables>(EDIT_MOUNTAIN_NOTE);
  return editMountainNote;
};
