import orderBy from 'lodash/orderBy';
import React from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../../../hooks/useFluent';
import { AddEditVariables, CommentDatum, useCommentsFor } from '../../../../queries/comments/useCommentsFor';
import {
  useLatestTripReports,
} from '../../../../queries/tripReports/useLatestTripReports';
import {
  PaddedSection,
  PlaceholderText,
} from '../../../../styling/styleUtils';
import { TripReport, TripReportPrivacy } from '../../../../types/graphQLTypes';
import {CoreItem} from '../../../../types/itemTypes';
import {
  stringToJsDate,
} from '../../../../utilities/dateUtils';
import LoadingSimple from '../../LoadingSimple';
import AddEditComment from '../comments/AddEditComment';
import AverageRating from '../comments/AverageRating';
import Comment from '../comments/Comment';
import { EmptyContainer, LoadingContainer } from '../comments/Comments';
import TripReportBlock from './TripReportBlock';

const Root = styled.div`
  position: relative;
`;

enum ReportOrComment {
  report = 'report',
  comment = 'comment',
}

type ReportOrCommentDatum =
{
  type: ReportOrComment.report,
  date: Date,
  datum: TripReport,
} | {
  type: ReportOrComment.comment,
  date: Date,
  datum: CommentDatum,
};

interface Props {
  id: string;
  name: string;
  type: CoreItem;
}

const TripReports = ({id, type}: Props) => {
  const getString = useFluent();
  const {loading, error, data} = useLatestTripReports({[type]: id, pageNumber: 1});
  const {
    response: commentsResponse,
    addEditComment: addEditCommentMutation,
    deleteComment,
  } = useCommentsFor({forId: id, forType: type});
  const addEditComment = (variables: AddEditVariables) => addEditCommentMutation({variables});
  let output: React.ReactElement<any> | null;
  if (loading || commentsResponse.loading) {
    output = (
      <LoadingContainer>
        <LoadingSimple />
      </LoadingContainer>
    );
  } else if (error || commentsResponse.error) {
    return (
      <PaddedSection>
        <PlaceholderText>
          {getString('global-error-retrieving-data')}
        </PlaceholderText>
      </PaddedSection>
    );
  } else if (data || commentsResponse.data) {
    const tripReports = data && data.tripReports ? data.tripReports : [];
    const commentsData = commentsResponse.data && commentsResponse.data.comments ? commentsResponse.data.comments : [];

    const merged: ReportOrCommentDatum[] = [
      ...tripReports.map(report => ({
        type: ReportOrComment.report,
        date: stringToJsDate(report.date),
        datum: report,
      })) as ReportOrCommentDatum[],
      ...commentsData.map(comment => ({
        type: ReportOrComment.comment,
        date: new Date(parseInt(comment.created, 10)),
        datum: comment,
      })) as ReportOrCommentDatum[],
    ];

    const sorted = orderBy(merged, ['date'], ['desc']);

    let totalRatingScore: number = 0;
    let totalRatingCount: number = 0;
    const commentsAndReports = sorted.map(d => {
      if (d.type === ReportOrComment.report) {
        const report = d.datum;
        if (report && report.privacy !== TripReportPrivacy.Private) {
          if (report.rating) {
            totalRatingScore += report.rating;
            totalRatingCount++;
          }
          return <TripReportBlock key={report.id} report={report} />;
        } else {
          return null;
        }
      } else if (d.type === ReportOrComment.comment) {
        const comment = d.datum;
        if (comment.rating) {
          totalRatingScore += comment.rating;
          totalRatingCount++;
        }
        return (
          <Comment
            key={comment.id}
            id={comment.id}
            author={comment.author}
            created={comment.created}
            edited={comment.edited}
            forId={comment.forId}
            forType={comment.forType}
            comment={comment.comment}
            rating={comment.rating}
            user={comment.user}
            deleteComment={() => deleteComment({variables: {id: comment.id}})}
          />
        );
      } else {
        return null;
      }
    });

    const averageRating = totalRatingCount && totalRatingScore
      ? (
        <AverageRating
          totalRatingCount={totalRatingCount}
          totalRatingScore={totalRatingScore}
        />
      ) : null;

    if (commentsAndReports.length) {

      output = (
        <>
          {averageRating}
          {commentsAndReports}
        </>
      );
    } else {
      output = (
        <PaddedSection>
          <EmptyContainer>
            {getString('global-text-value-no-reviews')}
          </EmptyContainer>
        </PaddedSection>
      );
    }
  } else {
    output = null;
  }
  return (
    <Root>
      <AddEditComment
        forId={id}
        forType={type}
        addEditComment={addEditComment}
      />
      {output}
    </Root>
  );

};

export default TripReports;
