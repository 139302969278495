import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import useMapContext from '../../../../hooks/useMapContext';
import { ZoomControlsWrapper } from './Utils';

const ZoomControls = () => {
  const mapContext = useMapContext();
  const zoomControlContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = zoomControlContainerRef.current;
    const nav = new mapboxgl.NavigationControl();
    if (node && mapContext.intialized) {
      node.appendChild(nav.onAdd(mapContext.map));
    }
    return () => {
      if (node && mapContext.intialized) {
        node.innerHTML = '';
      }
    };
  }, [mapContext, zoomControlContainerRef]);

  return <ZoomControlsWrapper ref={zoomControlContainerRef} />;
};

export default ZoomControls;
