import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { baseColor, lightBaseColor } from '../../../styling/styleUtils';
import ScrollContainer from '../ScrollContainer';
import {
  ActiveNavButton,
  Header,
  IconContainer,
  NavButton,
  NavContainer,
  NavContent,
} from './DetailSegment';

export const Root = styled.div`
  margin: 0 -1rem 0.75rem;
`;

const NavigationRoot = styled(NavContainer)`
  white-space: nowrap;
`;

export type LinkDatum = {
  title: string
  route: string
  routeState?: {
    noScrollOnLoad?: boolean; // disables scrolling the view box on load
  }
  customIcon?: boolean;
  icon?: string | any;
} & (
    {
      customIcon?: undefined;
    } |
    {
      customIcon: true,
      icon: string,
    } | {
      customIcon: false,
    // icon is of type "any" due to an issue with TypeScript overloading memory
      icon: any,
    }
  );

interface Props {
  links: LinkDatum[];
}

const TabNav = (props: Props) => {
  const history = useHistory();
  const links = props.links.map(d => {
    const active = history.location.pathname === d.route;
    const Button = active ? ActiveNavButton : NavButton;

    let icon: React.ReactElement<any> | null;
    if (d.customIcon === true) {
      icon = (
        <IconContainer
          $color={active ? baseColor : lightBaseColor}
          dangerouslySetInnerHTML={{__html: d.icon}}
        />
      );
    } else if (d.customIcon === false) {
      icon = (
        <IconContainer
          $color={active ? baseColor : lightBaseColor}
        >
          <FontAwesomeIcon icon={d.icon} />
        </IconContainer>
      );
    } else {
      icon = null;
    }

    return (
      <Button
          key={'tab-nav-button' + d.title}
          as={Link}
          to={{
            pathname: d.route,
            state: {...d.routeState},
          }}
        >
          <NavContent>
            {icon}
            {d.title}
          </NavContent>
        </Button>
    );
  });
  return (
    <ScrollContainer hideArrows={true}>
      <Root>
        <Header>
            <NavigationRoot>
              {links}
            </NavigationRoot>
        </Header>
      </Root>
    </ScrollContainer>
  );
};

export default TabNav;
