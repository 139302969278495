import React from 'react';
import styled from 'styled-components/macro';
import { lightBaseColor, lightBorderColor, primaryColor } from '../../styling/styleUtils';

const Root = styled.div`
  margin: 0.2rem;
  cursor: pointer;

  input {
    display: none;
  }

  label {
    font-size: 0.7rem;
    border-radius: 800px;
    border: solid 1px ${lightBorderColor};
    color: ${lightBaseColor};
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &.item-checked label {
    border-color: ${primaryColor};
    background-color: ${primaryColor};
    color: #fff;

    &:after {
      content: '×';
      font-size: 1rem;
      line-height: 0;
      margin-left: 0.5rem;
    }
  }
`;

interface Props {
  label: string;
  checked: boolean;
  onChange: () => void;
  checkboxId: string;
}

const TagCheckbox = (props: Props) => {
  const {
    label, checked, onChange, checkboxId,
  } = props;
  return (
    <Root className={checked ? 'item-checked' : undefined}>
      <input
        type='checkbox'
        id={checkboxId}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={checkboxId}>
        {label}
      </label>
    </Root>
  );
};

export default TagCheckbox;
