import { css } from 'styled-components';
import { semiBoldFontBoldWeight } from '../../../styling/styleUtils';
import { mobileSize } from '../../../Utils';

export const linkStyles = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.4rem 0.15rem;
  font-size: 0.75rem;
  font-weight: ${semiBoldFontBoldWeight};

  &:first-child {
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
  }

  @media(max-width: ${mobileSize}px) {
    flex-grow: 1;
    flex-direction: column;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 0.4rem 0.15rem;

    &:first-child {
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-right-radius: 0;
    }
  }
`;
