import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Comment,
  User,
} from '../../types/graphQLTypes';
import { AuxiliaryItem, CoreItem } from '../../types/itemTypes';

const innerQuery = `
    id
    author {
      id
      name
      hideProfileInSearch
    }
    created
    edited
    forId
    forType
    comment
    rating
`;

const GET_SAVED_COMMENTS_FOR = gql`
  query GetCommentsForPlace($forId: ID!, $forType: String!) {
    comments: commentsFor(forId: $forId, forType: $forType) {
      ${innerQuery}
    }
  }
`;

const ADD_EDIT_COMMENT = gql`
  mutation AddEditComment(
    $id: ID,
    $date: String!,
    $author: ID!,
    $forId: ID!,
    $forType: String!,
    $comment: String!,
    $rating: Float,
  ) {
    comment: addEditComment(
      id: $id,
      date: $date,
      author: $author,
      forId: $forId,
      forType: $forType,
      comment: $comment,
      rating: $rating,
    ) {
      ${innerQuery}
    }
  }
`;

const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    comment: deleteComment(id: $id) {
      ${innerQuery}
    }
  }
`;

interface QueryVariables {
  forId: string;
  forType: Omit<AuxiliaryItem, 'trailhead'> | CoreItem;
}

export interface AddEditVariables {
  id: string | null;
  author: string;
  date: Date;
  forId: string;
  forType: string;
  comment: string;
  rating: number;
}

export interface CommentDatum extends Comment {
  user: null | {
    id: User['id'],
    name: User['name'],
    hideProfileInSearch: User['hideProfileInSearch'],
  };
}

interface QuerySuccessResponse {
  comments: CommentDatum[];
}

export const useCommentsFor = (variables: QueryVariables) => {
  const response = useQuery<QuerySuccessResponse, QueryVariables>(
    GET_SAVED_COMMENTS_FOR, {variables},
  );

  const [addEditComment] = useMutation<{comment: CommentDatum}, AddEditVariables>(
    ADD_EDIT_COMMENT, {
      refetchQueries: [{query: GET_SAVED_COMMENTS_FOR, variables}],
    });

  const [deleteComment] = useMutation<{comment: CommentDatum}, {id: string}>(
    DELETE_COMMENT, {
      refetchQueries: [{query: GET_SAVED_COMMENTS_FOR, variables}],
    });

  return {response, addEditComment, deleteComment};
};
