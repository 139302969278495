import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, {css} from 'styled-components/macro';
import { useOfflineContext } from '../../../../contextProviders/OfflineContext';
import {
  BasicIconInText,
  BasicIconInTextCompact,
  IconContainer as IconContainerBase,
  lightBaseColor,
  lowWarningColorDark,
  mediumBaseColor,
  successColor,
  tertiaryColor,
} from '../../../../styling/styleUtils';
import {AutoItem, AuxiliaryItem, CoreItem, coreItemToCoreItems, isAuxiliaryItem, isCoreItem, UserContentItem} from '../../../../types/itemTypes';
import AppearsIn from './AppearsIn';
import EditFlagButton from './EditFlagButton';
import SimpleShare from './sharing/SimpleShare';
import StarButtonWrapper from './starButton';

const StandardRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.35rem;
  margin-bottom: 1rem;
  margin-right: -1rem;
`;

const RouteRoot = styled.div`
  margin-bottom: 1rem;
`;

const IconHeader = styled.h1`
  display: flex;
  align-items: center;
  margin: 0;
`;

const IconContainer = styled(IconContainerBase)`
  font-size: 2rem;
  margin-right: 0.75rem;

  svg {
    width: 2rem;
  }
`;

const Subtitle = styled.div`
  margin: 0.45rem 0;
`;

const Settings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
`;

const EditFlagButtonContainer = styled.div`
  margin-right: 0.5rem;
`;

const ShareSaveContainer = styled.div`
  display: flex;
`;

const detailBaseStyles = css`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  white-space: nowrap;
  max-width: min-content;
`;
export const DetailBase = styled.div`
  ${detailBaseStyles}
  color: ${mediumBaseColor};
`;
const DetailLink = styled(Link)`
${detailBaseStyles}
`;
const DetailAnchor = styled.a`
${detailBaseStyles}
`;
const DetailWithWrapping = styled(DetailBase)`
  white-space: normal;
  max-width: 100%;
`;
export const DetailIcon = styled.div`
  margin-right: 0.55rem;
  font-size: 0.65rem;
  width: 0.75rem;

   svg {
     width: 100%;
     .fill-path {
       fill: ${mediumBaseColor};
     }
     .stroke-path {
       fill: #fff;
     }
   }
`;

const SecondaryDetailsColumnRoot = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
`;

const ModifiedText = styled.div`
   font-size: 0.7rem;
   right: 0.75rem;
   position: absolute;
   bottom: -0.5rem;
   white-space: nowrap;
`;

export interface Detail {
  text: React.ReactNode | string;
  icon: any;
  isCustomIcon?: boolean;
  internalLink?: string;
  externalLink?: string;
  faded?: boolean;
  allowWrap?: boolean;
}

interface Props {
  loading: boolean;
  id: string | null;
  title: string;
  titleComponent?: React.ReactNode;
  customIcon: boolean;
  icon: string | any;
  subtitle: string | React.ReactNode;
  authorId: null | string;
  type: CoreItem | AutoItem | AuxiliaryItem | UserContentItem | 'trip-report' | 'geo-location';
  url?: string;
  isParentTrail?: boolean;
  secondaryDetails?: Detail[];
  primaryDetails?: Detail[];
  parentTrails?: { id: string, name: string }[] | undefined;
  isModified?: boolean;
}

const SimpleHeader = (props: Props) => {
  const {
    id, title, loading, customIcon, icon, subtitle,
    authorId, type, isParentTrail, parentTrails, titleComponent,
    isModified, url,
  } = props;
  const {offline} = useOfflineContext();

  const iconEl = customIcon ? (
    <IconContainer
      $color={lightBaseColor}
      dangerouslySetInnerHTML={{__html: icon}}
    />
  ) : (
      <IconContainer $color={lightBaseColor}>
      <BasicIconInText icon={icon} />
    </IconContainer>
  );

  const Root = type === AutoItem.route ? RouteRoot : StandardRoot;

  let settings: React.ReactElement<any> | null;
  if (id && (isCoreItem(type) || isAuxiliaryItem(type))) {
    settings = (
      <Settings>
        <ShareSaveContainer>
          <SimpleShare
            pageName={title}
            type={type}
            url={url}
          />
          <StarButtonWrapper
            id={id}
            type={type}
          />
        </ShareSaveContainer>
        {!offline && (
          <EditFlagButtonContainer>
            <EditFlagButton
              authorId={authorId}
              type={type}
              id={id}
              name={title}
              isParentTrail={isParentTrail}
            />
          </EditFlagButtonContainer>
        )}
      </Settings>
    );
  } else if (type === UserContentItem.route) {
    const shareButton = id ? (
      <SimpleShare
        pageName={title}
        type={type}
        url={url}
      />
    ) : null;
    const saveButton = id && !titleComponent ? (
      <StarButtonWrapper
        id={id}
        type={type}
      />
    ) : null;
    const modifiedText = isModified !== undefined ? (
      <ModifiedText
        style={{
          color: isModified ? lowWarningColorDark : successColor,
        }}
      >
        <BasicIconInTextCompact
          icon={isModified ? faExclamationTriangle : faCheck}
        />
        <em>
          {
            isModified
            ? 'Route has unsaved changes'
            : 'All changes are saved'
          }
        </em>
      </ModifiedText>
    ) : null;
    settings = (
        <Settings>
          <ShareSaveContainer>
            {saveButton}
            {shareButton}
          </ShareSaveContainer>
          {modifiedText}
        </Settings>
    );
  } else if (type === 'trip-report' || type === 'geo-location') {
    settings = (
      <Settings>
        <ShareSaveContainer>
          <SimpleShare
            pageName={typeof subtitle === 'string' ? subtitle : title}
            type={type}
            url={url}
          />
        </ShareSaveContainer>
      </Settings>
    );
  } else {
    settings = null;
  }

  const primaryDetails = props.primaryDetails && props.primaryDetails.length
    ? props.primaryDetails.map((detail, i) => {
      const style = detail.faded ? { opacity: 0.5 } : undefined;
      let detailIcon: React.ReactNode | null;
      if (detail.icon && detail.isCustomIcon) {
        detailIcon = (
          <DetailIcon
            dangerouslySetInnerHTML={{__html: detail.icon}}
          />
        );
      } else if (detail.icon) {
        detailIcon = (
          <DetailIcon>
            <BasicIconInTextCompact icon={detail.icon} />
          </DetailIcon>
        );
      } else {
        detailIcon = null;
      }
      if (detail.internalLink) {
        return (
          <DetailLink to={detail.internalLink} style={style} key={detail.internalLink}>
            {detailIcon} {detail.text}
          </DetailLink>
        );
      } else if (detail.externalLink) {
        return (
          <DetailAnchor href={detail.externalLink} target='_blank' style={style} key={detail.externalLink}>
            {detailIcon} {detail.text}
          </DetailAnchor>
        );
      } else if (detail.allowWrap) {
        return (
          <DetailWithWrapping style={style} key={i}>
            {detailIcon} {detail.text}
          </DetailWithWrapping>
        );
      } else {
        return (
          <DetailBase style={style} key={i}>
            {detailIcon} {detail.text}
          </DetailBase>
        );
      }
    }) : null;

  const secondaryDetailsList = props.secondaryDetails && props.secondaryDetails.length
    ? props.secondaryDetails.map((detail, i) => {
      const style = detail.faded ? {opacity: 0.5} : undefined;
      const detailIcon = detail.icon ? (
        <DetailIcon>
          <BasicIconInTextCompact icon={detail.icon} />
        </DetailIcon>
      ) : null;
      if (detail.internalLink) {
        return (
          <DetailLink to={detail.internalLink} style={style} key={detail.internalLink}>
            {detailIcon} {detail.text}
          </DetailLink>
        );
      } else if (detail.externalLink) {
        return (
          <DetailAnchor href={detail.externalLink} target='_blank' style={style} key={detail.externalLink}>
            {detailIcon} {detail.text}
          </DetailAnchor>
        );
      } else {
        return (
          <DetailBase style={style} key={i}>
            {detailIcon} {detail.text}
          </DetailBase>
        );
      }
    }) : null;

  const SecondaryDetailsRoot = secondaryDetailsList && secondaryDetailsList.length > 3
    ? SecondaryDetailsColumnRoot : React.Fragment;
  const secondaryDetails = secondaryDetailsList && secondaryDetailsList.length ? (
    <SecondaryDetailsRoot>
      {secondaryDetailsList}
    </SecondaryDetailsRoot>
  ) : null;

  const appearsIn = id && isCoreItem(type) && !offline ? (
    <AppearsIn
      id={id}
      field={coreItemToCoreItems(type as CoreItem)}
      parentTrails={parentTrails}
    />
  ) : null;

  return (
    <Root>
      <div>
        <IconHeader>
          {iconEl}
          {titleComponent && !loading ? titleComponent : (
            <span
              style={loading ? {
                width: '75%', backgroundColor: tertiaryColor, color: 'transparent',
              } : undefined}
            >
              {title}
            </span>
          )}
        </IconHeader>
        <Subtitle
          style={loading ? {
            width: '45%', backgroundColor: tertiaryColor, color: 'transparent',
          } : undefined}
        >
          {subtitle}
        </Subtitle>
        {appearsIn}
        {primaryDetails}
        {secondaryDetails}
      </div>
      {settings}
    </Root>
  );
};

export default SimpleHeader;
