import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { GET_USERS_SAVED_TRAILHEADS, MutationVariables, REMOVED_SAVED_TRAILHEAD_FROM_USER, SAVE_TRAILHEAD_TO_USER, SuccessResponse, Variables} from './query';

export const useSavedTrailheads = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_TRAILHEADS, {variables: {userId}},
  );

  const [saveTrailheadToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_TRAILHEAD_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_TRAILHEADS, variables: {userId}}],
    });

  const [removeSavedTrailheadFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_TRAILHEAD_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_TRAILHEADS, variables: {userId}}],
    });

  return {response, saveTrailheadToUser, removeSavedTrailheadFromUser};
};
