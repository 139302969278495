import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';

export const GET_WATERPOINT_NOTE = gql`
  query getWaterpointNote($userId: ID, $waterpointId: ID) {
    user(id: $userId) {
      id
      waterpointNote(waterpointId: $waterpointId) {
        id
        text
      }
    }
  }
`;

export interface QuerySuccessResponse {
  user: null | {
    id: User['name'];
    __typename: string;
    waterpointNote: null | {
      id: string;
      __typename: string;
      text: string;
    }
  };
}

export interface QueryVariables {
  userId: string | null;
  waterpointId: string | null;
}

export const ADD_WATERPOINT_NOTE = gql`
  mutation($userId: ID!, $waterpointId: ID!, $text: String!) {
    user: addWaterpointNote(
      userId: $userId,
      waterpointId: $waterpointId,
      text: $text
    ) {
      id
      waterpointNote(waterpointId: $waterpointId) {
        id
        text
      }
    }
  }
`;

export const EDIT_WATERPOINT_NOTE = gql`
  mutation($userId: ID!, $waterpointId: ID!, $text: String!) {
    user: editWaterpointNote(
      userId: $userId,
      waterpointId: $waterpointId,
      text: $text
    ) {
      id
      waterpointNote(waterpointId: $waterpointId) {
        id
        text
      }
    }
  }
`;

export interface WaterpointNoteSuccess {
  user: {
    id: User['id'];
    waterpointNote: User['waterpointNote'];
  };
}

export interface Variables {
  userId: string;
  waterpointId: string;
  text: string;
}
