import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import BackupImage from '../../../assets/images/default-user-image.jpg';
import useFluent from '../../../hooks/useFluent';
import useWindowWidth from '../../../hooks/useWindowWidth';
import {
  primaryColor,
  primaryHoverColor,
  tertiaryColor,
} from '../../../styling/styleUtils';
import { User } from '../../../types/graphQLTypes';
import {mobileSize} from '../../../Utils';
import SignUpModal from '../../sharedComponents/SignUpModal';
import { slideIn } from './HamburgerMenu';
import UserList from './UserList';
import { linkStyles } from './Utils';

const UserMenu = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: all;

  @media(max-width: ${mobileSize}px) {
    &.active:before {
      animation: ${slideIn} 0.15s ease-in-out 1 forwards;
      content: '';
      position: fixed;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-color: #fff;
    }
  }
`;

const ButtonBase = styled.button`
  ${linkStyles}
  border-bottom-right-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: #fff;

  && {
    border-top-right-radius: 0;
  }

  &:hover {
    background-color: ${tertiaryColor};
  }

  img {
    max-width: 24px;
  }

  @media(max-width: ${mobileSize}px) {
    flex-direction: row;
    gap: 0.6rem;
    padding: 0 0.75rem;
    border-radius: 0;
  }
`;

const UserButton = styled(ButtonBase)`
  @media(max-width: ${mobileSize}px) {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    img {
      max-width: 28px;
    }
  }
`;

const SignInButton = styled(ButtonBase)`

  @media(max-width: ${mobileSize}px) {
      color: #fff;
      background-color: ${primaryColor};
      font-size: 1rem;
      text-transform: none;
      box-shadow: 0px 0px 4px -1px #8d8d8d;
      height: 2rem;

      && {
        border-radius: 8px;
      }

      &:hover {
        background-color: ${primaryHoverColor};
      }
    }
`;

const UserImage = styled.img`
  display: inline-block;
  border-radius: 1000px;
  background-color: #fff;

  @media(max-width: ${mobileSize}px) {
    box-shadow: 0px 0px 4px -1px #8d8d8d;
  }
`;

interface Props {
  userMenuOpen: boolean;
  setUserMenuOpen: (value: boolean | ((curr: boolean) => boolean)) => void;
  user: {
    _id: User['_id']
    name: User['name']
    permissions: User['permissions']
    profilePictureUrl: User['profilePictureUrl'],
  } | null;
}

const UserMenuComponent = (props: Props) => {
  const {
    userMenuOpen, setUserMenuOpen, user,
  } = props;
  const windowWidth = useWindowWidth();

  const initialProfilePictureUrl = !user ? BackupImage : user.profilePictureUrl;
  const [profilePictureUrl, setProfilePictureUrl] = useState<string>(initialProfilePictureUrl);
  const onImageError = () => {
    if (profilePictureUrl !== BackupImage) {
      setProfilePictureUrl(BackupImage);
    }
  };

  const userMenuButtonEl = useRef<HTMLDivElement | null>(null);
  const getString = useFluent();
  useEffect(() => {
    if (userMenuButtonEl.current !== null) {
      const el = userMenuButtonEl.current;
      const preventClickFromPropagating = (e: MouseEvent) => {
        e.stopPropagation();
      };
      el.addEventListener('mousedown', preventClickFromPropagating);
      return () => el.removeEventListener('mousedown', preventClickFromPropagating);
    }
  }, [userMenuButtonEl]);

  const closeUserMenu = useCallback(() => setUserMenuOpen(false), [setUserMenuOpen]);
  const toggleUserMenu = useCallback(() => setUserMenuOpen(curr => !curr), [setUserMenuOpen]);

  let output: React.ReactElement<any> | null;
  if (user) {
    const userMenuList = userMenuOpen === true
      ? (
        <UserList
          user={user}
          closeUserMenu={closeUserMenu}
         />
      ) : null;

    output = (
      <UserMenu
        className={userMenuOpen ? 'active' : undefined}
      >
        <UserButton
          onClick={toggleUserMenu}
        >
          <UserImage src={profilePictureUrl} onError={onImageError} />
          {windowWidth > mobileSize && getString('header-text-menu-account')}
        </UserButton>
        {userMenuList}
      </UserMenu>
    );
  } else if (user === '') {
    const signUpModal = userMenuOpen === false ? null : (
      <SignUpModal
        onCancel={closeUserMenu}
      />
    );

    output = (
      <UserMenu>
        <SignInButton
          onClick={toggleUserMenu}
        >
          {windowWidth > mobileSize && <UserImage src={BackupImage} />}
          {getString(windowWidth <= mobileSize ? 'header-text-login-or-sign-up' : 'header-text-sign-in')}
        </SignInButton>
        {signUpModal}
      </UserMenu>
    );
  } else {
    output = null;
  }
  return (
    <div
      ref={userMenuButtonEl}
      style={!user && windowWidth <= mobileSize ? {
        marginLeft: 'auto',
      } : undefined}
    >
      {output}
    </div>
  );
};

export default UserMenuComponent;
