import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';

export const GET_VIEWPOINT_NOTE = gql`
  query getViewpointNote($userId: ID, $viewpointId: ID) {
    user(id: $userId) {
      id
      viewpointNote(viewpointId: $viewpointId) {
        id
        text
      }
    }
  }
`;

export interface QuerySuccessResponse {
  user: null | {
    id: User['name'];
    __typename: string;
    viewpointNote: null | {
      id: string;
      __typename: string;
      text: string;
    }
  };
}

export interface QueryVariables {
  userId: string | null;
  viewpointId: string | null;
}

export const ADD_VIEWPOINT_NOTE = gql`
  mutation($userId: ID!, $viewpointId: ID!, $text: String!) {
    user: addViewpointNote(
      userId: $userId,
      viewpointId: $viewpointId,
      text: $text
    ) {
      id
      viewpointNote(viewpointId: $viewpointId) {
        id
        text
      }
    }
  }
`;

export const EDIT_VIEWPOINT_NOTE = gql`
  mutation($userId: ID!, $viewpointId: ID!, $text: String!) {
    user: editViewpointNote(
      userId: $userId,
      viewpointId: $viewpointId,
      text: $text
    ) {
      id
      viewpointNote(viewpointId: $viewpointId) {
        id
        text
      }
    }
  }
`;

export interface ViewpointNoteSuccess {
  user: {
    id: User['id'];
    viewpointNote: User['viewpointNote'];
  };
}

export interface Variables {
  userId: string;
  viewpointId: string;
  text: string;
}
