import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';
import { GhostButton, primaryColor } from '../../styling/styleUtils';

const Icon = styled.div`
  font-size: 1.1rem;
  box-shadow: 0px 0px 4px -1px #8d8d8d;
  border-radius: 800px;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HighlightedIcon = styled(Icon)`
  background-color: ${primaryColor};
  color: #fff;
`;

const RootBase = styled(GhostButton)<{$color: string | undefined}>`
  color: ${({ $color }) => $color ? $color : primaryColor};
  font-weight: 400;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    color: ${primaryColor};

    ${Icon} {
      color: #fff;
      background-color: ${primaryColor};
    }
  }
`;

const RootCompact = styled(RootBase)`
  padding-top: 0;
`;

interface Props {
  icon: any;
  text?: string;
  compact?: boolean;
  onClick?: () => void;
  highlighted?: boolean;
  rootWidth?: string;
  color?: string;
}

const IconButton = ({ text, compact, icon, onClick, highlighted, rootWidth, color }: Props) => {
  const Root = compact ? RootCompact : RootBase;
  const IconContainer = highlighted ? HighlightedIcon : Icon;

  return (
    <Root onClick={onClick} style={{ width: rootWidth }} $color={color}>
      <IconContainer>
        <FontAwesomeIcon icon={icon} />
      </IconContainer>
      <div>{text}</div>
    </Root>
  );
};

export default IconButton;
