import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_WATERPOINT_NOTE,
  EDIT_WATERPOINT_NOTE,
  GET_WATERPOINT_NOTE,
  QuerySuccessResponse,
  QueryVariables,
  Variables,
  WaterpointNoteSuccess,
} from './query';

export const useWaterpointNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_WATERPOINT_NOTE, {variables});

export const useAddWaterpointNote = () => {
  const [addWaterpointNote] = useMutation<WaterpointNoteSuccess, Variables>(ADD_WATERPOINT_NOTE);
  return addWaterpointNote;
};
export const useEditWaterpointNote = () => {
  const [editWaterpointNote] = useMutation<WaterpointNoteSuccess, Variables>(EDIT_WATERPOINT_NOTE);
  return editWaterpointNote;
};
