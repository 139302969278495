import React from 'react';
import { useEffect } from 'react';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useMapContext from '../../../hooks/useMapContext';
import useQueryParams from '../../../routing/useQueryParams';
import { DottedSegment, FullWidthContainer } from '../../../styling/styleUtils';
import DefaultError from '../../sharedComponents/DefaultError';
import { defaultGeoJsonLineString } from '../../template/globalMap/map/layers';
import Content from './Content';
import Header from './Header';
import TrailBaggingListDetail from './TrailBaggingListDetail';

interface Props {
  id: string;
}

const ViewpointDetail = (props: Props) => {
  const { id } = props;
  const { view } = useQueryParams();
  const mapContext = useMapContext();
  const {offline} = useOfflineContext();

  useEffect(() => {
    if (mapContext.intialized && !view) {
      mapContext.setHighlightedTrails(defaultGeoJsonLineString);
      mapContext.setHighlightedRoads(defaultGeoJsonLineString);
    }
  }, [mapContext, view]);

  let content: React.ReactElement<any> | null;
  if (offline) {
    content = (
      <FullWidthContainer>
        <DottedSegment>
          <DefaultError
            error={'You are offline'}
            offlineMessage={'Connect to the internet for more details.'}
          />
        </DottedSegment>
      </FullWidthContainer>
    );
  } else {
    content = view === 'list' ? (
      <TrailBaggingListDetail id={id} />
    ) : (
      <Content id={id} />
    );
  }

  return (
    <>
      <Header id={id} />
      {content}
    </>
  );
};

export default ViewpointDetail;
