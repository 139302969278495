import { partition } from 'lodash';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import { GetString } from '../hooks/useFluent';
import { CampsiteType, TrailType } from '../types/graphQLTypes';

export interface Input {
  mountains: {
    name: string;
    elevation: number;
  }[];
  campsites: {
    name: string | null;
    type: CampsiteType;
  }[];
  trails: {
    name: string | null;
    type: TrailType;
    trailLength: number | null;
  }[];
  friends: {
    name: string;
  }[];
  getString: GetString;
  tense?: 'past' | 'present';
}

const getTripName = (input: Input) => {
  const { campsites, mountains, trails, friends, getString, tense } = input;
  const tensedHike = tense === 'present' ? 'hiking' : 'Hiked';
  let locationText: string = 'Hike';
  if (campsites.length && !mountains.length) {
    const [named, notNamed] = partition(campsites, d => d.name);
    const firstCampsiteWithName = uniq(
      [...named, ...notNamed]
        .map(d => d.name ? d.name : upperFirst(getString('global-formatted-campsite-type', { type: d.type }))),
    )[0];
    if (firstCampsiteWithName) {
      locationText = tense === 'present' ? 'camping at ' + firstCampsiteWithName : 'Camped at ' + firstCampsiteWithName;
    } else {
      locationText = tense === 'present' ? 'camping' : 'Camping trip';
    }
  } else if (mountains.length) {
    const mtnsSorted = orderBy(mountains, ['elevation'], ['desc']);
    if (mtnsSorted.length === 3) {
      locationText = `${tensedHike} ${mtnsSorted[0].name}, ${mtnsSorted[1].name}, and ${mtnsSorted[2].name}`;
    } else if (mtnsSorted.length === 2) {
      locationText = `${tensedHike} ${mtnsSorted[0].name} and ${mtnsSorted[1].name}`;
    } else if (mtnsSorted.length === 1) {
      locationText = `${tensedHike} ${mtnsSorted[0].name}`;
    } else if (mtnsSorted.length === 0) {
      locationText = tense === 'present' ? 'hiking' : `Hike`;
    } else {
      locationText = `${tensedHike} ${mtnsSorted[0].name}, ${mtnsSorted[1].name}, and ${mtnsSorted.length - 2} others`;
    }
  } else if (trails.length) {

    const trailsSorted = orderBy(trails, ['trailLength'], ['desc']);
    const trailNames = uniq(
      trailsSorted.map(d => d.name ? d.name : upperFirst(getString('global-formatted-trail-type', { type: d.type }))),
    );
    if (trailNames.length === 3) {
      locationText = `${tensedHike} ${trailNames[0]}, ${trailNames[1]}, and ${trailNames[2]}`;
    } else if (trailNames.length === 2) {
      locationText = `${tensedHike} ${trailNames[0]} and ${trailNames[1]}`;
    } else if (trailNames.length === 1) {
      locationText = `${tensedHike} ${trailNames[0]}`;
    } else if (trailNames.length === 0) {
      locationText = tense === 'present' ? 'hiking' : `Hike`;
    } else {
      locationText = `${tensedHike} ${trailNames[0]}, ${trailNames[1]}, and ${trailNames.length - 2} others`;
    }
  }
  let friendsText = '';
  if (friends.length) {
    const filteredFriends = friends.filter(d => d.name).map(d => d.name);
    if (filteredFriends.length === 3) {
      friendsText = ` with ${filteredFriends[0]}, ${filteredFriends[1]}, and ${filteredFriends[2]}`;
    } else if (filteredFriends.length === 2) {
      friendsText = ` with ${filteredFriends[0]} and ${filteredFriends[1]}`;
    } else if (filteredFriends.length === 1) {
      friendsText = ` with ${filteredFriends[0]}`;
    } else if (filteredFriends.length === 0) {
      friendsText = '';
    } else {
      friendsText = ` with ${filteredFriends[0]}, ${filteredFriends[1]}, and ${filteredFriends.length - 2} others`;
    }
  }
  return locationText + friendsText;
};

export default getTripName;
