import { gql } from '@apollo/client';
import {
  RoutePlan,
  Segment,
  User,
  Trail,
} from '../../../types/graphQLTypes';
import { ReturnedRoutePlan } from '../useRoutePlanDetails/query';

export const N_PER_PAGE = 10;

export type BasicReturnedRoutePlan = Omit<ReturnedRoutePlan, 'segments'> & {
  numSegments: RoutePlan['numSegments']
  segments: {
    id: Segment['id']
    name: Segment['name']
    lines: Segment['lines']
    color: Segment['color'],
    // TODO: Add gql endpoint to just return raw id
    trails: {
      id: Trail['id'],
    }[],
  }[],
};

export const routePlanQuery = `
  id
  name
  visibility
  autoGenerated
  authors {
    id
    name
  }
  canEdit
  authorVisibility
  totalLength
  created
  modified
  center
  numSegments
  segments {
    id
    name
    lines
    color
    trails {
      id
    }
  }
`;

export const GET_BUFFERED_USERS_SAVED_ROUTE_PLANS = gql`
  query GetBufferedSavedRoutePlans($userId: ID, $limit: Int!, $offset: Int!) {
    user(id: $userId) {
      id
      totalSavedRoutes
      savedRoutePlans(limit: $limit, offset: $offset) {
        ${routePlanQuery}
      }
    }
  }
`;

export const GET_USERS_SAVED_ROUTE_PLANS = gql`
  query GetSavedRoutePlans($userId: ID) {
    user(id: $userId) {
      id
      totalSavedRoutes
      savedRoutePlans {
        ${routePlanQuery}
      }
    }
  }
`;

export const SAVE_ROUTE_PLAN_TO_USER = gql`
  mutation SaveRoutePlanToUser($userId: ID!, $routePlanId: ID!) {
    user: saveRoutePlanToUser(userId: $userId, routePlanId: $routePlanId) {
      id
      totalSavedRoutes
      savedRoutePlans {
        ${routePlanQuery}
      }
    }
  }
`;

export const REMOVE_SAVED_ROUTE_PLAN_TO_USER = gql`
  mutation RemoveSavedRoutePlanFromUser($userId: ID!, $routePlanId: ID!) {
    user: removeSavedRoutePlanFromUser(userId: $userId, routePlanId: $routePlanId) {
      id
      totalSavedRoutes
      savedRoutePlans {
        ${routePlanQuery}
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface BufferVariables {
  userId: string | null;
  limit: number;
  offset: number;
}

export interface MutationVariables {
  userId: string;
  routePlanId: string;
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    totalSavedRoutes: User['totalSavedRoutes'];
    savedRoutePlans: (null | BasicReturnedRoutePlan)[];
  };
}
