const {point} = require('@turf/helpers');
const distance = require('@turf/distance').default;
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import Helmet from 'react-helmet';
import useFluent from '../../../hooks/useFluent';
import useMapCenter from '../../../hooks/useMapCenter';
import { useGeoBoxTrailheads } from '../../../queries/trails/useGeoBoxTrailheads';
import { trailDetailLink, trailheadDetailLink } from '../../../routing/Utils';
import { AuxiliaryItem } from '../../../types/itemTypes';
import DefaultError from '../../sharedComponents/DefaultError';
import GhostCard from '../../sharedComponents/GhostDetailCard';
import Results from '../../sharedComponents/listComponents/Results';
import MapRenderProp, { TrailheadGeoDatum } from '../../sharedComponents/MapRenderProp';
import {trailheadDefaultSvg} from '../../sharedComponents/svgIcons';
import sortTrailheads from './sortTrailheads';

const TrailSearchPage = () => {
  const getString = useFluent();

  const mapCenter = useMapCenter();
  const mapCenterPoint = point(mapCenter);
  const {loading, error, data, bbox} = useGeoBoxTrailheads({limit: 100});

  let list: React.ReactElement<any> | null;
  if (loading === true && data === undefined) {
    const loadingCards: React.ReactElement<any>[] = [];
    for (let i = 0; i < 3; i++) {
      loadingCards.push(<GhostCard key={i} />);
    }
    list = <>{loadingCards}</>;
  } else if (error !== undefined) {
    list = (
      <DefaultError
        error={error}
        offlineMessage={'Connect to the internet to search for trails.'}
      />
    );
  } else if (data !== undefined) {
    if (!data.trailheads) {
      const loadingCards: React.ReactElement<any>[] = [];
      for (let i = 0; i < 3; i++) {
        loadingCards.push(<GhostCard key={i} />);
      }
      list = <>{loadingCards}</>;
    } else {
      const sortedTrailheads = sortTrailheads({trailheads: data.trailheads, mapCenterPoint, bbox});
      const trailheadMapPoints: TrailheadGeoDatum[] = [];
      const trails = sortedTrailheads.map(t => {
        const formattedType = upperFirst(getString('global-formatted-trail-type', {type: t.type}));
        const name = t.name ? t.name + ' Trailhead' : 'Trailhead';
        if (!loading && t.roadAccessPoint) {
          trailheadMapPoints.push({
            id: t.id,
            name: t.name,
            location: t.roadAccessPoint,
          });
        }
        return {
          id: t.id,
          title: name,
          location: t.roadAccessPoint,
          locationText: t.locationText,
          type: AuxiliaryItem.trailhead,
          url: trailheadDetailLink(t.id),
          icon: trailheadDefaultSvg,
          customIcon: true,
          distanceToCenter: distance(mapCenterPoint, point(t.roadAccessPoint)),
          formattedType,
          totalTrailMiles: t.totalTrailMiles,
        };
      });
      const mapRenderProp = loading ? null : (
          <MapRenderProp
            id={'search-for-trailheads'}
            trailheads={trailheadMapPoints}
          />
      );
      list = (
        <>
          <Results
            data={trails as any}
            type={AuxiliaryItem.trailhead}
          />
          {mapRenderProp}
        </>
      );
    }
  } else {
    const loadingCards: React.ReactElement<any>[] = [];
    for (let i = 0; i < 3; i++) {
      loadingCards.push(<GhostCard key={i} />);
    }
    list = <>{loadingCards}</>;
  }

  const metaDescription = getString('meta-data-trail-search-description');

  return (
    <>
      <Helmet>
        <title>{getString('meta-data-trail-search-default-title')}</title>
        <meta
          name='description'
          content={metaDescription}
        />
        <meta property='og:title' content={getString('meta-data-trail-search-default-title')} />
        <meta
          property='og:description'
          content={metaDescription}
        />
        <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + trailDetailLink('search')} />
      </Helmet>
      {list}
    </>
  );
};

export default TrailSearchPage;
