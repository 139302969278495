import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Timeline from '.';
import useFluent from '../../../hooks/useFluent';
import { secondaryColor } from '../../../styling/styleUtils';
import DetailSegment, { Panel } from '../../sharedComponents/detailComponents/DetailSegment';

const Root = styled.div`
  position: relative;
`;

const Close = styled(Link)`
  background-color: transparent;
  color: ${secondaryColor};
  text-decoration: none;
  position: absolute;
  top: 0;
  right: -1rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  small {
    font-size: 0.7rem;
    margin-left: 0.5rem;
  }

  &:hover {
    color: ${secondaryColor};
  }
`;

interface Props {
  author: string;
  mountain?: string;
  campsite?: string;
  trail?: string;
  returnLink: string;
}

const InlineTimeline = (props: Props) => {

  const getString = useFluent();
  const panels: Panel[] = [
    {
      title: getString('global-text-value-timeline'),
      reactNode: (
        <Timeline
        {...props}
        />
        ),
        customIcon: false,
        icon: faCalendarAlt,
      },
    ];

  return (
    <Root>
      <Close to={props.returnLink}>× <small>{getString('global-text-value-modal-close')}</small></Close>
      <DetailSegment
        panels={panels}
        panelId={'detailStatsPanel'}
      />
    </Root>
  );
};

export default InlineTimeline;
