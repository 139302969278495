export interface Region {
  id: string;
  name: string;
  states: (State | null)[];
}

export interface State {
  _id: string;
  id: string;
  name: string;
  abbreviation: string;
  regions: (Region | null)[];
  mountains: (Mountain | null)[];
  peakLists: (PeakList | null)[] | null;
  numPeakLists: number;
  numMountains: number;
}

export enum CreatedItemStatus {
  pending = 'pending',
  auto = 'auto',
  accepted = 'accepted',
}

export type Longitude = number;
export type Latitude = number;
export type Elevation = number;
export type Coordinate = [Longitude, Latitude];
export type CoordinateWithElevation = [Longitude, Latitude, Elevation];

export interface Mountain {
  id: string;
  __typename: string;
  name: string;
  state: State | null;
  latitude: number;
  longitude: number;
  elevation: number;
  prominence: number | null;
  lists: (PeakList | null)[];
  optionalLists: null | (PeakList | null)[];
  author: User | null;
  status: CreatedItemStatus | null;
  flag: string | null;
  description: string | null;
  resources: ExternalResource[] | null;
  location: Coordinate;
  trailAccessible: boolean;
  locationText: string | null;
  locationTextShort: string | null;
  roadAccessMiles: number | null;
  trailAccessMiles: number | null;
  nearestViewpointDistMiles: number | null;
  userNote: string | null;
}

export enum PeakListVariants {
  standard = 'standard',
  winter = 'winter',
  fourSeason = 'fourSeason',
  grid = 'grid',
}

export interface ExternalResource {
  title: string;
  url: string;
}

export enum PeakListTier {
  casual = 'casual',
  advanced = 'advanced',
  expert = 'expert',
  mountaineer = 'mountaineer',
}

export enum ListPrivacy {
  Private = 'private',
  Public = 'public',
}

export interface PeakList {
  _id: string;
  __typename: string;
  id: string;
  name: string;
  shortName: string;
  description: string | null;
  optionalPeaksDescription: string | null;
  type: PeakListVariants;
  parent: PeakList | null;
  mountains: (Mountain | null)[];
  optionalMountains: null | (Mountain | null)[];
  trails: (Trail | null)[];
  optionalTrails: null | (Trail | null)[];
  campsites: (Campsite | null)[];
  optionalCampsites: null | (Campsite | null)[];
  users: (User | null)[];
  numUsers: number;
  searchString: string;
  states: (State | null)[] | null;
  children: (PeakList | null)[] | null;
  siblings: (PeakList | null)[] | null;
  resources: ExternalResource[] | null;
  author: User | null;
  status: CreatedItemStatus | null;
  flag: string | null;
  tier: PeakListTier | null;
  numMountains: number;
  numTrails: number;
  numCampsites: number;
  numCompletedAscents: number;
  numCompletedTrails: number;
  numCompletedCampsites: number;
  numCompletedTrips: number;
  latestAscent: string | null;
  latestTrip: string | null;
  isActive: boolean | null;
  stateOrRegionString: string | null;
  center: Coordinate | null;
  bbox: [Longitude, Latitude, Longitude, Latitude] | null;
  classification: string | null;
  privacy: ListPrivacy | null;
  locationText: string | null;
  locationTextShort: string | null;
  userNote: string | null;
}

export enum TrailType {
  dirtroad = 'dirtroad',
  trail = 'trail',
  path = 'path',
  stairs = 'stairs',
  cycleway = 'cycleway',
  road = 'road',
  hiking = 'hiking',
  bridleway = 'bridleway',
  demandingMountainHiking = 'demanding_mountain_hiking',
  mountainHiking = 'mountain_hiking',
  herdpath = 'herdpath',
  alpineHiking = 'alpine_hiking',
  demandingAlpineHiking = 'demanding_alpine_hiking',
  difficultAlpineHiking = 'difficult_alpine_hiking',
  parentTrail = 'parent_trail',
}

export interface Trail {
  _id: string;
  id: string;
  name: string | null;
  osmId: number | null;
  relId: number | null;
  type: TrailType;
  states: (State | null)[] | null;
  line: Coordinate[];
  bbox: [Longitude, Latitude, Longitude, Latitude] | null;
  center: Coordinate;
  allowsBikes: boolean | null;
  allowsHorses: boolean | null;
  parents: (Trail | null)[] | null;
  children: (Trail | null)[] | null;
  waterCrossing: string | null;
  skiTrail: boolean | null;
  flag: string | null;
  locationText: string | null;
  locationTextShort: string | null;
  trailLength: number | null;
  avgSlope: number | null;
  childrenCount: number;
  latestTrip: string | null;
  roadAccessPoint: Coordinate | null;
  totalTrailMiles: number | null;
  userNote: string | null;
}

export interface Parking {
  _id: string;
  name: string | null;
  osmId: string | null;
  type: string | null;
  location: Coordinate;
}

export enum ParkingType {
  informationBoard = 'information_board',
  informationMap = 'information_map',
  picnicSite = 'picnic_site',
  park = 'park',
  trailhead = 'trailhead',
  parkingSpace = 'parking_space',
  intersection = 'intersection',
  parking = 'parking',
}

export enum CampsiteType {
  campSite = 'camp_site',
  caravanSite = 'caravan_site',
  weatherShelter = 'weather_shelter',
  campPitch = 'camp_pitch',
  leanTo = 'lean_to',
  wildernessHut = 'wilderness_hut',
  alpineHut = 'alpine_hut',
  basicHut = 'basic_hut',
  rockShelter = 'rock_shelter',
}

export enum CampsiteOwnership {
  private = 'private',
  federal = 'federal',
  state = 'state',
}

export enum CampsiteReservation {
  reservable = 'reservable',
  notReservable = 'not reservable',
  recommended = 'recommended',
  required = 'required',
}

export interface Campsite {
  _id: string;
  id: string;
  reserveamericaId: string | null;
  ridbId: string | null;
  osmId: string | null;
  name: string | null;
  location: Coordinate;
  state: State | null;
  website: string | null;
  type: CampsiteType;
  ownership: CampsiteOwnership | null;
  electricity: boolean | null;
  toilets: boolean | null;
  drinking_water: boolean | null;
  email: string | null;
  reservation: CampsiteReservation | string | null;
  showers: boolean | null;
  phone: string | null;
  fee: boolean | null;
  tents: boolean | null;
  capacity: number | null;
  internet_access: boolean | null;
  fire: boolean | null;
  maxtents: number | null;
  author: User | null;
  status: CreatedItemStatus | null;
  description: string | null;
  resources: ExternalResource[] | null;
  flag: string | null;
  elevation: number;
  locationText: string | null;
  locationTextShort: string;
  roadAccessMiles: number | null;
  trailAccessMiles: number | null;
  userNote: string | null;
}

export interface Viewpoint {
  _id: string;
  id: string;
  osmId: string | null;
  state: State | null;
  locationText: string;
  locationTextShort: string;
  location: Coordinate;
  name: string | null;
  generatedName: boolean;
  elevation: number | null;
  roadAccessMiles: number | null;
  trailAccessMiles: number | null;
  nearestMountainDistMiles: number | null;
  nearestCampsitesDistMiles: number | null;
  flag: string | null;
  author: User | null;
  status: CreatedItemStatus | null;
  userNote: string | null;
}

export enum WaterpointType {
  water = 'water',
  lake = 'lake',
  pool = 'pool',
  pond = 'pond',
  reservoir = 'reservoir',
  bay = 'bay',
  bayou = 'bayou',
  bog = 'bog',
  brook = 'brook',
  canal = 'canal',
  creek = 'creek',
  fountain = 'fountain',
  marsh = 'marsh',
  spring = 'spring',
  basin = 'basin',
  drinking_water = 'drinking_water',
  estuary = 'estuary',
  harbour = 'harbour',
  quarry = 'quarry',
  swamp = 'swamp',
  stream = 'stream',
  lagoon = 'lagoon',
  waterfall = 'waterfall',
  vernal_pool = 'vernal_pool',
  cove = 'cove',
  drainage = 'drainage',
  floodway = 'floodway',
  intermittent_water = 'intermittent_water',
  rapids = 'rapids',
  river = 'river',
  salt_water = 'salt_water',
  shoal = 'shoal',
  slough = 'slough',
  wetland = 'wetland',
}

export interface Waterpoint {
  _id: string;
  id: string;
  name: string | null;
  type: WaterpointType | null;
  state: State | null;
  locationText: string;
  locationTextShort: string;
  location: Coordinate;
  potable: boolean | null;
  seasonal: boolean | null;
  elevation: number | null;
  trailPoint: Coordinate | null;
  trailDistanceMiles: number | null;
  flag: string | null;
  author: User | null;
  status: CreatedItemStatus | null;
  userNote: string | null;
}

export interface Park {
  _id: string;
  id: string;
  osmId: string;
  name: string;
  trailMileage: number;
  numCampsitesBackcountry: number;
  numCampsitesCar: number;
  numMountains: number;
  areaSquareMeters: number;
  bbox: [Longitude, Latitude, Longitude, Latitude];
  center: Coordinate;
  protectTitle: string;
  landType: string;
  operator: string | null;
  ownership: string | null;
  locationText: string;
  locationTextShort: string;
  restrictedAcess: string | null;
  state: State;
  trailBaggingList: PeakList;
  website: string | null;
  flag: string | null;
  userNote: string | null;
}

export enum PermissionTypes {
  standard = 'standard',
  admin = 'admin',
}

export interface CompletedMountain {
  _id: string;
  mountain: Mountain | null;
  dates: string[];
}

export interface CompletedTrail {
  _id: string;
  trail: Trail | null;
  dates: string[];
}

export interface CompletedCampsite {
  _id: string;
  campsite: Campsite | null;
  dates: string[];
}

export enum FriendStatus {
  friends = 'friends',
  sent = 'sent',
  recieved = 'recieved',
}

export interface Friend {
  user: User | null;
  status: FriendStatus;
}

export interface AscentNotification {
  id: string;
  user: User | null;
  mountain: Mountain | null;
  date: string;
}

export interface TrailNotification {
  id: string;
  user: User | null;
  trail: Trail | null;
  date: string;
}

export interface CampsiteNotification {
  id: string;
  user: User | null;
  campsite: Campsite | null;
  date: string;
}

export interface PeakListNote {
  id: string;
  peakList: PeakList | null;
  text: string;
}

export interface MountainNote {
  id: string;
  __typename: string;
  mountain: Mountain | null;
  text: string;
}

export interface TrailNote {
  id: string;
  trail: Trail | null;
  text: string;
}

export interface CampsiteNote {
  id: string;
  campsite: Campsite | null;
  text: string;
}

export interface ViewpointNote {
  id: string;
  viewpoint: Viewpoint | null;
  text: string;
}
export interface WaterpointNote {
  id: string;
  viewpoint: Waterpoint | null;
  text: string;
}
export interface ParkNote {
  id: string;
  viewpoint: Park | null;
  text: string;
}

export interface User {
  _id: string;
  id: string;
  googleId: string | null;
  redditId: string | null;
  facebookId: string | null;
  name: string;
  email: string | null;
  profilePictureUrl: string;
  friends: Friend[] | null;
  friendRequests: Friend[] | null;
  peakLists: (PeakList | null)[] | null;
  savedMountains: (Mountain | null)[] | null;
  savedTrails: (Trail | null)[] | null;
  savedTrailheads: (Trail | null)[] | null;
  savedCampsites: (Campsite | null)[] | null;
  savedViewpoints: (Viewpoint | null)[] | null;
  savedWaterpoints: (Waterpoint | null)[] | null;
  savedParks: (Park | null)[] | null;
  mountains: CompletedMountain[] | null;
  trails: CompletedTrail[] | null;
  campsites: CompletedCampsite[] | null;
  permissions: PermissionTypes;
  hideEmail: boolean | null;
  hideProfilePicture: boolean | null;
  hideProfileInSearch: boolean | null;
  disableEmailNotifications: boolean | null;
  ascentNotifications: AscentNotification[] | null;
  trailNotifications: TrailNotification[] | null;
  campsiteNotifications: CampsiteNotification[] | null;
  peakListNotes: (PeakListNote | null)[] | null;
  peakListNote: PeakListNote | null;
  mountainNotes: (MountainNote | null)[] | null;
  mountainNote: MountainNote | null;
  parkNotes: (ParkNote | null)[] | null;
  parkNote: ParkNote | null;
  trailNote: TrailNote | null;
  trailNotes: (TrailNote | null)[] | null;
  campsiteNote: CampsiteNote | null;
  campsiteNotes: (CampsiteNote | null)[] | null;
  viewpointNote: ViewpointNote | null;
  viewpointNotes: (ViewpointNote | null)[] | null;
  waterpointNote: WaterpointNote | null;
  waterpointNotes: (WaterpointNote | null)[] | null;
  mountainPermissions: number | null;
  campsitePermissions: number | null;
  peakListPermissions: number | null;
  viewpointPermissions: number | null;
  waterpointPermissions: number | null;
  latestAscent: CompletedMountain | null;
  allInProgressMountains: Mountain[] | null;
  allInProgressTrails: Trail[] | null;
  allInProgressCampsites: Campsite[] | null;
  authoredMountains: Mountain[] | null;
  authoredPeakLists: PeakList[] | null;
  savedRoutePlans: RoutePlan[] | null;
  totalSavedRoutes: number;
}

export interface Conditions {
  mudMinor: boolean | null;
  mudMajor: boolean | null;
  waterSlipperyRocks: boolean | null;
  waterOnTrail: boolean | null;
  leavesSlippery: boolean | null;
  iceMinor: boolean | null;
  iceBlack: boolean | null;
  iceBlue: boolean | null;
  iceCrust: boolean | null;
  snowIceFrozenGranular: boolean | null;
  snowIceMonorailStable: boolean | null;
  snowIceMonorailUnstable: boolean | null;
  snowIcePostholes: boolean | null;
  snowMinor: boolean | null;
  snowPackedPowder: boolean | null;
  snowUnpackedPowder: boolean | null;
  snowDrifts: boolean | null;
  snowSticky: boolean | null;
  snowSlush: boolean | null;
  obstaclesBlowdown: boolean | null;
  obstaclesErosion: boolean | null;
  obstaclesRockslides: boolean | null;
  obstaclesOvergrown: boolean | null;
  obstaclesOther: boolean | null;
}

export enum TripReportPrivacy {
  Private = 'private',
  Public = 'public',
  Anonymous = 'anonymous',
}

export interface TripReport extends Conditions {
  id: string;
  date: string;
  parent: TripReport | null;
  author: User | null;
  mountains: (Mountain | null)[];
  trails: (Trail | null)[];
  campsites: (Campsite | null)[];
  route: Coordinate[][] | null;
  users: (User | null)[];
  notes: string | null;
  link: string | null;
  privacy: TripReportPrivacy | null;
  rating: number | null;
}

export interface Comment {
  id: string;
  author: User | null;
  created: string;
  edited: string | null;
  forId: string;
  forType: string;
  comment: string;
  rating: number;
}

export interface Segment {
  id: string;
  name: string | null;
  color: string | null;
  lines: Coordinate[][];
  trails: (Trail | null)[] | null;
}

export interface RoutePlan {
  id: string; // indexed
  name: string;
  visibility: 'public' | 'friends' | 'private';
  autoGenerated: boolean | null;
  authors: User[];
  canEdit: boolean; // resolve on server
  authorVisibility: 'public' | 'anonymous';
  totalLength: number;
  created: string;
  modified: string;
  segments: Segment[];
  numSegments: number;
  center: Coordinate; // indexed
}
