import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import DragScrollRoot from 'react-indiana-drag-scroll';
import styled from 'styled-components/macro';
import { primaryColor } from '../../styling/styleUtils';

const Root = styled.div`
  position: relative;
`;

const ArrowBase = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2rem;
  color: ${primaryColor};
  padding: 0;
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  align-items: center;
`;

const ArrowLeft = styled(ArrowBase)`
left: 0;
background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,0.75) 100%);
padding-left: 5px;
`;

const ArrowRight = styled(ArrowBase)`
right: 0;
background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,0.75) 100%);
justify-content: flex-end;
padding-right: 5px;
`;

const ArrowContainer = styled.span`
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  border-radius: 4000px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const scrollBuffer = 15;

interface AtEdge {
  left: boolean;
  right: boolean;
}

const ScrollContainer = (props: PropsWithChildren<{
  hideArrows?: boolean
  [key: string]: any,
}>) => {
  const {children, ...passedProps} = props;
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [atEdge, setAtEdge] = useState<AtEdge>({left: true, right: true});

  const onScroll = useCallback(() => {
    const node = innerRef.current;
    if (node) {
      const left = node.scrollLeft;
      const width = node.getBoundingClientRect().width;
      const scrollWidth = node.scrollWidth;
      const right = left + width;
      setAtEdge({
        left: left < scrollBuffer,
        right: right > scrollWidth - scrollBuffer,
      });
    }
  }, [innerRef]);

  useEffect(() => {
    const node = innerRef.current;
    const onScrollDebounce = debounce(onScroll, 100);
    if (node) {
      onScroll();
      node.addEventListener('scroll', onScrollDebounce);
    }
    return () => {
      if (node) {
        node.removeEventListener('scroll', onScrollDebounce);
      }
    };
  }, [innerRef, children, onScroll]);

  const scrollRight = () => {
    const node = innerRef.current;
    if (node) {
      const width = node.getBoundingClientRect().width;
      node.scrollTo({
        left: node.scrollLeft + (width / 2),
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    const node = innerRef.current;
    if (node) {
      const width = node.getBoundingClientRect().width;
      node.scrollTo({
        left: node.scrollLeft - (width / 2),
        behavior: 'smooth',
      });
    }
  };

  return (
    <Root
      onMouseEnter={onScroll}
    >
      <ArrowLeft
        style={{
          display: atEdge.left ? 'none' : undefined,
        }}
        onClick={scrollLeft}
      >
        <ArrowContainer style={props.hideArrows ? {visibility: 'hidden'} : undefined}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ArrowContainer>
      </ArrowLeft>
      <ArrowRight
        style={{
          display: atEdge.right ? 'none' : undefined,
        }}
        onClick={scrollRight}
      >
        <ArrowContainer style={props.hideArrows ? {visibility: 'hidden'} : undefined}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowContainer>
      </ArrowRight>
      <DragScrollRoot
        {...passedProps}
        innerRef={innerRef}
        hideScrollbars={true}
      >
        {children}
      </DragScrollRoot>
    </Root>
  );
};

export default ScrollContainer;
