import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';

interface OfflineState {
  offline: boolean;
}

const OfflineContext = createContext<OfflineState>({offline: !navigator.onLine});

const OfflineContextProvider = ({children}: PropsWithChildren<Record<string, unknown>>) => {
  const [offline, setOffline] = useState<boolean>(!navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setOffline(!navigator.onLine);
    window.addEventListener('DOMContentLoaded',  updateOnlineStatus);
    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    return () => {
      window.removeEventListener('DOMContentLoaded',  updateOnlineStatus);
      window.removeEventListener('online',  updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <OfflineContext.Provider value={{offline}}>
      {children}
    </OfflineContext.Provider>
  );
};

export const useOfflineContext = () => {
  const offline = useContext(OfflineContext);

  return offline;
};

export default OfflineContextProvider;
