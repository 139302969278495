import { useApolloClient, useMutation, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  BufferVariables,
  GET_BUFFERED_USERS_SAVED_ROUTE_PLANS,
  GET_USERS_SAVED_ROUTE_PLANS,
  MutationVariables,
  REMOVE_SAVED_ROUTE_PLAN_TO_USER,
  SAVE_ROUTE_PLAN_TO_USER,
  SuccessResponse,
  Variables,
} from './query';
import { useEffect, useMemo, useState } from 'react';
import uniqBy from 'lodash/uniqBy';

const FIRST_PAGE = 15;
const BUFFER_LIMIT = 50;

export const useSavedRoutePlans = () => {
  const user = useCurrentUser();
  const userId = useMemo(() => user && user._id ? user._id : null, [user]);
  const client = useApolloClient();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const { offline } = useOfflineContext();

  useEffect(() => {
    const bufferRoutes = async (offset: number) => {
        const existingData = client.readQuery<SuccessResponse, Variables>({
          query: GET_USERS_SAVED_ROUTE_PLANS,
          variables: {userId},
        });
        const {data: savedRoutesData} = await client.query<SuccessResponse, BufferVariables>({
          query: GET_BUFFERED_USERS_SAVED_ROUTE_PLANS,
          variables: {userId, limit: offset === 0 ? FIRST_PAGE : BUFFER_LIMIT, offset},
          fetchPolicy: 'network-only',
        });
        if (
            typeof savedRoutesData.user?.savedRoutePlans.length === 'number' &&
            savedRoutesData.user?.savedRoutePlans.length &&
            existingData?.user?.savedRoutePlans.length !== savedRoutesData.user?.totalSavedRoutes
          ) {
          const mergedRoutes = offset === 0 ? savedRoutesData.user?.savedRoutePlans ?? [] : uniqBy([
            ...(existingData?.user?.savedRoutePlans ?? []), ...(savedRoutesData.user?.savedRoutePlans ?? []),
          ], 'id');
          client.writeQuery<SuccessResponse, Variables>({
            query: GET_USERS_SAVED_ROUTE_PLANS,
            variables: {userId},
            data: {...savedRoutesData, user: {...savedRoutesData.user, savedRoutePlans: mergedRoutes}},
          });
          const nextOffset = offset === 0 ? FIRST_PAGE : offset + BUFFER_LIMIT;
          bufferRoutes(nextOffset);
          setInitialLoading(false);
        } else if (existingData?.user?.savedRoutePlans) {
          setInitialLoading(false);
        }
    };
    if (!offline && userId) {
      bufferRoutes(0);
    } else {
      setInitialLoading(false);
    }
  }, [offline, userId, client, setInitialLoading]);

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_ROUTE_PLANS, {variables: {userId}, skip: !userId, fetchPolicy: 'cache-only'},
  );

  const [saveRoutePlanToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_ROUTE_PLAN_TO_USER, {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery<SuccessResponse>({ query: GET_USERS_SAVED_ROUTE_PLANS, variables: {userId}, data});
        }
      },
    });

  const [removeSavedRoutePlanFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVE_SAVED_ROUTE_PLAN_TO_USER, {
      update(cache, { data }) {
        if (data) {
          cache.writeQuery<SuccessResponse>({ query: GET_USERS_SAVED_ROUTE_PLANS, variables: {userId}, data});
        }
      },
    });

  return {initialLoading, response, saveRoutePlanToUser, removeSavedRoutePlanFromUser};
};
