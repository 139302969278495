import { gql } from '@apollo/client';
import {
  PeakList,
  User,
} from '../../../types/graphQLTypes';

export const GET_BASIC_LIST_DETAILS = gql`
  query GetBasicListDetails($id: ID!, $userId: ID) {
    peakList(id: $id) {
      id
      name
      shortName
      type
      description
      locationText
      bbox
      numMountains
      numTrails
      numCampsites
      privacy
      numCompletedTrips(userId: $userId)
      latestTrip(userId: $userId, raw: true)
      isActive(userId: $userId)
      resources {
        title
        url
      }
      parent {
        id
        type
      }
      children {
        id
        type
      }
      siblings {
        id
        type
      }
      author {
        id
      }
    }
  }
`;

interface PeakListDatum {
  __typename: string;
  id: PeakList['id'];
  name: PeakList['name'];
  shortName: PeakList['shortName'];
  type: PeakList['type'];
  description: PeakList['description'];
  resources: PeakList['resources'];
  locationText: PeakList['locationText'];
  numMountains: PeakList['numMountains'];
  numTrails: PeakList['numTrails'];
  numCampsites: PeakList['numCampsites'];
  numCompletedTrips: PeakList['numCompletedTrips'];
  isActive: PeakList['isActive'];
  latestTrip: PeakList['latestTrip'];
  bbox: PeakList['bbox'];
  privacy: PeakList['privacy'];
  parent: null | {id: PeakList['id'], type: PeakList['type'], __typename: string};
  children: null | {id: PeakList['id'], type: PeakList['type']}[];
  siblings: null | {id: PeakList['id'], type: PeakList['type']}[];
  author: null | { id: User['id'] };
}

export interface SuccessResponse {
  peakList: PeakListDatum;
}

export interface Variables {
  id: string;
  userId: string | null;
}
