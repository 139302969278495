import { gql } from '@apollo/client';
import {
  Campsite,
  Conditions,
  Mountain,
  Trail,
  TripReport,
  User,
} from '../../../types/graphQLTypes';

export const N_PER_PAGE = 15;

export const GET_TRIP_REPORTS_FOR_USER = gql`
  query GetTripReportByAuthorAndItems(
    $author: ID!
    $mountain: ID
    $trail: ID
    $campsite: ID
    $limit: Int
    $offset: Int
    $sortDirection: String
    $month: String
    $search: String
  ) {
    totalReports(author: $author)
    tripReports: allTripReportsByAuthorAndItems(
      author: $author
      mountain: $mountain
      trail: $trail
      campsite: $campsite
      limit: $limit
      offset: $offset
      sortDirection: $sortDirection
      month: $month
      search: $search
    ) {
      id
      date
      author {
        id
      }
      mountains {
        id
        name
        elevation
      }
      trails {
        id
        name
        type
        trailLength
      }
      campsites {
        id
        name
        type
      }
      users {
        id
        name
      }
      privacy
      notes
      link
      rating
      mudMinor
      mudMajor
      waterSlipperyRocks
      waterOnTrail
      leavesSlippery
      iceMinor
      iceBlack
      iceBlue
      iceCrust
      snowIceFrozenGranular
      snowIceMonorailStable
      snowIceMonorailUnstable
      snowIcePostholes
      snowMinor
      snowPackedPowder
      snowUnpackedPowder
      snowDrifts
      snowSticky
      snowSlush
      obstaclesBlowdown
      obstaclesErosion
      obstaclesRockslides
      obstaclesOvergrown
      obstaclesOther
    }
  }
`;

export interface MountainDatum {
  id: Mountain['id'];
  name: Mountain['name'];
  elevation: Mountain['elevation'];
}

export interface TrailDatum {
  id: Trail['id'];
  name: Trail['name'];
  type: Trail['type'];
  trailLength: Trail['trailLength'];
}
export interface CampsiteDatum {
  id: Campsite['id'];
  name: Campsite['name'];
  type: Campsite['type'];
}
export interface FriendDatum {
  id: User['id'];
  name: User['name'];
}

export interface TripReportDatum extends Conditions {
  id: TripReport['id'];
  date: TripReport['date'];
  author: null | {
    id: User['id'];
  };
  mountains: (MountainDatum | null)[];
  trails: (TrailDatum | null)[];
  campsites: (CampsiteDatum | null)[];
  users: (FriendDatum | null)[];
  notes: TripReport['notes'];
  link: TripReport['link'];
  privacy: TripReport['privacy'];
  rating: TripReport['rating'];
}

export interface SuccessResponse {
  totalReports: number;
  tripReports: null | TripReportDatum[];
}

export interface DateDatum {
  date: string;
  tripReports: TripReportDatum[];
}

export interface Variables {
  author: string;
  mountain: string | null;
  trail: string | null;
  campsite: string | null;
  limit?: number | null;
  offset?: number | null;
  sortDirection?: 'asc' | 'desc' | null;
  month?: string | null;
  search?: string | null;
}
