import { faWifi } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components/macro';
import {
  NoResultsHelp,
  NoResultsIcon,
  NoResultsRoot,
  NoResultsTitle,
  primaryColor,
} from '../../styling/styleUtils';

const WifiIcon = styled.div`
  position: relative;

  &:after {
    content: '';
    width: 120%;
    height: 0;
    border-top: solid 3px ${primaryColor};
    border-bottom: solid 2px white;
    position: absolute;
    left: -5px;
    top: -23px;
    bottom: 0;
    margin: auto;
    transform: rotate(50deg);
  }
`;

interface Props {
  message?: string;
}

const OfflineError = ({message}: Props) => {
  return (
    <NoResultsRoot>
      <WifiIcon>
        <NoResultsIcon icon={faWifi} />
      </WifiIcon>
      <NoResultsTitle>
        You are offline.
      </NoResultsTitle>
      <NoResultsHelp>
        {message}
      </NoResultsHelp>
    </NoResultsRoot>
  );
};

export default OfflineError;
