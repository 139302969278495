import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import useConstrainedMapBbox from '../../hooks/useConstrainedMapBbox';
import useMapCenter from '../../hooks/useMapCenter';
import {
  Coordinate,
  Park,
 } from '../../types/graphQLTypes';

const GEO_BOX_PARKS = gql`
  query GeoBoxParks(
    $bottomLeftCoordinates: [Float]!,
    $topRightCoordinates: [Float]!,
    $limit: Int!,
  ) {
    parks: geoBoxParks(
      bottomLeftCoordinates: $bottomLeftCoordinates,
      topRightCoordinates: $topRightCoordinates,
      limit: $limit,
    ) {
      id
      name
      center
      bbox
      locationText
      locationTextShort
      areaSquareMeters
      trailMileage
      numCampsitesBackcountry
      numCampsitesCar
      numMountains
      protectTitle
      landType
      operator
      ownership
    }
  }
`;

export interface ParkDatum {
  id: Park['id'];
  name: Park['name'];
  center: Park['center'];
  bbox: Park['bbox'];
  locationText: Park['locationText'];
  locationTextShort: Park['locationTextShort'];
  areaSquareMeters: Park['areaSquareMeters'];
  trailMileage: Park['trailMileage'];
  numCampsitesBackcountry: Park['numCampsitesBackcountry'];
  numCampsitesCar: Park['numCampsitesCar'];
  numMountains: Park['numMountains'];
  protectTitle: Park['protectTitle'];
  landType: Park['landType'];
  operator: Park['operator'];
  ownership: Park['ownership'];
}

interface SuccessResponse {
  parks: ParkDatum[];
}

interface Variables {
  bottomLeftCoordinates: Coordinate;
  topRightCoordinates: Coordinate;
  limit: number;
}

export const useGeoBoxParks = ({limit}: {limit: number}) => {
  const {bbox, refreshBbox} = useConstrainedMapBbox();
  const mapCenter = useMapCenter();
  useEffect(() => {
    refreshBbox();
  }, [refreshBbox, mapCenter]);
  const {loading, error, data, previousData} = useQuery<SuccessResponse, Variables>(GEO_BOX_PARKS, {
    variables: {
      limit,
      bottomLeftCoordinates: bbox[0],
      topRightCoordinates: bbox[1],
    },
    // there is no need to cache this since the user will rarely have the exact same
    // bbox twice and it will otherise just fill up the cache
    fetchPolicy: 'no-cache',
  });

  let dataToUse: SuccessResponse | undefined;
  if (data !== undefined) {
    dataToUse = data;
  } else if (previousData !== undefined) {
    dataToUse = previousData;
  } else {
    dataToUse = undefined;
  }

  return {loading, error, data: dataToUse};
};
