import { gql, useMutation } from '@apollo/client';
import {
  Park,
} from '../../types/graphQLTypes';

const FLAG_PARK = gql`
  mutation($id: ID!, $flag: String) {
    park: updateParkFlag(id: $id, flag: $flag) {
      id
      flag
    }
  }
`;

interface FlagSuccessResponse {
  park: null | {
    id: Park['id'];
    flag: Park['flag'];
  };
}

interface FlagVariables {
  id: string;
  flag: string | null;
}

export const useUpdateParkFlag = () => {
  const [updateParkFlag] = useMutation<FlagSuccessResponse, FlagVariables>(FLAG_PARK);
  return updateParkFlag;
};
