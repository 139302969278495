import {
  faChartArea,
  faCrosshairs,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useFluent from '../../../hooks/useFluent';
import {useBasicMountainDetails} from '../../../queries/mountains/useBasicMountainDetails';
import {Routes, setMountainOgImageUrl} from '../../../routing/routes';
import {mountainDetailLink, summitViewLink} from '../../../routing/Utils';
import {CoreItem} from '../../../types/itemTypes';
import DefaultError from '../../sharedComponents/DefaultError';
import FormattedCoordinates from '../../sharedComponents/detailComponents/header/FormattedCoordinates';
import FormattedElevation from '../../sharedComponents/detailComponents/header/FormattedElevation';
import SimpleHeader, { Detail } from '../../sharedComponents/detailComponents/header/SimpleHeader';
import TripsAndNotes from '../../sharedComponents/detailComponents/TripsAndNotes';
import MapRenderProp from '../../sharedComponents/MapRenderProp';
import {mountainNeutralSvg} from '../../sharedComponents/svgIcons';

interface Props {
  id: string;
}

const MountainDetail = (props: Props) => {
  const { id } = props;

  const getString = useFluent();

  const {loading, data, error} = useBasicMountainDetails(id);
  const {offline} = useOfflineContext();
  const history = useHistory();

  if (error && !data) {
    if (offline) {
      return null;
    }
    return <DefaultError error={error} />;
  }

  let title: string = '----';
  let subtitle: string = '----';
  let authorId: null | string = null;
  let map: React.ReactElement<any> | null = null;
  if (data !== undefined) {
    const { mountain } = data;
    if (!mountain) {
      history.replace(Routes.NotFound);
      return null;
    } else {
      title = mountain.name;
      subtitle = mountain.locationText ? mountain.locationText : '';
      authorId = mountain.author ? mountain.author.id : null;
      map = (
        <MapRenderProp
          id={mountain.id}
          mountains={[mountain]}
          center={mountain.location}
        />
      );
    }
  }

  const summitViewUrl = data && data.mountain ? summitViewLink(
    data.mountain.location[1],
    data.mountain.location[0],
    data.mountain.elevation,
    id,
  ) : '';

  const metaDescription = data && data.mountain
    ? getString('meta-data-mountain-detail-description', {
      name: data.mountain.name,
      state: data.mountain.locationTextShort,
      elevation: data.mountain.elevation,
    })
    : null;
  const metaTitle = data && data.mountain ? getString('meta-data-detail-default-title', {
    title: data.mountain.name + ', ' + data.mountain.locationTextShort, type: '',
  }) : '';
  const metaData = metaDescription && data && data.mountain ? (
    <Helmet>
      <title>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
      />
      <meta property='og:title' content={metaTitle} />
      <meta
        property='og:description'
        content={metaDescription}
      />
      <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + mountainDetailLink(id)} />
      <meta property='og:image' content={setMountainOgImageUrl(id)} />
    </Helmet>
  ) : null;

  const details: Detail[] = [
    {
      text: (
        <FormattedElevation
          elevation={data && data.mountain && data.mountain.elevation ? data.mountain.elevation : undefined}
        />
      ),
      icon: faChartArea,
    }, {
      text: (
        <FormattedCoordinates
          coordinates={data && data.mountain && data.mountain.location ? data.mountain.location : undefined}
          noPadding={true}
        />
      ),
      icon: faCrosshairs,
    },
  ];

  if (!offline) {
    details.push({
      text: getString('mountain-detail-summit-view'),
      icon: faEye,
      internalLink: summitViewUrl,
    });
  }

  return (
    <>
      {metaData}
      <SimpleHeader
        id={id}
        loading={loading}
        title={title}
        subtitle={subtitle}
        customIcon={true}
        icon={mountainNeutralSvg}
        authorId={authorId}
        type={CoreItem.mountain}
        primaryDetails={details}
      />
      <TripsAndNotes
        id={id}
        name={title}
        item={CoreItem.mountain}
      />
      {map}
    </>
  );
};

export default MountainDetail;
