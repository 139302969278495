import axios from 'axios';
import React, {useEffect, useState} from 'react';
import useFluent from '../../../hooks/useFluent';
import useMapContext from '../../../hooks/useMapContext';
import { GeojsonPolygon } from '../../../queries/compound/usePointsWithin';
import {useBasicParkDetails} from '../../../queries/parks/useBasicParkDetails';
import { getParksGeojsonUrl } from '../../../routing/services';
import { AuxiliaryItem } from '../../../types/itemTypes';
import CommentsPanel from '../../sharedComponents/detailComponents/comments';
import ExploreWithin from '../../sharedComponents/detailComponents/exploreWithin';
import Weather from '../../sharedComponents/detailComponents/weather';
import { defaultGeoJsonPolygon, highlightedShapeOutlineLayerId } from '../../template/globalMap/map/layers';

interface Props {
  id: string;
}

const Content = (props: Props) => {
  const  {id} = props;
  const {loading, error, data} = useBasicParkDetails(id);
  const [geojson, setGeojson] = useState<GeojsonPolygon | undefined | null>();

  const getString = useFluent();

  const mapContext = useMapContext();
  useEffect(() => {
    axios.get(getParksGeojsonUrl(id))
      .then((res) => {
        if (res.data) {
          setGeojson(res.data);
        } else {
          setGeojson(null);
        }
      })
      .catch(err => {
        console.error(err);
        setGeojson(null);
      });
  }, [id]);

  useEffect(() => {
    if (mapContext.intialized && geojson) {
      const layer = mapContext.map.getSource(highlightedShapeOutlineLayerId) as any;
      if (layer) {
        layer.setData(geojson);
      }
    }
    return () => {
      if (mapContext.intialized) {
        const layer = mapContext.map.getSource(highlightedShapeOutlineLayerId) as any;
        if (layer) {
          layer.setData(defaultGeoJsonPolygon);
        }
      }
    };
  }, [geojson, mapContext]);

  if (loading) {
    return null;
  } else if (error !== undefined) {
    return <p>{error.message}</p>;
  } else if (data !== undefined && data.park) {
    const {park} = data;
    const {locationTextShort, center} = park;
    const name = park.name ? park.name : getString('global-text-value-park-access');
    const exploreWithin = geojson ? (
      <ExploreWithin
        geojson={geojson}
        hasMountains={park.numMountains > 0}
        hasTrails={park.trailMileage > 0}
      />
    ) : null;
    const reviews = geojson === undefined ? null : (
      <CommentsPanel
        name={name}
        forId={id}
        forType={AuxiliaryItem.park}
      />
    );
    return (
      <>
        <Weather
          forecastTabs={[
            {title: getString('weather-forecast-forecast'), location: center},
          ]}
          snowReport={{location: center, stateAbbr: locationTextShort}}
        />
        {exploreWithin}
        {reviews}
      </>
    );
  } else {
    return null;
  }
};

export default Content;
