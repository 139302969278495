import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {
  NoResultsHelp,
  NoResultsIcon,
  NoResultsRoot,
  NoResultsTitle,
} from '../../styling/styleUtils';

interface Props {
  message: string;
}

const NetworkError = ({message}: Props) => {
  return (
    <NoResultsRoot>
      <NoResultsIcon icon={faExclamationTriangle} />
      <NoResultsTitle>
        Network error.
      </NoResultsTitle>
      <NoResultsHelp>
        {message}
      </NoResultsHelp>
    </NoResultsRoot>
  );
};

export default NetworkError;
