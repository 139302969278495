import {faBiking, faHorse, faSkiing} from '@fortawesome/free-solid-svg-icons';
import { upperFirst } from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useFluent from '../../../hooks/useFluent';
import {useBasicTrailDetail} from '../../../queries/trails/useBasicTrailDetail';
import {Routes, setTrailheadOgImageUrl} from '../../../routing/routes';
import {trailheadDetailLink} from '../../../routing/Utils';
import {AuxiliaryItem, CoreItem} from '../../../types/itemTypes';
import { getTrailAccessNice } from '../../../utilities/trailUtils';
import DefaultError from '../../sharedComponents/DefaultError';
import SimpleHeader, { Detail } from '../../sharedComponents/detailComponents/header/SimpleHeader';
import TripsAndNotes from '../../sharedComponents/detailComponents/TripsAndNotes';
import MapRenderProp, { TrailheadGeoDatum } from '../../sharedComponents/MapRenderProp';
import {trailDefaultSvg, trailheadDefaultSvg} from '../../sharedComponents/svgIcons';

interface Props {
  id: string;
}

const TrailDetail = (props: Props) => {
  const { id } = props;

  const getString = useFluent();
  const history = useHistory();

  const {loading, data, error} = useBasicTrailDetail(id);
  const {offline} = useOfflineContext();

  if (error && !data) {
    if (offline) {
      return null;
    }
    return <DefaultError error={error} />;
  }

  let name: string = '----';
  let subtitle: string = '----';
  let trailAccessText: string | undefined;
  let map: React.ReactElement<any> | null = null;
  const details: Detail[] = [];
  if (data !== undefined) {
    const { trail } = data;
    if (!trail) {
      history.replace(Routes.NotFound);
      return null;
    } else {
      const {
        locationText,
      } = trail;

      const totalTrailMiles = trail.totalTrailMiles ? trail.totalTrailMiles : 0;
      trailAccessText = getTrailAccessNice(totalTrailMiles);

      if (totalTrailMiles) {
      details.push({
        text: trailAccessText,
        icon: trailDefaultSvg,
        isCustomIcon: true,
      });
      }

      name = trail.name
        ? trail.name + ' Trailhead'
        : upperFirst(getString('global-formatted-trail-type', {type: trail.type})) + ' Trailhead';

      subtitle = locationText ? locationText : '';
      if (trail.allowsBikes !== null) {
        details.push({
          text: getString('trail-detail-formatted-allows-bikes', {value: trail.allowsBikes.toString()}),
          icon: faBiking,
          faded: !trail.allowsBikes,
        });
      }
      if (trail.allowsHorses !== null) {
        details.push({
          text: getString('trail-detail-formatted-allows-horses', {value: trail.allowsHorses.toString()}),
          icon: faHorse,
          faded: !trail.allowsHorses,
        });
      }
      if (trail.skiTrail ||
        (name.toLowerCase() + ' ').includes(' ski ') ||
        name.toLowerCase().includes(' skiing ')
        ) {
        details.push({
          text: getString('trail-detail-ski-trail'),
          icon: faSkiing,
        });
      }
      if (trail.line && trail.line.length && trail.roadAccessPoint) {
        const trailhead: TrailheadGeoDatum = {
          id: trail.id,
          name: trail.name,
          location: trail.roadAccessPoint,
        };
        map = (
          <MapRenderProp
            id={trail.id}
            trailheads={[trailhead]}
            center={trail.roadAccessPoint}
          />
        );
      }
    }
  }

  const metaDescription = data && data.trail
    ? getString('meta-data-trailhead-detail-description', {
      name,
      'location': data.trail.locationText,
      'miles-nice': trailAccessText?.toLowerCase(),
    })
    : null;
  const metaTitle = data && data.trail ? getString('meta-data-detail-default-title', {
      title: name, type: '',
    }) : '';
  const metaData = metaTitle && metaDescription && data && data.trail ? (
    <Helmet>
      <title>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
      />
      <meta property='og:title' content={metaTitle} />
      <meta
        property='og:description'
        content={metaDescription}
      />
      <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + trailheadDetailLink(id)} />
      <meta property='og:image' content={setTrailheadOgImageUrl(id)} />
    </Helmet>
  ) : null;

  return (
    <>
      {metaData}
      <SimpleHeader
        id={id}
        loading={loading}
        title={name}
        subtitle={subtitle}
        customIcon={true}
        icon={trailheadDefaultSvg}
        authorId={null}
        type={AuxiliaryItem.trailhead}
        primaryDetails={details}
      />
      <TripsAndNotes
        id={id}
        name={name}
        item={CoreItem.trail}
        notesOnly={true}
      />
      {map}
    </>
  );
};

export default TrailDetail;
