import { gql } from '@apollo/client';
import {
  Trail,
  User,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_TRAILHEADS = gql`
  query GetSavedTrailheads($userId: ID) {
    user(id: $userId) {
      id
      savedTrailheads {
        id
        name
        type
        locationText
        locationTextShort
        totalTrailMiles
        roadAccessPoint
        userNote
      }
    }
  }
`;

export const SAVE_TRAILHEAD_TO_USER = gql`
  mutation SaveTrailheadToUser($userId: ID!, $trailId: ID!) {
    user: saveTrailheadToUser(userId: $userId, trailId: $trailId) {
      id
      savedTrailheads {
        id
        name
        type
        locationText
        locationTextShort
        totalTrailMiles
        roadAccessPoint
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_TRAILHEAD_FROM_USER = gql`
  mutation RemoveSaveTrailheadToUser($userId: ID!, $trailId: ID!) {
    user: removeSavedTrailheadFromUser(userId: $userId, trailId: $trailId) {
      id
      savedTrailheads {
        id
        name
        type
        locationText
        locationTextShort
        totalTrailMiles
        roadAccessPoint
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  trailId: string;
}

export interface TrailheadDatum {
  id: Trail['id'];
  __typename: string;
  name: Trail['name'];
  type: Trail['type'];
  locationText: Trail['locationText'];
  locationTextShort: Trail['locationTextShort'];
  totalTrailMiles: Trail['totalTrailMiles'];
  roadAccessPoint: Trail['roadAccessPoint'];
  userNote: Trail['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedTrailheads: (null | TrailheadDatum)[];
  };
}
