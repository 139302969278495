import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faLink, faPencilAlt, faShareAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import useFluent from '../../../hooks/useFluent';
import {
  CampsiteDatum,
  FriendDatum,
  MountainDatum,
  TrailDatum,
  TripReportDatum,
} from '../../../queries/users/useAllTripReports/query';
import { editTripReportLink, tripReportDetailLink } from '../../../routing/Utils';
import {
  BlockBase,
  DetailBase,
} from '../../../styling/sharedContentStyles';
import {
  BasicIconInTextCompact,
  IconContainerCompact,
  lightBaseColor,
  LinkButton,
  PreFormattedDiv,
} from '../../../styling/styleUtils';
import getTripName from '../../../utilities/getTripName';
import StaticStars from '../../sharedComponents/detailComponents/comments/StaticStars';
import ShareModal from '../../sharedComponents/detailComponents/header/sharing/ShareModal';
import {
  mountainNeutralSvg,
  tentNeutralSvg,
  trailDefaultSvg,
} from '../../sharedComponents/svgIcons';

const BlockHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
`;

const Title = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: flex-start;
  gap: 0.7rem;
  gap: 0.7rem;
`;

interface Props {
  tripReport: TripReportDatum;
}

const ReportBlock = ({ tripReport }: Props) => {
  const getString = useFluent();
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  const nonNullMountains = tripReport.mountains ? tripReport.mountains.filter(d => d !== null) as MountainDatum[] : [];
  const nonNullCampsites = tripReport.campsites ? tripReport.campsites.filter(d => d !== null) as CampsiteDatum[] : [];
  const nonNullTrails = tripReport.trails ? tripReport.trails.filter(d => d !== null) as TrailDatum[] : [];
  const nonNullUsers = tripReport.users ? tripReport.users.filter(d => d !== null) as FriendDatum[] : [];

  const tripName = getTripName({
    mountains: nonNullMountains,
    campsites: nonNullCampsites,
    trails: nonNullTrails,
    friends: nonNullUsers,
    getString,
  });
  const tripNameWithoutUsers = getTripName({
    mountains: nonNullMountains,
    campsites: nonNullCampsites,
    trails: nonNullTrails,
    friends: [],
    getString,
  });

  const rating = tripReport.rating ? (
    <StaticStars
      id={tripReport.id}
      rating={tripReport.rating}
    />
  ) : null;

  const mountains = nonNullMountains.length ? (
    <DetailBase>
      <IconContainerCompact
        $color={lightBaseColor}
        dangerouslySetInnerHTML={{ __html: mountainNeutralSvg}}
      />
      <div>
        {nonNullMountains.map(d => d.name).join(', ')}
      </div>
    </DetailBase>
  ) : null;
  const campsites = nonNullCampsites.length ? (
    <DetailBase>
      <IconContainerCompact
        $color={lightBaseColor}
        dangerouslySetInnerHTML={{ __html: tentNeutralSvg}}
      />
      <div>
        {
          uniq(
            nonNullCampsites
              .map(d => d.name ? d.name : upperFirst(getString('global-formatted-campsite-type', {type: d.type}))),
          ).join(', ')
        }
      </div>
    </DetailBase>
  ) : null;
  const trails = nonNullTrails.length ? (
    <DetailBase>
      <IconContainerCompact
        $color={lightBaseColor}
        dangerouslySetInnerHTML={{ __html: trailDefaultSvg }}
      />
      <div>
        {
          uniq(
            nonNullTrails
              .map(d => d.name ? d.name : upperFirst(getString('global-formatted-trail-type', { type: d.type }))),
          ).join(', ')
        }
      </div>
    </DetailBase>
  ) : null;
  const friends = nonNullUsers.length ? (
    <DetailBase>
      <IconContainerCompact $color={lightBaseColor}>
        <FontAwesomeIcon icon={faUserFriends} />
      </IconContainerCompact>
      <div>
        {nonNullUsers.map(d => d.name).join(', ')}
      </div>
    </DetailBase>
  ) : null;
  const reportDetails = tripReport.notes ? (
    <DetailBase style={{ alignItems: 'flex-start' }}>
      <IconContainerCompact $color={lightBaseColor}>
        <FontAwesomeIcon icon={faCommentDots} />
      </IconContainerCompact>
      <PreFormattedDiv>
        <em>{tripReport.notes}</em>
      </PreFormattedDiv>
    </DetailBase>
  ) : null;
  const externalLink = tripReport.link ? (
    <DetailBase style={{ alignItems: 'flex-start' }}>
      <IconContainerCompact $color={lightBaseColor}>
        <FontAwesomeIcon icon={faLink} />
      </IconContainerCompact>
      <a href={tripReport.link} target={'_blank'} rel='noreferrer'>
        {tripReport.link}
      </a>
    </DetailBase>
  ) : null;

  const editTripReportUrl = editTripReportLink({
    refpath: window.location.pathname,
    id: tripReport.id,
  });
  const tripReportDetailUrl = tripReportDetailLink({
    id: tripReport.id,
  });

  const shareModal = shareModalOpen ? (
    <ShareModal
      onClose={() => setShareModalOpen(false)}
      pageName={tripNameWithoutUsers}
      type={'trip-report'}
      url={process.env.REACT_APP_DOMAIN_NAME + tripReportDetailUrl}
      snippet={tripReport.notes && tripReport.notes.length ? tripReport.notes : undefined}
    />
  ) : null;

  return (
    <BlockBase>
      <BlockHeader>
        <Title>
          <Link to={tripReportDetailUrl}>
            {tripName}
          </Link>
        </Title>
        <ButtonContainer>
          <LinkButton onClick={() => setShareModalOpen(true)}>
            <BasicIconInTextCompact icon={faShareAlt} />
            {getString('global-text-value-share')}
          </LinkButton>
          <Link to={editTripReportUrl}>
            <BasicIconInTextCompact icon={faPencilAlt} />
            {getString('global-text-value-edit')}
          </Link>
        </ButtonContainer>
      </BlockHeader>
      <div>
        {rating}
        {mountains}
        {campsites}
        {trails}
        {friends}
        {reportDetails}
        {externalLink}
      </div>
      {shareModal}
    </BlockBase>
  );
};

export default ReportBlock;
