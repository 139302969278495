import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  GET_USERS_SAVED_VIEWPOINTS,
  MutationVariables,
  REMOVED_SAVED_VIEWPOINT_FROM_USER,
  SAVE_VIEWPOINT_TO_USER,
  SuccessResponse,
  Variables,
} from './query';

export const useSavedViewpoints = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_VIEWPOINTS, {variables: {userId}},
  );

  const [saveViewpointToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_VIEWPOINT_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_VIEWPOINTS, variables: {userId}}],
    });

  const [removeSavedViewpointFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_VIEWPOINT_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_VIEWPOINTS, variables: {userId}}],
    });

  return {response, saveViewpointToUser, removeSavedViewpointFromUser};
};
