import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { BasicIconAtEndOfTextCompact, IconContainer, lightBaseColor } from '../../../../styling/styleUtils';
import { Button } from './Utils';

const Root = styled.div`
  position: relative;
`;

const Subcontent = styled.div`
  position: absolute;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.13);
`;

type Props = PropsWithChildren<{
  label: string;
  icon: any;
  adjustUp: boolean;
  adjustLeft: boolean;
}>;

const Submenu = (props: Props) => {
  const {
    icon, label, children, adjustUp, adjustLeft,
  } = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const timeout = useRef<number>(0);
  useEffect(() => () => clearTimeout(timeout.current), []);

  const onMouseEnter = () => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      setOpen(true);
    }, 0);
  };
  const onMouseLeave = () => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      setOpen(false);
    }, 150);
  };
  const onTouchStart = (e: React.TouchEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Root
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
    >
      <Button>
        <IconContainer $color={lightBaseColor}>
          <FontAwesomeIcon icon={icon} />
        </IconContainer>
        {label}
        <BasicIconAtEndOfTextCompact icon={faChevronRight} />
      </Button>
      {isOpen && (
        <Subcontent
          style={{
            right: adjustLeft ? undefined : 0,
            top: adjustUp ? undefined : 0,
            left: adjustLeft ? 0 : undefined,
            // bottom: adjustUp ? 0 : undefined,
            transform: `
              translate(
                ${adjustLeft ? -100 : 100}%,
                ${adjustUp ? -100 : 0}%
              )
            `,
          }}
        >
          {children}
        </Subcontent>
      )}
    </Root>
  );
};

export default Submenu;
