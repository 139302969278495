import { faSun } from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import SunCalc from 'suncalc';
import useFluent from '../../../../../hooks/useFluent';
import {
  InlineColumns,
} from '../../../../../styling/sharedContentStyles';
import {
  BasicIconInTextCompact,
  LinkButton,
  SemiBold,
  Subtext,
} from '../../../../../styling/styleUtils';
import getIcon from './getIcon';
import {
  Daylight,
  ForecastBlockContainer,
  ForecastBlockHeader,
  ForecastShort,
  formatAMPM,
  SunTimes,
  TempAndDaylightContainer,
  Temperatures,
  WeatherIcon,
} from './Utils';
import WeatherDetailNWSModal from './WeatherDetailNWSModal';

export interface NWSForecastDatum {
  detailedForecast: string;
  icon: string;
  isDaytime: boolean;
  name: string;
  shortForecast: string;
  startTime: string;
  temperature: number;
  precipitation: number;
}

interface DetailForecastState {
  today: NWSForecastDatum | null;
  tonight: NWSForecastDatum | null;
}

interface Props {
  forecast: NWSForecastDatum[];
  latitude: number;
  longitude: number;
}

const NWSForecast = (props: Props) => {
  const {
    forecast, latitude, longitude,
  } = props;
  const [weatherDetail, setWeatherDetail] = useState<DetailForecastState | null>(null);

  const getString = useFluent();

  const closeWeatherDetailModal = () => {
    setWeatherDetail(null);
  };

  const weatherDetailModal = weatherDetail === null ? null : (
    <WeatherDetailNWSModal
      onCancel={closeWeatherDetailModal}
      today={weatherDetail.today}
      tonight={weatherDetail.tonight}
    />
  );
  const date = new Date();

  const start = forecast[0].isDaytime === true ? 0 : -1;
  const forecastDays: React.ReactElement<any>[] = [];
  let i = start;
  while (i < forecast.length) {
    let name: string | undefined;
    let shortForecast: string | undefined;
    let temp: string | undefined;
    let precip: number | undefined;
    let onClick: (() => void) | undefined;
    if (i === -1) {
      const tonight = forecast[i + 1];
      name = tonight.name;
      shortForecast = tonight.shortForecast;
      temp = tonight.temperature + '°F';
      precip = tonight.precipitation;
      onClick = () => setWeatherDetail({today: null, tonight});
    } else {
      const today = forecast[i];
      const tonight = forecast[i + 1];
      if (today && tonight) {
        name = today.name;
        shortForecast = today.shortForecast;
        temp = today.temperature + '° / ' + tonight.temperature + '°F';
        precip = today.precipitation;
        onClick = () => setWeatherDetail({today, tonight});
      } else if (today) {
        name = today.name;
        shortForecast = today.shortForecast;
        temp = today.temperature + '°F';
        precip = today.precipitation;
        onClick = () => setWeatherDetail({today, tonight: null});
      }
    }
    if (name !== undefined && shortForecast !== undefined && temp !== undefined &&
        precip !== undefined && onClick !== undefined) {
      const sunTimes = SunCalc.getTimes(date, latitude, longitude);
      const sunriseStr = formatAMPM(sunTimes.sunrise);
      const sunsetStr = formatAMPM(sunTimes.sunset);
      forecastDays.push(
        <ForecastBlockContainer key={name}>
          <ForecastBlockHeader>
            <LinkButton onClick={onClick}>
              <SemiBold>{name}</SemiBold>
            </LinkButton>
            <WeatherIcon icon={getIcon(shortForecast)} />
          </ForecastBlockHeader>
          <ForecastShort>{shortForecast}</ForecastShort>
          <TempAndDaylightContainer>
            <Temperatures>
              {temp}
            </Temperatures>
            <Daylight>
              <BasicIconInTextCompact icon={faSun} />
              <SunTimes>
                <div>
                  <strong>↑</strong> {sunriseStr}
                </div>
                <div>
                  <strong>↓</strong> {sunsetStr}
                </div>
              </SunTimes>
            </Daylight>
          </TempAndDaylightContainer>
          <InlineColumns>
            <Subtext>
              {precip}% {getString('weather-forecast-chance')} {getString('weather-forecast-precipitation')}
            </Subtext>
          </InlineColumns>
        </ForecastBlockContainer>,
      );
    }
    date.setDate(date.getDate() + 1);
    i = i + 2;
  }

  return (
    <>
      {forecastDays}
      {weatherDetailModal}
    </>
  );

};

export default NWSForecast;
