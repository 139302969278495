import React from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../hooks/useFluent';
import { ButtonPrimary, PaddedSection } from '../../styling/styleUtils';

const Root = styled(PaddedSection)`
  display: flex;
  justify-content: space-between;
`;
const NextButton = styled(ButtonPrimary)`
  margin-left: auto;
`;

interface Props {
  nPerPage: number;
  total: number;
  page: number;
  goToNext: () => void;
  goToPrev: () => void;
}

export default function Pagination({
  nPerPage,
  total,
  page,
  goToNext,
  goToPrev,
}: Props) {
  const getString = useFluent();
  return (
    <Root>
      {page > 0 && (
        <ButtonPrimary onClick={goToPrev}>← {getString('global-text-value-navigation-prev')}</ButtonPrimary>
      )}
      {total === nPerPage && (
        <NextButton onClick={goToNext}>{getString('global-text-value-navigation-next')} →</NextButton>
      )}
    </Root>
  );
}
