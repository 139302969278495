import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import {
  faAt,
  faBed,
  faChartArea,
  faCrosshairs,
  faLink,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {isVowel} from '../../../contextProviders/getFluentLocalizationContext';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useFluent from '../../../hooks/useFluent';
import {useBasicCampsiteDetails} from '../../../queries/campsites/useBasicCampsiteDetails';
import {Routes, setCampsiteOgImageUrl} from '../../../routing/routes';
import {campsiteDetailLink} from '../../../routing/Utils';
import {CoreItem} from '../../../types/itemTypes';
import DefaultError from '../../sharedComponents/DefaultError';
import FormattedCoordinates from '../../sharedComponents/detailComponents/header/FormattedCoordinates';
import FormattedElevation from '../../sharedComponents/detailComponents/header/FormattedElevation';
import SimpleHeader, { Detail } from '../../sharedComponents/detailComponents/header/SimpleHeader';
import TripsAndNotes from '../../sharedComponents/detailComponents/TripsAndNotes';
import MapRenderProp from '../../sharedComponents/MapRenderProp';
import {tentNeutralSvg} from '../../sharedComponents/svgIcons';
import informationDetails from './moreInformationPanel';

interface Props {
  id: string;
}

const CampsiteDetail = (props: Props) => {
  const { id } = props;

  const getString = useFluent();

  const {loading, error, data} = useBasicCampsiteDetails(id);
  const {offline} = useOfflineContext();
  const history = useHistory();

  if (error && !data) {
    if (offline) {
      return null;
    }
    return <DefaultError error={error} />;
  }
  let title: string = '----';
  let subtitle: string = '----';
  let formattedType: string = '';
  let authorId: null | string = null;
  let secondaryDetails: Detail[] = [];
  const contactDetails: Detail[] = [];
  let map: React.ReactElement<any> | null = null;
  if (data !== undefined) {
    const { campsite } = data;
    if (!campsite) {
      history.replace(Routes.NotFound);
      return null;
    } else {
      formattedType = upperFirst(getString('global-formatted-campsite-type', {type: campsite.type}));
      title = campsite.name ? campsite.name : formattedType;
      subtitle = getString('campsite-detail-subtitle', {
        ownership: campsite.ownership,
        type: formattedType,
        location: campsite.locationText,
      });
      authorId = campsite.author ? campsite.author.id : null;
      map = (
        <MapRenderProp
          id={campsite.id}
          campsites={[campsite]}
          center={campsite.location}
        />
      );
      if (campsite.website) {
        let url: URL | null = null;
        try {
          const validUrl = new URL(campsite.website);
          url = validUrl;
        } catch (err) {
          url = null;
          console.error(err);
        }
        let displayUrl = url && url.hostname
          ? url.hostname : campsite.website;
        if (displayUrl.length > 35) {
          displayUrl = displayUrl.slice(0, 35) + '...';
        }
        contactDetails.push({
          text: displayUrl,
          icon: faLink,
          externalLink: campsite.website,
        });
      }
      if (campsite.email) {
        const displayEmail = campsite.email.length > 35 ? campsite.email.slice(0, 35) + '...' : campsite.email;
        contactDetails.push({
          text: displayEmail,
          icon: faAt,
          externalLink: 'mailto:' + campsite.email,
        });
      }
      if (campsite.phone) {
        contactDetails.push({
          text: campsite.phone,
          icon: faPhone,
          externalLink: 'tel:' + campsite.phone,
        });
      }
      if (!campsite.website && !campsite.email && !campsite.phone) {
        contactDetails.push({
          text: getString('global-text-value-no-contact'),
          icon: faIdCard,
          faded: true,
        });
      }
      if (campsite.reservation) {
        contactDetails.push({
          text: `${getString('campsite-detail-reservation')} - ${campsite.reservation}`,
          icon: faBed,
        });
      }
      secondaryDetails = informationDetails({ campsite, getString});
    }
  }

  const metaDescription = data && data.campsite
    ? getString('meta-data-campsite-detail-description', {
      name: title,
      location: data.campsite.locationText,
      a: isVowel(formattedType[0]) ? 'an' : 'a',
      type: formattedType.toLowerCase(),
    })
    : null;
  const metaTitle = data && data.campsite ? getString('meta-data-detail-default-title', {
    title: title + ', ' + data.campsite.locationTextShort, type: '',
  }) : '';
  const metaData = metaTitle && metaDescription && data && data.campsite ? (
    <Helmet>
      <title>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
      />
      <meta property='og:title' content={metaTitle} />
      <meta
        property='og:description'
        content={metaDescription}
      />
      <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + campsiteDetailLink(id)} />
      <meta property='og:image' content={setCampsiteOgImageUrl(id)} />
    </Helmet>
  ) : null;

  const details: Detail[] = [
    {
      text: (
        <FormattedElevation
          elevation={data && data.campsite && data.campsite.elevation ? data.campsite.elevation : undefined}
        />
      ),
      icon: faChartArea,
    }, {
      text: (
        <FormattedCoordinates
          coordinates={data && data.campsite && data.campsite.location ? data.campsite.location : undefined}
          noPadding={true}
        />
      ),
      icon: faCrosshairs,
    },
    ...contactDetails,
  ];

  return (
    <>
      {metaData}
      <SimpleHeader
        id={id}
        loading={loading}
        title={title}
        subtitle={subtitle}
        customIcon={true}
        icon={tentNeutralSvg}
        authorId={authorId}
        type={CoreItem.campsite}
        primaryDetails={details}
        secondaryDetails={secondaryDetails}
      />
      <TripsAndNotes
        id={id}
        name={title}
        item={CoreItem.campsite}
      />
      {map}
    </>
  );
};

export default CampsiteDetail;
