import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_CAMPSITE_NOTE,
  CampsiteNoteSuccess,
  EDIT_CAMPSITE_NOTE,
  GET_CAMPSITE_NOTE,
  QuerySuccessResponse,
  QueryVariables,
  Variables,
} from './query';

export const useCampsiteNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_CAMPSITE_NOTE, {variables});

export const useAddCampsiteNote = () => {
  const [addCampsiteNote] = useMutation<CampsiteNoteSuccess, Variables>(ADD_CAMPSITE_NOTE);
  return addCampsiteNote;
};
export const useEditCampsiteNote = () => {
  const [editCampsiteNote] = useMutation<CampsiteNoteSuccess, Variables>(EDIT_CAMPSITE_NOTE);
  return editCampsiteNote;
};
