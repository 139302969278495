import React from 'react';
import useFluent from '../../../hooks/useFluent';
import {useBasicMountainDetails} from '../../../queries/mountains/useBasicMountainDetails';
import {CoreItem} from '../../../types/itemTypes';
import ExploreNearby from '../../sharedComponents/detailComponents/exploreNearby';
import GettingThere, { Via } from '../../sharedComponents/detailComponents/gettingThere';
import ReportsAndReviews from '../../sharedComponents/detailComponents/ReportsAndReviews';
import Weather from '../../sharedComponents/detailComponents/weather';

interface Props {
  id: string;
}

const Content = (props: Props) => {
  const  {id} = props;
  const {loading, error, data} = useBasicMountainDetails(id);

  const getString = useFluent();

  if (loading) {
    return null;
  } else if (error !== undefined) {
    return <p>{error.message}</p>;
  } else if (data !== undefined && data.mountain) {
    const {mountain} = data;
    const {name, locationTextShort, location} = mountain;
    let via: Via;
    if (mountain.trailAccessMiles !== null && mountain.trailAccessMiles < 0.05) {
      via = Via.Trails;
    } else if (mountain.roadAccessMiles !== null && mountain.roadAccessMiles < 0.1) {
      via = Via.Driving;
    } else if (mountain.trailAccessMiles !== null && mountain.trailAccessMiles < 0.2) {
      via = Via.Trails;
    } else if (mountain.roadAccessMiles !== null && mountain.roadAccessMiles < 0.2) {
      via = Via.Driving;
    } else {
      via = Via.Trails;
    }
    return (
      <>
        <Weather
          forecastTabs={[
            {title: getString('weather-forecast-summit-weather'), location},
            {title: getString('weather-forecast-valley-weather'), location, valley: true},
          ]}
          snowReport={locationTextShort ? {location, stateAbbr: locationTextShort} : undefined}
        />
        <GettingThere
          via={via}
          name={name}
          location={location}
        />
        <ExploreNearby
          location={location}
          name={name}
          id={id}
        />
        <ReportsAndReviews
          id={id}
          name={name}
          item={CoreItem.mountain}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Content;
