import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import {
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import {CallbackInput} from '../';
import MountainIcon from '../../../../../assets/images/icons/mountain-highlighted.svg';
import ParkIcon from '../../../../../assets/images/icons/park-highlighted.svg';
import TentIcon from '../../../../../assets/images/icons/tent-highlighted.svg';
import TrailIcon from '../../../../../assets/images/icons/trail-highlighted.svg';
import TrailheadIcon from '../../../../../assets/images/icons/trailhead-highlighted.svg';
import ViewpointIcon from '../../../../../assets/images/icons/viewpoint-highlighted.svg';
import WaterpointIcon from '../../../../../assets/images/icons/water-highlighted.svg';
import { useOfflineContext } from '../../../../../contextProviders/OfflineContext';
import useFluent from '../../../../../hooks/useFluent';
import {
  Routes,
} from '../../../../../routing/routes';
import {
  campsiteDetailLink,
  mountainDetailLink,
  parkDetailLink,
  trailDetailLink,
  trailheadDetailLink,
  viewpointDetailLink,
  waterpointDetailLink,
} from '../../../../../routing/Utils';
import {
  BasicIconInText,
  tertiaryColor,
} from '../../../../../styling/styleUtils';
import {Coordinate, TrailType} from '../../../../../types/graphQLTypes';
import {AuxiliaryItems, auxiliaryItemsToAuxiliaryItem, CoreItems, coreItemsToCoreItem, isAuxiliaryItems, isCoreItems} from '../../../../../types/itemTypes';
import { failIfValidOrNonExhaustive } from '../../../../../Utils';
import LoadingSimple from '../../../../sharedComponents/LoadingSimple';
import ActionButtons from './ActionButtons';
import AddRemoveActions from './AddRemoveActions';
import DrivingDirections from './directions';
import HikingDirections from './HikingDirections';
import LastTrip from './LastTrip';
import OfflinePopup from './OfflinePopup';
import PopupTitle from './PopupTitle';
import usePopupData from './usePopupData';

const MissingFeatureTitle = styled.h2`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

const Content = styled.div`
  padding: 0.4rem 0.6rem;
`;

const LoadingContainer = styled.div`
  background-color: ${tertiaryColor};
  padding: 2rem;
`;

interface Props {
  name: string | null;
  displayName: string | null;
  subtitle: string | null;
  location: Coordinate;
  id: string | null;
  itemType: CoreItems | AuxiliaryItems;
  close: () => void;
  highlightedPointsGeojson: mapboxgl.GeoJSONSourceSpecification['data'] | undefined;
  highlightedTrailsGeojson: mapboxgl.GeoJSONSourceSpecification['data'] | undefined;
  highlightedRoadsGeojson: mapboxgl.GeoJSONSourceSpecification['data'] | undefined;
  callback: ((input: CallbackInput) => void) | undefined;
}

const ClickedPopup = (props: Props) => {
  const {
    itemType, location, close,
    highlightedPointsGeojson, highlightedTrailsGeojson, highlightedRoadsGeojson,
    callback,
  } = props;
  const {offline} = useOfflineContext();

  const {loading, error, data} = usePopupData(itemType, props.id, location, props.name);
  const getString = useFluent();
  const {push} = useHistory();

  let output: React.ReactElement<any> | null;
  if (loading && !offline) {
    output = (
      <LoadingContainer>
        <LoadingSimple />
      </LoadingContainer>
    );
  } else if (error || (offline && !data)) {
    if (offline && props.displayName !== null && props.itemType) {
      const offlinePopupItemType = isAuxiliaryItems(props.itemType)
        ? auxiliaryItemsToAuxiliaryItem(props.itemType)
        : coreItemsToCoreItem(props.itemType);
      output = (
        <Content>
          <OfflinePopup
            name={props.displayName}
            subtitle={props.subtitle || ''}
            type={offlinePopupItemType}
            location={location}
          />
        </Content>
      );
    } else {
      output = (
        <Content>
          <MissingFeatureTitle>
            <BasicIconInText icon={faQuestion} />
            {getString('global-text-value-missing-feature-title')}
          </MissingFeatureTitle>
          <p dangerouslySetInnerHTML={{__html: getString('global-text-value-missing-feature-desc')}} />
        </Content>
      );
    }
  } else if (data !== undefined) {
    const {id, type} = data;
    const onClick = () => {
      if (itemType === CoreItems.mountains) {
        push(mountainDetailLink(id));
      } else if (itemType === CoreItems.campsites) {
        push(campsiteDetailLink(id));
      } else if (itemType === CoreItems.trails) {
        push(trailDetailLink(id));
      } else if (itemType === AuxiliaryItems.trailheads) {
        push(trailheadDetailLink(id));
      } else if (itemType === AuxiliaryItems.viewpoints) {
        push(viewpointDetailLink(id));
      } else if (itemType === AuxiliaryItems.waterpoints) {
        push(waterpointDetailLink(id));
      } else if (itemType === AuxiliaryItems.parks) {
        push(parkDetailLink(id));
      } else {
        failIfValidOrNonExhaustive(itemType, 'Invalid item type ' + itemType);
      }
      close();
    };
    let name: string;
    let subtitle: string;
    let imgSrc: string;
    if (itemType === CoreItems.mountains) {
      name = data.name ? data.name : 'Unnamed Peak';
      subtitle = data.subtitle ? data.subtitle : '';
      imgSrc = MountainIcon;
    } else if (itemType === CoreItems.trails) {
      name = data.name ? data.name : upperFirst(getString('global-formatted-trail-type', {type}));
      const trailLength = parseFloat(data.subtitle);
      const trailLengthDisplay = trailLength < 0.1
        ? Math.round(trailLength * 5280) + ' ft'
        : parseFloat(trailLength.toFixed(1)) + ' mi';
      subtitle = !isNaN(trailLength)
        ? trailLengthDisplay + ' long ' + getString('global-formatted-trail-type', {type})
        : getString('global-formatted-trail-type', {type});
      if (data.parents && data.parents.length && data.subtitle) {
        subtitle += ' segment';
      }
      imgSrc = TrailIcon;
    } else if (itemType === CoreItems.campsites) {
      name = data.name ? data.name : upperFirst(getString('global-formatted-campsite-type', {type}));
      subtitle = upperFirst(getString('global-formatted-campsite-type', {type}));
      imgSrc = TentIcon;
    } else if (itemType === AuxiliaryItems.trailheads) {
      name = data.name ? data.name : upperFirst(getString('global-formatted-anything-type', {type: 'trailhead'}));
      subtitle = data.name ? upperFirst(getString('global-formatted-anything-type', {type: 'trailhead'})) : '';
      imgSrc = TrailheadIcon;
    } else if (itemType === AuxiliaryItems.viewpoints) {
      name = data.name ? data.name : upperFirst(getString('global-text-value-viewpoint'));
      subtitle = data.name ? upperFirst(getString('global-text-value-viewpoint')) : '';
      imgSrc = ViewpointIcon;
    } else if (itemType === AuxiliaryItems.waterpoints) {
      name = data.name ? data.name : upperFirst(getString('global-formatted-water-type', {type}));
      subtitle = upperFirst(getString('global-formatted-water-type', {type}));
      imgSrc = WaterpointIcon;
    } else if (itemType === AuxiliaryItems.parks) {
      name = data.name;
      if (highlightedPointsGeojson &&
          (highlightedPointsGeojson as any).features &&
          (highlightedPointsGeojson as any).features[0] &&
          (highlightedPointsGeojson as any).features[0].properties &&
          (highlightedPointsGeojson as any).features[0].properties.type
      ) {
        subtitle = (highlightedPointsGeojson as any).features[0].properties.type;
      } else {
        subtitle = '';
      }
      imgSrc = ParkIcon;
    } else {
      name = data.name ? data.name : 'Point';
      subtitle = '';
      imgSrc = MountainIcon;
      failIfValidOrNonExhaustive(itemType, 'Invalid item type ' + itemType);
    }

    let highlighted: boolean = false;
    if ((itemType === CoreItems.mountains || itemType === CoreItems.campsites) &&
      highlightedPointsGeojson && (highlightedPointsGeojson as any).features) {
      highlighted = Boolean((highlightedPointsGeojson as any).features.find((f: any) =>
        f.properties.id === id));
    } else if (itemType === CoreItems.trails && (type === TrailType.road || type === TrailType.dirtroad) &&
        highlightedRoadsGeojson && (highlightedRoadsGeojson as any).features) {
      highlighted = Boolean((highlightedRoadsGeojson as any).features.find((f: any) =>
        f.properties.id === id));
    } else if (itemType === CoreItems.trails && highlightedTrailsGeojson &&
        (highlightedTrailsGeojson as any).features) {
      highlighted = Boolean((highlightedTrailsGeojson as any).features.find((f: any) =>
        f.properties.id === id));
    }

    const addEditRenderProp = () => isCoreItems(itemType) ? (
      <AddRemoveActions
        id={id}
        itemType={itemType}
        highlighted={highlighted}
        callback={callback}
        close={close}
      />
    ) : null;

    const lastTrip = isCoreItems(itemType) ? (
      <LastTrip
        id={id}
        itemType={itemType}
        close={close}
      />
    ) : null;

    output = (
      <>
        <PopupTitle
          title={name}
          subtitle={subtitle}
          imgSrc={imgSrc}
          onClick={onClick}
        />
        <Switch>
          <Route exact path={Routes.AddTripReport} component={addEditRenderProp} />
          <Route exact path={Routes.EditTripReport} component={addEditRenderProp} />
          <Route exact path={Routes.CreateList} component={addEditRenderProp} />
          <Route exact path={Routes.EditList} component={addEditRenderProp} />
          <Route exact path={Routes.EditTrailParent} component={addEditRenderProp} />
          <Route render={() => (
              <>
                <Content>
                  <DrivingDirections
                    itemType={itemType}
                    destination={location}
                  />
                  {lastTrip}
                  <HikingDirections
                    id={id}
                    name={name}
                    destination={location}
                    closePopup={close}
                  />
                </Content>
                <ActionButtons
                  detailAction={onClick}
                  name={name}
                  location={location}
                  closePopup={close}
                />
              </>
            )}
          />
        </Switch>
      </>
    );
  } else {
    output = null;
  }

  return (
    <>
      {output}
    </>
  );

};

export default ClickedPopup;
