import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import styled from 'styled-components/macro';
import useMapContext from '../../../../hooks/useMapContext';
import { primaryColor } from '../../../../styling/styleUtils';
import getUsersGeolocation from '../../../../utilities/getUsersGeolocation';
import Loading from '../../../sharedComponents/LoadingSimple';
import { Button as ButtonBase } from './Utils';

const Button = styled(ButtonBase)`
  color: ${primaryColor};
`;

const YourLocationButton = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const mapContext = useMapContext();

  const goToUsersLocation = () => {
    if (mapContext.intialized) {
      getUsersGeolocation({
        onNotSupported: () => setLoading( false ),
        onBeforeFetch: () => setLoading( true ),
        onError: () => setLoading( false ),
        onSuccess: position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const currentZoom = mapContext.map.getZoom();
          const zoom = currentZoom > 15 ? currentZoom : 15;
          mapContext.setNewCenter([longitude, latitude], zoom);
          setLoading( false );
        },
      });
    }
  };

  const icon = !loading
    ? <FontAwesomeIcon icon={faCrosshairs} />
    : <Loading size={20} color={primaryColor} marginRight={19.5} />;

  return (
    <Button
      onClick={goToUsersLocation}
      title='Your Location'
    >
      {icon}
    </Button>
  );

};

export default YourLocationButton;
