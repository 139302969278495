import { gql } from '@apollo/client';
import {
  Campsite,
  User,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_CAMPSITES = gql`
  query GetSavedCampsites($userId: ID) {
    user(id: $userId) {
      id
      savedCampsites {
        id
        name
        type
        location
        locationText
        locationTextShort
        ownership
        userNote
      }
    }
  }
`;

export const SAVE_CAMPSITE_TO_USER = gql`
  mutation SaveCampsiteToUser($userId: ID!, $campsiteId: ID!) {
    user: saveCampsiteToUser(userId: $userId, campsiteId: $campsiteId) {
      id
      savedCampsites {
        id
        name
        type
        location
        locationText
        locationTextShort
        ownership
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_CAMPSITE_FROM_USER = gql`
  mutation RemoveSaveCampsiteToUser($userId: ID!, $campsiteId: ID!) {
    user: removeSavedCampsiteFromUser(userId: $userId, campsiteId: $campsiteId) {
      id
      savedCampsites {
        id
        name
        type
        location
        locationText
        locationTextShort
        ownership
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  campsiteId: string;
}

export interface CampsiteDatum {
  id: Campsite['id'];
  __typename: string;
  name: Campsite['name'];
  type: Campsite['type'];
  location: Campsite['location'];
  locationText: Campsite['locationText'];
  locationTextShort: Campsite['locationTextShort'];
  ownership: Campsite['ownership'];
  userNote: Campsite['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedCampsites: (null | CampsiteDatum)[];
  };
}
