import { faCar, faCloudSun, faCrosshairs, faMapMarkerAlt, faRoute } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import upperFirst from 'lodash/upperFirst';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useFluent from '../../../../hooks/useFluent';
import useMapContext from '../../../../hooks/useMapContext';
import { Routes, YesNo } from '../../../../routing/routes';
import useQueryParams from '../../../../routing/useQueryParams';
import { routePlanLink } from '../../../../routing/Utils';
import { IconContainer, lightBaseColor } from '../../../../styling/styleUtils';
import { Coordinate } from '../../../../types/graphQLTypes';
import { isTouchDevice } from '../../../../Utils';
import WeatherModal from '../../../sharedComponents/detailComponents/weather/WeatherModal';
import { mountainNeutralSvg, tentNeutralSvg, viewpointNeutralSvg, waterpointNeutralSvg } from '../../../sharedComponents/svgIcons';
import { isRoutePlanningOn } from '../map/interactions/routePlanning';
import DrivingDirectionsModal from './DrivingDirections';
import PointInfo from './PointInfo';
import Submenu from './Submenu';
import {Button} from './Utils';

const isTouchDeviceAndMobile = () => isTouchDevice() && window.innerWidth < 850;

const width = 155; // in px

const Root = styled.div`
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.13);
  width: ${width}px;
`;

interface Props {
  top: number;
  left: number;
  coordinate: Coordinate;
  close: () => void;
}

enum ModalMode {
  PointInfo = 'point_info',
  Weather = 'weather',
  DrivingDirections = 'driving_directions',
}

const ContextMenu = ({top, left, coordinate, close}: Props) => {
  const getString = useFluent();
  const {push, location} = useHistory();
  const [modalMode, setModalMode] = useState<ModalMode | null>(null);
  const params = useQueryParams();

  const mapContext = useMapContext();

  const onClose = useCallback(() => {
    if (!modalMode) {
      close();
    }
  }, [modalMode, close]);

  useEffect(() => {
    document.addEventListener('closecontextmenu', onClose);
    return () => {
      document.removeEventListener('closecontextmenu', onClose);
    };
  }, [onClose]);

  useLayoutEffect(() => {
    if (!isTouchDeviceAndMobile()) {
      window.addEventListener('click', onClose);
      window.addEventListener('contextmenu', onClose);
    } else {
      window.addEventListener('touchstart', onClose);
    }
    if (mapContext.intialized) {
      mapContext.map.on('movestart', onClose);
    }
    return () => {
      if (!isTouchDeviceAndMobile()) {
        window.removeEventListener('click', onClose);
        window.removeEventListener('contextmenu', onClose);
      } else {
        window.removeEventListener('touchstart', onClose);
      }
      if (mapContext.intialized) {
        mapContext.map.off('movestart', onClose);
      }
    };
  }, [onClose, mapContext]);

  let modal: React.ReactElement<any> | null;
  if (modalMode === ModalMode.PointInfo) {
    modal = (
      <PointInfo
        latitude={coordinate[1]}
        longitude={coordinate[0]}
        onClose={close}
      />
    );
  } else if (modalMode === ModalMode.DrivingDirections) {
    modal = (
      <DrivingDirectionsModal
        latitude={coordinate[1]}
        longitude={coordinate[0]}
        onClose={close}
      />
    );
  } else if (modalMode === ModalMode.Weather) {
    modal = (
      <WeatherModal
        latitude={coordinate[1]}
        longitude={coordinate[0]}
        onClose={close}
      />
    );
  } else {
    modal = null;
  }

  const goToAddMountain = useCallback(() => {
    const route = Routes.CreateMountain +
      '?lat=' + coordinate[1] +
      '&lng=' + coordinate[0];
    push(route);
  }, [coordinate, push]);

  const goToAddCampsite = useCallback(() => {
    const route = Routes.CreateCampsite +
      '?lat=' + coordinate[1] +
      '&lng=' + coordinate[0];
    push(route);
  }, [coordinate, push]);

  const goToAddWaterpoint = useCallback(() => {
    const route = Routes.CreateWaterpoint +
      '?lat=' + coordinate[1] +
      '&lng=' + coordinate[0];
    push(route);
  }, [coordinate, push]);

  const goToAddViewpoint = useCallback(() => {
    const route = Routes.CreateViewpoint +
      '?lat=' + coordinate[1] +
      '&lng=' + coordinate[0];
    push(route);
  }, [coordinate, push]);
  const goToRoutePlan = useCallback(() => {
    if (location.pathname === Routes.RoutePlan && (!params.id || params.edit === YesNo.Yes)) {
      const event = new CustomEvent('addsegment', { detail: {coordinate} });
      document.dispatchEvent(event);
    } else {
      push(routePlanLink(), {firstPoint: coordinate});
    }
  }, [coordinate, push, location, params]);

  const positionAdjustTop = isTouchDeviceAndMobile() ? 5 : 4;
  const positionAdjustLeft = isTouchDeviceAndMobile() ? -50 : 4;

  const transform = `translate(${
    left + width > window.innerWidth ? -100 - positionAdjustLeft : 0 + positionAdjustLeft
  }%, ${
    top + 150 > window.innerHeight ? -100 - positionAdjustTop : 0 + positionAdjustTop // 100px as rought estimate for size
  }%)`;

  if (isRoutePlanningOn()) {
    return null;
  }

  return (
    <>
      <Root style={{left, top, display: modalMode ? 'none' : '', transform}}>
        <Button
          onTouchStart={goToRoutePlan}
          onClick={goToRoutePlan}
        >
          <IconContainer $color={lightBaseColor}>
            <FontAwesomeIcon icon={faRoute} />
          </IconContainer>
          {getString('map-context-menu-create-route')}
        </Button>
        <Button
          onTouchStart={() => setModalMode(ModalMode.PointInfo)}
          onClick={() => setModalMode(ModalMode.PointInfo)}
        >
          <IconContainer $color={lightBaseColor}>
            <FontAwesomeIcon icon={faCrosshairs} />
          </IconContainer>
          {getString('map-context-menu-point-info')}
        </Button>
        <Button
          onTouchStart={() => setModalMode(ModalMode.Weather)}
          onClick={() => setModalMode(ModalMode.Weather)}
        >
          <IconContainer $color={lightBaseColor}>
            <FontAwesomeIcon icon={faCloudSun} />
          </IconContainer>
          {getString('map-context-weather-at-point')}
        </Button>
        <Button
          onTouchStart={() => setModalMode(ModalMode.DrivingDirections)}
          onClick={() => setModalMode(ModalMode.DrivingDirections)}
        >
          <IconContainer $color={lightBaseColor}>
            <FontAwesomeIcon icon={faCar} />
          </IconContainer>
          {getString('map-context-directions-to-point')}
        </Button>
        <Submenu
          label={getString('map-context-missing-place')}
          icon={faMapMarkerAlt}
          adjustUp={top + 250 > window.innerHeight}
          adjustLeft={left + 100 + width > window.innerWidth}
        >
          <Button
            onTouchStart={goToAddMountain}
            onClick={goToAddMountain}
          >
            <IconContainer
              $color={lightBaseColor}
              dangerouslySetInnerHTML={{ __html: mountainNeutralSvg}}
            />
            {upperFirst(getString('create-mountain-title-create').toLowerCase())}
          </Button>
          <Button
            onTouchStart={goToAddCampsite}
            onClick={goToAddCampsite}
          >
            <IconContainer
              $color={lightBaseColor}
              dangerouslySetInnerHTML={{ __html: tentNeutralSvg}}
            />
            {upperFirst(getString('create-campsite-title-create').toLowerCase())}
          </Button>
          <Button
            onTouchStart={goToAddWaterpoint}
            onClick={goToAddWaterpoint}
          >
            <IconContainer
              $color={lightBaseColor}
              dangerouslySetInnerHTML={{ __html: waterpointNeutralSvg}}
            />
            {upperFirst(getString('create-waterpoint-title-create').toLowerCase())}
          </Button>
          <Button
            onTouchStart={goToAddViewpoint}
            onClick={goToAddViewpoint}
          >
            <IconContainer
              $color={lightBaseColor}
              dangerouslySetInnerHTML={{ __html: viewpointNeutralSvg}}
            />
            {upperFirst(getString('create-viewpoint-title-create').toLowerCase())}
          </Button>
        </Submenu>
      </Root>
      {modal}
    </>
  );
};

export default ContextMenu;
