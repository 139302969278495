import { faCar, faCrow, faExternalLinkAlt, faHiking, faPencilAlt, faRoute } from '@fortawesome/free-solid-svg-icons';
import getCenter from '@turf/center';
import { Types } from 'mongoose';
import React from 'react';
import { useEffect } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { OriginLocation } from '../../../../../hooks/directions/useDirectionsOrigin';
import { Feature } from '../../../../../hooks/servicesHooks/pathfinding/simpleCache';
import useFluent from '../../../../../hooks/useFluent';
import useMapContext from '../../../../../hooks/useMapContext';
import { routePlanLink, trailheadDetailLink } from '../../../../../routing/Utils';
import { BlockContainer, BlockHeaderVerticalCenter, BlockHeaderWithMargin, Column, DetailsCompact } from '../../../../../styling/sharedContentStyles';
import {
  BasicIconAtEndOfText,
  BasicIconInText,
  HighlightedLargeIconInText,
  LargeIconInText,
  LinkButton,
  LinkButtonCompact,
  primaryColor,
  SemiBold,
  SmallExternalLink,
  SmallLink,
  Subtext,
} from '../../../../../styling/styleUtils';
import { AuxiliaryItem } from '../../../../../types/itemTypes';
import getGoogleMapsLink from '../../../../../utilities/getGoogleMapsLink';

interface Props {
  feature: Feature;
  onEditDirections: () => void;
  start: OriginLocation | undefined | null;
  end: {
    name: string;
  };
}

const TrailheadBlock = ({start, end, feature, onEditDirections}: Props) => {
  const {
    properties: {
      destination,
      drivingDirections,
      trails,
    },
    properties,
    geometry,
  } = feature;
  const getString = useFluent();
  const mapContext = useMapContext();
  const onMouseLeave = () => {
    if (mapContext.intialized) {
      mapContext.clearExternalHoveredPopup();
    }
  };
  useEffect(() => {
    return () => {
      if (mapContext.intialized) {
        mapContext.clearExternalHoveredPopup();
      }
    };
  }, [mapContext]);

  let name: string = 'Trailhead';
  if (destination && destination.name) {
    name = destination.name + ' Trailhead';
  } else if (drivingDirections && drivingDirections.returnedName) {
    name = drivingDirections.returnedName + ' Trailhead';
  } else if (trails) {
    const firstNamedTrail = trails.find(trail => trail.name);
    if (firstNamedTrail && firstNamedTrail.name) {
      name = firstNamedTrail.name + ' Trailhead';
    }
  }
  let routeDistance: string | undefined;
  if (
    properties.routeLength !== undefined && properties.routeLength !== null
  ) {
    const routeLengthOutAndBack = properties.routeLength;
    routeDistance = routeLengthOutAndBack < 0.1
      ? getString('global-text-value-feet-one-way', {dist: Math.round(routeLengthOutAndBack * 5280)})
      : getString('global-text-value-mile-one-way', {dist: parseFloat(routeLengthOutAndBack.toFixed(1))});
  }

  const onMouseEnter = () => {
    if (mapContext.intialized) {
    mapContext.setExternalHoveredPopup(
        name,
        AuxiliaryItem.trailhead,
        routeDistance ? routeDistance : '',
        destination.location,
        geometry.type === 'LineString' ? geometry.coordinates : undefined,
      );
    }
  };

  const validTrails = trails !== undefined
  ? trails.filter(t => t.name && t.type && Types.ObjectId.isValid(t.id))
  : [];

  let trailRouteContent: React.ReactElement<any>;
  let titleLink: LinkProps['to'];
  if (routeDistance !== undefined) {
    titleLink = {
      pathname: routePlanLink(),
      state: {
        routePlan: {
          id: null,
          name: 'Hike to ' + end.name,
          visibility: 'public',
          autoGenerated: false,
          authors: null,
          authorVisibility: 'public',
          totalLength: properties.routeLength,
          date: new Date(),
          segments: [{
            name: `From ${name} to ${end.name}`,
            color: primaryColor,
            lines: [[...geometry.coordinates].reverse().map(coord => coord.map(Number))],
            trails: validTrails,
            hashKey: new Types.ObjectId().toString(),
          }],
          center: getCenter(feature).geometry.coordinates,
        },
      },
    };
    trailRouteContent = (
      <>
        <BlockHeaderVerticalCenter>
            <HighlightedLargeIconInText icon={faHiking} />
            <Column>
              <SemiBold>{routeDistance}</SemiBold>
              <DetailsCompact>
                <Subtext>
                  <em>from {name}</em>
                </Subtext>
                <Subtext>
                  <em>to {end.name}</em>
                </Subtext>
              </DetailsCompact>
            </Column>
        </BlockHeaderVerticalCenter>
      </>
    );
  } else if (properties.crowFliesDistance !== null && properties.crowFliesDistance !== undefined) {
    const distanceUnit = properties.crowFliesDistance < 0.1
      ? getString('distance-feet-formatted', {feet: Math.round(properties.crowFliesDistance * 5280)})
      : getString('directions-driving-distance', {miles: parseFloat(properties.crowFliesDistance.toFixed(1))});
    titleLink = trailheadDetailLink(destination._id);
    trailRouteContent = (
      <>
        <BlockHeaderVerticalCenter>
            <LargeIconInText icon={faCrow} />
            <Column>
              <SemiBold>{distanceUnit} {getString('crow-flies').split(' ').join('-')}</SemiBold>
              <DetailsCompact>
                <Subtext>
                  <em>from {name}</em>
                </Subtext>
                <Subtext>
                  <em>to {end.name}</em>
                </Subtext>
              </DetailsCompact>
            </Column>
        </BlockHeaderVerticalCenter>
      </>
    );
  } else {
    titleLink = routePlanLink();
    trailRouteContent = (
      <small>
        <SmallLink to={routePlanLink()}>
          <small><BasicIconInText icon={faRoute} /></small>
          {getString('global-text-value-create-route')}
        </SmallLink>
      </small>
    );
  }

  let drivingContent: React.ReactElement<any>;
  if (drivingDirections && start) {
    const {hours, minutes} = drivingDirections;
    const googleUrl = getGoogleMapsLink(destination.location);
    drivingContent = (
      <>
        <BlockHeaderVerticalCenter>
            <HighlightedLargeIconInText icon={faCar} />
            <Column>
              <SemiBold>{getString('directions-driving-duration', {hours, minutes})} drive</SemiBold>
              <DetailsCompact>
                  <Subtext>
                    <em>from {start.name}</em>
                    <LinkButtonCompact onClick={onEditDirections}>
                      <BasicIconAtEndOfText icon={faPencilAlt} />
                    </LinkButtonCompact>
                  </Subtext>
                  <Subtext>
                    <em>to {name}</em>
                  </Subtext>
              </DetailsCompact>
              <small>
                <SmallExternalLink href={googleUrl} target={'_blank'}>
                  {getString('directions-open-in-google-maps')}
                  <small><BasicIconAtEndOfText icon={faExternalLinkAlt} /></small>
                </SmallExternalLink>
              </small>
            </Column>
        </BlockHeaderVerticalCenter>
      </>
    );
  } else {
    drivingContent = (
      <>
        <BlockHeaderVerticalCenter>
            <HighlightedLargeIconInText icon={faCar} />
            <LinkButton onClick={onEditDirections}>
              {getString('map-get-directions')}
            </LinkButton>
        </BlockHeaderVerticalCenter>
      </>
    );
  }

  return (
    <BlockContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <BlockHeaderWithMargin>
        <Link to={titleLink}>
          <SemiBold>{name}</SemiBold>
        </Link>
      </BlockHeaderWithMargin>
      {trailRouteContent}
      <br />
      {drivingContent}
    </BlockContainer>
  );
};

export default TrailheadBlock;
