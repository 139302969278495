import { gql } from '@apollo/client';
import {
  Trail,
  User,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_TRAILS = gql`
  query GetSavedTrails($userId: ID) {
    user(id: $userId) {
      id
      savedTrails {
        id
        name
        type
        trailLength
        avgSlope
        line
        center
        locationText
        locationTextShort
        childrenCount
        userNote
      }
    }
  }
`;

export const SAVE_TRAIL_TO_USER = gql`
  mutation SaveTrailToUser($userId: ID!, $trailId: ID!) {
    user: saveTrailToUser(userId: $userId, trailId: $trailId) {
      id
      savedTrails {
        id
        name
        type
        trailLength
        avgSlope
        line
        center
        locationText
        locationTextShort
        childrenCount
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_TRAIL_FROM_USER = gql`
  mutation RemoveSaveTrailToUser($userId: ID!, $trailId: ID!) {
    user: removeSavedTrailFromUser(userId: $userId, trailId: $trailId) {
      id
      savedTrails {
        id
        name
        type
        trailLength
        avgSlope
        line
        center
        locationText
        locationTextShort
        childrenCount
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  trailId: string;
}

export interface TrailDatum {
  id: Trail['id'];
  __typename: string;
  name: Trail['name'];
  type: Trail['type'];
  trailLength: Trail['trailLength'];
  avgSlope: Trail['avgSlope'];
  line: Trail['line'];
  center: Trail['center'];
  locationText: Trail['locationText'];
  locationTextShort: Trail['locationTextShort'];
  childrenCount: Trail['childrenCount'];
  userNote: Trail['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedTrails: (null | TrailDatum)[];
  };
}
