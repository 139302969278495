import { faArrowRight, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import HikerOnMapImage from '../../assets/images/about-page/hiker-on-map.svg';
import LogoPng from '../../assets/logo/logo.png';
import useFluent from '../../hooks/useFluent';
import {Routes} from '../../routing/routes';
import {
  baseColor,
  BasicIconAtEndOfText,
  BasicIconInText,
  ButtonPrimaryLink,
  GhostButton,
  IconContainer as IconContainerBase,
  primaryColor,
  secondaryColor,
  tertiaryColor,
} from '../../styling/styleUtils';
import { mobileSize } from '../../Utils';
import {
  mountainNeutralSvg,
  parkNeutralSvg,
  tentNeutralSvg,
  trailheadDefaultSvg,
  viewpointNeutralSvg,
} from '../sharedComponents/svgIcons';
import { EXPLORE_BUTTON_CLASSNAME } from '../template/navigation/Header';

const Root = styled.div`
  position: relative;
  padding: 1rem 0;

  @media (max-width: ${mobileSize}px) {
    padding: 1rem 0.5rem 4.25rem;
    display: flex;
    flex-direction: column-reverse;
    z-index: 500;
  }
`;

const Popup = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  pointer-events: all;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  border-radius: 8px;
  margin-top: 2.4rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto atuo;
  grid-template-areas:
    "title img"
    "copy img";

  @media (max-width: ${mobileSize}px) {
    margin-top: 0;
    margin-bottom: auto;
  }
`;

const CloseButton = styled(GhostButton)`
  display: block;
  padding: 0.35rem 0.5rem;
  text-align: right;
  display: flex;
  position: absolute;
  top: 0.5rem;
  right: 0;
`;

const Header = styled.h1`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.75rem 0 0.25rem 1rem;
  position: relative;
  grid-area: title;
`;

const TitleText = styled.span`
  font-size: 0;
  color: rgba(0, 0, 0, 0);
  position: absolute;
  opacity: 0;
`;

const WelcomeText = styled.span`
font-size: 0.9rem;
white-space: nowrap;

  @media (max-width: ${mobileSize}px) {
    font-size: 0.8rem;
  }
`;

const WelcomeImg = styled.div`
  grid-area: img;
  max-width: 100%;
  width: 120px;
  margin-top: 2rem;
  margin-right: 0.5rem;

  img {
    max-width: 100%:
  }

  @media (max-width: ${mobileSize}px) {
    width: 90px;
  }
`;

const Logo = styled.img`
  width: 140px;
  margin-left: 0.75rem;

  @media (max-width: 1000px) {
    width: 120px;
  }

  @media (max-width: 500px) {
    width: 100px;
  }
`;

const WelcomeCopy = styled.div`
  grid-area: copy;
  margin: 0.75rem 1rem 1rem;
  font-size: 0.8rem;

  p {
    margin-top: 0;
  }
`;

const ExploreButtonsRoot = styled.div`
  display: flex;
  justify-content: stretch;
  margin-bottom: 0.3rem;
  position: fixed;

  @media (max-width: ${mobileSize}px) {
    box-sizing: border-box;
    width: 100vw;
    padding: 0.1rem 0.5rem 0.5rem;
    left: 0;
    bottom: 4rem;
    max-width: 100%;
    overflow: auto;
    pointer-events: all;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 0;
      width: 0;
      background: transparent;
    }

  }
`;

const ExploreButton = styled(Link)`
  background-color: #fff;
  flex-grow: 1;
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: ${baseColor};
  font-size: 0.75rem;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  border-radius: 8px;
  pointer-events: all;

  &:not(:first-child) {
    margin-left: 1.5rem;
  }

  &:hover {
    color: ${baseColor};
    background-color: ${tertiaryColor};
  }

  @media (max-width: 1350px) {
    &:not(:first-child) {
      margin-left: 0.6rem;
    }
  }

  @media (max-width: 1250px) {
    &:not(:first-child) {
      margin-left: 0.4rem;
    }
  }
`;

const IconContainer = styled(IconContainerBase)`
  margin: 0 0 0.1rem;
  font-size: 0.8rem;

  svg {
    width: 0.85rem;
  }
`;

const popupVersion = '0.4';
const localstorageKey = 'landingPageFirstVisitPopupLocalstorageKey';
export const disableLandingPopup = () => localStorage.setItem(localstorageKey, popupVersion);

const Landing = () => {
  const [showPopup, setShowPopup] = useState<boolean>(localStorage.getItem(localstorageKey) !== popupVersion);
  const getString = useFluent();
  const closePopup = useCallback(() => {
    setShowPopup(false);
    disableLandingPopup();
  }, []);
  useEffect(() => {
    const exploreButton = document.querySelector(`.${EXPLORE_BUTTON_CLASSNAME}`);
    if (exploreButton) {
      exploreButton.addEventListener('click', closePopup);
    }
    return () => {
      if (exploreButton) {
        exploreButton.removeEventListener('click', closePopup);
      }
    };
  }, [closePopup]);

  const popup = showPopup ? (
    <Popup>
        <Header>
          <WelcomeText>{getString('landing-popup-welcome')}</WelcomeText> <TitleText>Wilderlist</TitleText>
          <Logo src={LogoPng} alt='Wilderlist' title='Wilderlist' />
        </Header>
        <WelcomeCopy>
          <p>
            {getString('landing-popup-welcome-copy')}
          </p>
          <div>
            <ButtonPrimaryLink onClick={closePopup} to={Routes.About}>
              {getString('learn-more')} <BasicIconAtEndOfText icon={faArrowRight} />
            </ButtonPrimaryLink>
          </div>
        </WelcomeCopy>
        <WelcomeImg>
          <img src={HikerOnMapImage} alt='Hiker on map' />
        </WelcomeImg>
      <CloseButton onClick={closePopup}>
        <IconContainer $color={secondaryColor}>
          <BasicIconInText icon={faTimes} />
        </IconContainer>
      </CloseButton>
    </Popup>
  ) : null;

  return (
    <Root>
      <ExploreButtonsRoot>
        <ExploreButton
          to={Routes.Saved}
          onClick={closePopup}
        >
          <IconContainer
            $color={primaryColor}
          >
            <FontAwesomeIcon icon={faStar} />
          </IconContainer>
          {getString('saved-places')}
        </ExploreButton>
        <ExploreButton
          to={Routes.SearchParks}
          onClick={closePopup}
        >
          <IconContainer
            $color={secondaryColor}
            dangerouslySetInnerHTML={{__html: parkNeutralSvg}}
          />
          {getString('global-text-value-parks')}
        </ExploreButton>
        <ExploreButton
          to={Routes.SearchMountains}
          onClick={closePopup}
        >
          <IconContainer
            $color={secondaryColor}
            dangerouslySetInnerHTML={{__html: mountainNeutralSvg}}
          />
          {getString('header-text-menu-item-mountains')}
        </ExploreButton>
        <ExploreButton
          to={Routes.SearchCampsites}
          onClick={closePopup}
        >
          <IconContainer
            $color={secondaryColor}
            dangerouslySetInnerHTML={{ __html: tentNeutralSvg }}
          />
          {getString('header-text-menu-item-camping')}
        </ExploreButton>
        <ExploreButton
          to={Routes.SearchViewpoints}
          onClick={closePopup}
        >
          <IconContainer
            $color={secondaryColor}
            dangerouslySetInnerHTML={{ __html: viewpointNeutralSvg }}
          />
          {getString('global-text-value-views')}
        </ExploreButton>
        <ExploreButton
          to={Routes.SearchTrails}
          onClick={closePopup}
        >
          <IconContainer
            $color={secondaryColor}
            dangerouslySetInnerHTML={{ __html: trailheadDefaultSvg }}
          />
          {getString('header-text-menu-item-trailheads')}
        </ExploreButton>
      </ExploreButtonsRoot>
      {popup}
    </Root>
  );
};

export default Landing;
