import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useFluent from '../../../hooks/useFluent';
import {
  useAcceptFriendRequestMutation,
  useClearAscentNotification,
  useGetNotifications,
  useRemoveFriendMutation,
} from '../../../queries/notifications/useGetNotifications';
import {
  useTripReportMutations,
} from '../../../queries/tripReports/tripReportMutations';
import {
  userProfileLink,
} from '../../../routing/Utils';
import {
  SemiBold,
} from '../../../styling/styleUtils';
import { CampsiteType, TrailType } from '../../../types/graphQLTypes';
import { formatStringDate } from '../../../utilities/dateUtils';
import getTripName from '../../../utilities/getTripName';
import { asyncForEach, formatType } from '../../../Utils';
import Notification from './Notification';

interface PlaceNotification {
  key: string; // `${date}-${fromId}
  sourceNotification: string;
  date: string;
  fromId: string;
  fromName: string;
  place: {
    id: string,
    name: string,
    type?: TrailType | CampsiteType
    elevation?: number,
    trailLength?: number,
    kind: 'mountain' | 'campsite' | 'trail',
  };
}

interface Props {
  userId: string;
}

const NotificationBar = (props: Props) => {
  const { userId } = props;

  const getString = useFluent();

  const {loading, error, data, refetch: refetchNotifications} = useGetNotifications(userId);
  const {addEditTripReport} = useTripReportMutations();
  const clearAscentNotification = useClearAscentNotification(userId);
  const acceptFriendRequestMutation = useAcceptFriendRequestMutation(userId);
  const removeFriendMutation = useRemoveFriendMutation(userId);

  const location = useLocation();

  useEffect(() => {
    refetchNotifications();
  }, [location, refetchNotifications]);

  if (loading === true) {
    return null;
  } else if (error !== undefined) {
    console.error(error);
    return null;
  } else if (data !== undefined) {
    const { user } = data;
    const placeNotifications: PlaceNotification[] = [];
    data?.user?.ascentNotifications?.forEach(d => {
      if (d.user && d.user.id && d.mountain && d.mountain.id) {
        placeNotifications.push({
          key: `${d.date}-${d.user?.id}`, // `${date}-${fromId}
          sourceNotification: d.id,
          date: d.date,
          fromId: d.user.id,
          fromName: d.user.name,
          place: {
            id: d.mountain.id,
            name: d.mountain.name,
            elevation: d.mountain.elevation,
            kind: 'mountain',
          },
        });
      }
    });
    data?.user?.campsiteNotifications?.forEach(d => {
      if (d.user && d.user.id && d.campsite && d.campsite.id) {
        placeNotifications.push({
          key: `${d.date}-${d.user?.id}`, // `${date}-${fromId}
          sourceNotification: d.id,
          date: d.date,
          fromId: d.user.id,
          fromName: d.user.name,
          place: {
            id: d.campsite.id,
            name: d.campsite.name || upperFirst(formatType(d.campsite.type)),
            kind: 'campsite',
            type: d.campsite.type,
          },
        });
      }
    });
    data?.user?.trailNotifications?.forEach(d => {
      if (d.user && d.user.id && d.trail && d.trail.id) {
        placeNotifications.push({
          key: `${d.date}-${d.user?.id}`, // `${date}-${fromId}
          sourceNotification: d.id,
          date: d.date,
          fromId: d.user.id,
          fromName: d.user.name,
          place: {
            id: d.trail.id,
            name: d.trail.name || upperFirst(formatType(d.trail.type)),
            trailLength: d.trail.trailLength || undefined,
            kind: 'trail',
            type: d.trail.type,
          },
        });
      }
    });
    if (user && user.friendRequests && user.friendRequests.length && user.friendRequests[0].user) {
      const { id, name } = user.friendRequests[0].user;
      const friendId = id ? id : null;
      const dismissNotification = () => {
        if (friendId) {
          removeFriendMutation({variables: { friendId, userId }, onCompleted: () => refetchNotifications()});
        }
      };
      if (friendId) {
        const onConfirm = () => {
          acceptFriendRequestMutation({variables: { friendId, userId }, onCompleted: () => refetchNotifications()});
        };

        return (
          <Notification
            key={id}
            confirmText={getString('global-text-value-modal-confirm')}
            onConfirm={onConfirm}
            dismissText={getString('global-text-value-modal-dismiss')}
            onDismiss={dismissNotification}
          >
            <Link to={userProfileLink(friendId)}><SemiBold>{name}</SemiBold></Link>
            {' '}
            {getString('user-profile-sent-you-a-friend-request', {name: ''})}
          </Notification>
        );

      } else {
        dismissNotification();
        return null;
      }
    } else if (placeNotifications.length) {
      const {
        date, fromId, fromName, places, mountains, campsites, trails,
      } = orderBy(
        Array.from(
          Object.entries(groupBy(placeNotifications, 'key')),
        ).map(([, notifications]) => {
          return {
            sourceNotifications: notifications.map(({sourceNotification}) => sourceNotification),
            date: notifications[0].date,
            fromId: notifications[0].fromId,
            fromName: notifications[0].fromName,
            places: notifications.map(({place}) => place),
            mountains: notifications.filter(({place}) => place.kind === 'mountain').map(({place}) => ({
              id: place.id,
              name: place.name,
              elevation: place.elevation || 0,
              kind: 'mountain',
            })),
            campsites: notifications.filter(({place}) => place.kind === 'campsite').map(({place}) => ({
              id: place.id,
              name: place.name,
              kind: 'campsite',
              type: place.type as CampsiteType,
            })),
            trails: notifications.filter(({place}) => place.kind === 'trail').map(({place}) => ({
              id: place.id,
              name: place.name,
              trailLength: place.trailLength || 0,
              kind: 'trail',
              type: place.type as TrailType,
            })),
          };
        }),
        ['date'],
      )[0];
      const dismissNotification = async () => {
        await asyncForEach(places, async (d, i) => {
          clearAscentNotification({variables: {
            userId,
            mountainId: d.kind === 'mountain' ? d.id : null,
            campsiteId: d.kind === 'campsite' ? d.id : null,
            trailId: d.kind === 'trail' ? d.id : null,
            date,
          }, onCompleted: () => i >= places.length - 1 && refetchNotifications()});
        });
      };
      const onConfirm = async () => {
        await addEditTripReport({ variables: {
          id: null,
          date,
          author: userId,
          mountains: mountains.map(({id}) => id),
          trails: trails.map(({id}) => id),
          campsites: campsites.map(({id}) => id),
          users: [fromId],
          privacy: null,
          notes: null,
          link: null,
          rating: null,
          mudMinor: null,
          mudMajor: null,
          waterSlipperyRocks: null,
          waterOnTrail: null,
          leavesSlippery: null,
          iceMinor: null,
          iceBlack: null,
          iceBlue: null,
          iceCrust: null,
          snowIceFrozenGranular: null,
          snowIceMonorailStable: null,
          snowIceMonorailUnstable: null,
          snowIcePostholes: null,
          snowMinor: null,
          snowPackedPowder: null,
          snowUnpackedPowder: null,
          snowDrifts: null,
          snowSticky: null,
          snowSlush: null,
          obstaclesBlowdown: null,
          obstaclesErosion: null,
          obstaclesRockslides: null,
          obstaclesOvergrown: null,
          obstaclesOther: null,
          originalDate: null,
          originalMountains: [],
          originalTrails: [],
          originalCampsites: [],
          route: null,
        }});
        dismissNotification();
      };

      return (
        <Notification
          key={`${date}-${fromId}`}
          confirmText={getString('global-text-value-modal-confirm')}
          onConfirm={onConfirm}
          dismissText={getString('global-text-value-modal-dismiss')}
          onDismiss={dismissNotification}
        >
          <Link to={userProfileLink(fromId)}><SemiBold>{fromName}</SemiBold></Link>
          {' '}
          {getString('notification-bar-marked-as')}
          {' '}
          {getTripName({
            mountains,
            trails,
            campsites,
            friends: [],
            getString,
            tense: 'present',
          })}
          {' '}
          {getString('global-text-value-on')}
          {' '}
          <SemiBold>{formatStringDate(date)}</SemiBold>
        </Notification>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }

};

export default NotificationBar;
