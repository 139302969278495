import { faChartArea, faHiking } from '@fortawesome/free-solid-svg-icons';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import useFluent from '../../../../hooks/useFluent';
import { GeojsonPolygon, usePointsWithin } from '../../../../queries/compound/usePointsWithin';
import { DetailBase } from '../../../../styling/sharedContentStyles';
import { BasicIconInText, IconContainer, lightBaseColor } from '../../../../styling/styleUtils';
import { Coordinate } from '../../../../types/graphQLTypes';
import { AuxiliaryItem, CoreItem } from '../../../../types/itemTypes';
import { getTrailAccessNice } from '../../../../utilities/trailUtils';
import { mountainNeutralSvg, trailDefaultSvg, trailheadDefaultSvg, viewpointNeutralSvg } from '../../svgIcons';
import PlacesBlocks from '../places';

interface Props {
  geojson: GeojsonPolygon;
  hasMountains: boolean;
  hasTrails: boolean;
  nearby?: boolean;
}

function isRoadOrTrailAccessible(trailAccessMiles: number | null, roadAccessMiles: number | null): string {
  if (trailAccessMiles && trailAccessMiles <= 0.3 && roadAccessMiles && roadAccessMiles <= 0.3) {
    return 'both';
  }
  if (trailAccessMiles && trailAccessMiles <= 0.3) {
    return 'trail';
  }
  if (roadAccessMiles && roadAccessMiles <= 0.3) {
    return 'road';
  }
  return 'none';
}

const ExploreWithin = (props: Props) => {
  const {
    geojson,
    hasMountains,
    hasTrails,
    nearby,
  } = props;

  const getString = useFluent();

  const {loading, error, data} = usePointsWithin({
    geojson,
    limitMountains: 10,
    limitViewpoints: 10,
    limitTrailheads: 10,
  });
  if (loading) {
    return (
      <>
        {hasMountains && (
          <PlacesBlocks
            title={
              nearby
                ? getString('detail-nearby-mountains')
                : getString('global-text-tallest-mountains')
            }
            places={[]}
            loading={true}
          />
        )}
        {hasTrails && (
          <PlacesBlocks
          title={
              nearby
                ? getString('detail-nearby-trailheads')
                : getString('global-text-value-largest-trailheads')
            }
            places={[]}
            loading={true}
          />
        )}
      </>
    );
  } else if (error) {
    console.error(error);
    return (
      <PlacesBlocks
        title={''}
        places={[]}
        error={error.message}
      />
    );

  } else if (data) {
    const {
      mountains,
      viewpoints,
    } = data;
    const tallestMountains = mountains && mountains.length ? (
      <PlacesBlocks
        title={
          nearby
            ? getString('detail-nearby-mountains')
            : getString('global-text-tallest-mountains')
        }
        places={mountains.map(d => ({
          ...d,
          type: CoreItem.mountain,
          children: (
            <div>
              <DetailBase><BasicIconInText icon={faChartArea} /> {d.elevation}ft</DetailBase>
              <DetailBase style={{
                opacity: isRoadOrTrailAccessible(d.trailAccessMiles, d.roadAccessMiles) === 'none'
                  ? 0.45 : undefined,
              }}><BasicIconInText icon={faHiking} /> {getString(
                'is-trail-road-accessible', {value: isRoadOrTrailAccessible(d.trailAccessMiles, d.roadAccessMiles)},
              )}</DetailBase>
            </div>
          ),
        }))}
        icon={mountainNeutralSvg}
        customIcon={true}
      />
    ) : null;
    const highestViewpoints = viewpoints && viewpoints.length ? (
      <PlacesBlocks
        title={
          nearby
            ? getString('detail-nearby-viewpoints')
            : getString('global-text-highest-viewpoints')
        }
        places={viewpoints.map(d => ({
          ...d,
          name: d.name ? d.name : getString('global-text-value-viewpoint'),
          type: AuxiliaryItem.viewpoint,
          children: (
            <div>
              <DetailBase><BasicIconInText icon={faChartArea} /> {d.elevation}ft</DetailBase>
            </div>
          ),
        }))}
        icon={viewpointNeutralSvg}
        customIcon={true}
      />
    ) : null;
    const trailheads = data.trailheads && data.trailheads.length ? (
      <PlacesBlocks
        title={
          nearby
            ? getString('detail-nearby-trailheads')
            : getString('global-text-value-largest-trailheads')
        }
        places={data.trailheads.filter(d => d.roadAccessPoint).map(d => ({
          ...d,
          name: d.name ? d.name + ' Trailhead' : upperFirst(getString('global-formatted-trail-type', {type: d.type})),
          type: AuxiliaryItem.trailhead,
          location: d.roadAccessPoint as Coordinate,
          children: (
            <div>
              <DetailBase>
                <IconContainer
                  $color={lightBaseColor}
                  dangerouslySetInnerHTML={{__html: trailDefaultSvg}}
                />
                {getTrailAccessNice(d.totalTrailMiles || 0)}
              </DetailBase>
            </div>
          ),
        }))}
        icon={trailheadDefaultSvg}
        customIcon={true}
      />
    ) : null;
    return (
      <>
        {tallestMountains}
        {highestViewpoints}
        {trailheads}
      </>
    );
  } else {
    return null;
  }
};

export default ExploreWithin;
