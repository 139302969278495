import React from 'react';
import useDirectionsOrigin from '../../../../../hooks/directions/useDirectionsOrigin';
import {
  CenteredHeader,
  EmptyBlock,
  SmallScrollContainer,
} from '../../../../../styling/sharedContentStyles';
import {Coordinate} from '../../../../../types/graphQLTypes';
import LoadingSimple from '../../../LoadingSimple';
import SetOrigin from '../../directions/SetOrigin';
import Destinations from './Destinations';

interface Props {
  destination: Coordinate;
  destinationName: string;
}

const PanelDirections = (props: Props) => {
  const {destination, destinationName} = props;
  const {location, updateLocation, getUsersLocation} = useDirectionsOrigin();
  const clearLocation = () => updateLocation(null);
  let output: React.ReactElement<any> | null;
  if (location.loading) {
    output = (
      <SmallScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            <LoadingSimple /> Getting directions...
          </CenteredHeader>
        </EmptyBlock>
      </SmallScrollContainer>
    );
  } else if (location.data) {
    output = (
      <SmallScrollContainer $noScroll={true} hideScrollbars={false}>
          <Destinations
            start={location.data}
            end={{name: destinationName, coordinates: destination}}
            onClearStart={clearLocation}
          />
      </SmallScrollContainer>
    );
  } else {
    output = (
      <SetOrigin
        error={location.error}
        loading={location.loading}
        updateLocation={updateLocation}
        getUsersLocation={getUsersLocation}
      />
    );
  }
  return (
    <>
      {output}
    </>
  );
};

export default PanelDirections;
