import { faMapMarkedAlt, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useDirectionsOrigin from '../../../hooks/directions/useDirectionsOrigin';
import useFluent from '../../../hooks/useFluent';
import useMapCenter from '../../../hooks/useMapCenter';
import {
  BasicIconInText,
  ButtonSecondary,
  NoResults,
  NoResultsHelp,
  NoResultsIcon,
  NoResultsRoot,
  NoResultsTitle,
  Subtext,
} from '../../../styling/styleUtils';
import {AggregateItem, AuxiliaryItem, CoreItem} from '../../../types/itemTypes';
import SetOrigin from '../detailComponents/directions/SetOrigin';
import Modal, {ButtonWrapper} from '../Modal';
import ResultItem, {TypeProps as Datum} from './ResultItem';

interface Props {
  data: Datum[];
  type: CoreItem | AggregateItem | AuxiliaryItem | null;
  hideText?: boolean;
}

const Results = (props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const getString = useFluent();
  const mapCenter = useMapCenter();
  const {location, updateLocation, getUsersLocation} = useDirectionsOrigin();
  const {offline} = useOfflineContext();

  const clearAndOpenOriginModal = () => {
    updateLocation(null);
    setModalOpen(true);
  };

  const results = props.data.map(d => (
    <ResultItem
      key={d.id}
      mapCenter={mapCenter}
      usersLocation={location.data}
      changeUsersLocation={clearAndOpenOriginModal}
      {...d}
    />
  ));

  const onClose = () => setModalOpen(false);

  const actions = (
    <ButtonWrapper>
      <ButtonSecondary onClick={onClose} mobileExtend={true}>
        {getString('global-text-value-modal-close')}
      </ButtonSecondary>
    </ButtonWrapper>
  );

  const setOriginModal = modalOpen && !location.data ? (
    <Modal
      onClose={onClose}
      width={'500px'}
      height={'auto'}
      actions={actions}
      contentStyles={{padding: 0, marginBottom: '-1rem', overflow: 'visible'}}
    >
      <SetOrigin
        error={location.error}
        loading={location.loading}
        updateLocation={updateLocation}
        getUsersLocation={getUsersLocation}
      />
      <div style={{width: 500}} />
    </Modal>
  ) : null;

  const resultsNearCenterText = props.hideText ? null : (
    <NoResults>
      <Subtext>

        {
          offline ? 'Offline. Results may be limited.' : (
            <>
              <BasicIconInText icon={faMapMarkedAlt} />
              {getString('global-text-value-results-bottom-of-results', {
                type: props.type ? props.type + 's' : 'results',
              })}
            </>
          )
        }
      </Subtext>
    </NoResults>
  );
  const noResultsText = props.hideText ? null : (
    <NoResultsRoot>
      <NoResultsIcon icon={faSearchLocation} />
      <NoResultsTitle>
        {getString('global-text-value-no-items-found-map', {
          type: props.type ? props.type + 's' : 'results',
        })}
      </NoResultsTitle>
      <NoResultsHelp>
        {getString('global-text-value-no-items-found-map-help', {
          type: props.type ? props.type + 's' : 'results',
        })}
      </NoResultsHelp>
    </NoResultsRoot>
  );

  return results.length ? (
    <>
      {resultsNearCenterText}
      <div>
        {results}
      </div>
      <br />
      <br />
      {setOriginModal}
    </>
  ) : noResultsText;
};

export default Results;
