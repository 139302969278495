import React from 'react';
import { useOfflineContext } from '../../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFluent from '../../../../hooks/useFluent';
import {
  useAddViewpointNote,
  useEditViewpointNote,
  useViewpointNote,
} from '../../../../queries/viewpoints/viewpointNotes';
import { PlaceholderText } from '../../../../styling/styleUtils';
import UserNote from '../../UserNote';

interface Props {
  id: string;
  name: string;
}

const ViewpointNote = ({id, name}: Props) => {
  const getString = useFluent();
  const currentUser = useCurrentUser();
  const userId = currentUser ? currentUser._id : null;
  const {offline} = useOfflineContext();

  const {loading, error, data} = useViewpointNote({
    userId,
    viewpointId: id,
  });

  const addViewpointNote = useAddViewpointNote();
  const editViewpointNote = useEditViewpointNote();

  const viewpointNote = data && data.user && data.user.viewpointNote ? data.user.viewpointNote : null;
  const defaultNoteText = viewpointNote && viewpointNote.text ? viewpointNote.text : '';
  const notesPlaceholderText = getString('user-notes-placeholder', {name});

  const saveNote = (text: string) => {
    if (userId) {
      if (viewpointNote === null) {
        addViewpointNote({variables: {userId, viewpointId: id, text}});
      } else {
        editViewpointNote({variables: {userId, viewpointId: id, text}});
      }
    }
  };

  if (loading === true) {
    return null;
  } else if (data !== undefined || offline) {
    return (
      <UserNote
        placeholder={notesPlaceholderText}
        defaultValue={defaultNoteText}
        onSave={saveNote}
        key={defaultNoteText}
      />
    );
  } else if (error !== undefined) {
    console.error(error);
    return (
      <PlaceholderText>
        {getString('global-error-retrieving-data')}
      </PlaceholderText>
    );
  } else {
    return null;
  }
};

export default ViewpointNote;
