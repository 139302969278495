import { gql } from '@apollo/client';
import {
  User,
} from '../../../types/graphQLTypes';

export const GET_MOUNTAIN_NOTE = gql`
  query getMountainNote($userId: ID, $mountainId: ID) {
    user(id: $userId) {
      id
      mountainNote(mountainId: $mountainId) {
        id
        text
      }
    }
  }
`;

export interface QuerySuccessResponse {
  user: null | {
    id: User['name'];
    __typename: string;
    mountainNote: null | {
      id: string;
      __typename: string;
      text: string;
    }
  };
}

export interface QueryVariables {
  userId: string | null;
  mountainId: string | null;
}

export const ADD_MOUNTAIN_NOTE = gql`
  mutation($userId: ID!, $mountainId: ID!, $text: String!) {
    user: addMountainNote(
      userId: $userId,
      mountainId: $mountainId,
      text: $text
    ) {
      id
      mountainNote(mountainId: $mountainId) {
        id
        text
      }
    }
  }
`;

export const EDIT_MOUNTAIN_NOTE = gql`
  mutation($userId: ID!, $mountainId: ID!, $text: String!) {
    user: editMountainNote(
      userId: $userId,
      mountainId: $mountainId,
      text: $text
    ) {
      id
      mountainNote(mountainId: $mountainId) {
        id
        text
      }
    }
  }
`;

export interface MountainNoteSuccess {
  user: {
    id: User['id'];
    mountainNote: User['mountainNote'];
  };
}

export interface Variables {
  userId: string;
  mountainId: string;
  text: string;
}
