import { gql, useQuery } from '@apollo/client';
import {
  Mountain,
  Trail,
  Viewpoint,
} from '../../types/graphQLTypes';

interface MountainDatum {
  id: Mountain['id'];
  name: Mountain['name'];
  location: Mountain['location'];
  elevation: Mountain['elevation'];
  roadAccessMiles: Mountain['roadAccessMiles'];
  trailAccessMiles: Mountain['trailAccessMiles'];
}
interface ViewpointDatum {
  id: Viewpoint['id'];
  name: Viewpoint['name'];
  elevation: Viewpoint['elevation'];
  location: Viewpoint['location'];
  roadAccessMiles: Viewpoint['roadAccessMiles'];
  trailAccessMiles: Viewpoint['trailAccessMiles'];
}
interface TrailheadDatum {
  id: Trail['id'];
  name: Trail['name'];
  type: Trail['type'];
  totalTrailMiles: Trail['totalTrailMiles'];
  roadAccessPoint: Trail['roadAccessPoint'];
}

interface SuccessResponse {
  mountains: MountainDatum[];
  viewpoints: ViewpointDatum[];
  trailheads: TrailheadDatum[];
}

export interface GeojsonPolygon {
  properties: any;
  geometry: {
    type: 'Polygon' | 'MultiPolygon',
    coordinates: [number, number][] | [number, number][][];
  };
}

export interface Variables {
  geojson: GeojsonPolygon;
  limitMountains: number;
  limitViewpoints: number;
  limitTrailheads: number;
}

const GEO_WITHIN_POINTS = gql`
  query SearchParks(
    $geojson: JSONObject!,
    $limitMountains: Int!,
    $limitViewpoints: Int!,
    $limitTrailheads: Int!,
  ) {
    mountains: geoWithinMountains(
      geojson: $geojson,
      limit: $limitMountains,
    ) {
      id
      name
      elevation
      location
      roadAccessMiles
      trailAccessMiles
    }
    viewpoints: geoWithinViewpoints(
      geojson: $geojson,
      limit: $limitViewpoints,
    ) {
      id
      name
      elevation
      location
      roadAccessMiles
      trailAccessMiles
    }
    trailheads: geoWithinTrailheads(
      geojson: $geojson,
      limit: $limitTrailheads,
    ) {
      id
      name
      totalTrailMiles
      roadAccessPoint
      type
    }
  }
`;

export const usePointsWithin = (variables: Variables) => {
  return useQuery<SuccessResponse, Variables>(GEO_WITHIN_POINTS, {
    variables,
  });
};
