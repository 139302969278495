import React from 'react';
import styled from 'styled-components/macro';
import { lightBorderColor } from '../../../styling/styleUtils';
import { AggregateItem, AuxiliaryItem, CoreItem, isCoreItem } from '../../../types/itemTypes';
import ListProgressButton from './listProgressButton';
import LoggedTrips from './loggedTrips';
import UsersNotes from './usersNotes';

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.5rem;
  margin: 0 -1rem;
  border-top: solid 1px ${lightBorderColor};
  padding: 0.2rem 1rem;
  min-height: 5.3125rem;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    grid-column-gap: 0.3rem;
  }
`;

interface Props {
  id: string;
  name: string;
  item: CoreItem | AggregateItem | AuxiliaryItem;
  notesOnly?: boolean;
  listId?: string;
}

const TripsAndNotes = (props: Props) => {
  const { id, name, item, notesOnly, listId } = props;

  const loggedTrips = !notesOnly && isCoreItem(item) ? (
    <LoggedTrips
      id={id}
      item={item}
    />
  ) : null;

  const listProgressButton = listId ? (
    <ListProgressButton id={listId} />
  ) : null;

  return (
    <Root>
      <UsersNotes
        id={id}
        name={name}
        type={item}
      />
      {loggedTrips}
      {listProgressButton}
    </Root>
  );
};

export default TripsAndNotes;
