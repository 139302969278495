const clustersDbscan = require('@turf/clusters-dbscan').default;
const {point, featureCollection} = require('@turf/helpers');
const distance = require('@turf/distance').default;
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { TrailheadDatum } from '../../../queries/trails/useGeoBoxTrailheads';
import { Coordinate } from '../../../types/graphQLTypes';
import { milesToKm } from '../../../utilities/trailUtils';

const limit = 30;

interface Input {
  trailheads: TrailheadDatum[];
  mapCenterPoint: {type: 'Feature', geometry: {type: 'Point', coordinates: Coordinate}};
  bbox: [Coordinate, Coordinate];
}

const filtered = ({
  trailheads,
  mapCenterPoint,
  bbox,
}: Input) => {
  const bboxRange = distance(mapCenterPoint, point(bbox[1]));
  const filteredTrailheads: TrailheadDatum[] = [];
  const allTrailsFeatureCollection = featureCollection(trailheads.map(t => point(t.roadAccessPoint, t)));
  const clusters = clustersDbscan(
    allTrailsFeatureCollection,
    Math.min(bboxRange * 0.05, milesToKm(1)),
  );
  const grouped = groupBy(clusters.features, 'properties.cluster');
  const backup: TrailheadDatum[] = [];
  Object.keys(grouped).forEach(group => {
    if (group === 'undefined') {
      const groupedByName = groupBy(grouped[group], 'properties.name');
      Object.keys(groupedByName).forEach(name => {
        filteredTrailheads.push(groupedByName[name].shift().properties);
        groupedByName[name].map(f => backup.push(f.properties));
      });
    } else {
      filteredTrailheads.push(grouped[group][0].properties);
    }
  });
  if (filteredTrailheads.length < limit) {
    filteredTrailheads.push(...backup.slice(0, limit - filteredTrailheads.length));
  }
  const orderedByMileage = orderBy(filteredTrailheads, ['totalTrailMiles'], ['desc']).slice(0, limit);
  const [withNames, withoutNames] = partition(orderedByMileage, t => t.name);
  return [...withNames, ...withoutNames];

};

const sortTrailheads = (input: Input) => {
  const [primary, other] = partition(
    input.trailheads, trail => trail.name && trail.totalTrailMiles && trail.totalTrailMiles > 2,
  );
  const [secondary, tertiary] = partition(
    other, trail => trail.name && trail.totalTrailMiles && trail.totalTrailMiles > 0.5,
  );
  const first = filtered({...input, trailheads: primary});
  const second = filtered({...input, trailheads: secondary});
  const third = filtered({...input, trailheads: tertiary});
  return [
    ...first,
    ...second,
    ...third,
  ].slice(0, limit);
};

export default sortTrailheads;
