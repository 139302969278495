const {
  featureCollection,
  lineString,
  point,
} = require('@turf/helpers');
import along from '@turf/along';
import {getCoord} from '@turf/invariant';
import getLineLength from '@turf/length';
import mapboxgl, {MapMouseEvent} from 'mapbox-gl';
import { getGlobalMap } from '../..';
import { Coordinate } from '../../../../../../types/graphQLTypes';
import {
  defaultGeoJsonLineString,
  defaultGeoJsonPoint,
  newSegmentLayerId,
  potentialSegmentLayerId,
  segmentMarkerLayerId,
} from '../../layers';
import worker, {
  buildNetwork,
  findRoute,
  WorkerMessageResponse,
} from './routeWorker';

export interface LineString {
  type: 'Feature';
  geometry: {
    type: 'LineString';
    coordinates: Coordinate[];
  };
  properties: {
    [key: string]: number | string | null | undefined | boolean;
  };
}

let routePlanningOn = false;

export const isRoutePlanningOn = () => routePlanningOn;
export const setIsRoutePlanningOn = (value: boolean) => routePlanningOn = value;

interface Input {
  onComplete: (lines: LineString[]) => void;
  color: string;
  map?: mapboxgl.Map | undefined;
  firstPoint?: Coordinate;
  clearMarkers?: boolean;
}

export interface RoutePlanActions {
  cancel: () => void;
  finishSegment: () => void;
  undoLastClick: () => void;
}

export const startRoutePlanning = (input: Input): RoutePlanActions | undefined => {
  const {
    onComplete,
    color,
    firstPoint,
    clearMarkers,
  } = input;

  const map = input.map ? input.map : getGlobalMap();

  if (map) {

    const initialCenter = map.getCenter();
    buildNetwork([initialCenter.lng, initialCenter.lat]);

    // SETUP
    routePlanningOn = true;
    map.getCanvas().style.cursor = 'pointer';
    const newSegmentLayerSource = (map.getSource(newSegmentLayerId) as any);
    const segmentMarkerLayerSource = (map.getSource(segmentMarkerLayerId) as any);
    const potentialSegmentLayerSource = (map.getSource(potentialSegmentLayerId) as any);
    const isTouchOnlyDevice = window.matchMedia('(any-hover: none)').matches;
    const mileageNode = document.createElement('div');
    mileageNode.style.cssText = `
      pointer-events: none;
      position: fixed;
      top: -100px;
      left: -100px;
      transform: translate(-50%, calc(-100% - 0.2rem));
      font-size: 0.7rem;
      font-weight: 600;
      color: ${color};
      text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
      text-align: center;
    `;
    document.body.append(mileageNode);

    // STATE
    const linestrings: LineString[] = [];
    let lengthOfLinestrings = 0;
    let lastClickedPoint: Coordinate | undefined = firstPoint;
    let currentHoveredPoint: Coordinate | undefined;
    let potentialLine: LineString | undefined;
    let lengthOfPotentialLine = 0;
    let lastPointHasBeenResolved = true;

    const renderLinestrings = () => {
      if (linestrings.length) {
        newSegmentLayerSource.setData(featureCollection(linestrings));
        const markerPoints = linestrings.map(line => point(line.geometry.coordinates[0], {color}));
        const last = linestrings[linestrings.length - 1].geometry.coordinates;
        markerPoints.push(point(last[last.length - 1], {color}));
        segmentMarkerLayerSource.setData(featureCollection(markerPoints));
      } else {
        const markerPoints = lastClickedPoint
        ? point(lastClickedPoint, {color})
        : defaultGeoJsonPoint;
        segmentMarkerLayerSource.setData(markerPoints);
        newSegmentLayerSource.setData(defaultGeoJsonLineString);
      }
    };

    if (firstPoint) {
      renderLinestrings();
    }

    const resetLinestrings = (options?: {clearAll?: boolean}) => {
      newSegmentLayerSource.setData(defaultGeoJsonLineString);
      lengthOfPotentialLine = 0;
      lengthOfLinestrings = 0;
      if (clearMarkers === true || options?.clearAll) {
        segmentMarkerLayerSource.setData(defaultGeoJsonPoint);
      }
    };

    const pushLinestring = (line: LineString) => {
      linestrings.push(line);
      lengthOfLinestrings = 0;
      linestrings.forEach(l => {
        try {
          lengthOfLinestrings = lengthOfLinestrings += getLineLength(l, {units: 'miles'});
        } catch (err) {
          console.error(err);
        }
      });
    };
    const setPotentialLine = (line: LineString | undefined): line is LineString => {
      potentialLine = line;
      if (line) {
        try {
          lengthOfPotentialLine = getLineLength(line, {units: 'miles'});
        } catch (err) {
          console.error(err);
          lengthOfPotentialLine = 0;
        }
      } else {
        lengthOfPotentialLine = 0;
      }
      return Boolean(potentialLine);
    };

    const updateMileage = (x: number, y: number) => {
      const mileage = lengthOfLinestrings + lengthOfPotentialLine;
      if (mileage && !isTouchOnlyDevice) {
        mileageNode.style.left = `${x}px`;
        mileageNode.style.top = `${y}px`;
        mileageNode.innerText = `${mileage.toFixed(2)} mi`;
      } else {
        mileageNode.style.left = '-1000px';
        mileageNode.style.left = '-1000px';
      }
    };

    // CLEANUP
    const cleanup = () => {
      lastPointHasBeenResolved = true;
      resetLinestrings();
      potentialSegmentLayerSource.setData(defaultGeoJsonLineString);
      map.off('click', onClick);
      map.off('mousemove', onMouseMove);
      map.off('mouseout', onMouseLeave);
      map.off('contextmenu', undoLastClick);
      map.off('drag', onMapDrag);
      map.off('dblclick', onDoubleClick);
      worker.removeEventListener('message', onWorkerMessage);
      routePlanningOn = false;
      mileageNode.remove();
    };
    const finishSegment = () => {
      onComplete(linestrings);
      cleanup();
    };

    // EVENTS
    const onClick = ({lngLat}: MapMouseEvent) => {
      if (isTouchOnlyDevice) {
        currentHoveredPoint = [lngLat.lng, lngLat.lat];
        if (lastClickedPoint && lastPointHasBeenResolved) {
          lastPointHasBeenResolved = false;
          findRoute({
            from: lastClickedPoint, to: currentHoveredPoint,
          });
        }
        if (!lastClickedPoint) {
          lastClickedPoint = currentHoveredPoint;
          renderLinestrings();
        }
      } else {
        if (potentialLine) {
          pushLinestring(potentialLine);
          const coordinates = potentialLine.geometry.coordinates;
          lastClickedPoint = coordinates[coordinates.length - 1];
          setPotentialLine(undefined);
        } else {
          lastClickedPoint = [lngLat.lng, lngLat.lat];
        }
        renderLinestrings();
      }
    };

    const onMapDrag = () => {
      updateMileage(-1000, -1000);
    };

    const onMouseMove = ({lngLat, originalEvent}: MapMouseEvent) => {
      updateMileage(originalEvent.clientX, originalEvent.clientY);
      if (lastClickedPoint && !isTouchOnlyDevice) {
        currentHoveredPoint = [lngLat.lng, lngLat.lat];
        if (lastPointHasBeenResolved) {
          lastPointHasBeenResolved = false;
          findRoute({
            from: lastClickedPoint, to: currentHoveredPoint,
          });
        }
      }
    };

    const onMouseLeave = () => {
      setPotentialLine(undefined);
      potentialSegmentLayerSource.setData(defaultGeoJsonLineString);
      updateMileage(-1000, -1000);
    };

    const undoLastClick = (event?: MapMouseEvent) => {
      const undoneLine = linestrings.pop();
      lastPointHasBeenResolved = true;
      if (linestrings.length) {
        const lastLine = linestrings[linestrings.length - 1].geometry.coordinates;
        lastClickedPoint = lastLine[lastLine.length - 1];
        renderLinestrings();
        if (event) {
          onMouseMove(event);
        } else {
          setPotentialLine(undefined);
          potentialSegmentLayerSource.setData(defaultGeoJsonLineString);
        }
      } else {
        if (undoneLine) {
          lastClickedPoint = undoneLine.geometry.coordinates[0];
          setPotentialLine(undefined);
          potentialSegmentLayerSource.setData(defaultGeoJsonLineString);
          renderLinestrings();
        } else {
          lastClickedPoint = undefined;
          setPotentialLine(undefined);
          potentialSegmentLayerSource.setData(defaultGeoJsonLineString);
          resetLinestrings({clearAll: true});
        }
      }
    };

    const onDoubleClick = (event: MapMouseEvent) => {
      event.preventDefault();
      finishSegment();
    };

    const onWorkerMessage = (event: MessageEvent<WorkerMessageResponse>) => {
      let newPotentialLine: LineString | undefined;
      if (event.data.loading !== undefined) {
        map.getCanvas().style.cursor = event.data.loading ? 'progress' : 'pointer';
      }
      if (event && event.data && event.data.line) {
        lastPointHasBeenResolved = true;
        newPotentialLine = event.data.line;
        newPotentialLine.properties.color = color;
      } else if (event && event.data.error) {
        if (lastClickedPoint && currentHoveredPoint) {
          const pointToPointLine = lineString([lastClickedPoint, currentHoveredPoint], {color});
          const length = getLineLength(pointToPointLine);
          let dist = 0;
          const lineWithAddedPoints: Coordinate[] = [];
          while (dist < length) {
            const newPoint = along(pointToPointLine, dist, {units: 'miles'});
            if (newPoint) {
              lineWithAddedPoints.push(getCoord(newPoint) as Coordinate);
            }
            dist = dist + 0.01;
          }
          if (lineWithAddedPoints.length > 1) {
            newPotentialLine = lineString(lineWithAddedPoints, {color});
          } else {
            newPotentialLine = pointToPointLine;
          }
        }
        lastPointHasBeenResolved = true;
      }
      if (newPotentialLine) {
        setPotentialLine(newPotentialLine);
        if (isTouchOnlyDevice) {
          pushLinestring(newPotentialLine);
          renderLinestrings();
          lastClickedPoint = newPotentialLine.geometry.coordinates[newPotentialLine.geometry.coordinates.length - 1];
        } else {
          potentialSegmentLayerSource.setData(featureCollection([newPotentialLine]));
        }
      }
    };

    map.on('click', onClick);
    map.on('mousemove', onMouseMove);
    map.on('mouseout', onMouseLeave);
    map.on('contextmenu', undoLastClick);
    map.on('drag', onMapDrag);
    map.on('dblclick', onDoubleClick);
    worker.addEventListener('message', onWorkerMessage);

    return {
      cancel: cleanup,
      finishSegment,
      undoLastClick,
    };
  }
};
