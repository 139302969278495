import React, {useState} from 'react';
import useMapContext from '../../../../hooks/useMapContext';
import {
  primaryColor,
} from '../../../../styling/styleUtils';
import { Button } from './Utils';

const Toggle3DModeButton = () => {
  const [is3dModeOn, setIs3dModeOn] = useState<boolean>(false);
  const mapContext = useMapContext();

  const toggle3dMode = () => {
    if (mapContext.intialized) {
      setIs3dModeOn(mapContext.toggle3dTerrain);
    }
  };

  const containerStyles: React.CSSProperties | undefined = is3dModeOn ? {
    backgroundColor: primaryColor,
    color: '#fff',
  } : undefined;

  return (
    <Button
      onClick={toggle3dMode}
      style={containerStyles}
      title='3D Mode'
    >
      3D
    </Button>
  );

};

export default Toggle3DModeButton;
