import { gql } from '@apollo/client';
import {
  Park, PeakList,
} from '../../../types/graphQLTypes';

export const GET_PARK_DETAIL = gql`
  query getPark($id: ID) {
    park(id: $id) {
      id
      name
      center
      bbox
      locationText
      locationTextShort
      areaSquareMeters
      trailMileage
      numCampsitesBackcountry
      numCampsitesCar
      numMountains
      protectTitle
      landType
      operator
      ownership
      trailBaggingList {
        id
      }
    }
  }
`;

export interface QuerySuccessResponse {
  park: null | {
    id: Park['id'];
    __typename: string;
    name: Park['name'];
    center: Park['center'];
    bbox: Park['bbox'];
    locationText: Park['locationText'];
    locationTextShort: Park['locationTextShort'];
    areaSquareMeters: Park['areaSquareMeters'];
    trailMileage: Park['trailMileage'];
    numCampsitesBackcountry: Park['numCampsitesBackcountry'];
    numCampsitesCar: Park['numCampsitesCar'];
    numMountains: Park['numMountains'];
    protectTitle: Park['protectTitle'];
    landType: Park['landType'];
    operator: Park['operator'];
    ownership: Park['ownership'];
    trailBaggingList: null | {
      id: PeakList['id'];
    }
  };
}

export interface QueryVariables {
  id: string | null;
}
