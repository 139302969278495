import React from 'react';
import styled from 'styled-components/macro';
import useUsersProgress from '../../../../queries/users/useUsersProgress';
import {CoreItem, CoreItems} from '../../../../types/itemTypes';
import LoggedTripButton from './LoggedTripButton';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.875rem 0;
  padding-left: 0.25rem;

  @media (max-width: 500px) {
    padding-left: 0;
  }
`;

interface Props {
  id: string;
  item: CoreItem;
}

const LoggedTrips = (props: Props) => {
  const {id, item} = props;
  const {loading, error,  data, variables} = useUsersProgress();
  let output: React.ReactElement<any> | null;
  if (loading) {
    output = null;
  } else if (error !== undefined) {
    console.error(error);
    output = (<p><small>{error.message}</small></p>);
  } else if (data !== undefined) {
    const {progress} = data;
    if (progress) {
      const field = item + 's' as unknown as CoreItems;
      const target = progress[field] !== null
        ? (progress[field] as any).find((d: any) => d[item + 'Id'] === id) : undefined;
      output = (
        <LoggedTripButton
          id={id}
          completedDates={target && target.dates ? target.dates : []}
          type={item}
          noUser={false}
        />
      );
    } else if (variables && variables.userId === null) {
      output = (
        <LoggedTripButton
          id={id}
          completedDates={[]}
          type={item}
          noUser={true}
        />
      );
    } else {
      output = null;
    }
  } else {
    output = null;
  }
  return (
    <Root>
      {output}
    </Root>
  );
};

export default LoggedTrips;
