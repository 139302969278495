import React, {useCallback} from 'react';
import { useUpdateCampsiteFlag } from '../../../../../queries/campsites/flagCampsite';
import { useUpdatePeakListFlag } from '../../../../../queries/lists/flagPeakList';
import { useUpdateMountainFlag } from '../../../../../queries/mountains/flagMountain';
import { useUpdateParkFlag } from '../../../../../queries/parks/flagPark';
import { useUpdateTrailFlag } from '../../../../../queries/trails/flagTrail';
import { useUpdateViewpointFlag } from '../../../../../queries/viewpoints/flagViewpoint';
import { useUpdateWaterpointFlag } from '../../../../../queries/waterpoints/flagWaterpoint';
import {AggregateItem, AuxiliaryItem, CoreItem} from '../../../../../types/itemTypes';
import { failIfValidOrNonExhaustive } from '../../../../../Utils';
import Modal from './Modal';

interface Props {
  name: string;
  id: string;
  type: CoreItem | AggregateItem | AuxiliaryItem;
  onClose: () => void;
}

const FlagMountain = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updateMountainFlag = useUpdateMountainFlag();
  const onSave = useCallback((flag: string) => {
    updateMountainFlag({variables: {id, flag}});
  }, [id, updateMountainFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

const FlagTrail = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updateTrailFlag = useUpdateTrailFlag();
  const onSave = useCallback((flag: string) => {
    updateTrailFlag({variables: {id, flag}});
  }, [id, updateTrailFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

const FlagCampsite = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updateCampsiteFlag = useUpdateCampsiteFlag();
  const onSave = useCallback((flag: string) => {
    updateCampsiteFlag({variables: {id, flag}});
  }, [id, updateCampsiteFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};
const FlagViewpoint = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updateViewpointFlag = useUpdateViewpointFlag();
  const onSave = useCallback((flag: string) => {
    updateViewpointFlag({variables: {id, flag}});
  }, [id, updateViewpointFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};
const FlagWaterpoint = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updateWaterpointFlag = useUpdateWaterpointFlag();
  const onSave = useCallback((flag: string) => {
    updateWaterpointFlag({variables: {id, flag}});
  }, [id, updateWaterpointFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

const FlagPeakList = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updatePeakListFlag = useUpdatePeakListFlag();
  const onSave = useCallback((flag: string) => {
    updatePeakListFlag({variables: {id, flag}});
  }, [id, updatePeakListFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

const FlagPark = (props: Props) => {
  const {name, id, type, onClose} = props;

  const updateParkFlag = useUpdateParkFlag();
  const onSave = useCallback((flag: string) => {
    updateParkFlag({variables: {id, flag}});
  }, [id, updateParkFlag]);

  return (
    <Modal
      id={id}
      name={name}
      type={type}
      onSave={onSave}
      onClose={onClose}
    />
  );
};

const FlagModal = (props: Props) => {
  const { type, id, name, onClose } = props;

  if (type === CoreItem.mountain) {
    return (
      <FlagMountain
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else if (
    type === CoreItem.trail ||
    type === AuxiliaryItem.trailhead ||
    type === AggregateItem.parentTrail
  ) {
    return (
      <FlagTrail
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else if (type === CoreItem.campsite) {
    return (
      <FlagCampsite
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else if (type === AuxiliaryItem.viewpoint) {
    return (
      <FlagViewpoint
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else if (type === AuxiliaryItem.waterpoint) {
    return (
      <FlagWaterpoint
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else if (type === AuxiliaryItem.park) {
    return (
      <FlagPark
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else if (type === AggregateItem.list) {
    return (
      <FlagPeakList
        id={id}
        name={name}
        type={type}
        onClose={onClose}
      />
    );
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid type ' + type);
  }
};

export default FlagModal;
