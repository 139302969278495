import styled from 'styled-components/macro';
import {mobileSize} from '../Utils';
import { baseFontSize, lightBorderColor, standardContainerPadding } from './styleUtils';

export const gridLines = {
  // Horizontal Grid Lines
  pageTop: 'wilderListGlobalGridPageTop',
  bannerTop: 'wilderListGlobalGridBannerTop',
  bannerBottom: 'wilderListGlobalGridBannerBottom',
  headerTop: 'wilderListGlobalGridHeaderTop',
  headerBottom: 'wilderListGlobalGridHeaderBottom',
  contentTop: 'wilderListGlobalGridContentTop',
  contentHeaderTop: 'wilderListGlobalGridContentHeaderTop',
  contentHeaderBottom: 'wilderListGlobalGridContentHeaderBottom',
  contentBottom: 'wilderListGlobalGridContentBottom',
  footerTop: 'wilderListGlobalGridFooterTop',
  footerbottom: 'wilderListGlobalGridFooterbottom',
  pageBottom: 'wilderListGlobalGridPageBottom',
  // Vertical Grid Lines
  verticalNavSpace: 'wilderListGlobalGridVerticalNavSpace',
  contentSpace: 'wilderListGlobalGridContentSpace',
  mapSpace: 'wilderListGlobalGridMapSpace',
};

export const navHeight = 37; // in px

const navWidthRem = 5; // in rem
const columnGapRem = 1; // in rem
export const navWidthPx = navWidthRem * baseFontSize; // in default px
export const columnGapPx = columnGapRem * baseFontSize; // in default px
export const contentColumnMin = 350; // in px
export const contentColumnIdeal = 40; // in vw
export const contentColumnMax = 600; // in px

export const EXTENDED_CONTENT_CLASSNAME = 'extended-content-body';

export const GRID_ROOT_ID = 'grid-content-root-id';
const Grid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows:
    [${gridLines.pageTop} ${gridLines.bannerTop}] auto
    [${gridLines.bannerBottom} ${gridLines.headerTop}] 1rem
    [${gridLines.headerBottom} ${gridLines.contentHeaderTop}] auto
    [${gridLines.contentHeaderBottom} ${gridLines.contentTop}] 1fr
    [${gridLines.contentBottom} ${gridLines.footerTop}] 2rem
    [${gridLines.footerbottom} ${gridLines.pageBottom}];
  grid-template-columns:
    [${gridLines.verticalNavSpace}] ${navWidthRem}rem
    [${gridLines.contentSpace}] clamp(${contentColumnMin}px, ${contentColumnIdeal}vw, ${contentColumnMax}px)
    [${gridLines.mapSpace}] 1fr;
  grid-column-gap: ${columnGapRem}rem;

  @media(min-width: ${mobileSize + 1}px) {
    &.${EXTENDED_CONTENT_CLASSNAME} {
      grid-template-columns:
        [${gridLines.verticalNavSpace}] ${navWidthRem}rem
        [${gridLines.contentSpace}] min(65vw, 800px)
        [${gridLines.mapSpace}] 1fr;
    }
  }

  @media(max-width: ${mobileSize}px) {
    height: auto;
    min-height: 100vh;
    grid-template-rows:
      [${gridLines.pageTop} ${gridLines.bannerTop}] auto
      [${gridLines.bannerBottom} ${gridLines.headerTop}] auto
      [${gridLines.headerBottom} ${gridLines.contentHeaderTop}] auto
      [${gridLines.contentHeaderBottom} ${gridLines.contentTop}] 1fr
      [${gridLines.contentBottom} ${gridLines.footerTop}] auto
      [${gridLines.footerbottom} ${gridLines.pageBottom}];
    grid-template-columns: [${gridLines.contentSpace} ${gridLines.mapSpace}] 1fr;
    grid-column-gap: 0;
  }


`;

export const NavigationContainer = styled.div`
  grid-column: ${gridLines.verticalNavSpace} / ${gridLines.contentSpace};
  grid-row: ${gridLines.contentTop} / ${gridLines.contentBottom};
  position: relative;

  @media(max-width: ${mobileSize}px) {
    grid-row: ${gridLines.headerTop} / ${gridLines.headerBottom};
    grid-column: 1 / -1;
  }
`;

export const PreContentHeaderFull = styled.div`
  grid-row: ${gridLines.pageTop} / ${gridLines.contentHeaderTop};
  grid-column: 1 / -1;
  position: relative;
`;

const contentGridLines = {
  header: 'wilderListContentGridHeader',
  body: 'wilderListContentGridBody',
};

export const mobileTopPadding = 33; // in px

export const ContentHeader = styled.div<{$user: boolean}>`
  grid-row: ${contentGridLines.header};
  pointer-events: all;

  @media(max-width: ${mobileSize}px) {
    pointer-events: none;
    padding-top: ${mobileTopPadding}px;
  }
`;

// Only use for specific style overrides in child components
export const CONTENT_BODY_ID = 'main-content-body-id';

export const ContentBody = styled.div`
  grid-row: ${contentGridLines.body};
  padding: 0.75rem ${standardContainerPadding} ${standardContainerPadding};
  box-shadow: 0px 0px 3px -1px #b5b5b5;
  border: solid 1px ${lightBorderColor};
  border-top: none;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: auto;
  pointer-events: all;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .1);
  }

  @media(max-width: ${mobileSize}px) {
    height: auto;
    min-height: 100vh;
    padding-top: 1rem;
    padding-bottom: 150px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    box-shadow: none;
  }
`;

export const ContentContainer = styled.div`
  grid-column: ${gridLines.contentSpace} / ${gridLines.mapSpace};
  grid-row: ${gridLines.contentTop} / ${gridLines.contentBottom};
  padding: 0 5px 5px;
  position: relative;
  overflow: hidden;
  display: grid;
  pointer-events: none;
  grid-template-rows:
    [${contentGridLines.header}] auto
    [${contentGridLines.body}] 1fr;

  @media(max-width: ${mobileSize}px) {
    padding: 0;
  }
`;

export const Root = styled(Grid)`
  /* Set overflow to hidden to ensure scroll bars never show up: */
  overflow: hidden;
`;
