import mapboxgl from 'mapbox-gl';

export enum AccessOverlay {
  bike = 'bike',
  horse = 'horse',
}

let accessState: AccessOverlay | null = null;
export const getAccessState = () => accessState;

const setAccessOverlay = (map: mapboxgl.Map, value: AccessOverlay | null): AccessOverlay | null => {
  if (accessState === AccessOverlay.horse) {
    map.setLayoutProperty('horse-access', 'visibility', 'none');
  }
  if (accessState === AccessOverlay.bike) {
    map.setLayoutProperty('bike-access', 'visibility', 'none');
  }
  if (value === AccessOverlay.horse) {
    map.setLayoutProperty('horse-access', 'visibility', 'visible');
  }
  if (value === AccessOverlay.bike) {
    map.setLayoutProperty('bike-access', 'visibility', 'visible');
  }
  accessState = value;
  return accessState;
};

export default setAccessOverlay;
