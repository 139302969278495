import { useMutation, useQuery } from '@apollo/client';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { GET_USERS_SAVED_PARKS, MutationVariables, REMOVED_SAVED_PARK_FROM_USER, SAVE_PARK_TO_USER, SuccessResponse, Variables } from './query';

export const useSavedParks = () => {
  const user = useCurrentUser();
  const userId = user && user._id ? user._id : null;

  const response = useQuery<SuccessResponse, Variables>(
    GET_USERS_SAVED_PARKS, {variables: {userId}},
  );

  const [saveParkToUser] = useMutation<SuccessResponse, MutationVariables>(
    SAVE_PARK_TO_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_PARKS, variables: {userId}}],
    });

  const [removeSavedParkFromUser] = useMutation<SuccessResponse, MutationVariables>(
    REMOVED_SAVED_PARK_FROM_USER, {
      refetchQueries: [{query: GET_USERS_SAVED_PARKS, variables: {userId}}],
    });

  return {response, saveParkToUser, removeSavedParkFromUser};
};
