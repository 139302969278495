import { orderBy } from 'lodash';
import React from 'react';
import { useState } from 'react';
import useDirectionsOrigin from '../../../../../hooks/directions/useDirectionsOrigin';
import useRoutesToPoint from '../../../../../hooks/servicesHooks/pathfinding/useRoutesToPoint';
import useFluent from '../../../../../hooks/useFluent';
import { CenteredHeader, EmptyBlock, LargeScrollContainer, MediumScrollContainer } from '../../../../../styling/sharedContentStyles';
import { ButtonSecondary } from '../../../../../styling/styleUtils';
import { Coordinate } from '../../../../../types/graphQLTypes';
import LoadingSimple from '../../../LoadingSimple';
import Modal, {ButtonWrapper} from '../../../Modal';
import SetOrigin from '../../directions/SetOrigin';
import TrailheadBlock from './TrailheadBlock';

interface Props {
  destination: Coordinate;
  destinationName: string;
}

const TrailheadsWithRoutes = (props: Props) => {
  const {
    destination, destinationName,
  } = props;
  const {location: {data: userLocation}, location, getUsersLocation, updateLocation} = useDirectionsOrigin();
  const {loading, error, data} = useRoutesToPoint({
    destination: 'trailheads',
    lat: destination[1],
    lng: destination[0],
    drivingOriginLat: userLocation ? userLocation.coordinates[1] : undefined,
    drivingOriginLng: userLocation ? userLocation.coordinates[0] : undefined,
    returnMaxPoints: true,
    skipElevation: true,
  });
  const getString = useFluent();
  const [isDirectionsOriginModalOpen, setDirectionsOriginModalOpen] = useState<boolean>(false);
  const onEditDirections = () => {
    updateLocation(null);
    setDirectionsOriginModalOpen(true);
  };
  const onClose = () => setDirectionsOriginModalOpen(false);
  const actions = (
    <ButtonWrapper>
      <ButtonSecondary onClick={onClose} mobileExtend={true}>
        {getString('global-text-value-modal-close')}
      </ButtonSecondary>
    </ButtonWrapper>
  );
  const setOriginModal = isDirectionsOriginModalOpen && !userLocation ? (
    <Modal
      onClose={onClose}
      width={'500px'}
      height={'auto'}
      actions={actions}
      contentStyles={{padding: 0, marginBottom: '-1rem', overflow: 'visible'}}
    >
      <SetOrigin
        error={location.error}
        loading={location.loading}
        updateLocation={updateLocation}
        getUsersLocation={getUsersLocation}
      />
      <div style={{width: 500}} />
    </Modal>
  ) : null;
  const ScrollContainer = userLocation ? LargeScrollContainer : MediumScrollContainer;
  let output: React.ReactElement<any> | null;
  if (loading) {
    output = (
      <ScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            <LoadingSimple />
            <p>Finding nearby routes</p>
          </CenteredHeader>
        </EmptyBlock>
      </ScrollContainer>
    );
  } else if (error !== undefined) {
    console.error(error);
    output = (
      <ScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            {getString('global-text-value-no-results-found')}
          </CenteredHeader>
        </EmptyBlock>
      </ScrollContainer>
    );
  } else if (data) {
    const features = data.features;
    const trailheadRoutes = orderBy(features, f => {
      if (f.properties.routeLength) {
        return f.properties.routeLength;
      }
      if (f.properties.crowFliesDistance) {
        return f.properties.crowFliesDistance + 100;
      }
      return 200;
    }).map(feature => {
      return (
        <TrailheadBlock
          key={feature.properties.destination._id}
          feature={feature}
          start={userLocation}
          end={{name: destinationName}}
          onEditDirections={onEditDirections}
        />
      );
    });
    if (trailheadRoutes.length === 0) {
      output = (
        <ScrollContainer hideScrollbars={false} $noScroll={true}>
          <EmptyBlock>
            <CenteredHeader>
              {getString('global-text-value-no-items-found', {type: 'routes'})}
            </CenteredHeader>
          </EmptyBlock>
        </ScrollContainer>
      );
    } else {
      output = (
        <ScrollContainer $noScroll={features.length < 2} hideScrollbars={false}>
          {trailheadRoutes}
        </ScrollContainer>
      );
    }
  } else {
    output = null;
  }
  return (
    <>
      {output}
      {setOriginModal}
    </>
  );
};

export default TrailheadsWithRoutes;
