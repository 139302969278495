import React from 'react';
import styled from 'styled-components/macro';
import useDisabledOnLoad from '../../../../hooks/useDisableOnLoad';
import useFluent from '../../../../hooks/useFluent';
import {
  ButtonSecondary,
} from '../../../../styling/styleUtils';
import PanelDirections from '../../../sharedComponents/detailComponents/directions/PanelDirections';
import Modal from '../../../sharedComponents/Modal';

const Root = styled.div`
  width: 500px;

  @media(max-width: 600px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

interface Props {
  onClose: () => void;
  latitude: number;
  longitude: number;
}

const DrivingDirections = ({latitude, longitude, onClose}: Props) => {
  const getString = useFluent();
  // disable close for a brief period to prevent conflicting click events on mobile
  const disableCloseButton = useDisabledOnLoad({ touchDeviceOnly: true });

  const actions = (
    <ButtonWrapper>
      <ButtonSecondary
        onClick={disableCloseButton ? undefined : onClose}
        mobileExtend={true}
        disabled={disableCloseButton}
      >
        {getString('global-text-value-modal-close')}
      </ButtonSecondary>
    </ButtonWrapper>
  );

  return (
    <Modal
      onClose={disableCloseButton ? () => null : onClose}
      width={'500px'}
      height={'auto'}
      actions={actions}
      contentStyles={{padding: 0, marginBottom: '-1rem', overflow: 'visible'}}
    >
      <Root>
        <PanelDirections
          destination={[longitude, latitude]}
          considerDirect={true}
        />
      </Root>
    </Modal>
  );

};

export default DrivingDirections;
