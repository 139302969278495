import React from 'react';
import useFluent from '../../../hooks/useFluent';
import {useBasicViewpointDetails} from '../../../queries/viewpoints/useBasicViewpointDetails';
import { AuxiliaryItem } from '../../../types/itemTypes';
import CommentsPanel from '../../sharedComponents/detailComponents/comments';
import ExploreNearby from '../../sharedComponents/detailComponents/exploreNearby';
import GettingThere, { Via } from '../../sharedComponents/detailComponents/gettingThere';
import Weather from '../../sharedComponents/detailComponents/weather';

interface Props {
  id: string;
}

const Content = (props: Props) => {
  const  {id} = props;
  const {loading, error, data} = useBasicViewpointDetails(id);

  const getString = useFluent();

  if (loading) {
    return null;
  } else if (error !== undefined) {
    return <p>{error.message}</p>;
  } else if (data !== undefined && data.viewpoint) {
    const {viewpoint} = data;
    const {locationTextShort, location} = viewpoint;
    const name = viewpoint.name ? viewpoint.name : getString('global-text-value-viewpoint');
    let via: Via;
    if (viewpoint.trailAccessMiles !== null && viewpoint.trailAccessMiles < 0.05) {
      via = Via.Trails;
    } else if (viewpoint.roadAccessMiles !== null && viewpoint.roadAccessMiles < 0.1) {
      via = Via.Driving;
    } else if (viewpoint.trailAccessMiles !== null && viewpoint.trailAccessMiles < 0.2) {
      via = Via.Trails;
    } else if (viewpoint.roadAccessMiles !== null && viewpoint.roadAccessMiles < 0.2) {
      via = Via.Driving;
    } else {
      via = Via.Trails;
    }
    return (
      <>
        <Weather
          forecastTabs={[
            {title: getString('weather-forecast-forecast'), location},
          ]}
          snowReport={{location, stateAbbr: locationTextShort}}
        />
        <GettingThere
          via={via}
          name={name}
          location={location}
        />
        <ExploreNearby
          location={location}
          name={name}
          id={id}
        />
        <CommentsPanel
          name={name}
          forId={id}
          forType={AuxiliaryItem.viewpoint}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Content;
