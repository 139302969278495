import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_VIEWPOINT_NOTE,
  EDIT_VIEWPOINT_NOTE,
  GET_VIEWPOINT_NOTE,
  QuerySuccessResponse,
  QueryVariables,
  Variables,
  ViewpointNoteSuccess,
} from './query';

export const useViewpointNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_VIEWPOINT_NOTE, {variables});

export const useAddViewpointNote = () => {
  const [addViewpointNote] = useMutation<ViewpointNoteSuccess, Variables>(ADD_VIEWPOINT_NOTE);
  return addViewpointNote;
};
export const useEditViewpointNote = () => {
  const [editViewpointNote] = useMutation<ViewpointNoteSuccess, Variables>(EDIT_VIEWPOINT_NOTE);
  return editViewpointNote;
};
