import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useOfflineContext } from '../../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFluent from '../../../../hooks/useFluent';
import {Routes, YesNo} from '../../../../routing/routes';
import useQueryParams from '../../../../routing/useQueryParams';
import {
  addQueryParam,
  addTripReportLink,
  campsiteDetailLink,
  mountainDetailLink,
  trailDetailLink,
} from '../../../../routing/Utils';
import {
  CompleteText,
  lightBaseColor,
  lightBorderColor,
  primaryColor,
  ShadowButtonLink,
} from '../../../../styling/styleUtils';
import { CoreItem, CoreItems } from '../../../../types/itemTypes';
import {
  formatDate,
  getDates,
} from '../../../../utilities/dateUtils';
import SignUpModal from '../../SignUpModal';

const ButtonContainer = styled.div`
  box-shadow: 0px 0px 3px -1px #b5b5b5;
  border-radius: 12px;
  width: min-content;
  margin: 0.5rem auto 0;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const ButtonBase = styled(ShadowButtonLink)`
  box-shadow: none;
  white-space: nowrap;
  border-radius: 0;
  padding: 0.45rem;
  flex-grow: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${primaryColor};
  margin-right: 0.3rem;
`;

const ViewTripsButton = styled(ButtonBase)`
  border-right: solid 1px ${lightBorderColor};
`;
const ViewTripsButtonActive = styled(ButtonBase)`
  border-right: solid 1px ${lightBorderColor};
  background-color: ${primaryColor};
  color: #fff;

  ${Icon} {
    color: #fff;
  }

  &:hover {
    background-color: ${primaryColor};
    color: #fff;
  }
`;

const TripsText = styled.em`
  font-size: 0.8rem;
  color: ${lightBaseColor};
  display: block;
`;

interface Props {
  id: string;
  completedDates: string[];
  type: CoreItem;
  noUser: boolean;
}

const LoggedTripButton = (props: Props) => {
  const { id, completedDates, type, noUser } = props;
  const [isShowSignUpModalVisible, setIsShowSignUpModalOpen] = useState<boolean>(false);
  const getString = useFluent();
  const { trips } = useQueryParams();
  const user = useCurrentUser();
  const {offline} = useOfflineContext();

  let returnLink: string;
  if (type === CoreItem.mountain) {
    returnLink = mountainDetailLink(id);
  } else if (type === CoreItem.campsite) {
    returnLink = campsiteDetailLink(id);
  } else if (type === CoreItem.trail) {
    returnLink = trailDetailLink(id);
  } else {
    returnLink = Routes.YourTrips;
  }

  const field = type + 's' as CoreItems;

  const addTripReportUrl = addTripReportLink({
    refpath: returnLink,
    [field]: [id],
  });

  let latestDateText: React.ReactElement<any> | null;
  let yourTripsButton: React.ReactElement<any> | null;
  if (completedDates && completedDates.length) {
    const dates = getDates(completedDates);
    const latestDate = dates[dates.length - 1];
    const { day, month, year } = latestDate;
    let textDate: string;
    if (!isNaN(month) && !isNaN(year)) {
      if (!isNaN(day)) {
        textDate = getString('global-formatted-text-date-compact', {
          day, month, year: year.toString(),
        });
      } else {
        textDate = getString('global-formatted-text-month-year-compact', {
          month, year: year.toString(),
        });
      }
    } else {
      textDate = formatDate(latestDate);
    }
    latestDateText = (
      <TripsText>
        <CompleteText>
          {getString('item-detail-generic-last-trip')} {textDate}
        </CompleteText>
      </TripsText>
    );

    const yourTripsButtonLink = user && user._id && trips === YesNo.Yes
      ? returnLink
      : addQueryParam(returnLink, { trips: YesNo.Yes });
    const [pathname, search] = yourTripsButtonLink.split('?');

    const ViewTripsButtonRoot = user && user._id && trips === YesNo.Yes
      ? ViewTripsButtonActive
      : ViewTripsButton;

    yourTripsButton = (
      <ViewTripsButtonRoot to={{
          pathname,
          search,
          state: {noScrollOnLoad: true},
        }}
      >
        <Icon icon={faHistory} />
        {getString('global-text-value-your-trips')} ({dates.length})
      </ViewTripsButtonRoot>
    );
  } else {
    latestDateText = (
      <TripsText>{getString('item-detail-generic-no-trips')}</TripsText>
    );
    yourTripsButton = null;
  }

  const signUpModal = noUser && isShowSignUpModalVisible ? (
    <SignUpModal
      onCancel={() => setIsShowSignUpModalOpen(false)}
    />
  ) : null;

  const showSignUpModal = noUser ? () => setIsShowSignUpModalOpen(true) : undefined;

  const tripButtons = !offline ? (
    <ButtonContainer>
      {yourTripsButton}
      <ButtonBase to={noUser ? '#' : addTripReportUrl} onClick={showSignUpModal}>
        <Icon icon='calendar-alt' />
        {getString('global-text-value-modal-log-a-trip')}
      </ButtonBase>
    </ButtonContainer>
  ) : null;

  return (
    <>
      <div>
        {latestDateText}
        {tripButtons}
      </div>
      {signUpModal}
    </>
  );
};

export default LoggedTripButton;
