import { faCloudDownloadAlt, faCog, faLock, faQuestionCircle, faSignOutAlt, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import useFluent from '../../../hooks/useFluent';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { Routes } from '../../../routing/routes';
import { userProfileLink } from '../../../routing/Utils';
import { PermissionTypes, User } from '../../../types/graphQLTypes';
import { mobileSize } from '../../../Utils';
import DropoutList, { MenuAnchor, MenuIcon, MenuLink } from './DropoutList';

interface Props {
  user: {
    _id: User['_id']
    name: User['name']
    permissions: User['permissions'],
  } | null;
  closeUserMenu: () => void;
}

const UserList = ({user, closeUserMenu}: Props) => {
  const windowWidth = useWindowWidth();
  const getString = useFluent();
  const appInfoLinks = windowWidth <= mobileSize ? (
    <>
      <MenuLink to={Routes.About}>
        <MenuIcon icon={faQuestionCircle} />
        {getString('header-text-menu-item-about')}
      </MenuLink>
    </>
  ) : null;
  if (user) {
    const adminLink = user.permissions === PermissionTypes.admin ? (
      <MenuLink to={Routes.Admin}>
        <MenuIcon icon={faLock} />
        Admin Panel
      </MenuLink>
    ) : null;
    return (
      <DropoutList
        closeMenu={closeUserMenu}
      >
        <MenuLink to={userProfileLink(user._id)}>
          <MenuIcon icon={faUser} />
          {user.name}
        </MenuLink>
        {windowWidth <= mobileSize && (
          <>
            <MenuLink to={Routes.SearchUsers}>
              <MenuIcon icon={faUserFriends} />
              {getString('header-text-menu-item-friends')}
            </MenuLink>
            <MenuLink
              to={Routes.OfflineMaps}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                if (!navigator?.serviceWorker?.controller) {
                  e.preventDefault();
                  window.location.href = Routes.OfflineMaps;
                }
              }}
            >
              <MenuIcon icon={faCloudDownloadAlt} />
              {getString('offline-maps-title')}
            </MenuLink>
          </>
        )}
        <MenuLink to={Routes.UserSettings}>
          <MenuIcon icon={faCog} />
          {getString('header-text-menu-settings')}
        </MenuLink>
        {appInfoLinks}
        {adminLink}
        <MenuAnchor href='/api/logout'>
          <MenuIcon icon={faSignOutAlt} />
          {getString('header-text-menu-item-logout')}
        </MenuAnchor>
      </DropoutList>
    );
  } else {
    return null;
  }
};

export default UserList;
