import { gql } from '@apollo/client';
import {
  Park,
  User,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_PARKS = gql`
  query GetSavedParks($userId: ID) {
    user(id: $userId) {
      id
      savedParks {
        id
        name
        center
        bbox
        locationText
        locationTextShort
        areaSquareMeters
        trailMileage
        numCampsitesBackcountry
        numCampsitesCar
        numMountains
        protectTitle
        landType
        operator
        ownership
        userNote
      }
    }
  }
`;

export const SAVE_PARK_TO_USER = gql`
  mutation SaveParkToUser($userId: ID!, $parkId: ID!) {
    user: saveParkToUser(userId: $userId, parkId: $parkId) {
      id
      savedParks {
        id
        name
        center
        bbox
        locationText
        locationTextShort
        areaSquareMeters
        trailMileage
        numCampsitesBackcountry
        numCampsitesCar
        numMountains
        protectTitle
        landType
        operator
        ownership
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_PARK_FROM_USER = gql`
  mutation RemoveSaveParkToUser($userId: ID!, $parkId: ID!) {
    user: removeSavedParkFromUser(userId: $userId, parkId: $parkId) {
      id
      savedParks {
        id
        name
        center
        bbox
        locationText
        locationTextShort
        areaSquareMeters
        trailMileage
        numCampsitesBackcountry
        numCampsitesCar
        numMountains
        protectTitle
        landType
        operator
        ownership
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  parkId: string;
}

export interface ParkDatum {
  id: Park['id'];
  __typename: string;
  name: Park['name'];
  center: Park['center'];
  bbox: Park['bbox'];
  locationText: Park['locationText'];
  locationTextShort: Park['locationTextShort'];
  areaSquareMeters: Park['areaSquareMeters'];
  trailMileage: Park['trailMileage'];
  numCampsitesBackcountry: Park['numCampsitesBackcountry'];
  numCampsitesCar: Park['numCampsitesCar'];
  numMountains: Park['numMountains'];
  protectTitle: Park['protectTitle'];
  landType: Park['landType'];
  operator: Park['operator'];
  ownership: Park['ownership'];
  userNote: Park['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedParks: (null | ParkDatum)[];
  };
}
