import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import useFluent from '../../../../hooks/useFluent';
import {
  isCondition,
} from '../../../../queries/tripReports/useLatestTripReports';
import {
  CampsiteDatum,
  MountainDatum,
  TrailDatum,
} from '../../../../queries/users/useAllTripReports/query';
import { tripReportDetailLink } from '../../../../routing/Utils';
import {
  Condition,
  ExternalLink,
  ReportBody,
  ReportHeader,
  Section,
  SectionTitle,
} from '../../../../styling/sharedContentStyles';
import {
  CollapsedParagraph,
  PreFormattedParagraph,
  SemiBold,
  Subtext,
} from '../../../../styling/styleUtils';
import { TripReport, TripReportPrivacy } from '../../../../types/graphQLTypes';
import {
  parseDate,
} from '../../../../utilities/dateUtils';
import getTripName from '../../../../utilities/getTripName';
import {
  isValidURL,
} from '../../../../Utils';
import { CommentRoot } from '../comments/Comment';
import StaticStars from '../comments/StaticStars';

const ReportRoot = styled(CommentRoot)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Text = styled(PreFormattedParagraph)`
  margin: 0;
`;

const ReportDateRoot = styled.div`
  margin-bottom: 0.5rem;
`;

interface Props {
  report: TripReport;
}

const TripReportBlock = ({report}: Props) => {
  const getString = useFluent();

  const allConditionsArray: string[] = [];
  Object.keys(report).forEach(function(key: string) {
    if (isCondition(key) && report[key as keyof TripReport]) {
      allConditionsArray.push(getString('trip-report-condition-name', {key}));
    }
  });
  let conditionsList: React.ReactElement<any> | null;
  if (allConditionsArray.length === 0) {
    conditionsList = null;
  } else if (allConditionsArray.length === 1) {
    conditionsList = (
      <Section>
        <SectionTitle>{getString('trip-report-conditions-title')}: </SectionTitle>
        <Condition>{allConditionsArray[0]}</Condition>
      </Section>
    );
  } else if (allConditionsArray.length === 2) {
    conditionsList = (
      <Section>
        <SectionTitle>{getString('trip-report-conditions-title')}: </SectionTitle>
        <Condition>{allConditionsArray[0]}</Condition>
        {' and '}
        <Condition>{allConditionsArray[1]}</Condition>
      </Section>
    );
  } else {
    const conditionsText: React.ReactElement<any>[] = [];
    allConditionsArray.forEach((condition, j) => {
      if (j === allConditionsArray.length - 2) {
        conditionsText.push(
          <React.Fragment  key={condition + report.id}>
            <Condition>{condition}</Condition>{' and '}
          </React.Fragment>,
        );
      } else if (j === allConditionsArray.length - 1) {
        conditionsText.push(
          <Condition key={condition + report.id}>{condition}</Condition>,
        );
      } else {
        conditionsText.push(
          <React.Fragment  key={condition + report.id}>
            <Condition key={condition + report.id}>{condition}</Condition>{', '}
          </React.Fragment>,
        );
      }
    });
    conditionsList = (
      <Section>
        <SectionTitle>{getString('trip-report-conditions-title')}: </SectionTitle>
        <CollapsedParagraph>{conditionsText}</CollapsedParagraph>
      </Section>
    );
  }

  let notes: React.ReactElement<any> | null;
  if (report.notes && report.notes.length) {
    notes = (
      <Section>
        <SectionTitle>
          {getString('trip-report-notes-title')}
        </SectionTitle>
        <Text>
          {report.notes}
        </Text>
      </Section>
    );
  } else {
    notes = null;
  }

  const link = report.link && isValidURL(report.link) ? (
    <Section>
      <SectionTitle>
        {getString('trip-report-external-link-title')}
      </SectionTitle>
      <ExternalLink href={report.link} rel='noopener noreferrer' target='_blank'>{report.link}</ExternalLink>
    </Section>
  ) : null;

  const authorName = report.privacy !== TripReportPrivacy.Anonymous &&
    report.author !== null && report.author.hideProfileInSearch !== true
      ? report.author.name : getString('global-text-value-generic-user');

  const parsed = parseDate(report.date);
  let formattedDate: string;
  if (!isNaN(parsed.year) && !isNaN(parsed.month) && !isNaN(parsed.day)) {
    formattedDate = getString('global-formatted-text-date', {
      month: parsed.month,
      day: parsed.day,
      year: parsed.year.toString(),
    });
  } else if (!isNaN(parsed.year) && !isNaN(parsed.month)) {
    formattedDate = getString('global-formatted-text-month-year', {
      month: parsed.month,
      year: parsed.year.toString(),
    });
  } else if (!isNaN(parsed.year)) {
    formattedDate = parsed.year.toString();
  } else {
    formattedDate = 'Unknown date';
  }
  let stars: React.ReactElement<any> | null = null;
  if (report.rating) {
    stars = <StaticStars id={report.id} rating={report.rating} />;
  }

  const nonNullMountains = report.mountains ? report.mountains.filter(m => m !== null) as MountainDatum[] : [];
  const nonNullCampsites = report.campsites ? report.campsites.filter(m => m !== null) as CampsiteDatum[] : [];
  const nonNullTrails = report.trails ? report.trails.filter(m => m !== null) as TrailDatum[] : [];
  const tripName = getTripName({
    mountains: nonNullMountains,
    campsites: nonNullCampsites,
    trails: nonNullTrails,
    friends: [],
    getString,
  });
  const tripReportDetailUrl = tripReportDetailLink({
    id: report.id,
  });
  return (
    <ReportRoot>
      <ReportHeader>
        <SemiBold>
          {getString('trip-report-title')}
        </SemiBold>
      </ReportHeader>
      <ReportDateRoot>
        <Subtext>
          <em>On {formattedDate} by {authorName}</em>
        </Subtext>
      </ReportDateRoot>
      <ReportBody>
        <Link to={tripReportDetailUrl}>{tripName}</Link>
        {stars}
        {conditionsList}
        {notes}
        {link}
      </ReportBody>
    </ReportRoot>
  );
};

export default TripReportBlock;
