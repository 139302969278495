import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import useConstrainedMapBbox from '../../hooks/useConstrainedMapBbox';
import useMapCenter from '../../hooks/useMapCenter';
import {
  Campsite,
  Coordinate,
 } from '../../types/graphQLTypes';

const GEO_BOX_CAMPSITES = gql`
  query GeoBoxCampsites(
    $bottomLeftCoordinates: [Float]!,
    $topRightCoordinates: [Float]!,
    $limit: Int!,
  ) {
    campsites: geoBoxCampsites(
      bottomLeftCoordinates: $bottomLeftCoordinates,
      topRightCoordinates: $topRightCoordinates,
      limit: $limit,
    ) {
      id
      name
      type
      locationText
      ownership
      location
      trailAccessMiles
      roadAccessMiles
    }
  }
`;

export interface CampsiteDatum {
  id: Campsite['id'];
  name: Campsite['name'];
  locationText: Campsite['locationText'];
  type: Campsite['type'];
  ownership: Campsite['ownership'];
  location: Campsite['location'];
  trailAccessMiles: Campsite['trailAccessMiles'];
  roadAccessMiles: Campsite['roadAccessMiles'];
}

interface SuccessResponse {
  campsites: CampsiteDatum[];
}

interface Variables {
  bottomLeftCoordinates: Coordinate;
  topRightCoordinates: Coordinate;
  limit: number;
}

export const useGeoBoxCampsites = ({limit}: {limit: number}) => {
  const {bbox, refreshBbox} = useConstrainedMapBbox();
  const mapCenter = useMapCenter();
  useEffect(() => {
    refreshBbox();
  }, [refreshBbox, mapCenter]);
  const {loading, error, data, previousData} = useQuery<SuccessResponse, Variables>(GEO_BOX_CAMPSITES, {
    variables: {
      limit,
      bottomLeftCoordinates: bbox[0],
      topRightCoordinates: bbox[1],
    },
    // there is no need to cache this since the user will rarely have the exact same
    // bbox twice and it will otherise just fill up the cache
    fetchPolicy: 'no-cache',
  });

  let dataToUse: SuccessResponse | undefined;
  if (data !== undefined) {
    dataToUse = data;
  } else if (previousData !== undefined) {
    dataToUse = previousData;
  } else {
    dataToUse = undefined;
  }

  return {loading, error, data: dataToUse};
};
