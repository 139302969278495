import localforage from 'localforage';
import { Coordinate } from '../../../../types/graphQLTypes';
import {SearchResultType} from '../../../../types/itemTypes';
import { convertDMS } from '../../../../Utils';
import {SearchResultDatum} from './Utils';

let searchHistory: SearchResultDatum[] = [];

const LOCALFORAGE_KEY = 'searchHistroyLocalForageKey';
localforage
  .getItem(LOCALFORAGE_KEY)
  .then((data: SearchResultDatum[] | undefined) => {
    if (data) {
      searchHistory = data;
    }
  })
  .catch(error => {
    console.error(error);
  });
export const yourLocationDatumId = 'YOUR_LOCATION_RESULT';
const yourLocationKeywords = 'your location my location this location here';
const yourLocationDatum: SearchResultDatum = {
  id: yourLocationDatumId,
  name: 'Your Location',
  distance: 0,
  coordinates: [-72, 41],
  type: SearchResultType.geolocation,
  locationName: '',
};

const coordinatesDatum = (coordinates: Coordinate): SearchResultDatum => {
  const {lat, long} = convertDMS(coordinates[1], coordinates[0]);
  return {
    id: `COORDS_${coordinates[1]}_${coordinates[1]}`,
    name: `${lat}, ${long}`,
    distance: 0,
    coordinates,
    type: SearchResultType.geolocation,
    locationName: `${coordinates[1].toFixed(4)}, ${coordinates[0].toFixed(4)}`,
  };
};

export const getLocalResults = (query: string, offline?: boolean): SearchResultDatum[] => {
  const specialLocationMatch: SearchResultDatum[] = yourLocationKeywords.toLowerCase().includes(query.toLowerCase().trim())
    ? [yourLocationDatum]
    : [];
  if (/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(query)) {
    const [stringLat, stringLng] = query.replace(/\s/g, '').split(',');
    const lat = parseFloat(stringLat);
    const lng = parseFloat(stringLng);
    if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
      specialLocationMatch.push(
        coordinatesDatum([lng, lat]),
      );
    }
  }
  if (offline) {
    return specialLocationMatch;
  }
  const matchedSearchHistory = searchHistory
    .filter(({name}) => name
      .toLowerCase()
      .includes(query.toLowerCase().trim()))
    .slice(0, specialLocationMatch.length ? 5 : 6);
  return [...matchedSearchHistory, ...specialLocationMatch];
};

export const pushToLocalResults = (value: SearchResultDatum) => {
  searchHistory = searchHistory.filter(d => d.id !== value.id);
  if (value.id !== yourLocationDatumId) {
    searchHistory.unshift(({...value, history: true}));
  }
  if (searchHistory.length > 300) {
    searchHistory.pop();
  }
  localforage.setItem(LOCALFORAGE_KEY, searchHistory);
};
