import axios from 'axios';
import localforage from 'localforage';
import {useCallback, useEffect, useState} from 'react';
import {Coordinate} from '../../types/graphQLTypes';
import getUsersGeolocation from '../../utilities/getUsersGeolocation';

export interface OriginLocation {
  name: string;
  locationName?: string;
  coordinates: Coordinate;
}

interface LocationResponse {
  loading: boolean;
  error: undefined | any;
  data: undefined | null | OriginLocation;
}

const LOCALFORAGE_KEY = 'userOriginLocationData';

interface MapBoxFeature {
  // selected relevant properties returned from feature object after mapbox geocode
  text?: string;
  place_name?: string;

}

const mapboxGeocode = async (lat: number, lng: number) => {
  try {
    const url = encodeURI(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat
      }.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`);
    const response = await axios.get(url);
    if (response.data && response.data.features && response.data.features[0]) {
      return response.data.features[0] as MapBoxFeature;
    }
    return undefined;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const useDirectionsOrigin = () => {
  const [location, setLocation] = useState<LocationResponse>({loading: true, error: undefined, data: undefined});

  const updateLocation = useCallback((newLocation: OriginLocation | null, loading?: boolean, error?: any) => {
    setLocation({
      loading: loading ? loading : false,
      error,
      data: newLocation,
    });
    localforage.setItem(LOCALFORAGE_KEY, newLocation);
  }, [setLocation]);

  useEffect(() => {
    localforage
      .getItem(LOCALFORAGE_KEY)
      .then((data: OriginLocation | undefined) => {
        if (data) {
          setLocation({loading: false, error: undefined, data});
        } else {
          setLocation({loading: false, error: undefined, data: undefined});
        }
      })
      .catch(error => {
        setLocation({loading: false, error, data: undefined});
      });
  }, []);

  const getUsersLocation = useCallback(() => {
    getUsersGeolocation({
      onNotSupported: () => updateLocation(null, false, 'Geolocation is not supported by your browser'),
      onBeforeFetch: () => updateLocation(null, true),
      onError: () => updateLocation(null, false, 'Unable to retrieve your location'),
      onSuccess: (position) => {
        const latitude  = position.coords.latitude;
        const longitude = position.coords.longitude;
        mapboxGeocode(latitude, longitude)
          .then(place => {
            let name = 'Your Location';
            let locationName: string | undefined;
            if (place && place.text) {
              name = place.text;
            }
            if (place && place.place_name) {
              locationName = place.place_name;
            }
            updateLocation({
              name,
              locationName,
              coordinates: [longitude, latitude],
            });
          })
          .catch(() => {
            updateLocation({
              name: 'Your Location',
              coordinates: [longitude, latitude],
            });
          });
      },
    });
  }, [updateLocation]);

  return {location, updateLocation, getUsersLocation};
};

export default useDirectionsOrigin;
