import React from 'react';
import useFluent from '../../../../hooks/useFluent';
import { CenteredHeader, CollapsedScrollContainer, EmptyBlock, SmallScrollContainer } from '../../../../styling/sharedContentStyles';
import LoadingSimple from '../../LoadingSimple';
import DetailSegment from '../DetailSegment';
import Block, {Place} from './Block';

interface Props {
  title: string;
  places: Place[];
  loading?: boolean;
  error?: string;
  icon?: any;
  customIcon?: boolean;
}

const PlacesBlocks = (props: Props) => {
  const {
    places, loading, error, title,
    icon, customIcon,
  } = props;
  const getString = useFluent();
  let output: React.ReactElement<any> | null;
  if (loading) {
    output = (
      <SmallScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            <LoadingSimple />
          </CenteredHeader>
        </EmptyBlock>
      </SmallScrollContainer>
    );
  } else if (error !== undefined) {
    console.error(error);
    output = (
      <SmallScrollContainer hideScrollbars={false} $noScroll={true}>
        <EmptyBlock>
          <CenteredHeader>
            {getString('global-text-value-no-results-found')}
          </CenteredHeader>
        </EmptyBlock>
      </SmallScrollContainer>
    );
  } else if (places && places.length) {
    const placeBlocks = places.map(place => {
      return (
        <Block
          key={place.id}
          id={place.id}
          name={place.name}
          location={place.location}
          type={place.type}
        >
          {place.children}
        </Block>
      );
    });
    if (placeBlocks.length === 0) {
      output = (
        <SmallScrollContainer hideScrollbars={false} $noScroll={true}>
          <EmptyBlock>
            <CenteredHeader>
              {getString('global-text-value-no-results-found')}
            </CenteredHeader>
          </EmptyBlock>
        </SmallScrollContainer>
      );
    } else {
      output = (
        <CollapsedScrollContainer $noScroll={placeBlocks.length < 2} hideScrollbars={false}>
          {placeBlocks}
        </CollapsedScrollContainer>
      );
    }
  } else {
    output = null;
  }
  return (
    <DetailSegment
      title={title}
      panels={[{
        title: '',
        dontShowTitle: true,
        reactNode: output,
      }]}
      panelId={'placesPanel'}
      icon={icon}
      customIcon={customIcon}
    />
  );
};

export default PlacesBlocks;
