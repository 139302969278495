import { gql } from '@apollo/client';
import {
  Mountain,
  User,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_MOUNTAINS = gql`
  query GetSavedMountains($userId: ID) {
    user(id: $userId) {
      id
      savedMountains {
        id
        name
        location
        elevation
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export const SAVE_MOUNTAIN_TO_USER = gql`
  mutation SaveMountainToUser($userId: ID!, $mountainId: ID!) {
    user: saveMountainToUser(userId: $userId, mountainId: $mountainId) {
      id
      savedMountains {
        id
        name
        location
        elevation
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_MOUNTAIN_FROM_USER = gql`
  mutation RemoveSaveMountainToUser($userId: ID!, $mountainId: ID!) {
    user: removeSavedMountainFromUser(userId: $userId, mountainId: $mountainId) {
      id
      savedMountains {
        id
        name
        location
        elevation
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  mountainId: string;
}

export interface MountainDatum {
  id: Mountain['id'];
  __typename: string;
  name: Mountain['name'];
  elevation: Mountain['elevation'];
  location: Mountain['location'];
  locationText: Mountain['locationText'];
  locationTextShort: Mountain['locationTextShort'];
  userNote: Mountain['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedMountains: (null | MountainDatum)[];
  };
}
