import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import {
  GET_TRIP_REPORTS_FOR_USER,
  SuccessResponse,
  Variables,
} from './query';

const useUsersTripReports = (variables: Variables) => {
  const { offline } = useOfflineContext();
  const fetchPolicy: WatchQueryFetchPolicy = offline ? 'cache-only' : 'cache-and-network';
  return useQuery<SuccessResponse, Variables>(GET_TRIP_REPORTS_FOR_USER, { variables, fetchPolicy });
};

export const useSortedMergedTripReports = (variables: Variables) => {
  const { loading, error, data } = useUsersTripReports(variables);
  const sortedTripReports = Object.entries(groupBy(data?.tripReports, 'date'))
      .map(([date, reports]) => {
        return { date, tripReports: reports };
      });
  return {loading, error, data: {sortedTripReports, totalReports: data?.totalReports ?? 0}, count: data?.tripReports?.length ?? 0};
};
