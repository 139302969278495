import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import useConstrainedMapBbox from '../../hooks/useConstrainedMapBbox';
import useMapCenter from '../../hooks/useMapCenter';
import {
  Coordinate,
  Trail,
 } from '../../types/graphQLTypes';

const GEO_BOX_TRAILS = gql`
  query GeoBoxTrails(
    $bottomLeftCoordinates: [Float]!,
    $topRightCoordinates: [Float]!,
    $limit: Int!,
  ) {
    trailheads: geoBoxTrailheads(
      bottomLeftCoordinates: $bottomLeftCoordinates,
      topRightCoordinates: $topRightCoordinates,
      limit: $limit,
    ) {
      id
      name
      type
      locationText
      locationTextShort
      totalTrailMiles
      roadAccessPoint
    }
  }
`;

export interface TrailheadDatum {
  id: Trail['id'];
  name: Trail['name'];
  type: Trail['type'];
  locationText: Trail['locationText'];
  locationTextShort: Trail['locationTextShort'];
  totalTrailMiles: Trail['totalTrailMiles'];
  roadAccessPoint: Trail['roadAccessPoint'];
}

interface SuccessResponse {
  trailheads: TrailheadDatum[];
}

interface Variables {
  bottomLeftCoordinates: Coordinate;
  topRightCoordinates: Coordinate;
  limit: number;
}

export const useGeoBoxTrailheads = ({limit}: {limit: number}) => {
  const {bbox, refreshBbox} = useConstrainedMapBbox();
  const mapCenter = useMapCenter();
  useEffect(() => {
    refreshBbox();
  }, [refreshBbox, mapCenter]);
  const {loading, error, data, previousData} = useQuery<SuccessResponse, Variables>(GEO_BOX_TRAILS, {
    variables: {
      limit,
      bottomLeftCoordinates: bbox[0],
      topRightCoordinates: bbox[1],
    },
    // there is no need to cache this since the user will rarely have the exact same
    // bbox twice and it will otherise just fill up the cache
    fetchPolicy: 'no-cache',
  });

  let dataToUse: SuccessResponse | undefined;
  if (data !== undefined) {
    dataToUse = data;
  } else if (previousData !== undefined) {
    dataToUse = previousData;
  } else {
    dataToUse = undefined;
  }

  return {loading, error, data: dataToUse, bbox};
};
