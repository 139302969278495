import {
  faBolt,
  faCloud,
  faCloudRain,
  faCloudShowersHeavy,
  faCloudSun,
  faCloudSunRain,
  faExclamationTriangle,
  faIcicles,
  faSmog,
  faSnowflake,
  faSun,
  faTemperatureHigh,
  faTemperatureLow,
  faWater,
  faWind,
} from '@fortawesome/free-solid-svg-icons';

const types = [
  {value: 'tornado', icon: faExclamationTriangle},
  {value: 'hurricane', icon: faExclamationTriangle},
  {value: 'patchy fog', icon: faSmog},
  {value: 'dense fog', icon: faSmog},
  {value: 'areas fog', icon: faWater},
  {value: 'blowing snow', icon: faSnowflake},
  {value: 'blowing dust', icon: faWind},
  {value: 'blowing sand', icon: faWind},
  {value: 'patchy haze', icon: faSmog},
  {value: 'areas haze', icon: faSmog},
  {value: 'patchy ice crystals', icon: faSnowflake},
  {value: 'areas ice crystals', icon: faSnowflake},
  {value: 'patchy ice fog', icon: faSnowflake},
  {value: 'areas ice fog', icon: faSnowflake},
  {value: 'patchy freezing fog', icon: faSnowflake},
  {value: 'areas freezing fog', icon: faSnowflake},
  {value: 'freezing fog', icon: faSnowflake},
  {value: 'freezing spray', icon: faSnowflake},
  {value: 'patchy smoke', icon: faSmog},
  {value: 'areas smoke', icon: faSmog},
  {value: 'patchy frost', icon: faSnowflake},
  {value: 'areas frost', icon: faSnowflake},
  {value: 'patchy ash', icon: faSmog},
  {value: 'areas ash', icon: faSmog},
  {value: 'volcanic ash', icon: faSmog},
  {value: 'slight chance sleet', icon: faSnowflake},
  {value: 'chance sleet', icon: faSnowflake},
  {value: 'sleet likely', icon: faSnowflake},
  {value: 'slight chance rain showers', icon: faCloudSunRain},
  {value: 'chance rain showers', icon: faCloudRain},
  {value: 'rain showers likely', icon: faCloudRain},
  {value: 'rain showers', icon: faCloudRain},
  {value: 'slight chance rain', icon: faCloudSunRain},
  {value: 'chance rain', icon: faCloudRain},
  {value: 'rain likely', icon: faCloudRain},
  {value: 'heavy rain', icon: faCloudShowersHeavy},
  {value: 'slight chance drizzle', icon: faCloudSunRain},
  {value: 'chance drizzle', icon: faCloudRain},
  {value: 'drizzle likely', icon: faCloudRain},
  {value: 'slight chance snow showers', icon: faSnowflake},
  {value: 'chance snow showers', icon: faSnowflake},
  {value: 'snow showers likely', icon: faSnowflake},
  {value: 'snow showers', icon: faSnowflake},
  {value: 'slight chance flurries', icon: faSnowflake},
  {value: 'chance flurries', icon: faSnowflake},
  {value: 'flurries likely', icon: faSnowflake},
  {value: 'slight chance snow', icon: faSnowflake},
  {value: 'chance snow', icon: faSnowflake},
  {value: 'snow likely', icon: faSnowflake},
  {value: 'blizzard', icon: faSnowflake},
  {value: 'slight chance rain/snow', icon: faSnowflake},
  {value: 'chance rain/snow', icon: faSnowflake},
  {value: 'rain/snow likely', icon: faSnowflake},
  {value: 'slight chance freezing rain', icon: faCloudShowersHeavy},
  {value: 'chance freezing rain', icon: faCloudShowersHeavy},
  {value: 'freezing rain likely', icon: faCloudShowersHeavy},
  {value: 'slight chance freezing drizzle', icon: faCloudShowersHeavy},
  {value: 'chance freezing drizzle', icon: faCloudShowersHeavy},
  {value: 'freezing drizzle likely', icon: faCloudShowersHeavy},
  {value: 'freezing drizzle', icon: faCloudShowersHeavy},
  {value: 'slight chance wintry mix', icon: faSnowflake},
  {value: 'chance wintry mix', icon: faSnowflake},
  {value: 'wintry mix likely', icon: faSnowflake},
  {value: 'slight chance rain/freezing rain', icon: faCloudShowersHeavy},
  {value: 'chance rain/freezing rain', icon: faCloudShowersHeavy},
  {value: 'rain/freezing rain likely', icon: faCloudShowersHeavy},
  {value: 'slight chance wintry mix', icon: faSnowflake},
  {value: 'chance wintry mix', icon: faSnowflake},
  {value: 'wintry mix likely', icon: faSnowflake},
  {value: 'slight chance rain/sleet', icon: faCloudShowersHeavy},
  {value: 'chance rain/sleet', icon: faCloudShowersHeavy},
  {value: 'rain/sleet likely', icon: faCloudShowersHeavy},
  {value: 'slight chance snow/sleet', icon: faSnowflake},
  {value: 'chance snow/sleet', icon: faSnowflake},
  {value: 'snow/sleet likely', icon: faSnowflake},
  {value: 'isolated thunderstorms', icon: faBolt},
  {value: 'slight chance thunderstorms', icon: faBolt},
  {value: 'chance thunderstorms', icon: faBolt},
  {value: 'thunderstorms likely', icon: faBolt},
  {value: 'severe tstms', icon: faBolt},
  {value: 'severe', icon: faExclamationTriangle},
  {value: 'water spouts', icon: faWind},
  {value: 'windy', icon: faWind},
  {value: 'blustery', icon: faWind},
  {value: 'breezy', icon: faWind},
  {value: 'haze', icon: faSmog},
  {value: 'ice crystals', icon: faSnowflake},
  {value: 'ice fog', icon: faSnowflake},
  {value: 'smoke', icon: faSmog},
  {value: 'frost', icon: faSnowflake},
  {value: 'sleet', icon: faSnowflake},
  {value: 'rain', icon: faCloudRain},
  {value: 'drizzle', icon: faCloudRain},
  {value: 'flurries', icon: faSnowflake},
  {value: 'snow', icon: faSnowflake},
  {value: 'rain/snow', icon: faSnowflake},
  {value: 'freezing rain', icon: faCloudShowersHeavy},
  {value: 'wintry mix', icon: faSnowflake},
  {value: 'rain/freezing rain', icon: faCloudShowersHeavy},
  {value: 'wintry mix', icon: faSnowflake},
  {value: 'rain/sleet', icon: faCloudShowersHeavy},
  {value: 'snow/sleet', icon: faSnowflake},
  {value: 'thunderstorms', icon: faBolt},
  {value: 'freezing drizzle', icon: faCloudShowersHeavy},
  {value: 'freezing rain', icon: faCloudShowersHeavy},
  {value: 'snow showers', icon: faSnowflake},
  {value: 'blowing snow', icon: faSnowflake},
  {value: 'blowing dust', icon: faWind},
  {value: 'rain showers', icon: faCloudShowersHeavy},
  {value: 'ice pellets', icon: faSnowflake},
  {value: 'blizzard', icon: faSnowflake},
  {value: 'frosts', icon: faIcicles},
  {value: 'rain', icon: faCloudRain},
  {value: 'hail', icon: faSnowflake},
  {value: 'snow', icon: faSnowflake},
  {value: 'thunderstorms', icon: faBolt},
  {value: 'tstms', icon: faBolt},
  {value: 'drizzle', icon: faCloudRain},
  {value: 'fog', icon: faWater},
  {value: 'haze', icon: faSmog},
  {value: 'smoke', icon: faSmog},
  {value: 'sand', icon: faWind},
  {value: 'ice', icon: faIcicles},
  {value: 'spray', icon: faCloudSunRain},
  {value: 'frost', icon: faSnowflake},
  {value: 'ash', icon: faSmog},
  {value: 'wintry mix', icon: faSnowflake},
  {value: 'sleet', icon: faSnowflake},
  {value: 'flurries', icon: faSnowflake},
  {value: 'scattered showers', icon: faCloudSunRain},
  {value: 'showers', icon: faCloudShowersHeavy},
  {value: 'wind', icon: faWind},
  {value: 'water spouts', icon: faWind},
  {value: 'blustery', icon: faWind},
  {value: 'breezy', icon: faWind},
  {value: 'hot', icon: faTemperatureHigh},
  {value: 'cold', icon: faTemperatureLow},
  {value: 'mostly sunny', icon: faSun},
  {value: 'partly sunny', icon: faCloudSun},
  {value: 'mostly cloudy', icon: faCloud},
  {value: 'mostly clear', icon: faSun},
  {value: 'partly cloudy', icon: faCloudSun},
  {value: 'increasing clouds', icon: faCloudSun},
  {value: 'decreasing clouds', icon: faCloudSun},
  {value: 'becoming cloudy', icon: faCloudSun},
  {value: 'gradual clearing', icon: faCloudSun},
  {value: 'clearing late', icon: faCloudSun},
  {value: 'becoming sunny', icon: faCloudSun},
  {value: 'sunny', icon: faSun},
  {value: 'cloudy', icon: faCloud},
  {value: 'cloud', icon: faCloudSun},
  {value: 'overcast', icon: faCloud},
  {value: 'none', icon: faCloudSun},
  {value: 'winter', icon: faSnowflake},
  {value: 'chill', icon: faTemperatureLow},
  {value: 'warm', icon: faTemperatureHigh},
  {value: 'cool', icon: faTemperatureLow},
  {value: 'clearing', icon: faCloudSun},
  {value: 'clear', icon: faSun},
  {value: 'fog', icon: faWater},
];

const getIcon = (summary: string) => {
  const lowerCaseSummary = summary.toLowerCase();
  const type = types.find(({value}) => lowerCaseSummary.includes(value));
  if (type) {
    return type.icon;
  }
  return faCloudSun;
};

export default getIcon;
