import { faKey } from '@fortawesome/free-solid-svg-icons';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useFluent from '../../../hooks/useFluent';
import {useBasicParkDetails} from '../../../queries/parks/useBasicParkDetails';
import { Routes, setParkOgImageUrl } from '../../../routing/routes';
import {parkDetailLink} from '../../../routing/Utils';
import {AuxiliaryItem} from '../../../types/itemTypes';
import { getTrailAccessNice } from '../../../utilities/trailUtils';
import DefaultError from '../../sharedComponents/DefaultError';
import SimpleHeader, { Detail } from '../../sharedComponents/detailComponents/header/SimpleHeader';
import TripsAndNotes from '../../sharedComponents/detailComponents/TripsAndNotes';
import MapRenderProp from '../../sharedComponents/MapRenderProp';
import {mountainNeutralSvg, parkNeutralSvg, tentNeutralSvg, trailDefaultSvg} from '../../sharedComponents/svgIcons';

interface Props {
  id: string;
}

const ParkDetail = (props: Props) => {
  const { id } = props;

  const getString = useFluent();

  const {loading, data, error} = useBasicParkDetails(id);
  const {offline} = useOfflineContext();
  const history = useHistory();

  if (error && !data) {
    if (offline) {
      return null;
    }
    return <DefaultError error={error} />;
  }

  let title: string = '----';
  let subtitle: string = '----';
  let map: React.ReactElement<any> | null = null;
  let listId: undefined | string;
  const details: Detail[] = [];
  if (data !== undefined) {
    const { park } = data;
    if (!park) {
      history.replace(Routes.NotFound);
      return null;
    } else {
      title = park.name;
      if (park.protectTitle) {
        subtitle = park.protectTitle;
      } else if (park.landType) {
        subtitle = park.landType;
      } else if (park.ownership) {
        subtitle = upperFirst(park.ownership) + ' Park';
      } else {
        subtitle = 'Park';
      }
      if (park.locationText) {
        subtitle += ', ' + park.locationText;
      }
      map = (
        <MapRenderProp
          id={park.id}
          bbox={park.bbox}
        />
      );
      listId = park.trailBaggingList ? park.trailBaggingList.id : undefined;
    }
    if (park.operator) {
      details.push({
        text: park.operator,
        icon: faKey,
        isCustomIcon: false,
        allowWrap: true,
      });
    }
    if (park.numMountains) {
      details.push({
        text: park.numMountains + ' ' +
          (park.numMountains > 1 ? getString('global-text-value-mountains') : getString('global-text-value-mountain')),
        icon: mountainNeutralSvg,
        isCustomIcon: true,
      });
    }
    if (park.numCampsitesCar || park.numCampsitesBackcountry) {
      const total = park.numCampsitesBackcountry + park.numCampsitesCar;
      const backcountryText = park.numCampsitesBackcountry
        ? ` (${park.numCampsitesBackcountry} ${park.numCampsitesBackcountry > 1 ? 'are' : 'is'} backcountry only)`
        : '';
      details.push({
        text: total + ' ' +
          (total > 1 ? getString('global-text-value-campsites') : getString('global-text-value-campsite')) +
          backcountryText,
        icon: tentNeutralSvg,
        isCustomIcon: true,
      });
    }
    if (park.trailMileage) {
      details.push({
        text: getTrailAccessNice(park.trailMileage),
        icon: trailDefaultSvg,
        isCustomIcon: true,
      });
    }
  }

  const metaDescription = data && data.park
    ? getString('meta-data-park-detail-description', {
      name: data.park.name,
    })
    : null;
  const metaTitle = data && data.park ? getString('meta-data-detail-default-title', {
    title: title + ', ' + data.park.locationTextShort, type: '',
  }) : '';
  const metaData = metaDescription && data && data.park ? (
    <Helmet>
      <title>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
      />
      <meta property='og:title' content={metaTitle} />
      <meta
        property='og:description'
        content={metaDescription}
      />
      <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + parkDetailLink(id)} />
      <meta property='og:image' content={setParkOgImageUrl(id)} />
    </Helmet>
  ) : null;

  return (
    <>
      {metaData}
      <SimpleHeader
        id={id}
        loading={loading}
        title={title}
        subtitle={subtitle}
        customIcon={true}
        icon={parkNeutralSvg}
        authorId={null}
        type={AuxiliaryItem.park}
        primaryDetails={details}
      />
      <TripsAndNotes
        id={id}
        name={title}
        item={AuxiliaryItem.park}
        listId={listId}
      />
      {map}
    </>
  );
};

export default ParkDetail;
