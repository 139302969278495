import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import useFluent from '../../../../hooks/useFluent';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { Coordinate } from '../../../../types/graphQLTypes';
import { mediumSize, mobileSize } from '../../../../Utils';
import DetailSegment, { Panel } from '../DetailSegment';
import NearbyPlaces from './NearbyPlaces';

interface Props {
  id: string;
  name: string;
  location: Coordinate;
}

const ExploreNearby = (props: Props) => {
  const {
    id, name, location,
  } = props;
  const getString = useFluent();
  const windowWidth = useWindowWidth();
  const panels: Panel[] = [
    {
      title: getString('global-text-value-camping'),
      reactNode: (
        <NearbyPlaces
          originId={id}
          origin={location}
          originName={name}
          destinationType={'campsites'}
        />
      ),
    },
    {
      title: getString('global-text-value-mountains'),
      reactNode: (
        <NearbyPlaces
          originId={id}
          origin={location}
          originName={name}
          destinationType={'mountains'}
        />
      ),
    },
    {
      title: getString('global-text-value-viewpoints'),
      reactNode: (
        <NearbyPlaces
          originId={id}
          origin={location}
          originName={name}
          destinationType={'viewpoints'}
        />
      ),
    },
    {
      title: windowWidth > mobileSize && windowWidth < mediumSize
        ? getString('global-text-value-water')
        : getString('global-text-value-water-access'),
      reactNode: (
        <NearbyPlaces
          originId={id}
          origin={location}
          originName={name}
          destinationType={'waterpoints'}
        />
      ),
    },

  ];

  return (
    <DetailSegment
      title={getString('header-text-menu-item-explore-nearby')}
      icon={faMapMarkedAlt}
      panels={panels}
      panelId={'exploreNearbyPanelId'}
    />
  );
};

export default ExploreNearby;
