import React from 'react';
import { useOfflineContext } from '../../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFluent from '../../../../hooks/useFluent';
import {
  useAddWaterpointNote,
  useEditWaterpointNote,
  useWaterpointNote,
} from '../../../../queries/waterpoints/waterpointNotes';
import { PlaceholderText } from '../../../../styling/styleUtils';
import UserNote from '../../UserNote';

interface Props {
  id: string;
  name: string;
}

const WaterpointNote = ({id, name}: Props) => {
  const getString = useFluent();
  const currentUser = useCurrentUser();
  const userId = currentUser ? currentUser._id : null;
  const {offline} = useOfflineContext();

  const {loading, error, data} = useWaterpointNote({
    userId,
    waterpointId: id,
  });

  const addWaterpointNote = useAddWaterpointNote();
  const editWaterpointNote = useEditWaterpointNote();

  const waterpointNote = data && data.user && data.user.waterpointNote ? data.user.waterpointNote : null;
  const defaultNoteText = waterpointNote && waterpointNote.text ? waterpointNote.text : '';
  const notesPlaceholderText = getString('user-notes-placeholder', {name});

  const saveNote = (text: string) => {
    if (userId) {
      if (waterpointNote === null) {
        addWaterpointNote({variables: {userId, waterpointId: id, text}});
      } else {
        editWaterpointNote({variables: {userId, waterpointId: id, text}});
      }
    }
  };

  if (loading === true) {
    return null;
  } else if (data !== undefined || offline) {
    return (
      <UserNote
        placeholder={notesPlaceholderText}
        defaultValue={defaultNoteText}
        onSave={saveNote}
        key={defaultNoteText}
      />
    );
  } else if (error !== undefined) {
    console.error(error);
    return (
      <PlaceholderText>
        {getString('global-error-retrieving-data')}
      </PlaceholderText>
    );
  } else {
    return null;
  }
};

export default WaterpointNote;
