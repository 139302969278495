export enum CoreItem {
  mountain = 'mountain',
  trail = 'trail',
  campsite = 'campsite',
}

export enum AggregateItem {
  list = 'list',
  parentTrail = 'parentTrail',
}

export enum AuxiliaryItem {
  trailhead = 'trailhead',
  viewpoint = 'viewpoint',
  waterpoint = 'waterpoint',
  park = 'park',
}

export enum UserContentItem {
  route = 'route-plan',
}

export enum AutoItem {
  route = 'route',
}

export enum MapItem {
  route = 'route',
  directions = 'directions',
  vehicleRoad = 'vehicle_road',
}

export enum CoreItems {
  mountains = 'mountains',
  trails = 'trails',
  campsites = 'campsites',
}

export enum AuxiliaryItems {
  trailheads = 'trailheads',
  viewpoints = 'viewpoints',
  waterpoints = 'waterpoints',
  parks = 'parks',
}

export enum UserContentItems {
  routes = 'route-plans',
}

export const isCoreItem = (item: any): item is CoreItem =>
  item === CoreItem.mountain || item === CoreItem.campsite || item === CoreItem.trail;
export const isCoreItems = (item: any): item is CoreItems =>
  item === CoreItems.mountains || item === CoreItems.campsites || item === CoreItems.trails;
export const coreItemToCoreItems = (item: CoreItem): CoreItems => item + 's' as CoreItems;
export const coreItemsToCoreItem = (item: CoreItems): CoreItem => item.slice(0, item.length - 1) as CoreItem;

export const isAuxiliaryItem = (item: any): item is AuxiliaryItem => (
  item === AuxiliaryItem.trailhead ||
  item === AuxiliaryItem.viewpoint ||
  item === AuxiliaryItem.waterpoint ||
  item === AuxiliaryItem.park
);
export const isAuxiliaryItems = (item: any): item is AuxiliaryItems => (
  item === AuxiliaryItems.trailheads ||
  item === AuxiliaryItems.viewpoints ||
  item === AuxiliaryItems.waterpoints
);
export const auxiliaryItemToAuxiliaryItems = (item: AuxiliaryItem): AuxiliaryItems => item + 's' as AuxiliaryItems;
export const auxiliaryItemsToAuxiliaryItem = (item: AuxiliaryItems): AuxiliaryItem =>
  item.slice(0, item.length - 1) as AuxiliaryItem;

export enum SearchResultType {
  mountain = 'mountain',
  trail = 'trail',
  trailhead = 'trailhead',
  campsite = 'campsite',
  viewpoint = 'viewpoint',
  park = 'park',
  list = 'list',
  geolocation = 'geolocation',
}
