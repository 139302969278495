// This should always reflect the routes found on the server at
// src/routing/index.ts

export enum Routes {
  Landing = '/',

  Saved = '/saved',

  ListDetail = '/list/:id',
  SearchLists = '/list/search',

  MountainDetail = '/mountain/:id',
  SearchMountains = '/mountain/search',
  SummitView = '/summit-view/:lat/:lng/:altitude/:id',

  CampsiteDetail = '/campsite/:id',
  SearchCampsites = '/campsite/search',

  TrailDetail = '/trail/:id',
  TrailheadDetail = '/trailhead/:id',
  SearchTrails = '/trail/search',
  EditTrail = '/edit-trail/:id',
  EditTrailParent = '/edit-trail-parent/:id',

  SearchViewpoints = '/viewpoint/search',
  ViewpointDetail = '/viewpoint/:id',

  WaterpointDetail = '/waterpoint/:id',

  SearchParks = '/park/search',
  ParkDetail = '/park/:id',

  GeoLocationDetail = '/point/:id',

  CreateMountain = '/create-mountain',
  EditMountain = '/edit-mountain/:id',

  CreateCampsite = '/create-campsite',
  EditCampsite = '/edit-campsite/:id',

  CreateViewpoint = '/create-viewpoint',
  EditViewpoint = '/edit-viewpoint/:id',

  CreateWaterpoint = '/create-waterpoint',
  EditWaterpoint = '/edit-waterpoint/:id',

  CreateList = '/create-list',
  EditList = '/edit-list/:id',

  AutoRouteDetail = '/route-detail',
  AutoRouteDetailParkingToMountain = '/route-detail/mountain/:mountainId/start/:parkingId',
  AutoRouteDetailMountainToCampsite = '/route-detail/mountain/:mountainId/campsite/:campsiteId',
  AutoRouteDetailCampsiteToCampsite = '/route-detail/campsite/:campsiteId1/campsite/:campsiteId2',
  AutoRouteDetailTrailToMountain = '/route-detail/trail/:trailId/mountain/:mountainId',
  AutoRouteDetailTrailToCampsite = '/route-detail/trail/:trailId/campsite/:campsiteId',

  TripReportDetail = '/trip-report',
  AddTripReport = '/add-trip-report',
  EditTripReport = '/edit-trip-report',

  SavedRoutePlans = '/routes',
  RoutePlan = '/route',

  OfflineMaps = '/offline-maps',

  SearchUsers = '/user/search',
  UserProfile = '/user/:id',
  OtherUserPeakList = '/user/:id/list/:peakListId',

  UserSettings = '/user-settings',

  YourTrips = '/timeline',
  YourProgress = '/progress',
  YourTotals = '/totals',

  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
  About = '/about',
  Contact = '/contact',
  DataDisclaimer = '/data-disclaimer',
  OsmData = '/osm-data',

  Admin = '/admin',

  NotFound = '/not-found',
}

export const defaultOgImageUrl = '/og-image/default/image.jpg';

const mountainOgImageUrl = '/og-image/mountain/:mountainId/image.jpg';
export const setMountainOgImageUrl = (id: string) => mountainOgImageUrl.replace(':mountainId', id);

const peakListOgImageUrl = '/og-image/peaklist/:peakListId/image.jpg';
export const setPeakListOgImageUrl = (id: string) => peakListOgImageUrl.replace(':peakListId', id);

const campsiteOgImageUrl = '/og-image/campsite/:campsiteId/image.jpg';
export const setCampsiteOgImageUrl = (id: string) => campsiteOgImageUrl.replace(':campsiteId', id);

const trailOgImageUrl = '/og-image/trail/:trailId/image.jpg';
export const setTrailOgImageUrl = (id: string) => trailOgImageUrl.replace(':trailId', id);

const viewpointOgImageUrl = '/og-image/viewpoint/:viewpointId/image.jpg';
export const setViewpointOgImageUrl = (id: string) => viewpointOgImageUrl.replace(':viewpointId', id);

const waterpointOgImageUrl = '/og-image/waterpoint/:waterpointId/image.jpg';
export const setWaterpointOgImageUrl = (id: string) => waterpointOgImageUrl.replace(':waterpointId', id);

const trailheadOgImageUrl = '/og-image/trailhead/:trailheadId/image.jpg';
export const setTrailheadOgImageUrl = (id: string) => trailheadOgImageUrl.replace(':trailheadId', id);

const parkOgImageUrl = '/og-image/park/:parkId/image.jpg';
export const setParkOgImageUrl = (id: string) => parkOgImageUrl.replace(':parkId', id);

const routePlanOgImageUrl = '/og-image/route/:routeId/image.jpg';
export const setRoutePlanOgImageUrl = (id: string) => routePlanOgImageUrl.replace(':routeId', id);

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

export interface GlobalQueryParams {
  trips?: YesNo;
  edit?: YesNo;
  id?: string;
  view?: 'list';
  [key: string]: string | undefined;
}
