import {
  faBicycle,
  faCloud,
  faExchangeAlt,
  faExclamationTriangle,
  faHorse,
  faThermometerEmpty,
  faTint,
  faWind,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';
import SatelliteMapLayerBG from '../../../../../assets/images/satellite-map-layer-icon-bg.jpg';
import StandardMapLayerBG from '../../../../../assets/images/standard-map-layer-icon-bg.jpg';
import useMapContext from '../../../../../hooks/useMapContext';
import {
  BasicIconInText,
  lightBaseColor,
  lightBorderColor,
  lowWarningColorDark,
  primaryColor,
  tertiaryColor,
} from '../../../../../styling/styleUtils';
import {mobileSize} from '../../../../../Utils';
import {MapStyle} from '../../map';
import { AccessOverlay } from '../../map/access';
import { isRoutePlanningOn } from '../../map/interactions/routePlanning';
import { WeatherOverlay, WeatherState } from '../../map/weather';

const Grid = styled.div`
  padding: 0 0.35rem 0.35rem;
  display: grid;
  grid-column-gap: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;

  &:not(:last-of-type) {
    border-bottom: solid 1px ${lightBorderColor};
  }
`;

const TwoColumnGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
`;

const ThreeColumnGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr;
`;

const Button = styled.button<{$highlighted: boolean}>`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-bottom: 0.4rem;
  outline: none;

  ${({$highlighted}) => $highlighted ? `
    color: ${primaryColor};
    font-weight: 700;

    div {
      border-color: ${primaryColor};
    }
  ` : ''}

  &:disabled,
  &[disabled]{
    opacity: 0.5;
  }
`;

const Thumnbail = styled.div`
  min-width: 5rem;
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  border: solid 3px transparent;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  margin-bottom: 0.25rem;
`;

const IconThumbnail = styled(Thumnbail)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: ${lightBaseColor};
  height: 5rem;
  background-color: ${tertiaryColor};
  border-color: ${lightBorderColor};
`;

const Title = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  font-weight: 600;

  @media (max-width: ${mobileSize}px) {
    padding-top: 0.25rem;
  }
`;

const AlertText = styled.div`
  grid-column: 1 / -1;
  font-size: 0.65rem;
  color: ${lowWarningColorDark};
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
`;

interface Props {
  mapStyle: MapStyle;
  setMapStyle: (style: MapStyle) => void;
  mapWeather: WeatherState | null;
  setWeather: (value: WeatherOverlay | null) => void;
  access: AccessOverlay | null;
  setAccess: (value: AccessOverlay | null) => void;
}

const MapLayersSelection = ({mapStyle, setMapStyle, mapWeather, setWeather, access, setAccess}: Props) => {
  const mapContext = useMapContext();
  const editModeOn = isRoutePlanningOn();

  const setToStandardStyle = () => {
    if (mapContext.intialized) {
      mapContext.setBaseMap(MapStyle.standard);
      setMapStyle(MapStyle.standard);
    }
  };

  const setToSatelliteStyle = () => {
    if (mapContext.intialized) {
      mapContext.setBaseMap(MapStyle.satellite);
      setMapStyle(MapStyle.satellite);
    }
  };

  return (
    <>
      <TwoColumnGrid>
        <Title>
          Base Maps
        </Title>
          {editModeOn && (
            <AlertText>
              <BasicIconInText icon={faExclamationTriangle} />
              Finish building your segment before changing layers
            </AlertText>
          )}
        <Button
          $highlighted={mapStyle === MapStyle.standard && !editModeOn}
          onClick={setToStandardStyle}
          disabled={editModeOn}
        >
          <Thumnbail style={{backgroundImage: `url("${StandardMapLayerBG}")`}} />
          {'Standard'}
        </Button>
        <Button
          $highlighted={mapStyle === MapStyle.satellite}
          onClick={setToSatelliteStyle}
          disabled={editModeOn}
        >
          <Thumnbail style={{backgroundImage: `url("${SatelliteMapLayerBG}")`}} />
          {'Satellite'}
        </Button>
      </TwoColumnGrid>
      <ThreeColumnGrid>
        <Title>Trail Access</Title>
        <Button
          $highlighted={!access}
          onClick={() => setAccess(null)}
        >
          <IconThumbnail />
          {'None'}
        </Button>
        <Button
          $highlighted={Boolean(access === AccessOverlay.bike)}
          onClick={() => setAccess(AccessOverlay.bike)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faBicycle} />
          </IconThumbnail>
          {'Bike Access'}
        </Button>
        <Button
          $highlighted={Boolean(access === AccessOverlay.horse)}
          onClick={() => setAccess(AccessOverlay.horse)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faHorse} />
          </IconThumbnail>
          {'Horse Access'}
        </Button>
      </ThreeColumnGrid>
      <ThreeColumnGrid>
        <Title>Weather Overlays</Title>
        <Button
          $highlighted={!mapWeather}
          onClick={() => setWeather(null)}
        >
          <IconThumbnail />
          {'None'}
        </Button>
        <Button
          $highlighted={Boolean(mapWeather && mapWeather.type === WeatherOverlay.precipitation)}
          onClick={() => setWeather(WeatherOverlay.precipitation)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faTint} />
          </IconThumbnail>
          {'Precip.'}
        </Button>
        <Button
          $highlighted={Boolean(mapWeather && mapWeather.type === WeatherOverlay.pressure)}
          onClick={() => setWeather(WeatherOverlay.pressure)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faExchangeAlt} />
          </IconThumbnail>
          {'Pressure'}
        </Button>
        <Button
          $highlighted={Boolean(mapWeather && mapWeather.type === WeatherOverlay.temp)}
          onClick={() => setWeather(WeatherOverlay.temp)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faThermometerEmpty} />
          </IconThumbnail>
          {'Temp.'}
        </Button>
        <Button
          $highlighted={Boolean(mapWeather && mapWeather.type === WeatherOverlay.wind)}
          onClick={() => setWeather(WeatherOverlay.wind)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faWind} />
          </IconThumbnail>
          {'Wind'}
        </Button>
        <Button
          $highlighted={Boolean(mapWeather && mapWeather.type === WeatherOverlay.clouds)}
          onClick={() => setWeather(WeatherOverlay.clouds)}
        >
          <IconThumbnail>
            <FontAwesomeIcon icon={faCloud} />
          </IconThumbnail>
          {'Clouds'}
        </Button>
      </ThreeColumnGrid>
    </>
  );
};

export default MapLayersSelection;
