import { faCalendarAlt, faCloudDownloadAlt, faPlus, faRoute, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useFluent from '../../../hooks/useFluent';
import { Routes } from '../../../routing/routes';
import { navHeight } from '../../../styling/Grid';
import { BasicIconInText, primaryColor } from '../../../styling/styleUtils';

const ActionButtonRoot = styled.div`
  position: fixed;
  right: 0.5rem;
  bottom: ${navHeight * 3}px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    z-index: 1000;
  }
`;

const ActionButton = styled.button`
  position: relative;
  background-color: ${primaryColor};
  color: #fff;
  font-size: 1.4rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  transition: all 0.2s ease;

  &.active {
    color: ${primaryColor};
    background-color: #fff;
  }
`;

const Menu = styled.div`
  position: absolute;
  background-color: ${primaryColor};
  border-radius: 10000px;
  width: 0rem;
  height: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.2s ease;

  &.active {
    width: 24rem;
    height: 24rem;
  }
`;

const MenuButton = styled(Link)`
  color: #fff;
  text-decoration: none;
  position: absolute;
  font-size: 1.3rem;

  &:hover, &:focus, &:active {
    color: #fff;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const MobileActionButton = () => {
  const currentUser = useCurrentUser();
  const getString = useFluent();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {open && <Backdrop onClick={() => setOpen(false)} />}
      <ActionButtonRoot
        className={open ? 'active' : undefined}
      >
        <Menu
          className={open ? 'active' : undefined}
        >
          <MenuButton
            to={Routes.RoutePlan}
            style={{transform: 'translate(-3.65rem, -5rem)'}}
          >
            <BasicIconInText icon={faRoute} />
            {getString('global-create-route')}
          </MenuButton>
          <MenuButton
            to={currentUser ? Routes.AddTripReport : Routes.YourTrips}
            style={{transform: 'translate(-7rem, 0)'}}
          >
            <BasicIconInText icon={faCalendarAlt} />
            {getString('global-add-trip-report')}
          </MenuButton>
          <MenuButton
            to={Routes.OfflineMaps}
            style={{transform: 'translate(-3.65rem, 5rem)'}}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              if (!navigator?.serviceWorker?.controller) {
                e.preventDefault();
                window.location.href = Routes.OfflineMaps;
              }
            }}
          >
            <BasicIconInText icon={faCloudDownloadAlt} />
            {getString('offline-maps-title')}
          </MenuButton>
        </Menu>
        <ActionButton
          className={open ? 'active' : undefined}
          onClick={() => setOpen(curr => !curr)}
        >
          <FontAwesomeIcon icon={open ? faTimes : faPlus} />
        </ActionButton>
      </ActionButtonRoot>
    </>
  );
};

export default MobileActionButton;
