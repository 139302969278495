import React from 'react';
import { createPortal } from 'react-dom';
import {Coordinate} from '../../../../types/graphQLTypes';
import {AuxiliaryItems, CoreItems} from '../../../../types/itemTypes';
import ClickedPopup from './popup/ClickedPopup';

export const noClickItemId = 'NO_CLICK_ITEM';

export type CallbackInput = {
  item: CoreItems | AuxiliaryItems;
  highlighted: boolean;
  datum: {
    [key: string]: any;
  };
};

export interface Props {
  node: HTMLElement | null;
  item?: CoreItems | AuxiliaryItems;
  id?: string | null;
  highlighted?: boolean;
  name?: string | null;
  displayName?: string | null;
  subtitle?: string | null;
  location?: Coordinate;
  closePopup?: () => void;
  callback?: (input: CallbackInput) => void;
  highlightedPointsGeojson?: mapboxgl.GeoJSONSourceSpecification['data'] | undefined;
  highlightedTrailsGeojson?: mapboxgl.GeoJSONSourceSpecification['data'] | undefined;
  highlightedRoadsGeojson?: mapboxgl.GeoJSONSourceSpecification['data'] | undefined;
}

const Tooltip = (props: Props) => {
  const {
    node, item, id, name, location, closePopup, callback,
    highlightedPointsGeojson, highlightedTrailsGeojson, highlightedRoadsGeojson,
    displayName, subtitle,
  } = props;
  if (props.id === noClickItemId) {
    return null;
  }

  let popup: React.ReactElement<any> | null;
  if (node !== null && item !== undefined && id !== undefined &&
      name !== undefined && location !== undefined && closePopup !== undefined) {
    popup = createPortal((
      <ClickedPopup
        name={name}
        displayName={displayName || null}
        subtitle={subtitle || null}
        id={id}
        itemType={item}
        location={location}
        close={closePopup}
        highlightedPointsGeojson={highlightedPointsGeojson}
        highlightedTrailsGeojson={highlightedTrailsGeojson}
        highlightedRoadsGeojson={highlightedRoadsGeojson}
        callback={callback}
      />
    ), node);
  } else {
    popup = null;
  }

  return popup;
};

export default Tooltip;
