const {point} = require('@turf/helpers');
const distance = require('@turf/distance').default;
const center = require('@turf/center').default;
const length = require('@turf/length').default;
import { faHiking, faRoute } from '@fortawesome/free-solid-svg-icons';
import { Types } from 'mongoose';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import useFluent from '../../../../../hooks/useFluent';
import { routePlanLink } from '../../../../../routing/Utils';
import { BasicIconInTextCompact, primaryColor } from '../../../../../styling/styleUtils';
import { Coordinate } from '../../../../../types/graphQLTypes';
import { LineString } from '../../map/interactions/routePlanning';
import worker, { findRoute, WorkerMessageResponse } from '../../map/interactions/routePlanning/routeWorker';
import { getCachedUsersLocation } from '../../usersLocation/track';
import {
  Icon,
  Root as RootBase,
} from './Utils';

const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  line-height: 1;
`;

const Top = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

const Bottom = styled(Link)`
  grid-column: 1 / -1;
  grid-row: 2;
  font-size: 0.85em;
`;

interface Props {
  id: string;
  destination: Coordinate;
  name: string;
  closePopup: () => void;
}

const HikingDirections = (props: Props) => {
  const {
    id, destination, name, closePopup,
  } = props;
  const getString = useFluent();

  const [route, setRoute] = useState<LineString | null>(null);

  useEffect(() => {
    const usersLocation = getCachedUsersLocation();
    if (usersLocation && usersLocation.coords && destination) {
      const distanceToDestination = distance(
        point(destination),
        point([usersLocation.coords.longitude, usersLocation.coords.latitude]),
        { units: 'miles' },
      );
      if (distanceToDestination < 5) {
        const from: Coordinate = [usersLocation.coords.longitude, usersLocation.coords.latitude];
        findRoute({
          from,
          to: destination,
          networkCenter: from,
          key: id,
          wait: true,
        });
      }
    }
    const onWorkerMessage = (event: MessageEvent<WorkerMessageResponse>) => {
      if (event.data && event.data.line && event.data.key && event.data.key === id) {
        setRoute(event.data.line);
      }
    };
    worker.addEventListener('message', onWorkerMessage);
    return () => {
      return worker.removeEventListener('message', onWorkerMessage);
    };
  }, [id, destination]);

  if (route) {
    const routeLength = length(route, { units: 'miles' });
    return (
      <RootBase>
        <Icon icon={faHiking} />
        <Text>
          <Top>
            {parseFloat(routeLength.toFixed(1))} mi hike from your location
          </Top>
          <Bottom
            to={{
              pathname: routePlanLink(),
              state: {
                routePlan: {
                  id: null,
                  name: 'Hike to ' + name,
                  visibility: 'public',
                  autoGenerated: false,
                  authors: null,
                  authorVisibility: 'public',
                  totalLength: routeLength,
                  date: new Date(),
                  segments: [{
                    name: `Your location to ${name}`,
                    color: primaryColor,
                    lines: [[...route.geometry.coordinates].map(coord => coord.map(Number))],
                    trails: route.properties.trails,
                    hashKey: new Types.ObjectId().toString(),
                  }],
                  center: center(route).geometry.coordinates,
                },
              },
            }}
            onClick={closePopup}
          >
            <BasicIconInTextCompact icon={faRoute} />
            {getString('global-text-value-view-customize-route')}
          </Bottom>
        </Text>
      </RootBase>
    );
  }
  return null;
};

export default HikingDirections;
