/* eslint-disable max-len */
/* tslint:disable:max-line-length */
import { faHiking } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, {keyframes} from 'styled-components/macro';
import { Routes } from '../../routing/routes';
import { BasicIconInText, CompactButtonWarningLow, lowWarningColor } from '../../styling/styleUtils';

const slideUp = keyframes`
  0%   {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
`;

const Root = styled.div`
  position: fixed;
  width: 500px;
  max-width: calc(100% - 2rem);
  padding: 0.45rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto 1rem;
  color: #fff;
  border-radius: 8px;
  z-index: 500;
  opacity: 0;
  pointer-events: none;
  animation: ${slideUp} 0.15s ease-in-out forwards;
  animation-delay: 3s;

  @media (max-width: 500px) {
    margin: 1rem;
  }
`;

const Title = styled.h3`
  color: #fff;
  font-weight: 600;
  font-size: 0.95rem;
  margin: 0.35rem 0 0.75rem;
`;

const Content = styled.p`
  margin: 0;
  font-size: 0.7rem;

  a {
    color: ${lowWarningColor};
  }
`;

const AcceptButton = styled(CompactButtonWarningLow)`
  float: right;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 80px;
`;

const localstorageKey = 'tosAcceptedVersion';
const tosVersion = 'April 07, 2021';
const TermsOfUseNotification = () => {
  const [accepted, setAccepted] = useState<boolean>(localStorage.getItem(localstorageKey) === tosVersion);
  if (accepted) {
    return null;
  }
  const acceptToS = () => {
    setAccepted(true);
    localStorage.setItem(localstorageKey, tosVersion);
  };
  return (
    <Root>
      <Title>
        <BasicIconInText icon={faHiking} />
        Hike Responsibly!
      </Title>
      <Content>
        Wilderlist relies on data from a number of sources such as our wonderful users and OpenStreetMaps. Please be aware that we don't verify this data and cannot guarantee its accuracy. Any reliance you place on such information is at your own risk. By using Wilderlist, you agree to our <Link to={Routes.TermsOfUse}>Terms of Use</Link>.
      </Content>
      <AcceptButton onClick={acceptToS}>
        Accept
      </AcceptButton>
    </Root>
  );
};

export default TermsOfUseNotification;
