import {
  faCalendarAlt,
  faCloudDownloadAlt,
  faList,
  faMap,
  faQuestionCircle,
  faRoute,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import MapBoxLogoSVG from '../../../assets/images/mapbox-logo.svg';
import LogoWhitePng from '../../../assets/logo/logo-white.png';
import LogoPng from '../../../assets/logo/logo.png';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useFluent from '../../../hooks/useFluent';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { Routes } from '../../../routing/routes';
import useRedirectWhenLoggedIn from '../../../routing/useRedirectWhenLoggedIn';
import {
  navHeight,
  NavigationContainer as HeaderContainerBase,
} from '../../../styling/Grid';
import {
  baseColor,
  BasicIconInText,
  lightBaseColor,
  lightBorderColor,
  primaryColor,
  ShadowButtonIcon,
  ShadowButtonLink,
  tertiaryColor,
} from '../../../styling/styleUtils';
import {mobileSize} from '../../../Utils';
import Search from '../contentHeader/search';
import Controls from '../globalMap/controls';
import {MapStyle, storageCheckedKeyId} from '../globalMap/map';
import HamburgerMenu from './HamburgerMenu';
import MobileActionButton from './MobileActionButton';
import NotificationBar from './NotificationBar';
import UserMenu from './UserMenu';
import { linkStyles } from './Utils';

const HeaderContainer = styled(HeaderContainerBase)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 450;
  pointer-events: none;

  @media(max-width: ${mobileSize}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 550;
  }
`;

const sideContentWidth = 180;

const SideContent = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: relative;
  height: 40px;

  > * {
    pointer-events: all;
  }

  @media(min-width: ${mobileSize + 1}px) {
    position: fixed;
    right: 0;
    top: 0;
    max-width: ${sideContentWidth}px;
    padding: 1rem;
    flex-direction: column;
    height: auto;
  }
`;

const SemanticLogoContainer = styled.h1`
  margin: 0;
  font-size: 0;
  color: rgba(0, 0, 0, 0);

  @media(min-width: ${mobileSize + 1}px) {
    margin-bottom: 1.5rem;
  }
`;

const LogoContainer = styled(Link)`
  text-indent: -10000px;
  font-size: 0;
  color: rgba(0, 0, 0, 0);
  pointer-events: all;
`;

const logoSmallWindoWidth = 900;
const logoSmallWidth = 160;

const Logo = styled.img`
  max-width: 100%;

  @media(max-width: ${logoSmallWindoWidth}px) {
    max-width: ${logoSmallWidth}px;
  }
  @media(max-width: ${mobileSize}px) {
    box-sizing: border-box;
    padding: 0.4rem 0.65rem;
    height: 42px;
  }
`;

const CoreNav = styled.nav`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  background-color: #fff;
  pointer-events: all;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media(max-width: ${mobileSize}px) {
    margin-right: 0;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-shadow: none;
  }
`;

const loggedInNavItemClassName = 'logged-in-nav-item';

const NavLink = styled(Link)`
  ${linkStyles}

  @media(max-width: ${mobileSize}px) {
    &.${loggedInNavItemClassName} {
      flex-direction: row;
      gap: 0.6rem;
    }
  }
`;

const InactiveNavLink = styled(NavLink)`
  color: ${baseColor};

  &:hover {
    color: ${baseColor};
    background-color: ${tertiaryColor};
  }
`;

const ActiveNavLink = styled(NavLink)`
  color: #fff;
  background-color: ${primaryColor};

  &:hover {
    color: #fff;
  }
`;

const IconContainerBase = styled.div`
  margin-top: 0.1em;
  font-size: 1.45em;

  @media(max-width: ${mobileSize}px) {
    font-size: 1.2em;
    margin-top: 0;
  }
`;

const ActiveIconContainer = styled(IconContainerBase)`
  color: #fff;
`;

const InactiveIconContainer = styled(IconContainerBase)`
  color: ${primaryColor};
`;

const BottomNavIcon = styled(FontAwesomeIcon)`
  @media(max-width: ${mobileSize}px) {
    font-size: 0.9em;
  }
`;

const TopNav = styled.div`
  height: ${navHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  z-index: 10;
  pointer-events: none;
`;

const BottomContent = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 450;
  box-shadow: 0 1px 3px 1px #d1d1d1;
`;

const BottomNav = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  height: ${navHeight}px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  position: relative;
`;

const Copyright = styled.div`
  background-color: ${tertiaryColor};
  color: ${lightBaseColor};
  font-size: 0.75rem;
  padding: 0.35rem;
  text-align: center;
  position: relative;

  a {
    color: ${lightBaseColor};
  }

  @media (max-width: 400px) {
    font-size: 0.62rem;
  }
  @media (max-width: 310px) {
    font-size: 0.5rem;
  }
`;

const CustomIconInTextBase = styled(IconContainerBase)`
  svg {
    width: 0.9rem;
  }
`;
const CustomIconInTextActive = styled(CustomIconInTextBase)`
  svg {
    .fill-path {
      fill: #fff;
    }
    .stroke-path {
      fill: ${primaryColor};
    }
  }
`;
const CustomIconInTextInactive = styled(CustomIconInTextBase)`
  svg {
    .fill-path {
      fill: ${primaryColor};
    }
    .stroke-path {
      fill: #fff;
    }
  }
`;
const LogoImg = styled.img<{$offline: boolean}>`
  position: absolute;
  top: ${({$offline}) => $offline ? -0.5 : -3.25}rem;
  left: 0.5rem;
  transform: translateY(-100%);
  width: 5rem;
  pointer-events: none;
`;

const Divider = styled.div`
  width: 75%;
  height: 0;
  border-bottom: 1px solid ${lightBorderColor};
  margin: 0.7rem auto 0.5rem;

  @media (max-height: 550px) {
    margin: 0.25rem auto;
  }
`;

const UtilityButton = styled(ShadowButtonLink)`
  padding: 0.35rem 0.75rem;
  box-sizing: border-box;
  text-align: left;
  margin-left: auto;
  width: 8rem;
  margin-bottom: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const EXPLORE_BUTTON_CLASSNAME = 'explore-nav-button';

type LinkInput = {
  route: string,
  label: string,
  isBottomNav: boolean;
  className?: string,
} & (
  {
    customIcon: true,
    icon: string,
  } | {
    customIcon: false,
    // icon is of type "any" due to an issue with TypeScript overloading memory
    icon: any,
  }
);

const Header = () => {
  const user = useCurrentUser();
  const { pathname } = useLocation();
  useRedirectWhenLoggedIn();
  const {offline} = useOfflineContext();

  const [userMenuOpen, setUserMenuOpenState] = useState<boolean>(false);
  const [hamburgerMenuOpen, setHamburgerMenuOpenState] = useState<boolean>(false);

  const setUserMenuOpen = (value: boolean) => {
    if (value) {
      setHamburgerMenuOpenState(false);
    }
    setUserMenuOpenState(value);
  };

  const setHamburgerMenuOpen = (value: boolean) => {
    if (value) {
      setUserMenuOpenState(false);
    }
    setHamburgerMenuOpenState(value);
  };

  const windowWidth = useWindowWidth();
  const getString = useFluent();
  const initialMapStyle = localStorage.getItem(storageCheckedKeyId);
  const [mapStyle, setMapStyle] = useState<MapStyle>(
    initialMapStyle && (initialMapStyle === MapStyle.standard || initialMapStyle === MapStyle.satellite)
    ? initialMapStyle : MapStyle.standard,
  );

  const createLink = useCallback((input: LinkInput) => {
    const {route, label} = input;
    let normalizedPathname: string;
    if (pathname.includes('saved')) {
      normalizedPathname = Routes.Saved;
    } else if (
      pathname.includes(Routes.YourTrips) ||
      pathname.includes(Routes.YourProgress) ||
      pathname.includes(Routes.YourTotals)
    ) {
      normalizedPathname = Routes.YourTrips;
    } else if (pathname.includes('user') &&
      !pathname.includes('settings') &&
      (!user || !pathname.includes(user._id))) {
      normalizedPathname = Routes.SearchUsers;
    } else if (pathname.includes('list')) {
      normalizedPathname = Routes.SearchLists;
    } else if (pathname.includes('mountain')) {
      normalizedPathname = Routes.SearchMountains;
    } else if (pathname.includes('campsite')) {
      normalizedPathname = Routes.SearchCampsites;
    } else if (pathname.includes('trail')) {
      normalizedPathname = Routes.SearchTrails;
    } else if (pathname.includes('routes')) {
      normalizedPathname = Routes.SavedRoutePlans;
    } else if (pathname.includes(Routes.About) ||
      pathname.includes(Routes.Contact) ||
      pathname.includes(Routes.TermsOfUse) ||
      pathname.includes(Routes.PrivacyPolicy) ||
      pathname.includes(Routes.DataDisclaimer)
    ) {
      normalizedPathname = Routes.About;
    } else {
      normalizedPathname = pathname;
    }
    const className = route === Routes.Saved ? 'header-dashboard-link ' + input.className : input.className;
    const Container = route === normalizedPathname ? ActiveNavLink : InactiveNavLink;
    let icon: React.ReactElement<any>;
    if (input.customIcon === true) {
      const IconContainer = route === normalizedPathname ? CustomIconInTextActive : CustomIconInTextInactive;
      icon = (
        <IconContainer
          dangerouslySetInnerHTML={{__html: input.icon}}
        />
      );
    } else {
      const IconContainer = route === normalizedPathname ? ActiveIconContainer : InactiveIconContainer;
      const Icon = input.isBottomNav ? BottomNavIcon : FontAwesomeIcon;
      icon = (
        <IconContainer>
          <Icon icon={input.icon} />
        </IconContainer>
      );
    }
    return (
      <Container
        className={className}
        to={route}
      >
        {icon}
        {label}
      </Container>
    );
  }, [pathname, user]);

  const hikingListsText = windowWidth > 320
    ? getString('header-text-menu-item-lists')
    : getString('header-text-menu-item-lists-short');

  const friendsLink = createLink({
    route: Routes.SearchUsers,
    label: getString('header-text-menu-item-friends'),
    customIcon: false,
    icon: faUserFriends,
    className: loggedInNavItemClassName,
    isBottomNav: false,
  });
  const exploreLink = createLink({
    route: Routes.Landing,
    label: getString('header-text-menu-item-explore'),
    customIcon: false,
    icon: faMap,
    className: EXPLORE_BUTTON_CLASSNAME,
    isBottomNav: true,
  });
  const logTripLink = createLink({
    route: Routes.YourTrips,
    label: getString('trip-logs'),
    customIcon: false,
    icon: faCalendarAlt,
    isBottomNav: true,
  });
  const planRouteLink = createLink({
    route: Routes.SavedRoutePlans,
    label: getString('saved-routes'),
    customIcon: false,
    icon: faRoute,
    isBottomNav: true,
  });
  const aboutLink = createLink({
    route: Routes.About,
    label: getString('header-text-menu-item-about'),
    customIcon: false,
    icon: faQuestionCircle,
    isBottomNav: false,
  });
  const listsLink = createLink({
    route: Routes.SearchLists,
    label: hikingListsText,
    customIcon: false,
    icon: faList,
    isBottomNav: true,
  });

  let notifications: React.ReactElement<any> | null;
  if (user) {
    notifications = <NotificationBar userId={user._id} />;
  } else {
    notifications = null;
  }

  const loginMenu = !user && !offline ? (
    <UserMenu
      userMenuOpen={userMenuOpen}
      setUserMenuOpen={setUserMenuOpen}
      user={user}
    />
  ) : null;

  if (windowWidth > mobileSize) {
    const logoSrc = mapStyle === MapStyle.satellite || pathname.includes('summit-view') ? LogoWhitePng : LogoPng;

    return (
      <>
        <HeaderContainer>
          <SideContent>
            <SemanticLogoContainer>
              <LogoContainer to={Routes.Landing}>
                {getString('global-text-value-wilderlist-name')}
                <Logo
                  src={logoSrc}
                  alt={getString('global-text-value-wilderlist-name')}
                  title={getString('global-text-value-wilderlist-name')}
                />
              </LogoContainer>
            </SemanticLogoContainer>
            <UtilityButton to={user ? Routes.AddTripReport : Routes.YourTrips}>
              <ShadowButtonIcon>
                <BasicIconInText icon={faCalendarAlt} />
              </ShadowButtonIcon>
              {getString('global-text-value-modal-log-a-trip')}
            </UtilityButton>
            <UtilityButton to={Routes.RoutePlan}>
              <ShadowButtonIcon>
                <BasicIconInText icon={faRoute} />
              </ShadowButtonIcon>
              {getString('global-create-route')}
            </UtilityButton>
            <UtilityButton
              to={Routes.OfflineMaps}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                if (!navigator?.serviceWorker?.controller) {
                  e.preventDefault();
                  window.location.href = Routes.OfflineMaps;
                }
              }}
            >
              <ShadowButtonIcon>
                <BasicIconInText icon={faCloudDownloadAlt} />
              </ShadowButtonIcon>
              {getString('offline-maps-title')}
            </UtilityButton>
            <div style={pathname.includes('summit-view') ? {display: 'none'} : undefined}>
              <Controls mapStyle={mapStyle} setMapStyle={setMapStyle} />
            </div>
          </SideContent>
          <CoreNav>
            {exploreLink}
            {listsLink}
            {planRouteLink}
            {logTripLink}
            <Divider />
            {aboutLink}
            <Divider />
            {friendsLink}
            <UserMenu
              userMenuOpen={userMenuOpen}
              setUserMenuOpen={setUserMenuOpen}
              user={user}
            />
          </CoreNav>
        </HeaderContainer>
        {notifications}
      </>
    );
  } else {
    const mapboxLogo = pathname === Routes.Landing ? (
      <>
        <LogoImg src={MapBoxLogoSVG} alt={'Mapbox'} title={'Mapbox'} $offline={offline} />
      </>
    ) : null;

    const moreContentMenu = user ? (
      <UserMenu
        userMenuOpen={userMenuOpen}
        setUserMenuOpen={setUserMenuOpen}
        user={user}
      />
    ) : (
      <HamburgerMenu
        hamburgerMenuOpen={hamburgerMenuOpen}
        setHamburgerMenuOpen={setHamburgerMenuOpen}
      />
    );

    return (
      <>
        <Controls mapStyle={mapStyle} setMapStyle={setMapStyle} />
        <HeaderContainer>
          <TopNav>
              <SemanticLogoContainer>
                <LogoContainer to={Routes.Landing}>
                  {getString('global-text-value-wilderlist-name')}
                  <Logo
                    src={LogoPng}
                    alt={getString('global-text-value-wilderlist-name')}
                    title={getString('global-text-value-wilderlist-name')}
                  />
                </LogoContainer>
              </SemanticLogoContainer>
              {loginMenu}
              <Search />
              {moreContentMenu}
          </TopNav>
        </HeaderContainer>
        {notifications}
        {pathname === Routes.Landing && !offline && <MobileActionButton />}
        <BottomContent>
          <BottomNav>
            {exploreLink}
            {listsLink}
            {planRouteLink}
            {logTripLink}
          </BottomNav>
          {mapboxLogo}
          <Copyright>
            {' © '}
            <Link to={Routes.About}>
              {getString('global-text-value-wilderlist-name')}
            </Link>
            {' | '}
            <Link to={Routes.TermsOfUse}>
              {getString('header-text-menu-terms-of-use')}
            </Link>
            {' | '}
            <Link to={Routes.PrivacyPolicy}>
              {getString('header-text-menu-privacy-policy')}
            </Link>
            {' | '}
            {' © '}
            <a href='https://www.mapbox.com/about/maps/' target='_blank' rel='noopener noreferrer'>
              MapBox
            </a>
            {' | '}
            {' © '}
            <a href='http://www.openstreetmap.org/copyright' target='_blank' rel='noopener noreferrer'>
              OSM
            </a>
            {' | '}
            <a href='https://www.mapbox.com/map-feedback/' target='_blank' rel='noopener noreferrer'>
              Improve this map
            </a>
          </Copyright>
        </BottomContent>
      </>
    );
  }

};

export default Header;
