import debounce from 'lodash/debounce';
import React from 'react';
import styled from 'styled-components/macro';
import {
  lightBorderColor,
  primaryColor,
} from '../../../../../styling/styleUtils';
import { mobileSize } from '../../../../../Utils';
import { formatAMPM } from '../../../../sharedComponents/detailComponents/weather/pointForecast/Utils';
import { WeatherState } from '../../map/weather';
import Legend from './Legend';

const DateSliderContainer = styled.div`
  margin-top: 0.25rem;
  padding: 0.35rem;
  width: 140px;
  background-color: #fff;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  input[type="range"] {
   -webkit-appearance: none;
  }

  input[type="range"]:focus {
   outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
   background: ${lightBorderColor};
   height: 5px;
  }

  input[type="range"]::-moz-range-track {
   background: ${lightBorderColor};
   height: 5px;
  }

  input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none;
   height: 15px;
   width: 15px;
   background: ${primaryColor};
   margin-top: -5px;
   border-radius: 50%;
  }

  input[type="range"]::-moz-range-thumb {
   height: 15px;
   width: 15px;
   background: ${primaryColor};
   margin-top: -5px;
   border-radius: 50%;
  }


  @media (max-height: 600px) {
    margin-top: 0.25rem;
  }

  @media (max-width: ${mobileSize}px) {
    max-width: 50px;
    grid-row: 3;
    grid-column: 1 /3;

    input[type="range"] {
      width: 100%;
    }
  }
`;

const DateText = styled.div`
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
`;

interface Props {
  mapWeather: WeatherState | null;
  setMapWeather: (value: WeatherState | null) => void;
}

const WeatherLegendWidget = ({ mapWeather, setMapWeather}: Props) => {
  if (mapWeather) {
    const timeOptions = mapWeather && mapWeather.allTimes ? mapWeather.allTimes.map(t => (
      <option
        key={t.toString()}
        value={t.toString()}
        defaultValue={mapWeather.activeTime.toString()}
      >
        {t.toString()}
      </option>
    )) : [];

    const onSlideChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      if (mapWeather && mapWeather.setTime) {
        const newMapWeather = mapWeather.setTime(mapWeather.allTimes[parseInt(e.target.value, 10)]);
        if (newMapWeather) {
          setMapWeather({ ...newMapWeather });
        } else {
          setMapWeather(null);
        }
      }
    }, 250);
    const indexOfTime = mapWeather
      ? mapWeather.allTimes.findIndex(t => t.toString() === mapWeather.activeTime.toString())
      : 0;
    const dateSlider = timeOptions.length > 1 ? (
      <>
        <input
          type='range'
          min='0'
          max={timeOptions.length - 1}
          step='1'
          list='weather-time-options'
          onChange={onSlideChange}
          defaultValue={indexOfTime !== -1 ? indexOfTime.toString() : '0'}
        />
        <datalist id='weather-time-options'>
          {timeOptions}
        </datalist>
      </>
    ) : null;
    return (
      <DateSliderContainer>
        <DateText>
          {formatAMPM(mapWeather.activeTime)}
        </DateText>
        {dateSlider}
        <Legend weatherType={mapWeather.type} />
      </DateSliderContainer>
    );
  }
  return null;
};

export default WeatherLegendWidget;
