const { point, featureCollection } = require('@turf/helpers');
const getCenter = require('@turf/center').default;
import { faList } from '@fortawesome/free-solid-svg-icons';
import orderBy from 'lodash/orderBy';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import useFluent from '../../../../hooks/useFluent';
import useMapContext from '../../../../hooks/useMapContext';
import useAppearsInLists from '../../../../queries/lists/useAppearsInLists';
import { listDetailLink, trailDetailLink } from '../../../../routing/Utils';
import { BasicIconInTextCompact, LinkButton } from '../../../../styling/styleUtils';
import { Latitude, Longitude } from '../../../../types/graphQLTypes';
import { AggregateItem, CoreItems } from '../../../../types/itemTypes';

const Root = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.4rem;
  display: flex;
`;

const IconContainer = styled(BasicIconInTextCompact)`
  transform: translateY(0.1rem);
  margin-right: 0.55rem;
  font-size: 0.65rem;
`;

interface Props {
  id: string;
  field: CoreItems;
  parentTrails: {id: string, name: string}[] | undefined;
}

interface LinkDatum {
  id: string;
  name: string;
  url: string;
  bbox?: [Longitude, Latitude, Longitude, Latitude] | null;
}

const AppearsIn = (props: Props) => {
  const { id, field, parentTrails } = props;
  const [showAllLists, setShowAllLists] = useState<boolean>(false);
  const getString = useFluent();
  const mapContext = useMapContext();
  const onMouseLeave = () => {
    if (mapContext.intialized) {
      mapContext.clearExternalHoveredPopup();
    }
  };
  useEffect(() => () => setShowAllLists(false), [id]);
  useEffect(() => {
    return () => {
      if (mapContext.intialized) {
        mapContext.clearExternalHoveredPopup();
      }
    };
  }, [mapContext]);
  const { data } = useAppearsInLists({ id, field });
  if ((data && data.appearsIn && data.appearsIn.length) || (parentTrails && parentTrails.length)) {

    const orderedLists: LinkDatum[] = data ? orderBy(
      data.appearsIn.map(d => ({
        ...d,
        totalItems: d.numMountains + d.numTrails + d.numCampsites,
        url: listDetailLink(d.id),
      })),
      ['isActive', 'totalItems'], ['desc', 'asc']) : [];
    if (parentTrails && parentTrails.length) {
      parentTrails.forEach(trail => {
        orderedLists.push({
          id: trail.id,
          name: trail.name,
          url: trailDetailLink(trail.id),
        });
      });
    }
    const slicedLists = orderedLists.length > 3 && !showAllLists ? orderedLists.slice(0, 2) : orderedLists;
    const lists = slicedLists
      .map((list, i) => {
        let seperator = ' ';
        if (i === slicedLists.length - 1 && slicedLists.length > 1) {
          // second to last link, use ampersand
          if (slicedLists.length >= orderedLists.length) {
            seperator = ' and ';
          } else {
            seperator = ', ';
          }
        } else if (slicedLists.length > 2 && i !== 0) {
          // else if not last link, add comma
          seperator = ', ';
        }
        const onMouseEnter = () => {
          if (mapContext.intialized && list.bbox) {
            const center = getCenter(featureCollection([
              point(list.bbox.slice(0, 2)),
              point(list.bbox.slice(2, 4)),
            ]));
            mapContext.setExternalHoveredPopup(
              list.name,
              AggregateItem.list,
              '',
              [center.geometry.coordinates[0], list.bbox[3]],
              undefined,
              list.bbox,
            );
          }
        };
        return (
          <React.Fragment key={'appears-in-' + list.id}>
            {seperator}
            <Link
              to={list.url}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {list.name}
            </Link>
          </React.Fragment>
        );
      });

    if (lists.length < orderedLists.length) {
      const andMoreText = parentTrails && parentTrails.length ? 'more' : 'more hiking lists';
      lists.push(
        <React.Fragment key={'appears-in-' + (orderedLists.length - lists.length)}>
          {' and '}
          <LinkButton onClick={() => setShowAllLists(true)}>
            {orderedLists.length - lists.length} {andMoreText}
          </LinkButton>
        </React.Fragment>,
      );
    }

    return (
      <Root>
        <IconContainer icon={faList} />
        <div>
          {getString('trail-parent-links')}
          {lists}
        </div>
      </Root>
    );

  }
  return null;
};

export default AppearsIn;
