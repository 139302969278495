import {
  faChartArea,
  faCrosshairs,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useFluent from '../../../hooks/useFluent';
import {useBasicViewpointDetails} from '../../../queries/viewpoints/useBasicViewpointDetails';
import { Routes, setViewpointOgImageUrl } from '../../../routing/routes';
import {viewpointDetailLink} from '../../../routing/Utils';
import {AuxiliaryItem} from '../../../types/itemTypes';
import DefaultError from '../../sharedComponents/DefaultError';
import FormattedCoordinates from '../../sharedComponents/detailComponents/header/FormattedCoordinates';
import FormattedElevation from '../../sharedComponents/detailComponents/header/FormattedElevation';
import SimpleHeader, { Detail } from '../../sharedComponents/detailComponents/header/SimpleHeader';
import TripsAndNotes from '../../sharedComponents/detailComponents/TripsAndNotes';
import MapRenderProp from '../../sharedComponents/MapRenderProp';
import {viewpointNeutralSvg} from '../../sharedComponents/svgIcons';

interface Props {
  id: string;
}

const ViewpointDetail = (props: Props) => {
  const { id } = props;

  const getString = useFluent();
  const history = useHistory();

  const {loading, data, error} = useBasicViewpointDetails(id);
  const {offline} = useOfflineContext();

  if (error && !data) {
    if (offline) {
      return null;
    }
    return <DefaultError error={error} />;
  }

  let title: string = '----';
  let subtitle: string = '----';
  let authorId: null | string = null;
  let map: React.ReactElement<any> | null = null;
  if (data !== undefined) {
    const { viewpoint } = data;
    if (!viewpoint) {
      history.replace(Routes.NotFound);
      return null;
    } else {
      title = viewpoint.name !== null ? viewpoint.name : getString('global-text-value-viewpoint');
      subtitle = viewpoint.locationText ? viewpoint.locationText : '';
      authorId = viewpoint.author ? viewpoint.author.id : null;
      map = (
        <MapRenderProp
          id={viewpoint.id}
          viewpoints={[viewpoint]}
          center={viewpoint.location}
        />
      );
    }
  }

  const metaDescription = data && data.viewpoint
    ? getString('meta-data-viewpoint-detail-description', {
      name: data.viewpoint.name,
    })
    : null;
  const metaTitle = data && data.viewpoint ? getString('meta-data-detail-default-title', {
    title: (data.viewpoint.name || 'Viewpoint') + ', ' + data.viewpoint.locationTextShort, type: '',
  }) : '';
  const metaData = metaDescription && data && data.viewpoint ? (
    <Helmet>
      <title>{metaTitle}</title>
      <meta
        name='description'
        content={metaDescription}
      />
      <meta property='og:title' content={metaTitle} />
      <meta
        property='og:description'
        content={metaDescription}
      />
      <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + viewpointDetailLink(id)} />
      <meta property='og:image' content={setViewpointOgImageUrl(id)} />
    </Helmet>
  ) : null;

  const details: Detail[] = [
    {
      text: (
        <FormattedElevation
          elevation={data && data.viewpoint && data.viewpoint.elevation ? data.viewpoint.elevation : undefined}
        />
      ),
      icon: faChartArea,
    }, {
      text: (
        <FormattedCoordinates
          coordinates={data && data.viewpoint && data.viewpoint.location ? data.viewpoint.location : undefined}
          noPadding={true}
        />
      ),
      icon: faCrosshairs,
    },
  ];

  return (
    <>
      {metaData}
      <SimpleHeader
        id={id}
        loading={loading}
        title={title}
        subtitle={subtitle}
        customIcon={true}
        icon={viewpointNeutralSvg}
        authorId={authorId}
        type={AuxiliaryItem.viewpoint}
        primaryDetails={details}
      />
      <TripsAndNotes
        id={id}
        name={title}
        item={AuxiliaryItem.viewpoint}
      />
      {map}
    </>
  );
};

export default ViewpointDetail;
