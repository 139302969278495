import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useMapContext from '../../../../hooks/useMapContext';
import { campsiteDetailLink, mountainDetailLink, trailheadDetailLink, viewpointDetailLink, waterpointDetailLink } from '../../../../routing/Utils';
import { BlockContainer, BlockHeaderVerticalCenter, BlockHeaderWithMargin } from '../../../../styling/sharedContentStyles';
import {
  IconContainerLarge,
  lightBaseColor,
  SemiBold,
} from '../../../../styling/styleUtils';
import { AuxiliaryItem, CoreItem } from '../../../../types/itemTypes';
import { Coordinate } from '../../../../worker/core/types';
import { mountainNeutralSvg, tentNeutralSvg, trailDefaultSvg, trailheadDefaultSvg, viewpointNeutralSvg, waterpointNeutralSvg } from '../../svgIcons';

const IconContainer = styled(IconContainerLarge)`
  margin-left: 0.75rem;
`;

export type Place = PropsWithChildren<{
  id: string;
  name: string;
  type: CoreItem | AuxiliaryItem;
  location: Coordinate;
}>;

const Block = ({id, name, type, location, children}: Place) => {
  const mapContext = useMapContext();
  const onMouseLeave = () => {
    if (mapContext.intialized) {
      mapContext.clearExternalHoveredPopup();
    }
  };
  useEffect(() => {
    return () => {
      if (mapContext.intialized) {
        mapContext.clearExternalHoveredPopup();
      }
    };
  }, [mapContext]);

  const [link, icon] = useMemo(() => {
    if (type === CoreItem.mountain) {
      return [
        mountainDetailLink(id),
        mountainNeutralSvg,
      ];
    } else if (type === CoreItem.campsite) {
      return [
        campsiteDetailLink(id),
        tentNeutralSvg,
      ];
    } else if (type === AuxiliaryItem.viewpoint) {
      return [
        viewpointDetailLink(id),
        viewpointNeutralSvg,
      ];
    } else if (type === AuxiliaryItem.waterpoint) {
      return [
        waterpointDetailLink(id),
        waterpointNeutralSvg,
      ];
    } else if (type === CoreItem.trail) {
      return [
        trailheadDetailLink(id),
        trailDefaultSvg,
      ];
    } else if (type === AuxiliaryItem.trailhead) {
      return [
        trailheadDetailLink(id),
        trailheadDefaultSvg,
      ];
    } else {
      return [
        '#',
        '',
        undefined,
      ];
    }
  }, [type, id]);

  const onMouseEnter = () => {
    if (mapContext.intialized) {
    mapContext.setExternalHoveredPopup(
        name,
        type,
        '',
        location,
      );
    }
  };

  return (
    <BlockContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <BlockHeaderWithMargin>
        <Link to={link}>
          <SemiBold>{name}</SemiBold>
        </Link>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: icon}}
        />
      </BlockHeaderWithMargin>
      <BlockHeaderVerticalCenter>
        {children}
      </BlockHeaderVerticalCenter>
    </BlockContainer>
  );
};

export default Block;
