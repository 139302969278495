const midpoint = require('@turf/midpoint').default;
const {point} = require('@turf/helpers');
import {Searcher} from 'fast-fuzzy';
import uniqBy from 'lodash/uniqBy';
import { getApolloClient } from '../../../../contextProviders/ApolloClientContext';
import { Coordinate, PeakListVariants } from '../../../../types/graphQLTypes';
import { SearchResultType } from '../../../../types/itemTypes';
import { SearchResultDatum } from './Utils';

let searcher: Searcher<SearchResultDatum, any> | undefined;

const buildSearcher = () => {
  const client = getApolloClient();
  if (client) {
    const cachedArray: SearchResultDatum[] = [];
    const cached = client.cache.extract();
    Object.entries(cached).forEach(([, value]: [string, Record<string, any>]) => {
      switch (value.__typename) {
        case 'PeakListType':
          if (
            Object.hasOwn(value, 'id') &&
            Object.hasOwn(value, 'name') &&
            typeof value.name === 'string' &&
            value.name.length > 0 &&
            Object.hasOwn(value, 'bbox') &&
            value.bbox.length === 4 &&
            Object.hasOwn(value, 'locationText') &&
            Object.hasOwn(value, 'type') &&
            value.type === PeakListVariants.standard
          ) {
            let numPeaks = 0;
            if (value.numPeaks) {
              numPeaks = value.numPeaks;
            } else if (value.mountains && Array.isArray(value.mountains) && value.mountains.length) {
              numPeaks = value.mountains.length;
            }
            let numTrails = 0;
            if (value.numTrails) {
              numTrails = value.numTrails;
            } else if (value.trails && Array.isArray(value.trails) && value.trails.length) {
              numTrails = value.trails.length;
            }
            let numCampsites = 0;
            if (value.numCampsites) {
              numCampsites = value.numCampsites;
            } else if (value.campsites && Array.isArray(value.campsites) && value.campsites.length) {
              numCampsites = value.campsites.length;
            }
            cachedArray.push({
              id: value.id,
              name: value.name,
              type: SearchResultType.list,
              distance: 0,
              coordinates: midpoint(
                point([value.bbox[0], value.bbox[1]]),
                point([value.bbox[2], value.bbox[3]]),
              ).geometry.coordinates,
              numPeaks,
              numTrails,
              numCampsites,
              locationText: value.locationText,
            });
          }
          break;
        case 'MountainType':
          if (
            Object.hasOwn(value, 'id') &&
            Object.hasOwn(value, 'name') &&
            typeof value.name === 'string' &&
            value.name.length > 0 &&
            Object.hasOwn(value, 'location') &&
            Object.hasOwn(value, 'locationText') &&
            Object.hasOwn(value, 'elevation')
          ) {
            cachedArray.push({
              id: value.id,
              name: value.name,
              type: SearchResultType.mountain,
              distance: 0,
              coordinates: value.location,
              locationText: value.locationText,
              elevation: value.elevation,
            });
          }
          break;
        case 'CampsiteType':
          if (
            Object.hasOwn(value, 'id') &&
            Object.hasOwn(value, 'name') &&
            typeof value.name === 'string' &&
            value.name.length > 0 &&
            Object.hasOwn(value, 'location') &&
            Object.hasOwn(value, 'locationText') &&
            Object.hasOwn(value, 'type')
          ) {
            cachedArray.push({
              id: value.id,
              name: value.name,
              type: SearchResultType.campsite,
              distance: 0,
              coordinates: value.location,
              locationText: value.locationText,
              campsiteType: value.type,
            });
          }
          break;
        case 'TrailType':
          if (
            Object.hasOwn(value, 'id') &&
            Object.hasOwn(value, 'name') &&
            typeof value.name === 'string' &&
            value.name.length > 0 &&
            Object.hasOwn(value, 'roadAccessPoint') &&
            Array.isArray(value.roadAccessPoint) &&
            value.roadAccessPoint.length === 2 &&
            Object.hasOwn(value, 'locationText') &&
            Object.hasOwn(value, 'totalTrailMiles') &&
            typeof value.totalTrailMiles === 'number' &&
            value.totalTrailMiles > 0
          ) {
            cachedArray.push({
              id: value.id,
              name: value.name,
              type: SearchResultType.trailhead,
              distance: 0,
              coordinates: value.roadAccessPoint as Coordinate,
              locationText: value.locationText,
              totalTrailMiles: value.totalTrailMiles,
            });
          }
          break;
        case 'ViewpointType':
          if (
            Object.hasOwn(value, 'id') &&
            Object.hasOwn(value, 'name') &&
            typeof value.name === 'string' &&
            value.name.length > 0 &&
            Object.hasOwn(value, 'location') &&
            Object.hasOwn(value, 'locationText') &&
            Object.hasOwn(value, 'elevation')
          ) {
            cachedArray.push({
              id: value.id,
              name: value.name,
              type: SearchResultType.viewpoint,
              distance: 0,
              coordinates: value.location,
              locationText: value.locationText,
              elevation: value.elevation,
            });
          }
          break;
        case 'ParkType':
          if (
            Object.hasOwn(value, 'id') &&
            Object.hasOwn(value, 'name') &&
            typeof value.name === 'string' &&
            value.name.length > 0 &&
            Object.hasOwn(value, 'center') &&
            Object.hasOwn(value, 'locationText') &&
            Object.hasOwn(value, 'areaSquareMeters')
          ) {
            cachedArray.push({
              id: value.id,
              name: value.name,
              type: SearchResultType.park,
              distance: 0,
              coordinates: value.center,
              locationText: value.locationText,
              areaSquareMeters: value.areaSquareMeters,
            });
          }
          break;
        default:
          break;
      }
    });
    const uniqueCachedArray = uniqBy(cachedArray, 'id');
    searcher = new Searcher(uniqueCachedArray, {
      keySelector: (obj: SearchResultDatum) => obj.name,
    });
  }
};

const offlineSearch = (query: string): SearchResultDatum[] => {
  if (!searcher) {
    buildSearcher();
  }
  if (searcher) {
    const results = searcher.search(query) as SearchResultDatum[];
    return results.slice(0, 7);
  }
  return [];
};

export default offlineSearch;
