import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFluent from '../../../../hooks/useFluent';
import { CommentDatum } from '../../../../queries/comments/useCommentsFor';
import { baseColor, GhostButton, lightBorderColor, Subtext } from '../../../../styling/styleUtils';
import AreYouSureModal from '../../AreYouSureModal';
import StaticStars from './StaticStars';

export const CommentRoot = styled.div`
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-top: solid 1px ${lightBorderColor};
  position: relative;
`;

const CommentText = styled.div`
  margin-top: 0.25rem;
  color: ${baseColor};
  font-size: 0.875rem;
`;

const DeleteButton = styled(GhostButton)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
`;

interface Props extends CommentDatum {
  deleteComment: () => void;
}

const Comment = (props: Props) => {
  const getString = useFluent();
  const {
    id, created, rating, comment, deleteComment,
  } = props;
  const currentUser = useCurrentUser();
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState<boolean>(false);
  const handleDelete = () => {
    deleteComment();
    setAreYouSureModalOpen(false);
  };

  const authorName = !props.author || (
    props.author.hideProfileInSearch && (!currentUser || currentUser._id !== props.author.id)
  )
    ? getString('global-text-value-generic-user')
    : props.author.name;

  const date = new Date(parseInt(created, 10))
    .toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});

  const deleteIcon = currentUser && props.author && currentUser._id === props.author.id ? (
    <DeleteButton
      onClick={() => setAreYouSureModalOpen(true)}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </DeleteButton>
  ) : null;

  const areYouSureDelete = areYouSureModalOpen ? (
    <AreYouSureModal
      onConfirm={handleDelete}
      onCancel={() => setAreYouSureModalOpen(false)}
      title={getString('global-text-value-delete-review')}
      text={getString('global-text-value-delete-review-are-you-sure')}
      confirmText={getString('global-text-value-modal-confirm')}
      cancelText={getString('global-text-value-modal-cancel')}
    />
  ) : null;

  return (
    <CommentRoot>
      <StaticStars
        rating={rating}
        id={id}
      />
      <div>
        <Subtext>
          <em>Posted {date} by {authorName}</em>
        </Subtext>
      </div>
      <CommentText>
        {comment}
      </CommentText>
      {deleteIcon}
      {areYouSureDelete}
    </CommentRoot>
  );
};

export default Comment;
