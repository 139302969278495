import { useMutation, useQuery } from '@apollo/client';
import { ADD_PARK_NOTE, EDIT_PARK_NOTE, GET_PARK_NOTE, ParkNoteSuccess, QuerySuccessResponse, QueryVariables, Variables } from './query';

export const useParkNote = (variables: QueryVariables) => useQuery<QuerySuccessResponse, QueryVariables>(
  GET_PARK_NOTE, {variables});

export const useAddParkNote = () => {
  const [addParkNote] = useMutation<ParkNoteSuccess, Variables>(ADD_PARK_NOTE);
  return addParkNote;
};
export const useEditParkNote = () => {
  const [editParkNote] = useMutation<ParkNoteSuccess, Variables>(EDIT_PARK_NOTE);
  return editParkNote;
};
