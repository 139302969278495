const {lineString} = require('@turf/helpers');
import togpx from 'togpx';
import {Coordinate, CoordinateWithElevation} from '../types/graphQLTypes';

enum SteepnessClass {
  Level = 'flat',
  NearlyLevel = 'nearly flat',
  VeryGentleSlope = 'very gentle',
  GentleSlope = 'gentle',
  ModerateSlope = 'moderate',
  StrongSlope = 'moderately steep',
  VeryStrongSlope = 'steep',
  ExtremeSlope = 'very steep',
  SteepSlope = 'extremely steep',
  VerySteepSlope = 'extremely steep',
}

export const slopeToSteepnessClass = (slope: number) => {
  if (slope < 0.5) {
    return SteepnessClass.Level;
  } else if (slope < 1.1) {
    return SteepnessClass.NearlyLevel;
  } else if (slope < 3) {
    return SteepnessClass.VeryGentleSlope;
  } else if (slope < 5) {
    return SteepnessClass.GentleSlope;
  } else if (slope < 8) {
    return SteepnessClass.ModerateSlope;
  } else if (slope < 13) {
    return SteepnessClass.StrongSlope;
  } else if (slope < 18) {
    return SteepnessClass.VeryStrongSlope;
  } else if (slope < 25) {
    return SteepnessClass.ExtremeSlope;
  } else if (slope < 35) {
    return SteepnessClass.SteepSlope;
  } else {
    return SteepnessClass.VerySteepSlope;
  }
};

interface GPXInput {
  name: string;
  line: (Coordinate | CoordinateWithElevation)[];
  url: string;
}

export const downloadGPXString = ({name, line, url}: GPXInput) => {
  const strippedLine = line.map(([lng, lat]) => [lng, lat]);
  const geojson = lineString(strippedLine, {name, url});
  const gpx = togpx(geojson, {
    creator: 'Wilderlist',
    metadata: {
      copyright: 'Wilderlist, Open Street Map',
      source: 'https://wilderlist.app/',
      time: new Date(),
    },
    featureTitle: () => name,
    featureLink: () => url,
  });

  const filename = name + '.gpx';
  const link = document.createElement('a');
  const blob = new Blob([gpx], {type: 'text/plain'});

  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', filename);

  link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
  link.draggable = true;
  link.classList.add('dragout');

  link.click();
  link.remove();
};

export const getTrailAccessNice = (value: number) => {
  if (value < 1) {
    return `Access to ${parseFloat(value.toFixed(2))} miles of trails`;
  } else if (value === 1) {
    return `Access to 1 mile of trail`;
  } else if (value < 1.5) {
    return `Access to over 1 mile of trail`;
  } else if (value < 2) {
    return `Access to nearly 2 miles of trails`;
  } else if (value < 10) {
    return `Access to over ${Math.floor(value)} miles of trails`;
  } else {
    return `Access to over ${Math.floor(value / 10) * 10} miles of trails`;
  }
};

export const milesToMeters = (miles: number) => miles * 1609.34;

export const milesToKm = (miles: number) => miles * 1.60934;

export const metersToMiles = (meters: number) => meters * 0.000621371;
