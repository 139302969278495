import { faCrow, faHiking, faRoute } from '@fortawesome/free-solid-svg-icons';
import getCenter from '@turf/center';
import upperFirst from 'lodash/upperFirst';
import { Types } from 'mongoose';
import React, { useEffect, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { Feature } from '../../../../hooks/servicesHooks/pathfinding/simpleCache';
import useFluent from '../../../../hooks/useFluent';
import useMapContext from '../../../../hooks/useMapContext';
import { RoutesToPointInput } from '../../../../routing/services';
import { campsiteDetailLink, mountainDetailLink, routePlanLink, trailheadDetailLink, viewpointDetailLink, waterpointDetailLink } from '../../../../routing/Utils';
import { BlockContainer, BlockHeaderVerticalCenter, BlockHeaderWithMargin, Column, DetailsCompact } from '../../../../styling/sharedContentStyles';
import {
  BasicIconInText,
  HighlightedLargeIconInText,
  IconContainerLarge,
  LargeIconInText,
  lightBaseColor,
  primaryColor,
  SemiBold,
  SmallLink,
  Subtext,
} from '../../../../styling/styleUtils';
import { AuxiliaryItem } from '../../../../types/itemTypes';
import { mountainNeutralSvg, tentNeutralSvg, trailDefaultSvg, viewpointNeutralSvg, waterpointNeutralSvg } from '../../svgIcons';

const IconContainer = styled(IconContainerLarge)`
  margin-left: 0.75rem;
`;

interface Props {
  feature: Feature;
  originName: string;
  destinationType: RoutesToPointInput['destination'];
}

const PlaceBlock = ({originName, feature, destinationType}: Props) => {
  const {
    properties: {
      destination,
    },
    properties,
    geometry,
  } = feature;
  const getString = useFluent();
  const mapContext = useMapContext();
  const onMouseLeave = () => {
    if (mapContext.intialized) {
      mapContext.clearExternalHoveredPopup();
    }
  };
  useEffect(() => {
    return () => {
      if (mapContext.intialized) {
        mapContext.clearExternalHoveredPopup();
      }
    };
  }, [mapContext]);

  let destinationName: string;
  if (destination && destination.name) {
    destinationName = destination.name;
  } else {
    destinationName = upperFirst(getString('global-formatted-anything-type', {
      type: destination && destination.type ? destination.type : '',
    }));
  }
  let routeDistance: string | undefined;
  if (
    properties.routeLength !== undefined && properties.routeLength !== null
  ) {
    const routeLengthOutAndBack = properties.routeLength;
    routeDistance = routeLengthOutAndBack < 0.1
      ? getString('global-text-value-feet-one-way', {dist: Math.round(routeLengthOutAndBack * 5280)})
      : getString('global-text-value-mile-one-way', {dist: parseFloat(routeLengthOutAndBack.toFixed(1))});
  }

  const onMouseEnter = () => {
    if (mapContext.intialized) {
    mapContext.setExternalHoveredPopup(
        destinationName,
        AuxiliaryItem.trailhead,
        routeDistance ? routeDistance : '',
        destination.location,
        geometry.type === 'LineString' ? geometry.coordinates : undefined,
      );
    }
  };

  const validTrails = properties.trails !== undefined
  ? properties.trails.filter(t => t.name && t.type && Types.ObjectId.isValid(t.id))
  : [];

  let linkWithState: LinkProps['to'] | undefined;
  let trailRouteContent: React.ReactElement<any>;
  if (routeDistance !== undefined) {
    linkWithState = {
      pathname: routePlanLink(),
      state: {
        routePlan: {
          id: null,
          name: 'Hike to ' + destinationName,
          visibility: 'public',
          autoGenerated: false,
          authors: null,
          authorVisibility: 'public',
          totalLength: properties.routeLength,
          date: new Date(),
          segments: [{
            name: `From ${originName} to ${destinationName}`,
            color: primaryColor,
            lines: [[...geometry.coordinates].reverse().map(coord => coord.map(Number))],
            trails: validTrails,
            hashKey: new Types.ObjectId().toString(),
          }],
          center: getCenter(feature).geometry.coordinates,
        },
      },
    };
    trailRouteContent = (
      <>
        <BlockHeaderVerticalCenter>
            <HighlightedLargeIconInText icon={faHiking} />
            <Column>
              <SemiBold>{routeDistance}</SemiBold>
              <DetailsCompact>
                <Subtext>
                  <em>from {originName}</em>
                </Subtext>
                <Subtext>
                  <em>to {destinationName}</em>
                </Subtext>
              </DetailsCompact>
            </Column>
        </BlockHeaderVerticalCenter>
      </>
    );
  } else if (properties.crowFliesDistance !== null && properties.crowFliesDistance !== undefined) {
    const distanceUnit = properties.crowFliesDistance < 0.1
      ? getString('distance-feet-formatted', {feet: Math.round(properties.crowFliesDistance * 5280)})
      : getString('directions-driving-distance', {miles: parseFloat(properties.crowFliesDistance.toFixed(1))});
    trailRouteContent = (
      <>
        <BlockHeaderVerticalCenter>
            <LargeIconInText icon={faCrow} />
            <Column>
              <SemiBold>{distanceUnit} {getString('crow-flies').split(' ').join('-')}</SemiBold>
              <DetailsCompact>
                <Subtext>
                  <em>from {originName}</em>
                </Subtext>
                <Subtext>
                  <em>to {destinationName}</em>
                </Subtext>
              </DetailsCompact>
            </Column>
        </BlockHeaderVerticalCenter>
      </>
    );
  } else {
    trailRouteContent = (
      <small>
        <SmallLink to={routePlanLink()}>
          <small><BasicIconInText icon={faRoute} /></small>
          {getString('global-text-value-create-route')}
        </SmallLink>
      </small>
    );
  }

  const [link, icon] = useMemo(() => {
    if (destinationType === 'mountains') {
      return [
        mountainDetailLink(destination._id),
        mountainNeutralSvg,
      ];
    } else if (destinationType === 'campsites') {
      return [
        campsiteDetailLink(destination._id),
        tentNeutralSvg,
      ];
    } else if (destinationType === 'viewpoints') {
      return [
        viewpointDetailLink(destination._id),
        viewpointNeutralSvg,
      ];
    } else if (destinationType === 'waterpoints') {
      return [
        waterpointDetailLink(destination._id),
        waterpointNeutralSvg,
      ];
    } else if (destinationType === 'trailheads') {
      return [
        trailheadDetailLink(destination._id),
        trailDefaultSvg,
      ];
    } else {
      return [
        '#',
        '',
      ];
    }
  }, [destinationType, destination._id]);

  return (
    <BlockContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <BlockHeaderWithMargin>
        <Link to={linkWithState ? linkWithState : link}>
          <SemiBold>{destinationName}</SemiBold>
        </Link>
        <IconContainer
          $color={lightBaseColor}
          dangerouslySetInnerHTML={{__html: icon}}
        />
      </BlockHeaderWithMargin>
      {trailRouteContent}
    </BlockContainer>
  );
};

export default PlaceBlock;
