const {point} = require('@turf/helpers');
const distance = require('@turf/distance').default;
import partition from 'lodash/partition';
import React from 'react';
import Helmet from 'react-helmet';
import useFluent from '../../../hooks/useFluent';
import useMapCenter from '../../../hooks/useMapCenter';
import { useGeoBoxMountains } from '../../../queries/mountains/useGeoBoxMountains';
import { mountainDetailLink } from '../../../routing/Utils';
import { CoreItem } from '../../../types/itemTypes';
import DefaultError from '../../sharedComponents/DefaultError';
import GhostDetailCard from '../../sharedComponents/GhostDetailCard';
import Results from '../../sharedComponents/listComponents/Results';
import MapRenderProp, {MountainGeoDatum} from '../../sharedComponents/MapRenderProp';
import {mountainNeutralSvg} from '../../sharedComponents/svgIcons';
import CreateMountainButton from './CreateMountainButton';

const MountainSearchPage = () => {
  const getString = useFluent();

  const mapCenter = useMapCenter();
  const mapCenterPoint = point(mapCenter);
  const {loading, error, data} = useGeoBoxMountains({limit: 30});

  let list: React.ReactElement<any> | null;
  if (loading === true && data === undefined) {
    const loadingCards: React.ReactElement<any>[] = [];
    for (let i = 0; i < 3; i++) {
      loadingCards.push(<GhostDetailCard key={i} />);
    }
    list = <>{loadingCards}</>;
  } else if (error !== undefined) {
    list = (
      <DefaultError
        error={error}
        offlineMessage={'Connect to the internet to search for mountains.'}
      />
    );
  } else if (data !== undefined) {
    if (!data.mountains) {
      const loadingCards: React.ReactElement<any>[] = [];
      for (let i = 0; i < 3; i++) {
        loadingCards.push(<GhostDetailCard key={i} />);
      }
      list = <>{loadingCards}</>;
    } else {
      const [withTrail, withoutTrail] = partition(
        data.mountains, m => m.trailAccessMiles !== null && m.trailAccessMiles <= 0.3,
      );
      const mountainMapPoints: MountainGeoDatum[] = [];
      const mountains = [...withTrail, ...withoutTrail].map(m => {
        if (!loading) {
          mountainMapPoints.push({
            id: m.id,
            name: m.name,
            elevation: m.elevation,
            location: m.location,
          });
        }
        return {
          id: m.id,
          title: m.name,
          location: m.location,
          locationText: m.locationText,
          elevation: m.elevation,
          type: CoreItem.mountain,
          url: mountainDetailLink(m.id),
          icon: mountainNeutralSvg,
          customIcon: true,
          distanceToCenter: distance(mapCenterPoint, point(m.location)),
          trailAccessible: m.trailAccessMiles !== null && m.trailAccessMiles <= 0.3,
          roadAccessible: m.roadAccessMiles !== null && m.roadAccessMiles < 0.1,
        };
      });
      const mapRenderProp = loading ? null : (
          <MapRenderProp
            id={'search-for-mountains'}
            mountains={mountainMapPoints}
          />
      );
      list = (
        <>
          <Results
            data={mountains as any}
            type={CoreItem.mountain}
          />
          {mapRenderProp}
        </>
      );
    }
  } else {
    const loadingCards: React.ReactElement<any>[] = [];
    for (let i = 0; i < 3; i++) {
      loadingCards.push(<GhostDetailCard key={i} />);
    }
    list = <>{loadingCards}</>;
  }

  const metaDescription = getString('meta-data-mountain-search-description');

  return (
    <>
      <Helmet>
        <title>{getString('meta-data-mtn-search-default-title')}</title>
        <meta
          name='description'
          content={metaDescription}
        />
        <meta property='og:title' content={getString('meta-data-mtn-search-default-title')} />
        <meta
          property='og:description'
          content={metaDescription}
        />
        <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + mountainDetailLink('search')} />
      </Helmet>
      {list}
       <CreateMountainButton />
    </>
  );
};

export default MountainSearchPage;
