import {
  faBinoculars,
  faCampground,
  faMapSigns,
  faMountain,
  faStar,
  faTree,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import useFluent from '../../hooks/useFluent';
import { Routes } from '../../routing/routes';
import TrackedRoute from '../../routing/TrackedRoute';
import CampsiteDetailPage from '../campsites/detail';
import SavedPlaces from '../dashboard/SavedPlaces';
import MountainDetailPage from '../mountains/detail';
import ParkDetailPage from '../parks/detail';
import TabNav, { LinkDatum, Root } from '../sharedComponents/detailComponents/TabNav';
import TrailheadDetailPage from '../trails/trailhead';
import ViewpointDetailPage from '../viewpoints/detail';

const NavRoute = styled.div`
  margin-top: -1rem;
`;

const SearchPlaces = () => {
  const getString = useFluent();
  const links: LinkDatum[] = [
    {
      title: getString('saved-places'),
      route: Routes.Saved,
      customIcon: false,
      icon: faStar,
      routeState: {noScrollOnLoad: true},
    },
    {
      title: getString('global-text-value-parks'),
      route: Routes.SearchParks,
      customIcon: false,
      icon: faTree,
      routeState: {noScrollOnLoad: true},
    },
    {
      title: getString('global-text-value-mountains'),
      route: Routes.SearchMountains,
      customIcon: false,
      icon: faMountain,
      routeState: {noScrollOnLoad: true},
    },
    {
      title: getString('global-text-value-camping'),
      route: Routes.SearchCampsites,
      customIcon: false,
      icon: faCampground,
      routeState: {noScrollOnLoad: true},
    },
    {
      title: getString('global-text-value-views'),
      route: Routes.SearchViewpoints,
      customIcon: false,
      icon: faBinoculars,
      routeState: {noScrollOnLoad: true},
    },
    {
      title: getString('global-text-value-trailheads'),
      route: Routes.SearchTrails,
      customIcon: false,
      icon: faMapSigns,
      routeState: {noScrollOnLoad: true},
    },
  ];

  return (
    <>
    <NavRoute>
      <TabNav
        links={links}
      />
    </NavRoute>
    <Switch>
      <TrackedRoute exact path={Routes.Saved}>
        <Root>
          <SavedPlaces />
        </Root>
      </TrackedRoute>
      <TrackedRoute exact path={Routes.ParkDetail} component={ParkDetailPage} />
      <TrackedRoute exact path={Routes.MountainDetail} component={MountainDetailPage} />
      <TrackedRoute exact path={Routes.CampsiteDetail} component={CampsiteDetailPage} />
      <TrackedRoute exact path={Routes.ViewpointDetail} component={ViewpointDetailPage} />
      <TrackedRoute exact path={Routes.TrailDetail} component={TrailheadDetailPage} />
    </Switch>
    </>
  );
};

export default SearchPlaces;
