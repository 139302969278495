import { gql } from '@apollo/client';
import {
  Mountain,
  User,
} from '../../../types/graphQLTypes';

export const GET_MOUNTAIN_DETAIL = gql`
  query getMountain($id: ID) {
    mountain(id: $id) {
      id
      name
      elevation
      location
      locationText
      locationTextShort
      roadAccessMiles
      trailAccessMiles
      author {
        id
      }
      status
    }
  }
`;

export interface QuerySuccessResponse {
  mountain: null | {
    id: Mountain['id'];
    __typename: string;
    name: Mountain['name'];
    elevation: Mountain['elevation'];
    location: Mountain['location'];
    locationText: Mountain['locationText'];
    locationTextShort: Mountain['locationTextShort'];
    roadAccessMiles: Mountain['roadAccessMiles'];
    trailAccessMiles: Mountain['trailAccessMiles'];
    author: null | { id: User['id'] };
    status: Mountain['status'];
  };
}

export interface QueryVariables {
  id: string | null;
}
