import React from 'react';
import { useOfflineContext } from '../../../../../contextProviders/OfflineContext';
import {AggregateItem, AuxiliaryItem, CoreItem, UserContentItem} from '../../../../../types/itemTypes';
import { failIfValidOrNonExhaustive } from '../../../../../Utils';
import StarCampsiteButton from './StarCampsiteButton';
import StarMountainButton from './StarMountainButton';
import StarParkButton from './StarParkButton';
import StarRoutePlanButton from './StarRoutePlanButton';
import StarTrailButton from './StarTrailButton';
import StarTrailheadButton from './StarTrailheadButton';
import StarViewpointButton from './StarViewpointButton';
import StarWaterpointButton from './StarWaterpointButton';

interface Props {
  type: CoreItem | AggregateItem.parentTrail | AuxiliaryItem | UserContentItem;
  id: string;
  compact?: boolean;
}

const StarButtonWrapper = ({type, id, compact}: Props) => {
  const {offline} = useOfflineContext();
  if (offline) {
    return null;
  }
  if (type === CoreItem.mountain) {
    return (
      <StarMountainButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === CoreItem.trail || type === AggregateItem.parentTrail) {
    return (
      <StarTrailButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === CoreItem.campsite) {
    return (
      <StarCampsiteButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === AuxiliaryItem.trailhead) {
    return (
      <StarTrailheadButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === AuxiliaryItem.viewpoint) {
    return (
      <StarViewpointButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === AuxiliaryItem.waterpoint) {
    return (
      <StarWaterpointButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === AuxiliaryItem.park) {
    return (
      <StarParkButton
        id={id}
        compact={compact}
      />
    );
  } else if (type === UserContentItem.route) {
    return (
      <StarRoutePlanButton
        id={id}
        compact={compact}
      />
    );
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid type ' + type);
  }
};

export default StarButtonWrapper;
