const {point} = require('@turf/helpers');
const distance = require('@turf/distance').default;
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import Helmet from 'react-helmet';
import useFluent from '../../../hooks/useFluent';
import useMapCenter from '../../../hooks/useMapCenter';
import { useGeoBoxParks } from '../../../queries/parks/useGeoBoxParks';
import { parkDetailLink } from '../../../routing/Utils';
import { AuxiliaryItem } from '../../../types/itemTypes';
import DefaultError from '../../sharedComponents/DefaultError';
import GhostDetailCard from '../../sharedComponents/GhostDetailCard';
import Results from '../../sharedComponents/listComponents/Results';
import MapRenderProp, { ParkGeoDatum } from '../../sharedComponents/MapRenderProp';
import {parkNeutralSvg} from '../../sharedComponents/svgIcons';

const ParkSearchPage = () => {
  const getString = useFluent();

  const mapCenter = useMapCenter();
  const mapCenterPoint = point(mapCenter);
  const {loading, error, data} = useGeoBoxParks({limit: 30});

  let list: React.ReactElement<any> | null;
  if (loading === true && data === undefined) {
    const loadingCards: React.ReactElement<any>[] = [];
    for (let i = 0; i < 3; i++) {
      loadingCards.push(<GhostDetailCard key={i} />);
    }
    list = <>{loadingCards}</>;
  } else if (error !== undefined) {
    list = (
      <DefaultError
        error={error}
        offlineMessage={'Connect to the internet to search for parks & forests.'}
      />
    );
  } else if (data !== undefined) {
    if (!data.parks) {
      const loadingCards: React.ReactElement<any>[] = [];
      for (let i = 0; i < 3; i++) {
        loadingCards.push(<GhostDetailCard key={i} />);
      }
      list = <>{loadingCards}</>;
    } else {
      const parkMapPoints: ParkGeoDatum[] = [];
      const parks = data.parks.map(m => {
        let formattedType: string;
        if (m.protectTitle) {
          formattedType = m.protectTitle;
        } else if (m.landType) {
          formattedType = m.landType;
        } else if (m.ownership) {
          formattedType = upperFirst(m.ownership) + ' Park';
        } else {
          formattedType = 'Park';
        }
        if (!loading) {
          parkMapPoints.push({
            id: m.id,
            name: m.name,
            location: m.center,
            type: formattedType,
          });
        }
        return {
          id: m.id,
          title: m.name,
          location: m.center,
          locationText: m.locationText,
          type: AuxiliaryItem.park,
          url: parkDetailLink(m.id),
          icon: parkNeutralSvg,
          customIcon: true,
          distanceToCenter: distance(mapCenterPoint, point(m.center)),
          bbox: m.bbox,
          formattedType,
          numMountains: m.numMountains,
          trailMileage: m.trailMileage,
          numCampsites: Number(m.numCampsitesBackcountry) + Number(m.numCampsitesCar),
        };
      });
      const mapRenderProp = loading ? null : (
          <MapRenderProp
            id={'search-for-parks-forests'}
            parks={parkMapPoints}
          />
      );
      list = (
        <>
          <Results
            data={parks as any}
            type={AuxiliaryItem.park}
          />
          {mapRenderProp}
        </>
      );
    }
  } else {
    const loadingCards: React.ReactElement<any>[] = [];
    for (let i = 0; i < 3; i++) {
      loadingCards.push(<GhostDetailCard key={i} />);
    }
    list = <>{loadingCards}</>;
  }

  const metaDescription = getString('meta-data-park-search-description');

  return (
    <>
      <Helmet>
        <title>{getString('meta-data-park-search-default-title')}</title>
        <meta
          name='description'
          content={metaDescription}
        />
        <meta property='og:title' content={getString('meta-data-park-search-default-title')} />
        <meta
          property='og:description'
          content={metaDescription}
        />
        <link rel='canonical' href={process.env.REACT_APP_DOMAIN_NAME + parkDetailLink('search')} />
      </Helmet>
      {list}
    </>
  );
};

export default ParkSearchPage;
