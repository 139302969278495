import axios from 'axios';
import localforage from 'localforage';
import {createContext, useEffect, useState} from 'react';
import { User } from '../types/graphQLTypes';
import { useOfflineContext } from './OfflineContext';

export const UserContext = createContext<{
  _id: User['_id']
  name: User['name']
  friends: User['friends']
  profilePictureUrl: User['profilePictureUrl']
  permissions: User['permissions']
  mountainPermissions: User['mountainPermissions']
  campsitePermissions: User['campsitePermissions']
  peakListPermissions: User['peakListPermissions']
  viewpointPermissions: User['viewpointPermissions']
  waterpointPermissions: User['waterpointPermissions']
  googleId: User['googleId']
  facebookId: User['facebookId']
  redditId: User['redditId'],
} | null>(null);

export const useQueryLoggedInUser = () => {
  /**
   * `null` means the check for the current user hasn't happened yet\
   * `''` empty string means there is no logged in user\
   * `User` is the logged in user
   */
  const [user, setUser] = useState<User| null>(null);
  const {offline} = useOfflineContext();

  useEffect(() => {
    const fetchUser = async () => {
      if (!offline) {
        try {
          const res = await axios.get('/api/current_user');
          setUser(res.data);
          localforage.setItem('currentUser', res.data);
          if (res.data) {
            localforage.setItem('prevUser', res.data);
          }
        } catch (error) {
          console.error(error);
          const currentUser = await localforage.getItem<User | null>('currentUser');
          setUser(currentUser);
        }
      } else {
        try {
          const currentUser = await localforage.getItem<User | null>('currentUser');
          setUser(currentUser);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchUser();
  }, [offline]);

  return user;
};
