import { gql, useMutation } from '@apollo/client';
import {
  Viewpoint,
} from '../../types/graphQLTypes';

const FLAG_VIEWPOINT = gql`
  mutation($id: ID!, $flag: String) {
    viewpoint: updateViewpointFlag(id: $id, flag: $flag) {
      id
      flag
    }
  }
`;

interface FlagSuccessResponse {
  viewpoint: null | {
    id: Viewpoint['id'];
    flag: Viewpoint['flag'];
  };
}

interface FlagVariables {
  id: string;
  flag: string | null;
}

export const useUpdateViewpointFlag = () => {
  const [updateViewpointFlag] = useMutation<FlagSuccessResponse, FlagVariables>(FLAG_VIEWPOINT);
  return updateViewpointFlag;
};
