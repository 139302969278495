import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import useConstrainedMapBbox from '../../hooks/useConstrainedMapBbox';
import useMapCenter from '../../hooks/useMapCenter';
import {
  Coordinate,
  Mountain,
 } from '../../types/graphQLTypes';

const GEO_BOX_MOUNTAINS = gql`
  query GeoBoxMountains(
    $bottomLeftCoordinates: [Float]!,
    $topRightCoordinates: [Float]!,
    $limit: Int!,
  ) {
    mountains: geoBoxMountains(
      bottomLeftCoordinates: $bottomLeftCoordinates,
      topRightCoordinates: $topRightCoordinates,
      limit: $limit,
    ) {
      id
      name
      locationText
      elevation
      location
      trailAccessMiles
      roadAccessMiles
    }
  }
`;

export interface MountainDatum {
  id: Mountain['id'];
  name: Mountain['name'];
  locationText: Mountain['locationText'];
  elevation: Mountain['elevation'];
  location: Mountain['location'];
  trailAccessMiles: Mountain['trailAccessMiles'];
  roadAccessMiles: Mountain['roadAccessMiles'];
}

interface SuccessResponse {
  mountains: MountainDatum[];
}

interface Variables {
  bottomLeftCoordinates: Coordinate;
  topRightCoordinates: Coordinate;
  limit: number;
}

export const useGeoBoxMountains = ({limit}: {limit: number}) => {
  const {bbox, refreshBbox} = useConstrainedMapBbox();
  const mapCenter = useMapCenter();
  useEffect(() => {
    refreshBbox();
  }, [refreshBbox, mapCenter]);
  const {loading, error, data, previousData} = useQuery<SuccessResponse, Variables>(GEO_BOX_MOUNTAINS, {
    variables: {
      limit,
      bottomLeftCoordinates: bbox[0],
      topRightCoordinates: bbox[1],
    },
    // there is no need to cache this since the user will rarely have the exact same
    // bbox twice and it will otherise just fill up the cache
    fetchPolicy: 'no-cache',
  });

  let dataToUse: SuccessResponse | undefined;
  if (data !== undefined) {
    dataToUse = data;
  } else if (previousData !== undefined) {
    dataToUse = previousData;
  } else {
    dataToUse = undefined;
  }

  return {loading, error, data: dataToUse};
};
