import React from 'react';
import {useParams} from 'react-router-dom';
import ParkSearchPage from '../list';
import ParkDetail from './ParkDetail';

const ParkDetailPage = () => {
  const { id, parkId: possibleParkId }: any = useParams();
  const parkId = possibleParkId ? possibleParkId : id;

  if (parkId === 'search') {
    return <ParkSearchPage />;
  } else {
    return <ParkDetail id={parkId} />;
  }
};

export default ParkDetailPage;
