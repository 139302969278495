import React from 'react';
import { useOfflineContext } from '../../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useFluent from '../../../../hooks/useFluent';
import {
  useAddParkNote,
  useEditParkNote,
  useParkNote,
} from '../../../../queries/parks/parkNotes';
import { PlaceholderText } from '../../../../styling/styleUtils';
import UserNote from '../../UserNote';

interface Props {
  id: string;
  name: string;
}

const ParkNote = ({id, name}: Props) => {
  const getString = useFluent();
  const currentUser = useCurrentUser();
  const userId = currentUser ? currentUser._id : null;
  const {offline} = useOfflineContext();

  const {loading, error, data} = useParkNote({
    userId,
    parkId: id,
  });

  const addParkNote = useAddParkNote();
  const editParkNote = useEditParkNote();

  const parkNote = data && data.user && data.user.parkNote ? data.user.parkNote : null;
  const defaultNoteText = parkNote && parkNote.text ? parkNote.text : '';
  const notesPlaceholderText = getString('user-notes-placeholder', {name});

  const saveNote = (text: string) => {
    if (userId) {
      if (parkNote === null) {
        addParkNote({variables: {userId, parkId: id, text}});
      } else {
        editParkNote({variables: {userId, parkId: id, text}});
      }
    }
  };

  if (loading === true) {
    return null;
  } else if (data !== undefined || offline) {
    return (
      <UserNote
        placeholder={notesPlaceholderText}
        defaultValue={defaultNoteText}
        onSave={saveNote}
        key={defaultNoteText}
      />
    );
  } else if (error !== undefined) {
    console.error(error);
    return (
      <PlaceholderText>
        {getString('global-error-retrieving-data')}
      </PlaceholderText>
    );
  } else {
    return null;
  }
};

export default ParkNote;
