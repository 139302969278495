import {ApolloCache, DefaultContext, gql, MutationFunctionOptions, useApolloClient, useMutation} from '@apollo/client';
import { useCallback } from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  TripReport,
} from '../../types/graphQLTypes';
import { GET_PROGRESS, Progress, SuccessResponse, Variables } from '../users/useUsersProgress/query';

export interface AddTripReportVariables {
  date: TripReport['date'];
  author: string;
  mountains: string[];
  trails: string[];
  campsites: string[];
  users: string[];
  privacy: TripReport['privacy'];
  notes: TripReport['notes'];
  link: TripReport['link'];
  rating: TripReport['rating'];
  mudMinor: TripReport['mudMinor'];
  mudMajor: TripReport['mudMajor'];
  waterSlipperyRocks: TripReport['waterSlipperyRocks'];
  waterOnTrail: TripReport['waterOnTrail'];
  leavesSlippery: TripReport['leavesSlippery'];
  iceMinor: TripReport['iceMinor'];
  iceBlack: TripReport['iceBlack'];
  iceBlue: TripReport['iceBlue'];
  iceCrust: TripReport['iceCrust'];
  snowIceFrozenGranular: TripReport['snowIceFrozenGranular'];
  snowIceMonorailStable: TripReport['snowIceMonorailStable'];
  snowIceMonorailUnstable: TripReport['snowIceMonorailUnstable'];
  snowIcePostholes: TripReport['snowIcePostholes'];
  snowMinor: TripReport['snowMinor'];
  snowPackedPowder: TripReport['snowPackedPowder'];
  snowUnpackedPowder: TripReport['snowUnpackedPowder'];
  snowDrifts: TripReport['snowDrifts'];
  snowSticky: TripReport['snowSticky'];
  snowSlush: TripReport['snowSlush'];
  obstaclesBlowdown: TripReport['obstaclesBlowdown'];
  obstaclesErosion: TripReport['obstaclesErosion'];
  obstaclesRockslides: TripReport['obstaclesRockslides'];
  obstaclesOvergrown: TripReport['obstaclesOvergrown'];
  obstaclesOther: TripReport['obstaclesOther'];
}
export interface AddTripReportSuccess {
  tripReport: TripReport;
}

export interface EditTripReportVariables extends AddTripReportVariables {
  id: TripReport['id'];
}

const ADD_EDIT_TRIP_REPORT = gql`
  mutation addEditTripReport(
    $id: ID,
    $date: String,
    $author: ID!,
    $mountains: [ID],
    $trails: [ID],
    $campsites: [ID],
    $users: [ID],
    $privacy: String,
    $notes: String,
    $link: String,
    $rating: Float,
    $mudMinor: Boolean,
    $mudMajor: Boolean,
    $waterSlipperyRocks: Boolean,
    $waterOnTrail: Boolean,
    $leavesSlippery: Boolean,
    $iceMinor: Boolean,
    $iceBlack: Boolean,
    $iceBlue: Boolean,
    $iceCrust: Boolean,
    $snowIceFrozenGranular: Boolean,
    $snowIceMonorailStable: Boolean,
    $snowIceMonorailUnstable: Boolean,
    $snowIcePostholes: Boolean,
    $snowMinor: Boolean,
    $snowPackedPowder: Boolean,
    $snowUnpackedPowder: Boolean,
    $snowDrifts: Boolean,
    $snowSticky: Boolean,
    $snowSlush: Boolean,
    $obstaclesBlowdown: Boolean,
    $obstaclesErosion: Boolean,
    $obstaclesRockslides: Boolean,
    $obstaclesOvergrown: Boolean,
    $obstaclesOther: Boolean,
    $originalDate: String,
    $originalMountains: [ID],
    $originalTrails: [ID],
    $originalCampsites: [ID],
    $route: [[[Float]]],
  ) {
    tripReport: addEditTripReport(
      id: $id,
      date: $date,
      author: $author,
      mountains: $mountains,
      trails: $trails,
      campsites: $campsites,
      users: $users,
      privacy: $privacy,
      notes: $notes,
      link: $link,
      rating: $rating,
      mudMinor: $mudMinor,
      mudMajor: $mudMajor,
      waterSlipperyRocks: $waterSlipperyRocks,
      waterOnTrail: $waterOnTrail,
      leavesSlippery: $leavesSlippery,
      iceMinor: $iceMinor,
      iceBlack: $iceBlack,
      iceBlue: $iceBlue,
      iceCrust: $iceCrust,
      snowIceFrozenGranular: $snowIceFrozenGranular,
      snowIceMonorailStable: $snowIceMonorailStable,
      snowIceMonorailUnstable: $snowIceMonorailUnstable,
      snowIcePostholes: $snowIcePostholes,
      snowMinor: $snowMinor,
      snowPackedPowder: $snowPackedPowder,
      snowUnpackedPowder: $snowUnpackedPowder,
      snowDrifts: $snowDrifts,
      snowSticky: $snowSticky,
      snowSlush: $snowSlush,
      obstaclesBlowdown: $obstaclesBlowdown,
      obstaclesErosion: $obstaclesErosion,
      obstaclesRockslides: $obstaclesRockslides,
      obstaclesOvergrown: $obstaclesOvergrown,
      obstaclesOther: $obstaclesOther,
      originalDate: $originalDate,
      originalMountains: $originalMountains,
      originalTrails: $originalTrails,
      originalCampsites: $originalCampsites,
      route: $route,
    ) {
      id
    }
  }
`;

export interface AddEditTripReportVariables {
  id: TripReport['id'] | null;
  date: TripReport['date'] | null;
  author: string;
  mountains: string[];
  trails: string[];
  campsites: string[];
  users: string[];
  privacy: TripReport['privacy'];
  notes: TripReport['notes'];
  link: TripReport['link'];
  rating: TripReport['rating'];
  mudMinor: TripReport['mudMinor'];
  mudMajor: TripReport['mudMajor'];
  waterSlipperyRocks: TripReport['waterSlipperyRocks'];
  waterOnTrail: TripReport['waterOnTrail'];
  leavesSlippery: TripReport['leavesSlippery'];
  iceMinor: TripReport['iceMinor'];
  iceBlack: TripReport['iceBlack'];
  iceBlue: TripReport['iceBlue'];
  iceCrust: TripReport['iceCrust'];
  snowIceFrozenGranular: TripReport['snowIceFrozenGranular'];
  snowIceMonorailStable: TripReport['snowIceMonorailStable'];
  snowIceMonorailUnstable: TripReport['snowIceMonorailUnstable'];
  snowIcePostholes: TripReport['snowIcePostholes'];
  snowMinor: TripReport['snowMinor'];
  snowPackedPowder: TripReport['snowPackedPowder'];
  snowUnpackedPowder: TripReport['snowUnpackedPowder'];
  snowDrifts: TripReport['snowDrifts'];
  snowSticky: TripReport['snowSticky'];
  snowSlush: TripReport['snowSlush'];
  obstaclesBlowdown: TripReport['obstaclesBlowdown'];
  obstaclesErosion: TripReport['obstaclesErosion'];
  obstaclesRockslides: TripReport['obstaclesRockslides'];
  obstaclesOvergrown: TripReport['obstaclesOvergrown'];
  obstaclesOther: TripReport['obstaclesOther'];
  route: TripReport['route'];
  // additional inputs not directly in TripReport:
  originalDate: string | null;
  originalMountains: string[]; // includes both original mountains and new mountains
  originalTrails: string[]; // includes both original trails and new trails
  originalCampsites: string[]; // includes both original campsites and new campsites
}

const DELETE_TRIP_REPORT = gql`
  mutation deleteTripReport($id: ID!) {
    tripReport: deleteTripReport(id: $id) {
      id
    }
  }
`;

export interface DeleteTripReportVariables {
  id: TripReport['id'];
}

export const useTripReportMutations = () => {
  const currentUser = useCurrentUser();
  const client = useApolloClient();

  const [addEditTripReportMutation] =
    useMutation<AddTripReportSuccess, AddEditTripReportVariables>(ADD_EDIT_TRIP_REPORT);

  const [deleteTripReportMutation] =
    useMutation<AddTripReportSuccess, DeleteTripReportVariables>(DELETE_TRIP_REPORT);

  const addEditTripReport = useCallback(async (
    options?: MutationFunctionOptions<AddTripReportSuccess, AddEditTripReportVariables, DefaultContext, ApolloCache<any>> | undefined,
  ) => {
    return await addEditTripReportMutation({
      ...options,
      onCompleted(data) {
        if (options?.onCompleted) {
          options.onCompleted(data);
        }
        if (options?.variables) {
          const {variables} = options;
          const cachedResponse = client.readQuery<SuccessResponse, Variables>({
            query: GET_PROGRESS,
            variables: {
              userId: variables.author,
            },
          });
          if (cachedResponse?.progress) {
            const progress: Progress = {
                __typename: 'UserType',
                id: variables.author,
                mountains: structuredClone(cachedResponse.progress.mountains),
                trails: structuredClone(cachedResponse.progress.trails),
                campsites: structuredClone(cachedResponse.progress.campsites),
            };
            // filter out original mountains/campsites/trails for date
            variables.originalMountains.forEach(d => {
              const index = progress?.mountains?.findIndex(e => e.mountainId === d);
              if (index !== undefined && index !== -1) {
                progress?.mountains?.[index].dates.filter(date => date !== variables.originalDate);
              }
            });
            variables.originalCampsites.forEach(d => {
              const index = progress?.campsites?.findIndex(e => e.campsiteId === d);
              if (index !== undefined && index !== -1) {
                progress?.campsites?.[index].dates.filter(date => date !== variables.originalDate);
              }
            });
            variables.originalTrails.forEach(d => {
              const index = progress?.trails?.findIndex(e => e.trailId === d);
              if (index !== undefined && index !== -1) {
                progress?.trails?.[index].dates.filter(date => date !== variables.originalDate);
              }
            });
            // add new mountains/campsites/trails for date
            variables.mountains.forEach(d => {
              const index = progress?.mountains?.findIndex(e => e.mountainId === d);
              if (index !== undefined && index !== -1 && variables.date) {
                progress?.mountains?.[index].dates.push(variables.date);
              } else if (variables.date) {
                if (!progress?.mountains) {
                  progress.mountains = [];
                }
                progress?.mountains.push({
                  __typename: 'CompletedMountainsType',
                  mountainId: d,
                  dates: [variables.date],
                });
              }
            });
            variables.campsites.forEach(d => {
              const index = progress?.campsites?.findIndex(e => e.campsiteId === d);
              if (index !== undefined && index !== -1 && variables.date) {
                progress?.campsites?.[index].dates.push(variables.date);
              } else if (variables.date) {
                if (!progress?.campsites) {
                  progress.campsites = [];
                }
                progress?.campsites.push({
                  __typename: 'CompletedCampsitesType',
                  campsiteId: d,
                  dates: [variables.date],
                });
              }
            });
            variables.trails.forEach(d => {
              const index = progress?.trails?.findIndex(e => e.trailId === d);
              if (index !== undefined && index !== -1 && variables.date) {
                progress?.trails?.[index].dates.push(variables.date);
              } else if (variables.date) {
                if (!progress?.trails) {
                  progress.trails = [];
                }
                progress?.trails.push({
                  __typename: 'CompletedTrailsType',
                  trailId: d,
                  dates: [variables.date],
                });
              }
            });
            client.writeQuery<SuccessResponse, Variables>({
              query: GET_PROGRESS,
              variables: {
                userId: options.variables.author,
              },
              data: { progress },
            });
          }
        }
      },
    });
  }, [addEditTripReportMutation, client]);
  const deleteTripReport = useCallback(async (
    options?: MutationFunctionOptions<AddTripReportSuccess, DeleteTripReportVariables, DefaultContext, ApolloCache<any>> | undefined,
  ) => {
    return await deleteTripReportMutation({
      ...options,
      refetchQueries: [{
        query: GET_PROGRESS,
        variables: {userId: currentUser?._id},
      }],
      onCompleted(data) {
        if (options?.onCompleted) {
          options.onCompleted(data);
        }
        if (options?.variables?.id) {
          client.cache.evict({
            id: client.cache.identify({id: options.variables.id, __typename: 'TripReportType'}),
          });
          client.cache.gc();
        }
      },
    });
  }, [deleteTripReportMutation, client, currentUser]);
  return {
    addEditTripReport,
    deleteTripReport,
  };
};
