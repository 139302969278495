import React from 'react';
import { useOfflineContext } from '../../contextProviders/OfflineContext';
import useFluent from '../../hooks/useFluent';
import NetworkError from './NetworkError';
import OfflineError from './OfflineError';

interface Props {
  error: unknown;
  networkErrorMessage?: string;
  offlineMessage?: string;
}

const DefaultError = (props: Props) => {
  const {
    error, offlineMessage, networkErrorMessage,
  } = props;
  console.error(error);

  const {offline} = useOfflineContext();
  const getString = useFluent();

  return offline
    ? <OfflineError message={offlineMessage} />
    : <NetworkError message={networkErrorMessage || getString('global-error-retrieving-data', {offline: offline.toString()})} />;
};

export default DefaultError;
