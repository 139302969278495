import { faBell } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import styled, {keyframes} from 'styled-components/macro';
import { navHeight } from '../../../styling/Grid';
import {
  BasicIconInText,
  ButtonPrimary,
  CompactButtonPrimaryLink,
  GhostButton,
  lowWarningColorLight,
  primaryColor,
} from '../../../styling/styleUtils';
import bounceContent from '../../../styling/utils/bounceContent';
import {mobileSize} from '../../../Utils';
import LoadingSimple from '../../sharedComponents/LoadingSimple';

const slideDown = keyframes`
  0%   {
    opacity: 0;
    min-height: 0px;
  }
  100% {
    opacity: 1;
    min-height: 70px;
  }
`;

const Root = styled.div`
  overflow: hidden;
  background-color: #fff;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease;
  animation: ${slideDown} 0.5s ease-in-out forwards;
  z-index: 2000;
  position: fixed;
  right: 1rem;
  top: 78px;
  width: 100%;
  max-width: 280px;
  box-shadow: 0 1px 3px 1px #d1d1d1;
  border-radius: 8px;
  background-color: ${lowWarningColorLight};
  font-size: 0.9rem;

  @media (max-width: ${mobileSize}px) {
    top: ${navHeight + 5}px;
    left: 0.5rem;
    right: 0;
    padding: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  width: 100%;
`;

const buttonSize = '0.7rem';

const ConfirmButton = styled(ButtonPrimary)`
  margin: 0 1rem;
  font-size: ${buttonSize};
`;

const TripReportButton = styled(CompactButtonPrimaryLink)`
  margin-right: 1rem;
  font-size: ${buttonSize};
  white-space: nowrap;
`;

const DismissButton = styled(GhostButton)`
  font-size: ${buttonSize};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(BasicIconInText)`
  color: ${primaryColor};
`;

interface Props {
  children: React.ReactNode;
  onConfirm: () => void;
  tripReportUrl?: string;
  onDismiss: () => void;
  confirmText: string;
  dismissText: string;
  tripReportText?: string;
  tripReportOnly?: boolean;
}

const Notification = (props: Props) => {
  const {
    children, confirmText, dismissText, tripReportUrl, tripReportText,
    tripReportOnly,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const onConfirm = () => {
    setLoading(true);
    props.onConfirm();
  };

  const onDismiss = () => {
    setLoading(true);
    props.onDismiss();
  };

  const tripReportButton = tripReportUrl && tripReportText ? (
    <TripReportButton
      to={tripReportUrl}
      onClick={bounceContent}
    >
      {tripReportText}
    </TripReportButton>
  ) : null;

  const onConfirmButton = tripReportOnly ? null : (
    <ConfirmButton onClick={onConfirm}>
      {confirmText}
    </ConfirmButton>
  );

  const buttons = loading ? (
    <LoadingSimple />
  ) : (
    <>
      {onConfirmButton}
      {tripReportButton}
      <DismissButton onClick={onDismiss}>
        {dismissText}
      </DismissButton>
    </>
  );

  return (
    <Root>
      <Content>
        <Icon icon={faBell} />
        <div>
          {children}
        </div>
      </Content>
      <ButtonContainer>
        {buttons}
      </ButtonContainer>
    </Root>
  );
};

export default Notification;
