import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { useOfflineContext } from '../../../contextProviders/OfflineContext';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { GET_PROGRESS, SuccessResponse, Variables } from './query';

const useUsersProgress = (profileId?: string | null) => {
  const user = useCurrentUser();
  const { offline } = useOfflineContext();
  const fetchPolicy: WatchQueryFetchPolicy = offline ? 'cache-only' : 'cache-first';
  let userId = user && user._id ? user._id : null;
  if (profileId) {
    userId = profileId;
  }
  const response = useQuery<SuccessResponse, Variables>(GET_PROGRESS, {
    variables: {
      userId,
    },
    fetchPolicy,
  });
  return response;
};

export default useUsersProgress;
