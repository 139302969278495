const {point} = require('@turf/helpers');
import { getGlobalMap } from '../map';
import { buildNetwork } from '../map/interactions/routePlanning/routeWorker';
import { userPointLayerId } from '../map/layers';

let isBeingTracked: boolean = false;
let USERS_LOCATION: GeolocationPosition | undefined;
export const getCachedUsersLocation = () => USERS_LOCATION;
export const setCachedUsersLocation = (position: GeolocationPosition) => USERS_LOCATION = position;

const trackUserLocalstorageKey = 'trackUserCountLocalstorageKey';
export const hasUserAllowedTracking = () => {
  const count = Number(localStorage.getItem(trackUserLocalstorageKey));
  return !isNaN(count) && count >= 3;
};

const trackUser = () => {
  if (!isBeingTracked) {
    const map = getGlobalMap();
    if (map) {
      if (navigator.geolocation) {
        const count = Number(localStorage.getItem(trackUserLocalstorageKey));
        if (!isNaN(count) && count > 0) {
          localStorage.setItem(trackUserLocalstorageKey, (count + 1).toString());
        } else {
          localStorage.setItem(trackUserLocalstorageKey, '1');
        }
        navigator.geolocation.watchPosition(position => {
          if (isBeingTracked === false) {
            buildNetwork([position.coords.longitude, position.coords.latitude]);
          }
          isBeingTracked = true;
          setCachedUsersLocation(position);
          const sourceLayer = map.getSource(userPointLayerId) as any;
          if (sourceLayer) {
            const userPoint = point([
              position.coords.longitude, position.coords.latitude,
            ], {
              rotation: position.coords.heading ? position.coords.heading : 0,
            });
            sourceLayer.setData(userPoint);
          }
        }, error => {
          console.error(error);
        }, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 5000,
        });
      }

    }
  }
};

export default trackUser;
