import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const legendRootId = 'list-legend-portal-root-id';

export const ListLegendRoot = () => {
  return (
    <div id={legendRootId} />
  );
};

interface Props {
  children: React.ReactNode;
}

export const ListLegendPortal = ({children}: Props) => {
  const legendRootNode = useRef<HTMLElement | null>(null);
  const [isLegendRootRendered, setIsLegendRootRendered] = useState<boolean>(false);
  useEffect(() => {
    const node = document.querySelector<HTMLElement>(`#${legendRootId}`);
    if (node !== null) {
      legendRootNode.current = node;
      setIsLegendRootRendered(true);
    }
  }, []);

  let legend: React.ReactElement<any> | null;
  if (isLegendRootRendered === true && legendRootNode.current !== null) {
    legend = createPortal((
      <React.Fragment>{children}</React.Fragment>
    ), legendRootNode.current);
  } else {
    legend = null;
  }

  return legend;
};
