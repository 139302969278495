import raw from 'raw.macro';
import React from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../../../hooks/useFluent';
import { golden, lightBorderColor, Subtext } from '../../../../styling/styleUtils';

const STARS_SVG_STRING = raw('../../../../assets/images/five-stars.svg');

const Root = styled.div`
  position: absolute;
  right: 1rem;
  top: -1.25rem;
  display: flex;
  border-bottom: solid 1px ${lightBorderColor};
`;

const STARS_WIDTH = 5; // in rem

const StarsRoot = styled.div`
  position: relative;
  width: ${STARS_WIDTH}rem;
  margin-right: 0.5rem;

  svg {
    width: ${STARS_WIDTH}rem;
  }
`;

const StarsBack = styled.div`
  width: ${STARS_WIDTH}rem;

  svg {
    fill: ${lightBorderColor};
  }
`;

const StarsFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  svg {
    fill: ${golden};
  }
`;

interface Props {
  totalRatingScore: number;
  totalRatingCount: number;
}

const AverageRating = ({totalRatingCount, totalRatingScore}: Props) => {
  const getString = useFluent();
  return (
    <Root>
      <StarsRoot>
        <StarsBack dangerouslySetInnerHTML={{__html: STARS_SVG_STRING}} />
        <StarsFront
          dangerouslySetInnerHTML={{__html: STARS_SVG_STRING}}
          style={{width: (totalRatingScore / totalRatingCount / 5) * 100 + '%'}}
        />
      </StarsRoot>
      <Subtext>
        <small>
          <em>
            {(totalRatingScore / totalRatingCount).toFixed(1)} (
              {totalRatingCount} {getString('global-text-value-reviews', {count: totalRatingCount}).toLowerCase()}
            )
          </em>
        </small>
      </Subtext>
    </Root>
  );
};

export default AverageRating;
