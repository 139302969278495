import React, { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  NoResultsHelp,
  NoResultsRoot,
  NoResultsTitle,
} from '../../styling/styleUtils';
import { mobileSize } from '../../Utils';
import { StackedLoginButtons } from './SignUpModal';

const Image = styled.img`
  width: 100%;
  max-width: 300px;
  margin-bottom: 1rem;

  @media(max-width: ${mobileSize}px) {
    max-width: 200px;
    margin-top: -2rem;
  }
`;

const LoginButtonContainer = styled.div`
  margin-top: 1rem;
`;

const Content = styled(NoResultsHelp)`
  max-width: 250px;
`;

interface Props {
  title: string;
  image: string;
}

const EmptyState = ({title, children, image}: PropsWithChildren<Props>) => {
  const currentUser = useCurrentUser();
  const loginButton = !currentUser && (
    <LoginButtonContainer>
      <StackedLoginButtons />
    </LoginButtonContainer>
  );
  return (
    <NoResultsRoot>
      <Image src={image} alt={title} />
      <NoResultsTitle>
        {title}
      </NoResultsTitle>
      <Content>
        {children}
      </Content>
      {loginButton}
    </NoResultsRoot>
  );
};

export default EmptyState;
