
import { gql } from '@apollo/client';
import {
  User,
  Viewpoint,
} from '../../../types/graphQLTypes';

export const GET_VIEWPOINT_DETAIL = gql`
  query getViewpoint($id: ID) {
    viewpoint(id: $id) {
      id
      name
      elevation
      location
      locationText
      locationTextShort
      trailAccessMiles
      roadAccessMiles
      author {
        id
      }
      status
    }
  }
`;

export interface QuerySuccessResponse {
  viewpoint: null | {
    id: Viewpoint['id'];
    __typename: string;
    name: Viewpoint['name'];
    elevation: Viewpoint['elevation'];
    location: Viewpoint['location'];
    locationText: Viewpoint['locationText'];
    locationTextShort: Viewpoint['locationTextShort'];
    trailAccessMiles: Viewpoint['trailAccessMiles'];
    roadAccessMiles: Viewpoint['roadAccessMiles'];
    author: null | { id: User['id'] };
    status: Viewpoint['status'];
  };
}

export interface QueryVariables {
  id: string | null;
}
