import React from 'react';
import styled from 'styled-components/macro';
import {AggregateItem, AuxiliaryItem, CoreItem} from '../../../../types/itemTypes';
import { failIfValidOrNonExhaustive } from '../../../../Utils';
import CampsiteNote from './CampsiteNote';
import MountainNote from './MountainNote';
import ParkNote from './ParkNote';
import PeakListNote from './PeakListNote';
import TrailNote from './TrailNote';
import ViewpointNote from './ViewpointNote';
import WaterpointNote from './WaterpointNote';

const Root = styled.div`
  padding: 0.875rem 0;
`;

interface Props {
  id: string;
  name: string;
  type: CoreItem | AggregateItem | AuxiliaryItem;
}

const UsersNotes = (props: Props) => {
  const {id, name, type} = props;

  let output: React.ReactElement<any> | null;
  if (type === CoreItem.mountain) {
    output = <MountainNote key={id} id={id} name={name} />;
  } else if (type === CoreItem.trail || type === AuxiliaryItem.trailhead || type === AggregateItem.parentTrail) {
    output = <TrailNote key={id} id={id} name={name} />;
  } else if (type === CoreItem.campsite) {
    output = <CampsiteNote key={id} id={id} name={name} />;
  } else if (type === AggregateItem.list) {
    output = <PeakListNote key={id} id={id} name={name} />;
  } else if (type === AuxiliaryItem.viewpoint) {
    output = <ViewpointNote key={id} id={id} name={name} />;
  } else if (type === AuxiliaryItem.waterpoint) {
    output = <WaterpointNote key={id} id={id} name={name} />;
  } else if (type === AuxiliaryItem.park) {
    output = <ParkNote key={id} id={id} name={name} />;
  } else {
    output = null;
    failIfValidOrNonExhaustive(type, 'Invalind value for type ' + type);
  }
  return (
    <Root>
      {output}
    </Root>
  );
};

export default UsersNotes;
