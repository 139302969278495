import { gql } from '@apollo/client';
import {
  User,
  Viewpoint,
} from '../../../types/graphQLTypes';

export const GET_USERS_SAVED_VIEWPOINTS = gql`
  query GetSavedViewpoints($userId: ID) {
    user(id: $userId) {
      id
      savedViewpoints {
        id
        name
        elevation
        location
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export const SAVE_VIEWPOINT_TO_USER = gql`
  mutation SaveViewpointToUser($userId: ID!, $viewpointId: ID!) {
    user: saveViewpointToUser(userId: $userId, viewpointId: $viewpointId) {
      id
      savedViewpoints {
        id
        name
        elevation
        location
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export const REMOVED_SAVED_VIEWPOINT_FROM_USER = gql`
  mutation RemoveSaveViewpointToUser($userId: ID!, $viewpointId: ID!) {
    user: removeSavedViewpointFromUser(userId: $userId, viewpointId: $viewpointId) {
      id
      savedViewpoints {
        id
        name
        elevation
        location
        locationText
        locationTextShort
        userNote
      }
    }
  }
`;

export interface Variables {
  userId: string | null;
}

export interface MutationVariables {
  userId: string;
  viewpointId: string;
}

export interface ViewpointDatum {
  id: Viewpoint['id'];
  __typename: string;
  name: Viewpoint['name'];
  elevation: Viewpoint['elevation'];
  location: Viewpoint['location'];
  locationText: Viewpoint['locationText'];
  locationTextShort: Viewpoint['locationTextShort'];
  userNote: Viewpoint['userNote'];
}

export interface SuccessResponse {
  user: null | {
    id: User['id'];
    __typename: string;
    savedViewpoints: (null | ViewpointDatum)[];
  };
}
