import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import { useCallback } from 'react';
import useFluent from '../../../../../hooks/useFluent';
import useMapContext from '../../../../../hooks/useMapContext';
import { MapStyle } from '../../map';
import { AccessOverlay, getAccessState } from '../../map/access';
import { getWeatherState, WeatherOverlay, WeatherState } from '../../map/weather';
import { LayerButton } from '../Utils';
import LayersModal from './LayersModal';
import WeatherLegendWidget from './WeatherLegendWidget';

interface Props {
  mapStyle: MapStyle;
  setMapStyle: (style: MapStyle) => void;
}

const Layers = (props: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [mapWeather, setMapWeather] = useState<WeatherState | null>(getWeatherState());
  const [access, setMapAccess] = useState<AccessOverlay | null>(getAccessState());
  const mapContext = useMapContext();
  const getString = useFluent();

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const setWeather = async (value: WeatherOverlay | null) => {
    if (mapContext.intialized) {
      const newWeatherState = await mapContext.setWeatherOverlay(value);
      setMapWeather(newWeatherState);
    }
  };
  const setAccess = (value: AccessOverlay | null) => {
    if (mapContext.intialized) {
      const newAccessState = mapContext.setAccessOverlay(value);
      setMapAccess(newAccessState);
    }
  };

  const modal = modalOpen ? (
    <LayersModal
      onClose={closeModal}
      mapStyle={props.mapStyle}
      setMapStyle={props.setMapStyle}
      mapWeather={mapWeather}
      setWeather={setWeather}
      access={access}
      setAccess={setAccess}
    />
  ) : null;

  return (
    <>
      <LayerButton onClick={openModal}>
        <span>{getString('global-map-layers')}</span>
        <FontAwesomeIcon icon={faLayerGroup} />
      </LayerButton>
      <WeatherLegendWidget
        mapWeather={mapWeather}
        setMapWeather={setMapWeather}
      />
      {modal}
    </>
  );
};

export default Layers;
