import upperFirst from 'lodash/upperFirst';
import React from 'react';
import styled from 'styled-components/macro';
import useFluent from '../../../hooks/useFluent';
import { DateDatum } from '../../../queries/users/useAllTripReports/query';
import { DateType, formatStringDate, getDateType, parseDate } from '../../../utilities/dateUtils';
import ReportBlock from './ReportBlock';

const Title = styled.h2`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 0.85rem;
`;

const DateSegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type Props = DateDatum;

const DateSegment = ({date, tripReports}: Props) => {
  const getString = useFluent();
  const reports = tripReports.map((report) => {
    return (
      <ReportBlock
        key={date + report.id}
        tripReport={report}
      />
    );
  });
  const dateObject = parseDate(date);
  const { day, month, year } = dateObject;
  const dateType = getDateType(dateObject);
  let titleDate: string;
  if (dateType === DateType.full) {
    titleDate = getString('global-formatted-text-date', { day, month, year: year.toString() });
  } else if (dateType === DateType.monthYear) {
    titleDate = getString('global-formatted-text-month-year', { month, year: year.toString() });
  } else if (dateType === DateType.yearOnly) {
    titleDate = dateObject.year.toString();
  } else {
    titleDate = upperFirst(formatStringDate(date));
  }
  return (
    <>
      <Title>{titleDate}</Title>
      <DateSegmentWrapper>
        {reports}
      </DateSegmentWrapper>
    </>
  );
};

export default DateSegment;
